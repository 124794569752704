import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function ClientDropdown(props) {

    const { clients, activeClient, handleSetClient } = props;
    // const personal = {
    //     "id": session ? session.user.id : 'personal',
    //     "name": "Personal"
    // }
    let name = 'None';
    if (activeClient) {
        name = activeClient.name.length > 17 ? `${activeClient.name.slice(0, 17)}..` : activeClient.name;
    }
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetClientFirst = (newClient) => {
        setAnchorEl(null);
        handleSetClient(newClient);
    }

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    
    return (
        <div>
            <div className='flex justify-center items-center'>
                <p>Client:</p>
                <p
                    id="demo-positioned-button"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={`${isMobile ? 'px-1.5' : 'px-2.5'} py-1.5 ml-1 text-base rounded-xl hover:bg-light-gray-2 cursor-pointer text-black font-semibold`}
                >
                    <span>
                        {name}
                        <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 text-dark-gray" />
                    </span>
                </p>
            </div>
            <Menu
                id="demo-positioned-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                onBlur={() => handleClose()}
                className='mt-1.5 ml-1.5'
            >
                {/* <MenuItem key='personal' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => handleSetClientFirst(personal)}>Personal</MenuItem> */}
                {clients.map(function (client) {
                    let name = '';
                    if (client.hasOwnProperty('name') && client.name !== null) {
                        name = client.name.length > 23 ? `${client.name.slice(0, 23)}..` : client.name;
                        if (activeClient && client.id === activeClient.id) {
                            name = client.name.length > 20 ? `${client.name.slice(0, 20)}..` : client.name;
                        }
                    }
                    return (
                        <MenuItem
                            key={client.id}
                            className='px-4'
                            onClick={() => handleSetClientFirst(client)}
                            sx={{ fontSize: isMobile ? '15px' : '17px' }}
                        >
                            {name}
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    )
}
