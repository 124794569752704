import React from 'react';
import PropertyDetail from './PropertyDetail';

export default function PropertyDetails(props) {
    let { property, livingArea, pricePerSqFt } = props;
    let homeStatus = property.homeStatus;

    if (homeStatus === "FOR_SALE") {
        homeStatus = "For Sale";
    } else if (homeStatus === "SOLD") {
        homeStatus = "Sold";
    } else if (homeStatus === "OTHER") {
        homeStatus = "Other";
    } else if (homeStatus === "RECENTLY_SOLD") {
        homeStatus = "Recently Sold";
    } else if (homeStatus === "PENDING") {
        homeStatus = "Pending";
    } else if (homeStatus === "FOR_RENT") {
        homeStatus = "For Rent";
    }

    // to local string
    livingArea = (livingArea !== null) ? ( livingArea.toLocaleString(undefined, { maximumFractionDigits: 2 })) : ('');
    let daysOnZillow = (property.daysOnZillow !== null) ? ( property.daysOnZillow.toLocaleString(undefined, { maximumFractionDigits: 2 })) : ('');
    let lotAreaValue = (property.lotAreaValue !== null) ? ( property.lotAreaValue.toLocaleString(undefined, { maximumFractionDigits: 2 })) : ('');
    let purchasePrice = (property.price !== null) ? ( `$${property.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}` ) : ('');
    let lotAreaUnits = (property.lotAreaUnits !== null) ? ( property.lotAreaUnits.toLowerCase()) : ('');

    const convertDate = (date) => {
        let newDate = '';
        if (date !== undefined && date !== null && date !== '') {
            newDate = new Date(`${date}`).toString().split(" ");
            newDate = `${newDate[1]} ${newDate[2]}, ${newDate[3]}`;
        }
        return newDate
    }

    // dates
    let datePosted = convertDate(property.hasOwnProperty('datePostedString') ? property.datePostedString : '');
    let dateSold = convertDate(property.hasOwnProperty('dateSoldString') ? property.dateSoldString : '');
    let datePriceChange = convertDate(property.hasOwnProperty('priceChangeDate') ? property.priceChangeDate : '');

    return (
        <div className='w-100 mb-3'>
            <p className='flex text-lg font-bold mt-4'>Features</p>
            <div className='lg:flex lg:gap-1'>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Listing price" value={purchasePrice} />
                    <PropertyDetail label="Bedrooms" value={property.bedrooms} />
                    <PropertyDetail label="Bathrooms" value={property.bathrooms} />
                    <PropertyDetail label="Square feet" value={livingArea} />
                    <PropertyDetail label="Lot area" value={lotAreaValue} suffix={lotAreaUnits} />
                    <PropertyDetail label="Date posted" value={datePosted} />
                </div>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Price/sq ft" value={pricePerSqFt} currency={true} />
                    <PropertyDetail label="Type" value={property.propertyTypeDimension} />
                    <PropertyDetail label="Year built" value={property.yearBuilt} />
                    <PropertyDetail label="Status" value={homeStatus} />
                    <PropertyDetail label="Days on market" value={daysOnZillow} />
                </div>
            </div>
            <p className={`flex text-lg font-bold mt-4 ${(property.zoning === null && property.zoningDescription === null) ? 'hidden' : ''}`}>
                Other Facts
            </p>
            <div className='lg:flex lg:gap-1'>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Tax assessed value" value={property.taxAssessedValue} currency={true} />
                    <PropertyDetail label="Date last sold" value={dateSold} />
                    <PropertyDetail label="Basement" value={property.basement} />
                </div>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Price change" value={property.priceChange} currency={true} />
                    <PropertyDetail label="Date price change" value={datePriceChange} />
                </div>
            </div>
            <p className={`flex text-lg font-bold mt-4 ${(property.zoning === null && property.zoningDescription === null) ? 'hidden' : ''}`}>
                Zoning
            </p>
            <div className='lg:flex'>
                <div className={(property.zoningDescription === null) ? 'hidden' : 'text-start'} style={{flex: '50%'}}>
                    <PropertyDetail label="Zoning description" value={property.zoningDescription} />
                </div>
                <div className={(property.zoning === null) ? 'hidden' : 'text-start'} style={{flex: '40%'}}>
                    <PropertyDetail label="Zoning" value={property.zoning} />
                </div>
            </div>
            <p className={`flex text-lg font-bold mt-4 ${(property.neighborhood === null && property.community === null && property.subdivision === null) ? 'hidden' : ''}`}>
                Neighborhood
            </p>
            <div className='flex'>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Neighborhood" value={property.neighborhood} />
                    <PropertyDetail label="Community" value={property.community} />
                </div>
                <div className='text-start' style={{flex: '50%'}}>
                    <PropertyDetail label="Subdivision" value={property.subdivision} />
                </div>
            </div>
        </div>
    )
}
