import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Home from './containers/Home';
import LearnPage from './containers/Learn';
import Article from './components/Article';
import Success from './containers/Success';
import DealCalculator from './containers/DealCalculator';
import LandingPage from './containers/LandingPage';
import AgentLandingPage from './containers/AgentLandingPage';
import CrunchLandingPage from './containers/CrunchLandingPage';
import Dashboard from './containers/Dashboard';
import Checkout from './containers/Checkout';
import ChatGPT from './components/ChatGPT';


function Routes() {
    return (
        <Switch>
            <Route
                exact path="/"
                component={window.location.href.includes('/investorbot.us')
                    ? LandingPage
                    : AgentLandingPage
                }
            ></Route>
            <Route exact path="/crunchlandingpage" component={CrunchLandingPage}></Route>
            <Route exact path="/search" component={Home}></Route>
            <Route exact path="/dashboard" component={Dashboard}></Route>
            <Route exact path="/learn" component={LearnPage}></Route>
            <Route exact path="/deal-calculator" component={DealCalculator}></Route>
            <Route exact path="/learn/:id" component={Article}></Route>
            <Route exact path="/success" component={Success}></Route>
            <Route exact path="/checkout-test-page" component={Checkout}></Route>
            <Route exact path="/chat-gpt" component={ChatGPT}></Route>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}

export default Routes;