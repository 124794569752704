import React, { useEffect, useState } from 'react';
import { Card, Form, Modal } from 'react-bootstrap';
import { Skeleton } from '@mui/material';
import { faCheckCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabase';
import logo_icon from '../assets/logo_icon.png'
import no_homes from '../assets/no_homes.png';

export default function SavedSearchesModal(props) {
    const { session } = props;
    const [ openEditSavedSearch, setOpenEditSavedSearch ] = useState(false);
    const [ savedSearches, setSavedSearches ] = useState([]);
    const [ loadingSavedSearches, setLoadingSavedSearches ] = useState(false);
    const [ searchId, setSearchId ] = useState(null);
    const [ searchName, setSearchName ] = useState(null);
    const [ emailFrequency, setEmailFrequency ] = useState(null);

    useEffect(() => {
        const getSavedSearches = async () => {
            // open modal
            setLoadingSavedSearches(true);
            // get data
            try {
                let { data, error, status } = await supabase
                    .from('emailAlerts')
                    .select('id, saved_search_name, email_frequency, saved_search')
                    .eq('user_id', session.user.id);
            
                if (error && status !== 406) {
                    throw error
                }
                
                if (data) {
                    setSavedSearches(data);
                    setLoadingSavedSearches(false);
                }
        
            } catch (error) {
                console.log(error.message);
            }
        }
        getSavedSearches()
        // eslint-disable-next-line
    }, [])

    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-bold'>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
    
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const updateSaveSearch = async () => {
        if (!session) {
            notifyErrorMsg("You must be signed in to save a search!");
        } else {
            // save search in supabase
            try {
                const updates = {
                    id: searchId,
                    created_at: new Date(),
                    user_id: session.user.id,
                    saved_search_name: searchName,
                    email_frequency: emailFrequency
                }
    
                let { error } = await supabase.from('emailAlerts').upsert(updates)
                if (error) {
                    throw error
                } else {
                    // filter out the saved search & reset the data
                    let newSavedSearches = savedSearches;
                    newSavedSearches.forEach(search => {
                        if (search.id === searchId) {
                            search.saved_search_name = searchName;
                            search.email_frequency = emailFrequency;
                        }
                    })
                    setSavedSearches(newSavedSearches);
                    setOpenEditSavedSearch(false);
                }
                notifySuccessMsg("Successfully updated search.")
            } catch (error) {
                console.error(error.message)
            }
        }
    }

    const filterDescription = (savedSearch) => {
        let filterDescription = ''
        
        if (savedSearch.priceFilter === 'Any'
            && savedSearch.bedBathFilter === 'Any'
            && savedSearch.propertyTypeFilter === 'Any'
            && savedSearch.hoaFilter === 'Any'
            && savedSearch.keywordsFilter === 'Any'
            && savedSearch.daysOnMarketFilter === 'Any'
            && savedSearch.ageOfPropertyFilter === 'Any'
        ) {
            filterDescription = ' None';
        } else {
            if (savedSearch.priceFilter !== 'Any') {
                filterDescription += ` Price: $${savedSearch.priceFilter[0].toLocaleString(undefined, { maximumFractionDigits: 0 })} - $${savedSearch.priceFilter[1].toLocaleString(undefined, { maximumFractionDigits: 0 })};`   
            }
            if (savedSearch.bedBathFilter !== 'Any') {
                filterDescription += ` Beds: ${savedSearch.bedBathFilter[0]}, Baths: ${savedSearch.bedBathFilter[1]};`   
            }
            if (savedSearch.propertyTypeFilter !== 'Any') {
                filterDescription += ` Type: ${String(savedSearch.propertyTypeFilter).replace(",", ", ")};`   
            }
            if (savedSearch.hoaFilter !== 'Any') {
                filterDescription += ` Max HOA: ${savedSearch.hoaFilter};`   
            }
            if (savedSearch.keywordsFilter !== 'Any') {
                filterDescription += ` Keywords: "${savedSearch.keywordsFilter}";`   
            }
            if (savedSearch.daysOnMarketFilter !== 'Any') {
                filterDescription += ` On market: ${savedSearch.daysOnMarketFilter[0]}-${savedSearch.daysOnMarketFilter[1]} days;`   
            }
            if (savedSearch.ageOfPropertyFilter !== 'Any') {
                filterDescription += ` Age of property: ${savedSearch.ageOfPropertyFilter[0]}-${savedSearch.ageOfPropertyFilter[1]} years;`
            }
        }
        
        return filterDescription
    }
   
    const deleteSaveSearch = async (e, search) => {
        if (!session) {
            notifyErrorMsg("You must be signed in to delete a search!");
        } else {
            // save search in supabase
            try {
                const { error } = await supabase
                    .from('emailAlerts')
                    .delete()
                    .eq('id', search.id)

                if (error) {
                    throw error
                } else {
                    // filter out the saved search & reset the data
                    let newSavedSearch = savedSearches.filter(data => data.id !== search.id);
                    setSavedSearches(newSavedSearch);
                }
                notifySuccessMsg("Successfully deleted search.")
            } catch (error) {
                console.error(error.message)
            }
        }
    }

    const handleEditSearch = (e, search) => {
        setSearchId(search.id);
        setSearchName(search.saved_search_name);
        setEmailFrequency(search.email_frequency);
        setOpenEditSavedSearch(true);
    }

    return (
        <>
            {/* Saved searches modal */}
            <Modal size={'md'} centered={true} show={openEditSavedSearch} onHide={() => setOpenEditSavedSearch(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col justify-start gap-5 lg:mx-5 mx-3 mb-2'>
                        <p className='font-bold text-center text-3xl tracking-tight'>Edit saved search</p>
                        <div className="mt-1">
                            <p className='font-semibold mb-1'>Search name:</p>

                            <Form.Control
                                type="text"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                        </div>
                        <div className='flex justify-start gap-3 mt-2'>
                            <p className='font-semibold'>Email me:</p>
                            <select
                                id="searchName"
                                name="searchName"
                                value={emailFrequency}
                                onChange={(e) => setEmailFrequency(e.target.value)}
                                style={{borderWidth: '1px'}}
                                className='w-32 py-1 pl-2 rounded-md border-light-gray-4 -mt-1 cursor-pointer font-normal'
                            >
                                <option value="Instantly" className='py-3 cursor-pointer hover:bg-gray'>Instantly</option>
                                <option value="Daily" className='py-3 cursor-pointer hover:bg-gray'>Daily</option>
                                <option value="Never" className='py-3 cursor-pointer hover:bg-gray'>Never</option>
                            </select>
                        </div>
                        <p
                            onClick={() => updateSaveSearch()}
                            className="text-xl font-bold mt-2 cursor-pointer rounded-md text-white text-center py-2.5 bg-bot-blue hover:bg-dark-bot-blue"
                        >
                            Update
                        </p>
                        <p className='flex justify-center text-sm -mt-2 text-gray italic'>To edit the filters, delete this search and save a new one.</p>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={isMobile ? 'px-0' : 'px-2'} style={{height: isMobile ? '' : '80vh'}}>
                <p className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold tracking-tighter mb-4 ml-3`}>My Saved Searches</p>
                <div className={`overflow-scroll pt-2 pb-3 ${isMobile ? 'px-1' : 'px-3'}`} style={{height: '73vh'}}>
                    {loadingSavedSearches ? (
                        <Skeleton
                            width={'100%'}
                            height={93}
                            animation="wave"
                            variant="rectangular"
                            className='rounded'
                        />
                    ) : (
                        savedSearches.length === 0 ? (
                            <div className='flex flex-col justify-center items-center'>
                                <Card
                                    className="lg:flex m-1 pb-2 drop-shadow-md shadow-gray rounded-xl"
                                >
                                    <div className='h-48 rounded-xl w-auto' style={{
                                            backgroundImage: `url(${no_homes})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover'
                                        }}
                                    />
                                    <div className='my-3 mx-2'>
                                        <p className='flex justify-center text-center text-xl font-bold'>No saved searches.</p>
                                        <p className='flex justify-center text-center mt-2'>Click the "Save Search" button on the map to get email alerts when the top five properties on a search change!</p>
                                    </div>
                                </Card>
                            </div>
                        ) : (
                            savedSearches.map(function (search, index) {
                                return (
                                    <div>
                                        {search.saved_search === null ? (
                                            <div className='hidden'></div>
                                        ) : (
                                            <div
                                                className={`text-lg gap-4 bg-light-gray rounded-md shadow-around-div ${isMobile ? 'px-2.5 pt-2 pb-1' : 'px-3 py-3'} ${index === 0 ? 'mt-0' : 'mt-4'}`}
                                            >
                                                <div className='lg:flex lg:justify-between lg:items-center'>
                                                    <div>
                                                        <p className={`font-semibold text-bot-blue ${isMobile ? 'text-lg ml-1.5' : 'text-xl'}`}>{search.saved_search_name}</p>
                                                        <p className={`flex justify-start lg:text-lg text-base gap-3 pt-1.5`}>
                                                            <FontAwesomeIcon icon={faCheckCircle} className='lg:text-xl ml-2 font-bold' style={{marginTop: '5px'}} />
                                                            Email {search.email_frequency.toLowerCase()}
                                                        </p>
                                                    </div>
                                                    <p className={isMobile ? 'text-sm text-gray mt-2.5 ml-1.5' : 'hidden'}>
                                                        Filters: {filterDescription(search.saved_search)}
                                                    </p>
                                                    <div className={`flex justify-between gap-3 ${isMobile ? 'mt-2' : 'mt-6'}`}>
                                                        <p className='hidden'>{search.id}</p>
                                                        <p
                                                            className={`py-2 px-3 rounded-md hover:bg-light-gray-2 cursor-pointer ${isMobile ? 'text-sm' : ''}`}
                                                            onClick={(e) => handleEditSearch(e, search)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} className='lg:text-xl text-base font-bold mr-2 mt-1' />
                                                            Edit
                                                        </p>
                                                        <p
                                                            className={`py-2 px-3 rounded-md hover:bg-light-gray-2 cursor-pointer ${isMobile ? 'text-sm' : ''}`}
                                                            onClick={(e) => deleteSaveSearch(e, search)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} className='lg:text-xl text-base font-bold mr-2 mt-1' />
                                                            Delete
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className={isMobile ? 'hidden' : 'text-sm text-gray mt-2.5 ml-1.5'}>
                                                    Filters: {filterDescription(search.saved_search)}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        )
                    )}
                </div>
            </div>
        </>
    )
}
