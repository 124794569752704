import React, { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../supabase';
import emailjs from 'emailjs-com';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import loading_spinner from '../assets/loading_spinner.gif';

export default function AddClient(props) {
    const { session, clients, setClients, setActiveClient, setOpenAddClientModal,
        setFavoritesList, setFavoritesListFull, clientLead, setViewActivity,
        setCompareFavorites, setViewLeads
    } = props;
    
    const [userPropertyTypes, setUserPropertyTypes] = useState([]);
    const propertyTypes = [
        {
            "title": 'Condo',
            "value": 'Condo'
        },
        {
            "title": 'Townhouse',
            "value": 'Townhouse'
        },
        {
            "title": 'Multi Family',
            "value": 'Multi Family'
        },
        {
            "title": 'Single Family',
            "value": 'Single Family'
        }
    ]
    // set defaults
    const defaults = {
        // init investment
        closingCosts: 3000,
        closingCostsPct: 2,
        closingCostsType: "%",
        rehab: 1000,
        miscCosts: 0,
        // income
        rentAppr: 3,
        laundry: 0,
        storage: 0,
        miscIncome: 0,
        // expenses
        expensesAppr: 2.5,
        taxes: null,
        insurance: 100,
        mortgageInsurancePct: 0.01,
        repairs: 100,
        repairsPct: 5,
        repairsType: "%",
        capex: 100,
        capexPct: 5,
        capexType: "%",
        propMgnt: 10,
        vacancy: 3,
        miscExpenses: 0,
        utilitiesPaid: true,
        electricity: 40,
        gas: 20,
        sewage: 0,
        garbage: 0,
        lawn: 40,
        HOA: 0,
        // selling expenses
        sellersAgent: 3,
        buyersAgent: 3,
        // mortgage
        downpayment: 30000,
        downpaymentPct: 25,
        downpaymentType: '%', // or '$'
        interestRate: 6.500,
        loanTerm: 30,
        // appr & taxes
        city: "Provo, UT",
        cityAppr: 3,
        personalAnnualIncome: 0,
        taxBracket: 0,
        filingStatus: "Single",
        deprPeriod: 27.5,
        // projection
        yearsProjected: 10
    };
    
    const [page, setPage] = useState(1);
    const [loadingAddClient, setLoadingAddClient] = useState(false);
    const [name, setName] = useState(clientLead ? clientLead.email : '');
    const [email, setEmail] = useState(clientLead ? clientLead.email : '');
    const [minBudget, setMinBudget] = useState('');
    const [maxBudget, setMaxBudget] = useState('');
    const [timeline, setTimeline] = useState('');
    const [clientId, setClientId] = useState(0);
    const [sendClientEmail, setSendClientEmail] = useState(false);
    const [agentEmail, setAgentEmail] = useState(null);
    const [agentName, setAgentName] = useState(null);
    const portfolioCode = useRef('');
    const HASH_LENGTH = 16;
    
    // company name
    let currentURL = window.location.href;
    let urlArray = currentURL.split("/dashboard");
    const SHARE_LINK = `${urlArray[0]}/dashboard?portfoliocode=${portfolioCode.current}`;

    urlArray = urlArray[0].split('/');
    let init_company_name = urlArray[urlArray.length - 1];
    const COMPANY_NAME = init_company_name.toLowerCase()
    
    // generate unique hash
    const generateHash = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let uniqueHash = '';
        for (let i = 0; i < HASH_LENGTH; i++) {
            uniqueHash += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return uniqueHash;
    }

    useEffect(() => {
        const getClientId = async () => {
            // get new invite code
            try {
                // let clientIdHash = generateHash();
                let clientIdHash = "AEYp-iPDQ8epHq6c"
                checkHashUniquenessClientId(clientIdHash);
            } catch (error) {
                console.log(error.message)
            }
        }
        
        const getProfile = async () => {
            // get new invite code
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select('email, name')
                    .eq('id', session.user.id);

                if (error && status !== 406) throw error

                if (data) {
                    setAgentName(data[0].name)
                    setAgentEmail(data[0].email)
                }
            } catch (error) {
                console.log(error.message)
            }
        }

        const checkHashUniqueness = async () => {
            let unique = false
            while (unique === false) {
                try {
                    let { data, error, status } = await supabase
                        .from('client')
                        .select('portfolio_code')
                        .eq("portfolio_code", portfolioCode.current)
    
                    if (error && status !== 406) throw error
                    
                    if (data.length > 0) {
                        portfolioCode.current = generateHash();
                        setTimeout(500);
                    } else {
                        unique = true;
                        return 0
                    }
                } catch (error) {
                    console.log(error.message)
                }
            }
            return 0
        }
        
        const checkHashUniquenessClientId = async (clientIdHash) => {
            let unique = false;
            while (unique === false) {
                try {
                    let { data, error, status } = await supabase
                        .from('client')
                        .select('id')
                        .eq("id", `client-id-${clientIdHash}`)
    
                    if (error && status !== 406) throw error
                    
                    if (data.length > 0) {
                        clientIdHash = generateHash();
                        setTimeout(500);
                    } else {
                        unique = true;
                        setClientId(clientIdHash)
                        return true
                    }
                } catch (error) {
                    console.log(error.message)
                }
            }
            return 0
        }
        
        getClientId();
        getProfile();
        portfolioCode.current = generateHash();
        checkHashUniqueness();

        let currentURL = window.location.href;
        if (currentURL.includes("agentsignup")) {
            let urlArray = currentURL.split("/dashboard");
            let newURL = `${urlArray[0]}/dashboard`;
            window.history.pushState('page2', 'Dashboard', newURL);
        }
        // eslint-disable-next-line
    }, [])
    
    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-semibold'>{errorMsg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'addError',
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    // const handleAddButton = async () => {
    //     setLoadingAddClient(true);
    //     handleShareAgent();
    //     handleAddClient();
    //     setTimeout(function() { setLoadingAddClient(false); }, 700);
    //     setTimeout(function() { setPage(page + 1); }, 700);
    // }

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    const sendEmail = (e) => {
        e.preventDefault();

        // first check to make user hasn't already been added
        let foundUser = false;
        if (email !== '') {
            clients.forEach(client => {
                if (client.email === email) {
                    foundUser = true;
                }
            })
        }
        if (foundUser) {
            notifyErrorMsg("Client has already been added.");
        } else {
            handleSendEmail(e);
        }
    }

    const handleSendEmail = async (e) => {
        // check this email against the DB; if not found, continue
        try {
            let { data, error, status } = await supabase
                .from('client_shares')
                .select('id, user_email, role')
                .eq('user_email', email);

            if (error && status !== 406) throw error
            
            if (data.length > 0) {
                notifyErrorMsg("Email is already linked with another portfolio. Contact info@investorbot.us for help.");
                return null
            } else if (clientId < 2) {
                notifyErrorMsg("System error. Please notify info@investorbot.us immediately.");
            } else {
                // email is not in DB, add it!
                setLoadingAddClient(true);
                if (sendClientEmail) {
                    emailjs.sendForm('service_xsn87gk', 'share_portfolio_template', e.target, '-zkNxR_jnyyJKePEU')
                        .then((result) => {
                            console.log("Successfully sent invitation.");
                        }, (error) => {
                            console.log(error.text);
                            notifyErrorMsg("Email failed. Please try again later.");
                        })
                    handleShareClient();
                }
        
                handleShareAgent();
                handleAddClient();
                setTimeout(function() { setLoadingAddClient(false); }, 700);
                setTimeout(function() { setPage(page + 1); }, 700);
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleAddClient = async () => {
        // save data to local
        let newClient = {
            'id': `client-id-${clientId}`,
            'name': name,
            'email': email,
            'phone': '',
            'budget': '',
            'propertyTypes': '',
            'timeline': '',
            'agentEmail': session.user.email
        }
        clients.push(newClient);
        setClients(clients);
        setActiveClient(newClient);
        setFavoritesList([]);
        setFavoritesListFull([]);
        // reset viewing (if any)
        setViewActivity(false);
        setCompareFavorites(false);
        setViewLeads(false);

        // initialize client in supabase
        try {
            const updates = {
                id: `client-id-${clientId}`,
                created_at: new Date(),
                updated_at: new Date(),
                agent_id: session.user.id,
                agent_email: session.user.email,
                name: name,
                email: email,
                portfolio_code: portfolioCode.current
            }
            
            let { error } = await supabase.from('client').upsert(updates)

            if (error) {
                throw error
            } else {
                handleSaveClientInfo();
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleShareAgent = async () => {
        // add agent record to sharing table
        try {
            const updates = {
                client_id: `client-id-${clientId}`,
                user_id: session.user.id,
                user_email: session.user.email,
                invite_code: 'Agent',
                portfolio_code: portfolioCode.current,
                role: 'Owner',
                confirmed: true,
            }
            
            let { error } = await supabase.from('client_shares').insert(updates)

            if (error) throw error
        } catch (error) {
            console.error(error.message)
        }
    }
    
    const handleShareClient = async () => {
        // add agent record to sharing table
        try {
            const updates = {
                client_id: `client-id-${clientId}`,
                user_email: email,
                invite_code: 'Shared',
                portfolio_code: portfolioCode.current,
                role: 'Editor',
                confirmed: false,
            }
            
            let { error } = await supabase.from('client_shares').insert(updates)

            if (error) throw error
        } catch (error) {
            console.error(error.message)
        }
    }

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleSaveClientInfo = async () => {
        // update local data
        let newClient = clients[clients.length - 1]
        let savePropertyTypes = '';
        userPropertyTypes.forEach(propType => {
            savePropertyTypes += (savePropertyTypes === '' ? `${propType.title}` : `, ${propType.title}`)
        })
        let newBudget = '';
        if (minBudget > 0 && maxBudget === '') {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - Any`
        } else if (minBudget === '' && maxBudget > 0) {
            newBudget = `Any - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        } else if (minBudget > 0 && maxBudget > 0) {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        }
        newClient.budget = newBudget;
        newClient.propertyTypes = savePropertyTypes;
        newClient.timeline = timeline;
        setActiveClient(newClient);
        // update most recent client push
        clients[clients.length - 1] = newClient;
        setClients(clients);
        
        // submit client to supabase
        try {
            const updates = {
                id: `client-id-${clientId}`,
                budget: newBudget,
                property_types: savePropertyTypes,
                timeline: timeline,
                agent_id: session.user.id,
                agent_email: session.user.email,
                portfolio_code: portfolioCode.current
            }
            
            let { error } = await supabase.from('client').upsert(updates)

            if (error) {
                throw error
            }
        } catch (error) {
            console.log(error.message)
        }
        
        // initialize favorites to supabase
        try {
            const updates = {
                id: `client-id-${clientId}`,
                updated_at: new Date(),
                saved_properties: []
            }
            
            let { error } = await supabase.from('client_favorites').upsert(updates)

            if (error) throw error
        } catch (error) {
            console.log(error.message)
        }
        
        // save defaults to supabase
        try {
            const updates = {
                id: `client-id-${clientId}`,
                updated_at: new Date(),
                downpayment: parseInt(defaults.downpayment),
                downpayment_pct: parseFloat(defaults.downpaymentPct),
                downpayment_type: defaults.downpaymentType,
                interest_rate: parseFloat(defaults.interestRate),
                loan_term: parseInt(defaults.loanTerm),
                closing_costs: parseInt(defaults.closingCosts),
                closing_costs_pct: parseFloat(defaults.closingCostsPct),
                closing_costs_type: parseInt(defaults.closingCostsType),
                rehab: parseInt(defaults.rehab),
                rent_appreciation: parseFloat(defaults.rentAppr),
                laundry: parseInt(defaults.laundry),
                storage: parseInt(defaults.storage),
                misc_income: parseInt(defaults.miscIncome),
                taxes: parseInt(defaults.taxes === undefined ? '' : defaults.taxes),
                expenses_appreciation: parseFloat(defaults.expensesAppr),
                insurance: parseInt(defaults.insurance),
                mortgage_insurance_pct: parseFloat(defaults.mortgageInsurancePct),
                repairs: parseInt(defaults.repairs),
                repairs_pct: parseFloat(defaults.repairsPct),
                repairs_type: defaults.repairsType,
                capital_expenditures: parseInt(defaults.capex),
                capital_expenditures_pct: parseFloat(defaults.capexPct),
                capital_expenditures_type: defaults.capexType,
                property_management_pct: parseFloat(defaults.propMgnt),
                vacancy_pct: parseFloat(defaults.vacancy),
                misc_costs: parseInt(defaults.miscCosts),
                misc_expenses: parseInt(defaults.miscExpenses),
                electricity: parseInt(defaults.electricity),
                gas: parseInt(defaults.gas),
                sewage: parseInt(defaults.sewage),
                garbage: parseInt(defaults.garbage),
                lawn: parseInt(defaults.lawn),
                sellers_agent_pct: parseFloat(defaults.sellersAgent),
                buyers_agent_pct: parseFloat(defaults.buyersAgent),
                city: defaults.city,
                city_appreciation: parseInt(defaults.cityAppr),
                personal_annual_income: parseInt(defaults.personalAnnualIncome),
                tax_bracket: parseInt(defaults.taxBracket),
                filing_status: defaults.filingStatus,
                depreciation_period: parseFloat(defaults.deprPeriod),
                utilities_paid: defaults.utilitiesPaid,
            }

            let { error } = await supabase.from('client_defaults').upsert(updates);

            if (error) throw error
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleSaveBtn = () => {
        handleSaveClientInfo();
        setOpenAddClientModal(false);
        notifySuccessMsg("Saved client's information.");
    }


    return (
        <div>

            {/* Add client */}
            <Form onSubmit={sendEmail} className={page === 1 ? 'lg:px-4' : 'hidden'}>
                <div className='flex justify-center'>
                    <p className='text-center text-3xl font-black tracking-tight mb-2'>Add Client</p>
                </div>
                <div className='flex justify-center -mt-1 -mb-2 bg-white text-white ml-4'>
                    <input
                        required
                        id='agentName'
                        name='agentName'
                        value={agentName ? agentName : 'Agent'}
                        className='w-1 bg-white text-white'
                    />
                    <input
                        required
                        id='agentEmail'
                        name='agentEmail'
                        value={agentEmail ? agentEmail : COMPANY_NAME}
                        className='w-1 bg-white text-white'
                    />
                </div>
                <div className='flex justify-center my-3'>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        placeholder="John Doe"
                        style={{width: '100%'}}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className='flex justify-center my-3'>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        placeholder="john.doe@gmail.com"
                        style={{width: '100%'}}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {sendClientEmail ? (
                    <div className={'flex justify-center my-3'}>
                        <textarea
                            id='message' name='message'
                            style={{ width: '100%' }}
                            defaultValue={`Hey!\n\nYou've been invited by ${agentName ? agentName : (agentEmail ? agentEmail : COMPANY_NAME)} to create a portfolio to find your next investment property. Follow this link to join:\n\n${SHARE_LINK}`}
                            className='h-32 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-xl'
                        />
                    </div>
                ) : (
                    <div className='hidden'></div>
                )}
                <FormControlLabel
                    control={
                        <Checkbox size="medium" checked={sendClientEmail} onClick={() => setSendClientEmail(!sendClientEmail)} />
                    }
                    label={<p className='text-base'>Send email invitation</p>}
                    className="ml-1"
                />
                <div className='flex justify-center mt-3 mb-4'>
                    <Button
                        type="submit"
                        block size="lg"
                        className='bg-bot-blue text-white text-xl font-semibold rounded-lg'
                    >
                        {loadingAddClient ? (
                            <div className='flex justify-center items-center'>
                                <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                            </div>
                        ) : (
                            <p>Add</p>
                        )}
                    </Button>
                </div>
            </Form>

            {/* Get started */}
            <div className={page === 2 ? 'px-2' : 'hidden'}>
                <div className='flex flex-col justify-center items-center'>
                    <div className='flex justify-center items-center text-center bg-light-green rounded-xl text-lg py-1 mb-2.5 mt-2 w-full'>
                        <FontAwesomeIcon icon={faCheckCircle} className="text-xl text-green -mb-0.5 mr-2" />
                        {sendClientEmail ? (
                            <p>Invite sent to {email}</p>
                        ) : (
                            <p>{name} was added</p>
                        )}
                    </div>
                    <p className='text-center text-3xl tracking-tight mb-2 font-black'>Get a head start</p>
                    <p className='text-center text-gray text-lg mt-2'>Give your client a head start by filling out this information for them (optional).</p>
                </div>
                <div className='flex justify-start items-center gap-1 mt-9 mb-3'>
                    <p className='mr-2'>Budget range:</p>
                    <CurrencyInput
                        id="min-budget"
                        onClick={() => handleSelectInput("min-budget")}
                        className="p-1 pl-2 w-32 border-one rounded-md text-lg"
                        decimalsLimit={1}
                        prefix='$'
                        value={minBudget}
                        placeholder="$250,000"
                        onValueChange={(e) => setMinBudget(e)}
                    />
                    <p>to</p>
                    <CurrencyInput
                        id="max-budget"
                        onClick={() => handleSelectInput("max-budget")}
                        className="p-1 pl-2 w-32 border-one rounded-md text-lg"
                        decimalsLimit={1}
                        prefix='$'
                        value={maxBudget}
                        placeholder="$500,000"
                        onValueChange={(e) => setMaxBudget(e)}
                    />
                </div>
                <div className='flex justify-center items-center gap-2 my-4 mr-1.5'>
                    <p className='mr-2'>Timeline:</p>
                    <input
                        id='timeline'
                        name='timeline'
                        value={timeline}
                        onChange={(e) => setTimeline(e.target.value)}
                        style={{ width: isMobile ? '100%' : '100%', borderWidth: '1px' }}
                        className='h-10 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                        placeholder='3-4 months'
                    />
                </div>
                <div className='flex justify-center items-center gap-3 mt-4 mr-1'>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        style={{width: isMobile ? '100%' : '100%'}}
                        options={propertyTypes}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, value) => setUserPropertyTypes(value)}
                        value={userPropertyTypes}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select property types"
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between items-center gap-16 mt-5 mb-3'>
                    <button
                        block
                        size="lg"
                        onClick={() => setOpenAddClientModal(false)}
                        className='text-gray text-xl px-4 py-2.5 font-semibold rounded-xl hover:bg-light-gray'
                    >
                        Skip
                    </button>
                    <button
                        block
                        size="lg"
                        onClick={() => handleSaveBtn()}
                        className='bg-bot-blue text-white text-xl px-4 py-2.5 font-semibold rounded-xl hover:bg-dark-bot-blue'
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}