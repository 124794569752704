import React, { useEffect, useState } from "react";
import { withGoogleMap, withScriptjs, GoogleMap, OverlayView, InfoWindow } from "react-google-maps";
import { supabase } from '../supabase';
import mapStyles from './mapStyles.json';
import allFunctions from '../containers/Functions';
import MapIcon from "./MapIcon";
import InfoWindowReturns from "./InfoWindowReturns";
import { setMapCache } from "../lib/mapCache";
import MapControl from "./MapControl";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { DropdownButton } from "react-bootstrap";
// import confirm_email from '../assets/confirm_email.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faPencil } from "@fortawesome/free-solid-svg-icons";
import loading_spinner from '../assets/loading_spinner.gif';
import SaveSearch from "./SaveSearch";

const Map = (props) => {
    const { properties, selectedProperty, setSelectedProperty, setShow, defaults,
        setDefaults, handleBoundsChange, session, setOpenSignUp, propertyEdits,
        setPropertyEdit, filterDescription, getCurrentFilters, sortTitle,
        setOpenSavedSearchesModal, setSearching, handleOpenProjectionsModal,
        setLoginHeader
    } = props;
    // local vars
    const [anchorEl, setAnchorEl] = useState(null);
    const [header, setHeader] = useState('Cash Flow');
    const [showLegend, setShowLegend] = useState((header === 'Cash Flow') ? true : false);
    const open = Boolean(anchorEl);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailAlertID, setEmailAlertID] = useState(null);
    const [showSavedSearch, setShowSavedSearch] = useState(false);
    const [loadingSaveSearch, setLoadingSaveSearch] = useState(false);

    let ref;
    let offsetX = 21;
    let offsetY = 10; // either 10 (top) or 147 (bottom)
    if (defaults.mapZoomedOut === true) {
        offsetX = 10;
    }
    if (selectedProperty) {
        // reset latitude
        let pctDistanceToTopLat = defaults.mapBounds.latitude.hi - selectedProperty.latitude
        let latDistance = defaults.mapBounds.latitude.hi - defaults.mapBounds.latitude.lo
        
        if ((pctDistanceToTopLat / latDistance) < 0.225) {
            offsetY = 147;
        }

        // reset longitude (if off screen)
        let pctDistanceToRightLng = Math.abs(defaults.mapBounds.longitude.hi - selectedProperty.longitude)
        let pctDistanceToLeftLng = Math.abs(defaults.mapBounds.longitude.lo - selectedProperty.longitude)
        let lngDistance = defaults.mapBounds.longitude.hi - defaults.mapBounds.longitude.lo
        
        let pctDistToRight = (pctDistanceToRightLng / lngDistance)
        if (pctDistToRight < 0.10) {
            offsetX = -120;
            offsetY = 75
        } else if (pctDistToRight < 0.15) {
            offsetX = -100;
        } else if (pctDistToRight < 0.20) {
            offsetX = -50;
        }

        let pctDistToLeft = (pctDistanceToLeftLng / lngDistance)
        if (pctDistToLeft < 0.10) {
            offsetX = 175;
            offsetY = 75
        } else if (pctDistToLeft < 0.15) {
            offsetX = 100;
        } else if (pctDistToLeft < 0.20) {
            offsetX = 50;
        }
    }

    let filteredPropertyData = [];
    properties.forEach((property) => {
        if (property.latitude !== null) {
            filteredPropertyData.push(property);
        }
    })

    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const defaultMapOptions = {
        styles: mapStyles,
        fullscreenControl: false,
        disableDefaultUI: true, // a way to quickly hide all controls
        mapTypeControl: !isMobile,
        scaleControl: true,
        zoomControl: true,
    };
    
    const handleMarkerClose = () => {
        setSelectedProperty(null);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = (value) => {
        setAnchorEl(null);
        setHeader(value);
        setDefaults((prevState) => { return { ...prevState, showBy: value } })
        // cash flow legend
        if (value === "Cash Flow") {
            setShowLegend(true);
        } else {
            setShowLegend(false);
        }
    };

    const handleCardClick = async () => {
        let propertiesViewed = localStorage.getItem('PROPERTIES_VIEWED');
        propertiesViewed = propertiesViewed ? parseInt(propertiesViewed) : 0
        if (session === null && propertiesViewed > 2) {
            // prompt a user to sign up after 3 properties viewed
            setLoginHeader('Sign up for free to view more');
            setOpenSignUp(true);
        } else {
            // increment properties viewed (if not signed in)
            if (session === null) {
                localStorage.setItem('PROPERTIES_VIEWED', propertiesViewed + 1);
            }
            
            let propertyEdit = null;
            if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
                if (propertyEdits.properties.hasOwnProperty(`${selectedProperty.zpid}`)) {
                    propertyEdit = propertyEdits.properties[`${selectedProperty.zpid}`];
                }
            }
            setPropertyEdit(propertyEdit);

            // set URL
            let currentURL = window.location.href;
            let urlArray = currentURL.split("/search");
            let newURL = `${urlArray[0]}/search?id=${selectedProperty.zpid}`;
            window.history.pushState('page2', `${selectedProperty.address} | Investorbot`, newURL);

            // open property modal
            setShow(true);

            let newMapCenter = {
                'lat': ref.getCenter().lat(),
                'lng': ref.getCenter().lng(),
            };
            let newMapZoom = ref.getZoom();
            setMapCache(defaults, selectedProperty, newMapCenter, newMapZoom);
        }
    }

    const getDataAndCallBoundsChange = async () => {
        setLoadingSaveSearch(false);
        let allProperties = [];
        
        // get low and high lat/lng
        let mapBounds = ref.getBounds();
        let latitude = Object.values(mapBounds)[0];
        let longitude = Object.values(mapBounds)[1];

        // reset save search if needed
        if (dropdownOpen) {
            setDropdownOpen(false);
        }

        try {
            let { data, error, status } = await supabase
                .from('propertyData')
                .select(`
                    zpid, address, bedrooms, bathrooms, price, imgSrc,
                    latitude, longitude, rentEstimate, rentEstimateSource,
                    monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                    livingArea, listingAgent, brokerageName, homeStatus, yearBuilt,
                    daysOnZillow ${filterDescription ? ', description' : ''}
                `)
                .gt('latitude', latitude.lo)
                .gt('longitude', longitude.lo)
                .lt('latitude', latitude.hi)
                .lt('longitude', longitude.hi)

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                allProperties = data;
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            handleBoundsChange(allProperties, ref.getBounds(), ref.getCenter(), ref.getZoom())
        }
    }

    const runSaveSearch = async () => {
        setDropdownOpen(true);
        setShowSavedSearch(true);
        // save the top 5 in this map view
        let topFiveProperties = properties.slice(0, 5);
        let topFive = []
        topFiveProperties.forEach(prop => {
            topFive.push({
                'zpid': prop.zpid,
                'cashFlow': prop.cashFlow,
                'capRate': prop.capRate,
                'totalReturn': prop.totalReturn
            })
        })
        let allFilters = getCurrentFilters();
        let savedSearch = {
            // map
            'mapBounds': defaults.mapBounds,
            'sort': sortTitle,
            // filters
            'priceFilter': allFilters.price,
            'bedBathFilter': allFilters.bedBath,
            'propertyTypeFilter': allFilters.propertyType,
            'hoaFilter': allFilters.hoa,
            'keywordsFilter': allFilters.keywords,
            'daysOnMarketFilter': allFilters.daysOnMarket,
            'ageOfPropertyFilter': allFilters.ageOfProperty,
            // top five
            'topFive': topFive
        }
        try {
            const updates = {
                created_at: new Date(),
                email: session.user.email,
                user_id: session.user.id,
                saved_search_name: "Property search",
                email_frequency: "Instantly",
                saved_search: savedSearch
            }

            let { data, error } = await supabase.from('emailAlerts').upsert(updates).select()

            if (data) {
                setEmailAlertID(data[0].id);
            }
            
            if (error) throw error
        } catch (error) {
            console.error(error.message)
        }
    }

    const handleSaveSearch = () => {
        if (dropdownOpen === false) {
            if (!session) {
                setLoadingSaveSearch(true);
                setLoginHeader('Sign up to save searches');
                setOpenSignUp(true);
            } else {
                setLoadingSaveSearch(true);
                runSaveSearch()
                setTimeout(function() { setLoadingSaveSearch(false); }, 500);
            }
        }
    }

    const handleOpenSavedSearches = () => {
        setShowSavedSearch(false);
        setOpenSavedSearchesModal(true);
    }

    return (
        <GoogleMap
            ref={(mapRef) => ref = mapRef}
            zoom={defaults.mapZoom}
            center={defaults.mapCenter}
            defaultOptions={defaultMapOptions}
            onIdle={getDataAndCallBoundsChange}
            onClick={() => setSearching(false)}
        >
            <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
                <div className="flex justify-center gap-3">
                    <div>
                        <p
                            id="demo-positioned-button"
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{marginTop: '22px', paddingTop: isMobile ? '8px' : '4px', paddingBottom: isMobile ? '8px' : '4px'}}
                            className={`${isMobile ? 'text-base' : 'text-xl'} px-3 shadow-md shadow-gray rounded bg-[#FFFFFF] hover:bg-light-gray cursor-pointer border-black text-black font-semi-bold`}
                        >
                            <span id='step-three' style={{fontSize: '16px'}}>
                                <span className={`text-base font-thin mr-2`}>Show:</span>{header}
                            </span>
                        </p>
                    </div>
                    <Menu
                        id="demo-positioned-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleClose(header)}
                        onBlur={() => handleClose(header)}
                        className='mt-1.5 ml-1.5'
                    >
                        <MenuItem key='cash-flow' sx={{fontSize: '16px', width: '150px'}} onClick={() => handleClose('Cash Flow')}>Cash Flow</MenuItem>
                        <MenuItem key='cap-rate' sx={{fontSize: '16px', width: '150px'}} onClick={() => handleClose('Cap Rate')}>Cap Rate</MenuItem>
                        {parseInt(defaults.yearsProjected) === 0 || parseInt(defaults.yearsProjected) > 29 ? (
                            <div className="hidden"></div>
                        ) : (
                            <MenuItem key='total-return' sx={{fontSize: '16px', width: '150px'}} onClick={() => handleClose('Total Return')}>Total Return</MenuItem>
                        )}
                    </Menu>
                    <DropdownButton
                        title={
                            <Tooltip title={<p className="text-sm">Investorbot will email you anytime the top five properties of this search change.</p>} >
                                <span className="text-white">
                                    {loadingSaveSearch ? (
                                        'Loading...'
                                    ) : (
                                        'Save search'
                                    )}
                                </span>
                            </Tooltip>
                        }
                        id="dropdown-basic-button"
                        style={{marginTop: isMobile ? '22px' : '22px'}}
                        onClick={() => handleSaveSearch()}
                        className="mr-3 ml-2 text-xl shadow-md shadow-gray font-semibold rounded-md bg-bot-blue"
                    >
                        <div className="-mb-4">
                            {loadingSaveSearch ? (
                                <Box sx={{ width: 300 }}>
                                    <p className='flex justify-center items-center gap-4 text-xl bg-bot-blue rounded-t py-2 -mt-2 text-white text-center font-semibold'>
                                        <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                                        Saving search...
                                    </p>
                                </Box>
                            ) : (
                                <Box sx={{ width: 300 }}>
                                    <SaveSearch
                                        session={session}
                                        setDropdownOpen={setDropdownOpen}
                                        emailAlertID={emailAlertID}
                                    />
                                </Box>
                            )}
                        </div>
                    </DropdownButton>
                </div>
                <div className={showSavedSearch ? "flex justify-center items-center mt-2 mr-3 px-2 py-2.5 bg-light-green text-base rounded-sm" : "hidden"}>
                    <FontAwesomeIcon icon={faCheckCircle} className='h-5 -pb-1 mr-2 text-green' />
                    <p>Search saved.</p>
                    <p onClick={() => handleOpenSavedSearches()} className="ml-2 underline cursor-pointer font-medium hover:font-bold">View my searches</p>
                </div>
                <div className="flex justify-end mr-3">
                    <div
                        style={{width: '165px'}}
                        className={showLegend ? `${showSavedSearch ? 'mt-1' : 'mt-3'} text-black bg-white text-base shadow rounded-md p-2` : "hidden"}
                    >
                        <p className="text-sm">
                            <FontAwesomeIcon icon={faCircle} className='text-dark-green-2 mr-2' />
                            Positive Cash Flow
                        </p>
                        <p className="text-sm">
                            <FontAwesomeIcon icon={faCircle} className='text-dark-red-2 mr-2' />
                            Negative Cash Flow
                        </p>
                    </div>
                </div>
            </MapControl>
            <MapControl position={window.google.maps.ControlPosition.BOTTOM_CENTER}>
                <div
                    onClick={() => handleOpenProjectionsModal()}
                    className={isMobile ? "hidden" : "flex justify-center gap-2 mb-5 px-3 py-2 text-base bg-black hover:bg-[#252525] text-white cursor-pointer rounded-full shadow-md shadow-gray"}
                >
                    <div className="flex justify-center gap-0.5 p-1.5">
                        <p>{defaults.yearsProjected} year projection</p>
                        <FontAwesomeIcon
                            icon={faPencil}
                            className="text-sm ml-2 mt-1 text-white"
                        />
                    </div>
                </div>
            </MapControl>
            {filteredPropertyData.slice(0).reverse().map(property => (
                <OverlayView
                    position={{
                        lat: parseFloat(property.latitude),
                        lng: parseFloat(property.longitude)
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <MapIcon
                        property={property}
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                        defaults={defaults}
                        propertyEdits={propertyEdits}
                    />
                </OverlayView>
            ))}
            {selectedProperty && (
                <InfoWindow
                    onCloseClick={() => {
                        handleMarkerClose();
                    }}
                    position={{
                        lat: parseFloat(selectedProperty.latitude),
                        lng: parseFloat(selectedProperty.longitude)
                    }}
                    options={{ pixelOffset: new window.google.maps.Size(offsetX, offsetY) }}
                >
                    <div className="flex cursor-pointer" onClick={() => handleCardClick()}>
                        <div className='h-20 w-16 mt-1' style={{
                                backgroundImage: `url(${selectedProperty.imgSrc})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                        />
                        <div className="ml-2">
                            <h2 className="text-base">${selectedProperty.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                            <p className="text-sm">{selectedProperty.bedrooms} Bed, {selectedProperty.bathrooms} Bath, {selectedProperty.livingArea} sf.</p>
                            <InfoWindowReturns property={selectedProperty} defaults={defaults} propertyEdits={propertyEdits} />
                        </div>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

const MapComponent = withScriptjs(withGoogleMap(Map));

const MapExport = (props) => {
    const {properties, selectedProperty, setSelectedProperty, setShow, defaults,
        setDefaults, handleBoundsChange, session, setOpenSignUp, propertyEdits,
        setPropertyEdit, filterDescription, getCurrentFilters, sortTitle,
        setOpenSavedSearchesModal, setSearching, handleOpenProjectionsModal,
        setLoginHeader
    } = props;
    const isMobile = allFunctions.Mobile();
    let height = '85vh';
    let width = isMobile ? '100vw' : `100%`;
    
    return (
        <MapComponent
            properties={properties}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            setShow={setShow}
            defaults={defaults}
            setDefaults={setDefaults}
            handleBoundsChange={handleBoundsChange}
            session={session}
            setOpenSignUp={setOpenSignUp}
            propertyEdits={propertyEdits}
            setPropertyEdit={setPropertyEdit}
            filterDescription={filterDescription}
            getCurrentFilters={getCurrentFilters}
            sortTitle={sortTitle}
            setOpenSavedSearchesModal={setOpenSavedSearchesModal}
            setSearching={setSearching}
            handleOpenProjectionsModal={handleOpenProjectionsModal}
            setLoginHeader={setLoginHeader}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDpXppfyrCJhg8A07o21JchP3zOhY_PF4o`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height, width: width }} />}
            mapElement={<div style={{ height: `100%`, borderTopRightRadius: isMobile ? '0px' : '10px' }} />}
        />
    )
};

export default MapExport;