import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperPlane, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com';

export default function Conversation(props) {
    const { activeClient, selectedProperty, conversation, loadingConversation,
        handleAddMessage, handleClearConversation, handleCloseConversation,
        clients, session, handleClickConversation, convoMessage
    } = props;
    const [newMessage, setNewMessage] = useState(convoMessage && convoMessage.length > 0 ? convoMessage : '');
    const [openVerifyClearConversation, setOpenVerifyClearConversation] = useState(false);
    
    // get property link for agent to respond to client
    let currentURL = window.location.href;
    let urlArray = currentURL.split(currentURL.includes("/dashboard") ? "/dashboard" : "/search");
    let propertyLink = `${urlArray[0]}/search?id=${selectedProperty.zpid}&user=${session.user.id}&clientname=${activeClient.name}&openchat=true`;

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        if (convoMessage === '') {
            setTimeout(() => { document.getElementById('newMessage').select(); }, 200);
        }
        setTimeout(() => { scrollElementIntoView("last-message"); }, 400);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line
    }, []);
    const isMobile = width <= 768;

    const handleMessage = (e) => {
        e.preventDefault();
        if (newMessage.length > 0) {
            handleAddMessage(newMessage);
            
            if (clients.length === 0) {
                emailjs.sendForm('service_xsn87gk', 'client_message_to_agent', e.target, '-zkNxR_jnyyJKePEU')
                    .then((result) => {
                        console.log("Sent email!");
                    }, (error) => {
                        console.error(error);
                    })
            }

            setNewMessage('');
            setTimeout(function() { scrollElementIntoView(`message-${conversation.length - 1}`); }, 50)
        }
    }

    const scrollElementIntoView = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView();
    }

    const MAX_HEADER_LENGTH = isMobile ? 20 : 45;

    const handleInitClearConversation = () => {
        setOpenVerifyClearConversation(false);
        handleClearConversation();
    }


    return (
        <div>

            {/* Verify clear conversation */}
            <Modal size={'sm'} centered={true} show={openVerifyClearConversation} onHide={() => setOpenVerifyClearConversation(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Body>
                    <div className="flex justify-end items-center mr-3">
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenVerifyClearConversation(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                            />
                    </div>
                    <div className='mx-2 mt-2 mb-3 text-center'>
                        <p className='text-xl'>Are you sure you want to clear this conversation?</p>
                        <p
                            onClick={() => handleInitClearConversation()}
                            className='text-xl font-semibold mt-3 rounded-xl py-1.5 bg-red text-white cursor-pointer hover:bg-dark-red-2'
                        >
                            Yes, clear
                        </p>
                    </div>
                </Modal.Body>
            </Modal>

            <Form
                id="messagesContainer"
                onSubmit={handleMessage}
                className={`pt-3 pb-4 ${isMobile ? 'px-1' : 'px-4'} flex flex-col justify-between items-between`}
                style={{height: isMobile ? '100%' : '90vh'}}
            >
                <div>
                    {/* Header */}
                    <div className='flex justify-between items-center'>
                        <div className='flex justify-center items-center lg:gap-2 gap-1'>
                            <img src={selectedProperty.imgSrc} alt="Property" className='h-10 rounded-md' />
                            <p className='lg:text-2xl text-lg font-bold tracking-tight ml-2 no-wrap'>
                                {selectedProperty === null
                                    ? 'Conversation'
                                    : selectedProperty.address.length > MAX_HEADER_LENGTH
                                        ? `${selectedProperty.address.slice(0, MAX_HEADER_LENGTH)}..`
                                        : selectedProperty.address
                                }
                            </p>
                        </div>
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => handleCloseConversation()}
                            className='text-2xl px-3 py-2.5 -mt-1 cursor-pointer rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <div className='lg:flex lg:justify-center lg:items-center text-sm text-white bg-white -mt-2 cursor-auto'>
                        <input
                            id='agentEmail'
                            name='agentEmail'
                            className='w-1'
                            value={activeClient && activeClient.agentEmail ? activeClient.agentEmail : 'info@investorbot.us'}
                        />
                        <input
                            id='clientEmail'
                            name='clientEmail'
                            className='w-1'
                            value={session ? session.user.email : 'info@investorbot.us'}
                        />
                        <input
                            id='address'
                            name='address'
                            className='w-1'
                            value={selectedProperty === null ? 'Conversation' : selectedProperty.address}
                        />
                        <input
                            id='propertyLink'
                            name='propertyLink'
                            className='w-1'
                            value={propertyLink}
                        />
                    </div>
                    {/* Messages */}
                    <div id="conversationMessages" className={`rounded-2xl mt-1 overflow-scroll`} style={{height: '75vh'}}>
                        {loadingConversation ? (
                            <p className='flex justify-center text-light-gray-4 pt-4'>Loading..</p>
                        ) : (
                            <div>
                                <div className={conversation.length > 0 ? 'flex justify-end items-center' : 'hidden'}>
                                    <p
                                        id="demo-positioned-button"
                                        aria-haspopup="true"
                                        onClick={() => setOpenVerifyClearConversation(true)}
                                        className={`text-light-gray-4 text-sm p-1 rounded-xl cursor-pointer w-36 text-center hover:bg-light-gray ${isMobile ? 'mb-2' : ''}`}
                                    >
                                        Clear conversation
                                    </p>
                                </div>
                                <div className={conversation.length > 0 ? 'hidden' : `flex flex-col justify-center items-center text-center gap-4 text-xl mt-5 ${isMobile ? 'px-2' : ''}`}>
                                    <p>Start your conversation below 🎉</p>
                                    <div className='flex justify-center items-center gap-3 text-light-gray-4'>
                                        <hr className='w-20'/>
                                        <p>or</p>
                                        <hr className='w-20'/>
                                    </div>
                                    <p
                                        className='px-4 py-1.5 text-light-gray-4 rounded-2xl cursor-pointer hover:bg-light-gray'
                                        onClick={() => handleClickConversation()}
                                    >
                                        <FontAwesomeIcon icon={faRefresh} className='text-xl mr-2.5' />
                                        Refresh
                                    </p>
                                </div>
                                <div className='mt-2'>
                                    {conversation.map(function(convo, index) {
                                        return (
                                            <div className={`flex ${convo.user === 'agent' ? 'justify-start' : 'justify-end'}`}>
                                                <div className='mb-2 lg:mx-3'>
                                                    <p className='text-sm text-light-gray-4 ml-3 mb-1'><span className='font-semibold'>{convo.user === 'agent' ? 'Agent' : (activeClient ? activeClient.name : 'Client')}</span> &#x2022; {convo.timeSent}</p>
                                                    <p id={(index === conversation.length - 1) ? 'last-message' : `message-${index}`} className={`text-start font-semibold rounded-xl ${convo.user === 'agent' ? 'text-dark-gray bg-light-gray-2' : 'text-white bg-light-bot-blue-3'} p-3 lg:w-96`}>
                                                        {convo.message}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* Write & send message */}
                <div className={`flex justify-between items-center gap-1 -mt-2`}>
                    <input
                        id='newMessage'
                        name='newMessage'
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        style={{ width: isMobile ? '100%' : '100%', borderWidth: '1px' }}
                        className='bg-white my-2 h-14 px-3 border-light-gray-3 text-dark-gray rounded-2xl'
                        placeholder='Message'
                    />
                    <button type='submit' className='rounded-full'>
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                            style={{ paddingTop: '11px', paddingBottom: '11px', paddingLeft: '13px', paddingRight: '13px' }}
                            className={`text-3xl ${newMessage.length > 0 ? 'text-bot-blue lg:hover:text-dark-bot-blue lg:hover:bg-light-gray-2 lg:cursor-pointer' : 'text-light-gray-4' } lg:rounded-full`}
                        />
                    </button>
                </div>
            </Form>
        </div>
    )
}
