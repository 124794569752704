import React, { useState, useEffect } from "react";

const SERVER_URL = window.location.href.includes('localhost:3000')
    ? process.env.REACT_APP_LOCAL_SERVER_URL
    : process.env.REACT_APP_SERVER_URL

const ProductDisplay = () => (
    <section className="flex flex-col justify-center items-center">
        <div className="product">
        <img
            src="https://picsum.photos/seed/picsum/400/300"
            alt="Temporary"
        />
            <div className="text-center">
                <h3>Investorbot Pro</h3>
                <h5>$19.99/mo</h5>
            </div>
        </div>
        <form action={`${SERVER_URL}/create-checkout-session`} method="POST">
            <button type="submit" className="mt-2 px-4 py-2 rounded-xl bg-bot-blue text-white">
                Checkout
            </button>
        </form>
    </section>
);

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function Checkout() {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
        setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
        setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
        );
        }
    }, []);

    return message ? (
        <Message message={message} />
    ) : (
        <ProductDisplay />
    );
}