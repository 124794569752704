import React, { useState, useEffect } from 'react';
import { faArrowLeft, faArrowUpFromBracket, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import { shareOnMobile } from "react-mobile-share";
import PortfolioUsers from './PortfolioUsers';
import { supabase } from '../supabase';
import loading_spinner from '../assets/loading_spinner.gif';

export default function SharePortfolio(props) {
    const { session, activeClient, setOpenSharePortfolioModal } = props;
    const [email, setEmail] = useState('');
    const [multipleEmails, setMultipleEmails] = useState(false);
    const [sharedWith, setSharedWith] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [inviteCode, setInviteCode] = useState(Math.floor(100000 + Math.random() * 900000000000));
    const [loading, setLoading] = useState(false);
    const [agentEmail, setAgentEmail] = useState(null);
    const [agentName, setAgentName] = useState(null);
    const [portfolioCode, setPortfolioCode] = useState('');
    const role = 'Editor';
    
    useEffect(() => {
        const getSharedUsers = async () => {
            // get all shared users of this portfolio
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select('id, user_email, role, portfolio_code')
                    .eq('client_id', activeClient.id);

                if (error && status !== 406) throw error
                
                if (data) {
                    // initialize the shared_with email array
                    let initSharedWith = [];
                    data.forEach(user => {
                        initSharedWith.push({
                            'id': user.id,
                            'email': user.user_email,
                            'role': user.role
                        })
                    })
                    initSharedWith.sort((a, b) => {
                        if (a.role === "Owner") {
                          return -1; // a should come before b
                        } else if (b.role === "Owner") {
                          return 1; // b should come before a
                        } else {
                          return 0; // no sorting needed
                        }
                    });
                    setSharedWith(initSharedWith);
                    setPortfolioCode(data[0].portfolio_code);
                    setTimeout(function() { setLoading(false); }, 200);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log(error.message);
            }
        }

        const getProfile = async () => {
            // get new invite code
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select('email, name')
                    .eq('id', session.user.id);

                if (error && status !== 406) throw error

                if (data) {
                    setAgentName(data[0].name)
                    setAgentEmail(data[0].email)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        
        getSharedUsers();
        getProfile();
        // reset the URL
        let urlArray = window.location.href.split("/dashboard");
        let newURL = `${urlArray[0]}/dashboard`;
        window.history.pushState('page2', 'Dashboard', newURL);
        // eslint-disable-next-line
    }, [])
    
    let currentURL = window.location.href;
    let urlArray = currentURL.split("/dashboard");
    const SHARE_LINK = `${urlArray[0]}/dashboard?portfoliocode=${portfolioCode}`;
    urlArray = urlArray[0].split('/');
    let company_name = urlArray[urlArray.length - 1];
    company_name = company_name.charAt(0).toUpperCase() + company_name.slice(1);

    const notifySuccessMsg = (msg) => toast.success(<p className='font-semibold text-gray'>{msg}</p>, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    const handleSelectId = () => {
        navigator.clipboard.writeText(SHARE_LINK);
        notifySuccessMsg('Link copied!');
    }
      
    function sendEmail(e) {
        e.preventDefault();

        // first check to make user hasn't already been added
        const foundUser = sharedWith.find(user => user.email === email);
        if (foundUser) {
            notifyErrorMsg("User has already been added.");
        } else {
            if (email.length > 0) {
                handleSendEmail(e);
                setLoading(true);
            }
        }
    }

    const handleSendEmail = async (e) => {
        // check this email against the DB; if not found, continue
        try {
            let { data, error, status } = await supabase
                .from('client_shares')
                .select('id, user_email, role')
                .eq('user_email', email);

            if (error && status !== 406) throw error
            
            if (data.length > 0) {
                notifyErrorMsg("Email is already linked with another portfolio. Contact info@investorbot.us for help.");
                return null
            } else {
                // email is not in DB, add it!
                emailjs.sendForm('service_xsn87gk', 'share_portfolio_template', e.target, '-zkNxR_jnyyJKePEU')
                    .then((result) => {
                        notifySuccessMsg("Successfully sent invitation.")
                        handleAddOrRemoveUser(email, role);
                    }, (error) => {
                        console.log(error.text);
                        notifyErrorMsg("Email failed. Please try again later.");
                        setLoading(false);
                    })
            }
        } catch (error) {
            console.log(error.message)
            setLoading(false);
        }
    }
    
    const handleAddOrRemoveUser = async (email, role, userId) => {
        // set active client
        if (role) {
            // add record
            try {
                const updates = {
                    "client_id": activeClient.id,
                    "user_email": email,
                    "invite_code": inviteCode,
                    "role": role,
                    "confirmed": false
                }

                const { data, error } = await supabase
                    .from('client_shares')
                    .insert(updates)
                    .select('*')
                    .single();
                
                if (error) throw error
                
                if (data) {
                    sharedWith.push({
                        'id': data.id,
                        'email': data.user_email,
                        'role': data.role
                    })
                    setSharedWith(sharedWith);
                    setInviteCode(Math.floor(100000 + Math.random() * 900000000000));
                    setShowMessage(false);
                    setEmail('');
                }

            } catch (error) {
                console.error(error.message)
            }
        } else {
            // remove user
            setLoading(true);
            let newSharedWith = [];
            newSharedWith = sharedWith.filter(user => user.email !== email);
            setSharedWith(newSharedWith);
            // delete record in db
            try {
                let { error } = await supabase
                    .from('client_shares')
                    .delete()
                    .eq('id', userId)
                
                if (error) {
                    throw error
                } else {
                    notifySuccessMsg("Successfully deleted user.");
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        setLoading(false);
    }
    
    const handleShareEmailText = (e) => {
        setEmail(e.target.value)
        if (e.target.value.includes(',')) {
            setMultipleEmails(true);
        } else {
            setMultipleEmails(false);
        }
    }

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;


    const handleClickEmail = () => {
        setTimeout(function() { setShowMessage(true); }, 100);
        setTimeout(function() { document.getElementById('email').select(); }, 100);
    }


    return (
        <form onSubmit={sendEmail}>
            <div className='my-2'>
                <div className='flex justify-center gap-2'>
                    <FontAwesomeIcon onClick={() => setShowMessage(false)} icon={faArrowLeft} className={showMessage ? `text-2xl -ml-12 cursor-pointer p-2.5 -mt-0.5 hover:bg-light-gray-2 rounded-full` : 'hidden'} />
                    <p className='text-3xl font-extrabold tracking-tight'>Share portfolio</p>
                </div>
                <div className='flex justify-center -mt-1 -mb-2 bg-white text-white ml-4'>
                    <input
                        required
                        id='agentName'
                        name='agentName'
                        value={agentName}
                        className='w-0 bg-white text-white'
                    />
                    <input
                        required
                        id='agentEmail'
                        name='agentEmail'
                        value={agentEmail ? agentEmail : company_name}
                        className='w-0 bg-white text-white'
                    />
                </div>
                <div className={`flex justify-center items-center ${showMessage ? 'mt-3' : 'mt-6'}`}>
                    <TextField
                        id="email"
                        name="email"
                        label="Enter an email"
                        placeholder="john.doe@gmail.com"
                        style={{width: '100%'}}
                        value={email}
                        onChange={(e) => handleShareEmailText(e)}
                        onClick={() => handleClickEmail()}
                    />
                </div>
                <div className='flex justify-start'>
                    <p className={multipleEmails ? 'text-red ml-2 mt-1.5 -mb-1.5' : 'hidden'}>Please enter only one email at a time.</p>
                </div>
                <div>
                    {showMessage ? (
                        <div>
                            <div className='flex justify-center mt-3 mb-2' style={{width: '100%'}}>
                                <textarea
                                    id="message"
                                    name="message"
                                    defaultValue={`Hey!\n\nYou've been invited by ${agentName ? agentName : (agentEmail ? agentEmail : company_name)} to join ${activeClient.name}'s real estate portfolio. Follow this link to join:\n\n${SHARE_LINK}`}
                                    className='-mt-1 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                                    style={{width: '100%', height: '160px', resize: 'none'}}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {loading ? (
                                <Skeleton
                                    width={'100%'}
                                    height={130}
                                    animation="wave"
                                    variant="rectangular"
                                    className='rounded-xl mt-3'
                                />
                            ) : (
                                <div className='flex flex-col justify-start items-start' style={{width: '100%'}}>
                                    <p className='text-start text-lg mt-3 mb-2 ml-2'>People with access:</p>
                                    <div className='px-4 pt-1 pb-3 bg-light-gray rounded-xl' style={{width: '100%'}}>
                                        {sharedWith.map(function (user, index) {
                                            return (
                                                <PortfolioUsers
                                                    user={user}
                                                    handleAddOrRemoveUser={handleAddOrRemoveUser}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className='flex justify-between items-center text-lg mt-4'>
                {isMobile ? (
                    <div
                        className='mt-1 flex justify-center items-center gap-2 -mt-1 px-2.5 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl'
                        onClick={() =>
                            shareOnMobile({
                                text: `Hey! Here's a link to your shared portfolio on ${company_name}. Check it out!`,
                                url: `${window.location.href}?portfoliocode=${portfolioCode}`,
                                title: `${activeClient.name}'s Dashboard`,
                            })
                        }
                    >
                        <FontAwesomeIcon
                            icon={faArrowUpFromBracket}
                            className='text-xl px-1 py-2 mt-0.5 hover:cursor-pointer hover:text-bot-blue'
                        />
                        <p className={'font-semibold'}>Share link</p>
                    </div>
                ) : (
                    <div
                        onClick={() => handleSelectId('link')}
                        className='flex justify-start items-center text-start gap-3 px-3 py-2.5 cursor-pointer rounded-xl hover:bg-light-gray'
                    >
                        <FontAwesomeIcon icon={faCopy} className="font-black" />
                        <p className='font-semibold'>Copy invite link</p>
                    </div>
                )}
                <div className='flex justify-end text-lg'>
                    {showMessage ? (
                        <div className='flex justify-center gap-3'>
                            <p
                                onClick={() => setShowMessage(false)}
                                style={{width: '100%'}}
                                className={showMessage ? 'font-semibold py-2.5 px-3 rounded-xl hover:bg-light-gray cursor-pointer' : 'hidden'}
                            >
                                Cancel
                            </p>
                            <button
                                type="submit"
                                style={{width: '100%'}}
                                disabled={email.includes(',') ? true : false}
                                className={showMessage ? `${email.includes(',') ? 'bg-light-bot-blue' : 'bg-bot-blue hover:bg-dark-bot-blue'} text-white font-semibold px-4 rounded-xl` : 'hidden'}
                            >
                                {loading ? (
                                    <div className='flex justify-center items-center gap-2 py-2 px-1.5'>
                                        <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                                    </div>
                                ) : (
                                    <p className='py-2.5'>Send</p>
                                )}
                            </button>
                        </div>
                    ) : (
                        <div className='flex justify-center gap-3'>
                            <p
                                onClick={() => setOpenSharePortfolioModal(false)}
                                style={{width: '100%'}}
                                className={showMessage ? 'hidden' : 'bg-bot-blue hover:bg-dark-bot-blue text-white font-semibold py-2.5 px-4 rounded-xl cursor-pointer'}
                            >
                                Done
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </form>
    )
}