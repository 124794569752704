import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyInput from 'react-currency-input-field';
import { Autocomplete, TextField } from '@mui/material';
import { supabase } from '../supabase';

export default function ClientInfo(props) {
    const { activeClient, setActiveClient, setOpenClientInfoModal } = props;
    
    // const steps = [1, 2, 3]
    let initBudget = activeClient.budget.split('-');
    initBudget.forEach((budget, index) => {
        initBudget[index] = budget.includes("Any") ? 0 : budget.trim()
    });
    let initMinBudget = initBudget.length > 1 && initBudget[0] !== 0 ? parseInt(initBudget[0].replace('$', '').replace(',', '')) : 0;
    let initMaxBudget = initBudget.length > 1 && initBudget[1] !== 0 ? parseInt(initBudget[1].replace('$', '').replace(',', '')) : 0;
    let initPropertyTypes = activeClient.propertyTypes ? activeClient.propertyTypes.split(',') : [];
    let newInitPropTypes = []
    if (initPropertyTypes.length > 0) {
        initPropertyTypes.forEach(str => {
            newInitPropTypes.push({
                "title": str.trim(),
                "value": str.trim()
            })
        })
    }
    const [minBudget, setMinBudget] = useState(initMinBudget);
    const [maxBudget, setMaxBudget] = useState(initMaxBudget);
    const [timeline, setTimeline] = useState(activeClient.timeline);
    const [userPropertyTypes, setUserPropertyTypes] = useState(newInitPropTypes);
    const propertyTypes = [
        {
            "title": 'Condo',
            "value": 'Condo'
        },
        {
            "title": 'Townhouse',
            "value": 'Townhouse'
        },
        {
            "title": 'Multi Family',
            "value": 'Multi Family'
        },
        {
            "title": 'Single Family',
            "value": 'Single Family'
        }
    ]

    const notifySuccessMsg = (msg) => toast.success(<p className='font-semibold'>{msg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleSaveClientInfo = async () => {
        // update local data
        let newClient = activeClient;
        let savePropertyTypes = '';
        userPropertyTypes.forEach(propType => {
            savePropertyTypes += (savePropertyTypes === '' ? `${propType.title}` : `, ${propType.title}`)
        })
        let newBudget = '';
        if (parseFloat(minBudget) > 0 && parseFloat(maxBudget) === 0) {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - Any`
        } else if (parseFloat(minBudget) === 0 && parseFloat(maxBudget) > 0) {
            newBudget = `Any - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        } else if (parseFloat(minBudget) > 0 && parseFloat(maxBudget) > 0) {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        }
        newClient.budget = newBudget;
        newClient.propertyTypes = savePropertyTypes;
        newClient.timeline = timeline;
        setActiveClient(newClient);
        
        // submit data to supabase
        try {
            const updates = {
                id: newClient.id,
                budget: newBudget,
                property_types: savePropertyTypes,
                timeline: timeline,
            }
            
            let { error } = await supabase.from('client').upsert(updates)

            if (error) {
                throw error
            } else {
                setOpenClientInfoModal(false);
                notifySuccessMsg("Saved successfully.");
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <div>
            <p className='text-3xl text-center font-extrabold tracking-tighter mb-5'>Portfolio details</p>
            <div className='flex justify-start lg:gap-4 gap-2 items-center my-3'>
                <p>Budget:</p>
                <div className='flex justify-center items-center gap-2'>
                    <CurrencyInput
                        id="min-budget"
                        onClick={() => handleSelectInput("min-budget")}
                        className="p-1 pl-2 lg:w-32 w-28 border-one rounded-md text-lg"
                        decimalsLimit={1}
                        prefix='$'
                        value={minBudget}
                        placeholder="$250,000"
                        onValueChange={(e) => setMinBudget(e)}
                    />
                    <p>to</p>
                    <CurrencyInput
                        id="max-budget"
                        onClick={() => handleSelectInput("max-budget")}
                        className="p-1 pl-2 lg:w-32 w-28 border-one rounded-md text-lg"
                        decimalsLimit={1}
                        prefix='$'
                        value={maxBudget}
                        placeholder="$500,000"
                        onValueChange={(e) => setMaxBudget(e)}
                    />
                </div>
            </div>
            <div className='flex justify-center items-center gap-2 my-4 mr-1.5'>
                <p className='mr-2'>Timeline:</p>
                <input
                    id='timeline'
                    name='timeline'
                    value={timeline}
                    onChange={(e) => setTimeline(e.target.value)}
                    style={{ width: isMobile ? '100%' : '100%', borderWidth: '1px' }}
                    className='h-10 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='3-4 months'
                />
            </div>
            <div className='flex justify-center items-center gap-3 mt-4 mr-1'>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    style={{width: isMobile ? '100%' : '100%'}}
                    options={propertyTypes}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, value) => setUserPropertyTypes(value)}
                    value={userPropertyTypes}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select property types"
                        />
                    )}
                />
            </div>
            <div className='flex justify-between items-center gap-16 mt-5 mb-3'>
                <button
                    block
                    size="lg"
                    onClick={() => setOpenClientInfoModal(false)}
                    className='text-gray text-xl py-2.5 px-4 font-semibold rounded-xl hover:bg-light-gray'
                >
                    Cancel
                </button>
                <button
                    block
                    size="lg"
                    onClick={() => handleSaveClientInfo()}
                    className='bg-bot-blue text-white text-xl px-4 py-2.5 font-semibold rounded-xl hover:bg-dark-bot-blue'
                >
                    Save
                </button>
            </div>
        </div>
    )
}
