import React from 'react';
import { Button } from 'react-bootstrap';

export default function BathButtons(props) {
    const { numBath, bath, setBath, bed, setBedBathTitle, handleFilter } = props;

    const handleBed = () => {
        setBath(numBath);
        setBedBathTitle(`Bd: ${bed}, Bth: ${numBath}`);
        handleFilter(numBath, '', 'bath');
    }
    
    return (
        <Button
            variant='primary'
            onClick={handleBed}
            className={(numBath === bath) ? 'bg-bot-blue text-white' : 'text-bot-blue hover:text-white'}
        >
            {numBath}
        </Button>
    )
}
