import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react';
import { supabase } from '../supabase';

export default function AddClientFavorite(props) {
    const { property, client, favoritesList, handleFavorite } = props;
    const [clientFavs, setClientFavs] = useState([])

    useEffect(() => {
        // get client favs
        const getClientFavs = async () => {
            try {
                let { data, error, status } = await supabase
                    .from('client_favorites')
                    .select(`saved_properties`)
                    .eq('id', `${client.id}`)
    
                if (error && status !== 406) {
                    throw error
                }
    
                if (data && data[0].saved_properties.length > 0) {
                    setClientFavs(data[0].saved_properties);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
        getClientFavs();

        // eslint-disable-next-line
    }, [])

    let initStatus = false;
    if (favoritesList !== null) {
        favoritesList.forEach((zpid) => {
            if (zpid === property.zpid) {
                initStatus = true;
            }
        })
    }
    const [addClientFavorite, setAddClientFavorite] = useState(initStatus);

    const handleCheckbox = async (newAddClientFavorite) => {
        if (newAddClientFavorite === false) {
            // add property to client's favorites
            clientFavs.push(property.zpid)
            try {
                const updates = {
                    id: client.id,
                    updated_at: new Date(),
                    saved_properties: clientFavs
                }
    
                let { error } = await supabase.from('client_favorites').upsert(updates)
    
                if (error) {
                    throw error
                } else {
                }
                setAddClientFavorite(!newAddClientFavorite);
                setClientFavs(clientFavs);
                handleFavorite(property, !newAddClientFavorite, null, client.id);
                
            } catch (error) {
                console.error(error.message)
            }
        } else {
            // remove property to client's favorites
            let newClientFavs = clientFavs
            newClientFavs = newClientFavs.length === 1 ? [] : (newClientFavs.filter(data => data !== property.zpid));
            try {
                const updates = {
                    id: client.id,
                    updated_at: new Date(),
                    saved_properties: newClientFavs
                }
    
                let { error } = await supabase.from('client_favorites').upsert(updates)
    
                if (error) {
                    throw error
                } else {
                    setAddClientFavorite(!newAddClientFavorite);
                    setClientFavs(newClientFavs);
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        }
    }
    
    return (
        <div className='flex justify-start items-center gap-3' onClick={() => handleCheckbox(addClientFavorite)}>
            <FormControlLabel
                control={
                    <Checkbox size="medium" checked={addClientFavorite} />
                }
                label={<p className='text-lg'>{client.name}</p>}
                className="text-lg"
            />
        </div>
    )
}