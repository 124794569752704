import React, { useEffect, useState } from 'react';
import { Form, Card, Collapse, Button, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowLeftRotate, faArrowRight, faXmark, faArrowUpFromBracket,
    faCheckCircle, faCopy, faMinus, faShare, faUpRightFromSquare, faPencil, faComment }
from '@fortawesome/free-solid-svg-icons';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import info_popup from '../assets/info_popup.jpg';
import loading_spinner from '../assets/loading_spinner.gif';
import "../index.css";
import { shareOnMobile } from "react-mobile-share";
// functions & components
import allFunctions from './Functions';
import ReactTooltip from 'react-tooltip';
import CurrencyInput from 'react-currency-input-field';
import ArrowIcon from '../components/ArrowIcon';
import ContactForm from './ContactForm';
import logo from '../assets/logo_icon.png';
import CompCard from '../components/CompCard';
import CompAnalysis from './CompAnalysis';
import RentalComps from './RentalComps';
import AboutDescription from '../components/AboutDescription';
import PropertyDetails from '../components/PropertyDetails';
import { Checkbox, FormControlLabel, Skeleton, Slider, Tooltip } from '@mui/material';
import { supabase } from '../supabase';
import HeartIcon from '../components/HeartIcon';
import AddClientFavorite from '../components/AddClientFavorite';
import Conversation from '../components/Conversation';
import { useRef } from 'react';


export default function Property(props) {
    // initial properties
    const { selectedProperty, defaults, setSelectedProperty, setShow, scrollToTop,
        propertyEdit, setPropertyEdit, propertyEdits, setPropertyEdits, session,
        userId, propertyId, prefModalShown, handleOpenExploreFeaturesModal,
        favoritesList, handleFavorite, setOpenLogin, setLoginHeader, activeClient,
        clients
    } = props;
    
    // parameters from URL
    const queryParameters = new URLSearchParams(window.location.search);
    const openChatFromURL = queryParameters.get("openchat");
    
    // local vars
    const [property, setProperty] = useState(selectedProperty);
    const [loading, setLoading] = useState(true);
    const [loadingComps, setLoadingComps] = useState(true);
    const [expand, setExpand] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [startSavingChanges, setStartSavingChanges] = useState(false);
    let [compProperties, setCompProperties] = useState([]);
    const [saveProperty, setSaveProperty] = useState(false)
    // initial variables
    let [pricePerSqFt, setPricePerSqFt] = useState(null);
    const [livingArea, setLivingArea] = useState(null);
    const [tourHome, setTourHome] = useState(false);
    
    // agent name
    let agentName = 'agent';

    const getYearZeroPropertyValues = (propertyEdit, property, defaults) => {
        // initial price
        let initPurchasePrice = (propertyEdit && propertyEdit.hasOwnProperty('price')) ? parseInt(propertyEdit.price) : parseInt((property.price === null) ? 0 : property.price)
        // closing costs
        const initClosingCosts = (propertyEdit && propertyEdit.hasOwnProperty('closingCosts'))
            ? +parseFloat(propertyEdit.closingCosts).toFixed(1)
            : (defaults.closingCostsType === '%')
                ? +parseFloat((defaults.closingCostsPct / 100) * initPurchasePrice).toFixed(1)
                : +parseFloat(defaults.closingCosts);
        const initClosingCostsPct = +parseFloat((initClosingCosts / initPurchasePrice) * 100).toFixed(1);
        
        let rents = [];
        let initUnits = (propertyEdit && propertyEdit.hasOwnProperty('rentEstimate') && propertyEdit.rentEstimate !== null)
            ? propertyEdit.rentEstimate
            : [{
                unitNum: 'Rent',
                unitRent: parseInt((property.rentEstimate === null) ? 0 : property.rentEstimate),
                unitRentProjected: parseInt((property.rentEstimate === null) ? 0 : property.rentEstimate),
            }];
        let totalRent = 0
        initUnits.forEach(initUnit => {
            rents.push(initUnit.unitRent);
            totalRent += parseInt(initUnit.unitRent);
        })
        
        // initial income
        let initLaundry = (propertyEdit && propertyEdit.hasOwnProperty('laundry')) ? parseInt(propertyEdit.laundry) : parseInt(defaults.laundry);
        let initStorage = (propertyEdit && propertyEdit.hasOwnProperty('storage')) ? parseInt(propertyEdit.storage) : parseInt(defaults.storage);
        let initMiscIncome = (propertyEdit && propertyEdit.hasOwnProperty('miscIncome')) ? parseInt(propertyEdit.miscIncome) : parseInt(defaults.miscIncome);
        let initTotalMonthlyIncome = totalRent + initLaundry + initStorage + initMiscIncome;

        // initial expenses
        const initRepairsPct = (propertyEdit && propertyEdit.hasOwnProperty('repairsPct'))
            ? +parseFloat(propertyEdit.repairsPct).toFixed(1)
            : (defaults.repairsType === '%')
                ? +parseFloat(defaults.repairsPct).toFixed(1)
                : +parseFloat((parseFloat(defaults.repairs / initTotalMonthlyIncome) * 100).toFixed(1));
        const initRepairs = +parseFloat(initTotalMonthlyIncome * (parseFloat(initRepairsPct) / 100)).toFixed(1);
        const initCapexPct = (propertyEdit && propertyEdit.hasOwnProperty('capexPct'))
                ? +parseFloat(propertyEdit.capexPct).toFixed(1)
                : (defaults.capexType === '%')
                    ? +parseFloat(defaults.capexPct).toFixed(1)
                    : +parseFloat((parseFloat(defaults.capex / initTotalMonthlyIncome) * 100).toFixed(1));
        const initCapex = +parseFloat(initTotalMonthlyIncome * (initCapexPct / 100)).toFixed(1);
        const initPropMgmtPct = (propertyEdit && propertyEdit.hasOwnProperty('propMgmtPct'))
                ? parseFloat(propertyEdit.propMgmtPct)
                : parseFloat(defaults.propMgnt);
        const initPropMgmt = +parseFloat(initTotalMonthlyIncome * (initPropMgmtPct / 100)).toFixed(1);
        const initVacancyPct = (propertyEdit && propertyEdit.hasOwnProperty('vacancyPct'))
                ? parseFloat(propertyEdit.vacancyPct)
                : parseFloat(defaults.vacancy);
        const initVacancy = +parseFloat(initTotalMonthlyIncome * (initVacancyPct / 100)).toFixed(1);

        // monthly mortgage for mortgage
        const initDownpaymentPct = (propertyEdit && propertyEdit.hasOwnProperty('downpaymentPct'))
                ? +parseFloat(propertyEdit.downpaymentPct).toFixed(3)
                : (defaults.downpaymentType === '%')
                    ? +parseFloat(defaults.downpaymentPct).toFixed(3)
                    : +parseFloat(parseInt(defaults.downpayment) / parseInt(initPurchasePrice) * 100).toFixed(3);
        const initDownpayment = (propertyEdit && propertyEdit.hasOwnProperty('downpayment'))
            ? parseInt(propertyEdit.downpayment)
            : (defaults.downpaymentType === '%')
                ? +parseInt(parseInt(initPurchasePrice) * (parseFloat(initDownpaymentPct) / 100)).toFixed(1)
                : +parseInt(defaults.downpayment).toFixed(1);
        const initInterestRate = (propertyEdit && propertyEdit.hasOwnProperty('interestRate')) ? +parseFloat(propertyEdit.interestRate).toFixed(3) : +parseFloat(defaults.interestRate).toFixed(3);
        const initLoanTerm = (propertyEdit && propertyEdit.hasOwnProperty('loanTerm')) ? parseInt(propertyEdit.loanTerm) : parseInt(defaults.loanTerm);
        const initTotalMonthlyMortgage = +parseInt(allFunctions.calcMortgage(parseInt(initPurchasePrice), parseInt(initDownpayment), parseFloat(initInterestRate), parseInt(initLoanTerm))).toFixed(0);
        let initMortgageInsurance = 0;
        if (initDownpaymentPct < 20) {
            initMortgageInsurance = (propertyEdit && propertyEdit.hasOwnProperty('mortgageInsurance'))
                ? propertyEdit.mortgageInsurance
                : parseInt(initTotalMonthlyMortgage * defaults.mortgageInsurancePct) * 12
        }

        let yearZeroValues = {
            "price": initPurchasePrice,
            "closingCosts": initClosingCosts,
            "closingCostsPct": initClosingCostsPct,
            "rehabCosts": (propertyEdit && propertyEdit.hasOwnProperty('rehab')) ? parseInt(propertyEdit.rehab) : parseInt(defaults.rehab),
            "miscCosts": (propertyEdit && propertyEdit.hasOwnProperty('miscCosts')) ? parseInt(propertyEdit.miscCosts) : parseInt(defaults.miscCosts),
            "rent": rents,
            "laundry": initLaundry,
            "storage": initStorage,
            "miscIncome": initMiscIncome,
            "downpayment": initDownpayment,
            "downpaymentPct": initDownpaymentPct,
            "interestRate": initInterestRate,
            "loanTerm": initLoanTerm,
            "taxes": (propertyEdit && propertyEdit.hasOwnProperty('taxes'))
                ? parseInt(propertyEdit.taxes)
                : parseInt(parseInt(initPurchasePrice) * (((property.propertyTaxRate === null) ? 0.0061 : property.propertyTaxRate) / 100) / 12),
            "insurance": (propertyEdit && propertyEdit.hasOwnProperty('insurance')) ? parseInt(propertyEdit.insurance) : parseInt(defaults.insurance),
            "mortgageInsurance": initMortgageInsurance,
            "hoa": (propertyEdit && propertyEdit.hasOwnProperty('monthlyHoaFee'))
            ? parseInt(propertyEdit.monthlyHoaFee)
            : parseInt((property.monthlyHoaFee > 0) ? property.monthlyHoaFee : 0),
            "utilitiesPaid": (propertyEdit && propertyEdit.hasOwnProperty('utilitiesPaid'))
                ? propertyEdit.utilitiesPaid
                : defaults.utilitiesPaid,
            "electricity": (propertyEdit && propertyEdit.hasOwnProperty('electricity'))
                ? parseInt(propertyEdit.electricity)
                : parseInt(defaults.electricity),
            "gas": (propertyEdit && propertyEdit.hasOwnProperty('gas'))
                ? parseInt(propertyEdit.gas)
                : parseInt(defaults.gas),
            "sewage": (propertyEdit && propertyEdit.hasOwnProperty('sewage'))
                ? parseInt(propertyEdit.sewage)
                : parseInt(defaults.sewage),
            "garbage": (propertyEdit && propertyEdit.hasOwnProperty('garbage'))
                ? parseInt(propertyEdit.garbage)
                : parseInt(defaults.garbage),
            "lawn": (propertyEdit && propertyEdit.hasOwnProperty('lawn'))
                ? parseInt(propertyEdit.lawn)
                : parseInt(defaults.lawn),
            "repairs": initRepairs,
            "repairsPct": initRepairsPct,
            "capex": initCapex,
            "capexPct": initCapexPct,
            "propMgmt": initPropMgmt,
            "propMgmtPct": initPropMgmtPct,
            "vacancy": initVacancy,
            "vacancyPct": initVacancyPct,
            "miscExpenses": (propertyEdit && propertyEdit.hasOwnProperty('miscExpenses')) ? parseInt(propertyEdit.miscExpenses) : parseInt(defaults.miscExpenses),
            "annualIncome": (propertyEdit && propertyEdit.hasOwnProperty('annualIncome')) ? parseInt(propertyEdit.annualIncome) : parseInt(defaults.personalAnnualIncome),
            "marketAppr": (propertyEdit && propertyEdit.hasOwnProperty('marketAppr')) ? parseInt(propertyEdit.marketAppr) : parseInt(defaults.cityAppr),
            "yearsProjected": (propertyEdit && propertyEdit.hasOwnProperty('yearsProjected')) ? parseInt(propertyEdit.yearsProjected) : parseInt(defaults.yearsProjected),
            "rentAppr": (propertyEdit && propertyEdit.hasOwnProperty('rentAppr')) ? parseFloat(propertyEdit.rentAppr) : parseFloat(defaults.rentAppr),
            "expensesAppr": (propertyEdit && propertyEdit.hasOwnProperty('expensesAppr')) ? parseFloat(propertyEdit.expensesAppr) : parseFloat(defaults.expensesAppr),
        }

        return yearZeroValues;
    }

    let initYearZeroPropertyValues = getYearZeroPropertyValues(propertyEdit, property, defaults);
    const [yearZeroPropertyValues, setYearZeroPropertyValues] = useState(initYearZeroPropertyValues);

    // get property data
    useEffect(() => {
        const getPropertyData = async (zpid) => {
            try {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, created_at, state, zipcode, community, neighborhood,
                        subdivision, bedrooms, bathrooms, brokerageName, compsZPIDs,
                        datePostedString, dateSoldString, daysOnZillow, description,
                        homeStatus, homeType, isListedByOwner, isNonOwnerOccupied,
                        isPreforeclosureAuction, isRecentStatusChange, brokerageName,
                        listingAgent, listingAgentPhone, listing_agent_profile_url,
                        livingArea, latitude, longitude, lotAreaValue, lotAreaUnits,
                        mlsId, monthlyHoaFee, moveInReady, photos, price, priceChange, mlsName,
                        propertyTaxRate, propertyTypeDimension, rentEstimateSource, taxAssessedValue,
                        taxAssessedYear, yearBuilt, zestimate, address, basement, mortgageRates,
                        priceChangeDate, rentEstimate, taxAnnualAmount, tenantPays, zoning,
                        zoningDescription, listingSubType, streetAddress, city, imgSrc, lastUpdated
                    `)
                    .eq('zpid', zpid)
                    .single()
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    setProperty(data);
                    setLivingArea(data.livingArea);
                    setPricePerSqFt(Math.round(data.price / data.livingArea));
                    setNumPhotos(data.photos.length);
                } else {
                    console.log("No data!");
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        }

        const getUserEdits = async (userId, newPropertyEdit) => {
            // get user edits from supabase
            try {
                let table = userId.includes('client') ? 'client' : 'profiles';
                let { data, error, status } = await supabase
                    .from(table)
                    .select(`property_edits`)
                    .eq('id', userId)
                
                if (error && status !== 406) {
                    throw error
                }
                
                if (userId && propertyId && data && data[0] && data[0].hasOwnProperty('property_edits') && data[0].property_edits.hasOwnProperty('properties')) {
                    if (data[0].property_edits.properties.hasOwnProperty(propertyId)) {
                        newPropertyEdit = data[0].property_edits.properties[propertyId];
                        setPropertyEdit(newPropertyEdit);
                    }
                    setPropertyEdits(data[0].property_edits);
                }
            } catch (error) {
                console.log(error.message);
            }
            
            // set all variables on page
            try {
                if (newPropertyEdit) {
                    setClosingCosts(newPropertyEdit.hasOwnProperty('closingCosts') ? newPropertyEdit.closingCosts : closingCosts)
                    setClosingCostsPct(newPropertyEdit.hasOwnProperty('closingCostsPct') ? newPropertyEdit.closingCostsPct : closingCostsPct)
                    setRehabCosts(newPropertyEdit.hasOwnProperty('rehab') ? newPropertyEdit.rehab : rehabCosts)
                    setMiscCosts(newPropertyEdit.hasOwnProperty('miscCosts') ? newPropertyEdit.miscCosts : miscCosts)
                    setUnits(newPropertyEdit.hasOwnProperty('rentEstimate') ? newPropertyEdit.rentEstimate : units)
                    setLaundry(newPropertyEdit.hasOwnProperty('laundry') ? newPropertyEdit.laundry : laundry)
                    setStorage(newPropertyEdit.hasOwnProperty('storage') ? newPropertyEdit.storage : storage)
                    setMiscIncome(newPropertyEdit.hasOwnProperty('miscIncome') ? newPropertyEdit.miscIncome : miscIncome)
                    setPurchasePrice(newPropertyEdit.hasOwnProperty('price') ? newPropertyEdit.price : purchasePrice)
                    setDownpaymentPct(newPropertyEdit.hasOwnProperty('downpaymentPct') ? newPropertyEdit.downpaymentPct : downpaymentPct)
                    setDownpayment(newPropertyEdit.hasOwnProperty('downpayment') ? newPropertyEdit.downpayment : downpayment)
                    setInterestRate(newPropertyEdit.hasOwnProperty('interestRate') ? newPropertyEdit.interestRate : interestRate)
                    setLoanTerm(newPropertyEdit.hasOwnProperty('loanTerm') ? newPropertyEdit.loanTerm : loanTerm)
                    setTaxes(newPropertyEdit.hasOwnProperty('taxes') ? newPropertyEdit.taxes : taxes)
                    setInsurance(newPropertyEdit.hasOwnProperty('insurance') ? newPropertyEdit.insurance : insurance)
                    setMortgageInsurance(newPropertyEdit.hasOwnProperty('mortgageInsurance') ? newPropertyEdit.mortgageInsurance : mortgageInsurance)
                    setHOA(newPropertyEdit.hasOwnProperty('monthlyHoaFee') ? newPropertyEdit.monthlyHoaFee : hoa)
                    setUtilitiesPaid(newPropertyEdit.hasOwnProperty('utilitiesPaid') ? newPropertyEdit.utilitiesPaid : utilitiesPaid)
                    setElectricity(newPropertyEdit.hasOwnProperty('electricity') ? newPropertyEdit.electricity : electricity)
                    setGas(newPropertyEdit.hasOwnProperty('gas') ? newPropertyEdit.gas : gas)
                    setSewage(newPropertyEdit.hasOwnProperty('sewage') ? newPropertyEdit.sewage : sewage)
                    setGarbage(newPropertyEdit.hasOwnProperty('garbage') ? newPropertyEdit.garbage : garbage)
                    setLawn(newPropertyEdit.hasOwnProperty('lawn') ? newPropertyEdit.lawn : lawn)
                    setRepairs(newPropertyEdit.hasOwnProperty('repairs') ? newPropertyEdit.repairs : repairs)
                    setRepairsPct(newPropertyEdit.hasOwnProperty('repairsPct') ? newPropertyEdit.repairsPct : repairsPct)
                    setCapex(newPropertyEdit.hasOwnProperty('capex') ? newPropertyEdit.capex : capex)
                    setCapexPct(newPropertyEdit.hasOwnProperty('capexPct') ? newPropertyEdit.capexPct : capexPct)
                    setpropMgmtPct(newPropertyEdit.hasOwnProperty('propMgmtPct') ? newPropertyEdit.propMgmtPct : propMgmtPct)
                    setpropMgmt(newPropertyEdit.hasOwnProperty('propMgmt') ? newPropertyEdit.propMgmt : propMgmt)
                    setVacancyPct(newPropertyEdit.hasOwnProperty('vacancyPct') ? newPropertyEdit.vacancyPct : vacancyPct)
                    setVacancy(newPropertyEdit.hasOwnProperty('vacancy') ? newPropertyEdit.vacancy : vacancy)
                    setMiscExpenses(newPropertyEdit.hasOwnProperty('miscExpenses') ? newPropertyEdit.miscExpenses : miscExpenses)
                    setMarketAppr(newPropertyEdit.hasOwnProperty('marketAppr') ? newPropertyEdit.marketAppr : marketAppr)
                    setAnnualIncome(newPropertyEdit.hasOwnProperty('annualIncome') ? newPropertyEdit.annualIncome : annualIncome)
                    setFilingStatus(newPropertyEdit.hasOwnProperty('filingStatus') ? newPropertyEdit.filingStatus : filingStatus)
                    setLandValue(newPropertyEdit.hasOwnProperty('landValue') ? newPropertyEdit.landValue : landValue)
                    setDeprPeriod(newPropertyEdit.hasOwnProperty('deprPeriod') ? newPropertyEdit.deprPeriod : deprPeriod)
                    setYearsProjected(newPropertyEdit.hasOwnProperty('yearsProjected') ? newPropertyEdit.yearsProjected : yearsProjected)
                    setRentAppr(newPropertyEdit.hasOwnProperty('rentAppr') ? newPropertyEdit.rentAppr : rentAppr)
                    setExpensesAppr(newPropertyEdit.hasOwnProperty('expensesAppr') ? newPropertyEdit.expensesAppr : expensesAppr)
                }
            } catch {
                setLoading(false);
            }
        }

        const getAndSetPropertiesViewed = async () => {
            // get properties viewed from supabase
            try {
                let { data, error } = await supabase
                    .from('client_shares')
                    .select(`id, properties_viewed, role, user_email`)
                    .eq('user_id', `${session.user.id}`)
    
                if (error) throw error

                if (data && data.length > 0) {
                    if (data[0].role !== "Owner") {
                        let propertiesViewed = data[0].properties_viewed.length > 0 ? data[0].properties_viewed : [];
                        const date = new Date();
                        const options = {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        };
                        const dateString = date.toLocaleString('en-US', options);
                        const propDetails = `${property.bedrooms} bd, ${property.bathrooms} ba, ${property.livingArea} sqft`
                        
                        // check if this property has been viewed before; if so, add timestamp
                        let duplicate = false;
                        propertiesViewed.forEach(prop => {
                            if (prop.id === property.zpid) {
                                prop.timeViewed.push(dateString);
                                duplicate = true;
                            }
                        })
                        
                        // add record if not already in there
                        if (duplicate === false) {
                            propertiesViewed.push({
                                "user": data[0].user_email,
                                "id": property.zpid,
                                "address": property.address,
                                "details": propDetails,
                                "timeViewed": [dateString]
                            })
                        }
                        // set to supabase
                        try {
                            const updates = {
                                id: data[0].id,
                                properties_viewed: propertiesViewed
                            }
                            let { error } = await supabase.from('client_shares').upsert(updates)
                            if (error) throw error
                        } catch (error) {
                            console.log(error.message)
                        }
                    }
                }
            } catch (error) {
                console.error(error.message);
            }
        }

        let newPropertyEdit = propertyEdit;
        if (propertyId && userId) {
            getUserEdits(userId, newPropertyEdit);
            getPropertyData(propertyId);
        } else if (propertyId) {
            getPropertyData(propertyId);
        } else if (selectedProperty) {
            getPropertyData(selectedProperty.zpid);
        }
        
        let initYearZeroPropertyValues = getYearZeroPropertyValues(newPropertyEdit, property, defaults);
        setYearZeroPropertyValues(initYearZeroPropertyValues);

        if (downpayment > purchasePrice) {
            setDownpayment(purchasePrice);
            setDownpaymentPct(100);
        }

        if (session) {
            getAndSetPropertiesViewed();
        }

        // eslint-disable-next-line
    }, [])

    const calcComps = async () => {
        if (property.compsZPIDs.length > 0) {
            try {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, address, bedrooms, bathrooms, price, imgSrc,
                        latitude, longitude, rentEstimate, rentEstimateSource,
                        monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                        livingArea, homeStatus, yearBuilt, datePostedString
                    `)
                    .or(`zpid.eq.${property.compsZPIDs[0]},zpid.eq.${property.compsZPIDs[1]},zpid.eq.${property.compsZPIDs[2]},zpid.eq.${property.compsZPIDs[3]},zpid.eq.${property.compsZPIDs[4]},zpid.eq.${property.compsZPIDs[5]},zpid.eq.${property.compsZPIDs[6]},zpid.eq.${property.compsZPIDs[7]},zpid.eq.${property.compsZPIDs[8]},zpid.eq.${property.compsZPIDs[9]}`)
                    .not('rentEstimate', 'is', null)
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    setCompProperties(data.slice(0, 4));
                } else {
                    console.log("No data!");
                }
            } finally {
                setLoadingComps(false);
            }
        } else {
            setLoadingComps(false);
        }
    }
    
    const notifyLoginMsg = () => toast.info(<p><span onClick={() => handleOpenLogin()} className='underline font-semibold'>Create an account</span> to save your changes.</p>, {
        toastId: 'defaultReminder',
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const handleOpenLogin = () => {
        setLoginHeader('Sign up to save edits');
        setOpenLogin(true);
    }

    // calculate comps
    let [calcCompsBool, setCalcCompsBool] = useState(false);
    if (property.hasOwnProperty('compsZPIDs') && calcCompsBool === false) {
        calcComps();
        setCalcCompsBool(true);
    }

    // appreciations
    const [yearsProjected, setYearsProjected] = useState(yearZeroPropertyValues.yearsProjected);
    const [rentAppr, setRentAppr] = useState(yearZeroPropertyValues.rentAppr);
    const [expensesAppr, setExpensesAppr] = useState(yearZeroPropertyValues.expensesAppr);

    // initial investment
    const [closingCosts, setClosingCosts] = useState(yearZeroPropertyValues.closingCosts);
    const [closingCostsPct, setClosingCostsPct] = useState(yearZeroPropertyValues.closingCostsPct);
    const [rehabCosts, setRehabCosts] = useState(yearZeroPropertyValues.rehabCosts);
    const [miscCosts, setMiscCosts] = useState(yearZeroPropertyValues.miscCosts);
    const totalOtherCosts = parseInt(closingCosts) + parseInt(rehabCosts) + parseInt(miscCosts);
    
    // get income at year projected
    let initUnits = [];
    let numUnits = 1
    yearZeroPropertyValues.rent.forEach(unit => {
        initUnits.push({
            unitNum: numUnits === 1 ? 'Rent' : `Unit ${numUnits} Rent`,
            unitRent: Math.round(unit),
            unitRentProjected: Math.round(unit),
        })
        numUnits += 1;
    })
    const [unitNumber, setUnitNumber] = useState(initUnits.length);
    const [units, setUnits] = useStateWithCallbackLazy(initUnits);
    const [laundry, setLaundry] = useState(yearZeroPropertyValues.laundry);
    const [storage, setStorage] = useState(yearZeroPropertyValues.storage);
    const [miscIncome, setMiscIncome] = useState(yearZeroPropertyValues.miscIncome);
    let totalMonthlyRent = 0
    units.forEach((units) => {
        totalMonthlyRent += parseInt(units.unitRent)
    })

    // mortgage
    const [marketAppr, setMarketAppr] = useState(yearZeroPropertyValues.marketAppr);
    const [purchasePrice, setPurchasePrice] = useState(yearZeroPropertyValues.price);
    const [downpaymentPct, setDownpaymentPct] = useState(yearZeroPropertyValues.downpaymentPct);
    const [downpayment, setDownpayment] = useState(yearZeroPropertyValues.downpayment);
    const [interestRate, setInterestRate] = useState(yearZeroPropertyValues.interestRate);
    const [loanTerm, setLoanTerm] = useState(yearZeroPropertyValues.loanTerm);
    const totalMonthlyMortgage = +parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), parseInt(downpayment), parseFloat(interestRate), parseInt(loanTerm))).toFixed(0);
    const totalInitialInvestment = parseInt(downpayment) + parseInt(totalOtherCosts);
    
    // expenses
    const [taxes, setTaxes] = useState(yearZeroPropertyValues.taxes);
    const [insurance, setInsurance] = useState(yearZeroPropertyValues.insurance);
    const [mortgageInsurance, setMortgageInsurance] = useState(yearZeroPropertyValues.mortgageInsurance);
    const [hoa, setHOA] = useState(yearZeroPropertyValues.hoa);
    const [utilitiesPaid, setUtilitiesPaid] = useState(yearZeroPropertyValues.utilitiesPaid);
    const [electricity, setElectricity] = useState(yearZeroPropertyValues.electricity);
    const [gas, setGas] = useState(yearZeroPropertyValues.gas);
    const [sewage, setSewage] = useState(yearZeroPropertyValues.sewage);
    const [garbage, setGarbage] = useState(yearZeroPropertyValues.garbage);
    const [lawn, setLawn] = useState(yearZeroPropertyValues.lawn);
    const [repairsPct, setRepairsPct] = useState(yearZeroPropertyValues.repairsPct);
    const [repairs, setRepairs] = useState(yearZeroPropertyValues.repairs);
    const [capexPct, setCapexPct] = useState(yearZeroPropertyValues.capexPct);
    const [capex, setCapex] = useState(yearZeroPropertyValues.capex);
    const [propMgmtPct, setpropMgmtPct] = useState(yearZeroPropertyValues.propMgmtPct);
    const [propMgmt, setpropMgmt] = useState(yearZeroPropertyValues.propMgmt);
    const [vacancyPct, setVacancyPct] = useState(yearZeroPropertyValues.vacancyPct);
    const [vacancy, setVacancy] = useState(yearZeroPropertyValues.vacancy);
    const [miscExpenses, setMiscExpenses] = useState(yearZeroPropertyValues.miscExpenses);

    // appreciation/taxes
    const [annualIncome, setAnnualIncome] = useState((propertyEdit && propertyEdit.hasOwnProperty('annualIncome')) ? propertyEdit.annualIncome : parseInt(defaults.personalAnnualIncome));
    const [filingStatus, setFilingStatus] = useState((propertyEdit && propertyEdit.hasOwnProperty('filingStatus')) ? propertyEdit.filingStatus : defaults.filingStatus);
    const [landValue, setLandValue] = useState((propertyEdit && propertyEdit.hasOwnProperty('landValue')) ? propertyEdit.landValue : (purchasePrice * 0.20));
    const [deprPeriod, setDeprPeriod] = useState((propertyEdit && propertyEdit.hasOwnProperty('deprPeriod')) ? propertyEdit.deprPeriod : +parseFloat(defaults.deprPeriod).toFixed(1));
    // You don't get any return at year 0. Nothing has appreciated or depreciated. Only 1+ years gets these benefits.
    let principal = (purchasePrice - parseInt(downpayment))
    const taxBracket = parseFloat(allFunctions.calcTaxBracket(parseInt(annualIncome), filingStatus));

    //=======================================================================================
    //                              FUTURE PROJECTIONS
    //
    
    // appreciations
    let newAppr = (yearsProjected === 0) ? 1 : Math.pow((1 + (parseFloat(marketAppr) / 100)), yearsProjected);
    let newRentAppr = (yearsProjected === 0) ? 1 : Math.pow((1 + (parseFloat(rentAppr) / 100)), yearsProjected);
    let newExpenseAppr = (yearsProjected === 0) ? 1 : Math.pow((1 + (parseFloat(expensesAppr) / 100)), yearsProjected);

    // price
    let newPriceProjection = Math.round(parseInt(purchasePrice) * newAppr);

    // income
    let newTotalMonthlyIncomeProjection = 0;
    let unitsProjection = [];
    units.forEach((unit, index) => {
        unitsProjection.push({
            unitNum: index === 0 ? 'Rent' : `Unit ${index + 1} Rent`,
            unitRent: unit.unitRent,
            unitRentProjected: Math.round(unit.unitRent * newRentAppr),
        })
        newTotalMonthlyIncomeProjection += Math.round(unit.unitRent * newRentAppr)
    })
    let laundryProjection = Math.round(laundry * newRentAppr);
    let storageProjection = Math.round(storage * newRentAppr);
    let miscIncomeProjection = Math.round(miscIncome * newRentAppr);
    let totalOtherIncomeProjection = laundryProjection + storageProjection + miscIncomeProjection;
    newTotalMonthlyIncomeProjection += totalOtherIncomeProjection;
    
    // expenses
    let taxesProjection = Math.round(taxes * newExpenseAppr);
    let insuranceProjection = Math.round(insurance * newExpenseAppr);
    let hoaProjection = Math.round(hoa * newExpenseAppr);
    let lawnProjection = Math.round(lawn * newExpenseAppr);
    let electricityProjection = Math.round(electricity * newExpenseAppr);
    let gasProjection = Math.round(gas * newExpenseAppr);
    let sewageProjection = Math.round(sewage * newExpenseAppr);
    let garbageProjection = Math.round(garbage * newExpenseAppr);
    let totalUtilitiesProjection = lawnProjection + electricityProjection + gasProjection + sewageProjection + garbageProjection
    let repairsProjection = Math.round((parseInt(repairsPct) / 100) * newTotalMonthlyIncomeProjection);
    let capexProjection = Math.round((parseInt(capexPct) / 100) * newTotalMonthlyIncomeProjection);
    let propMgmtProjection = Math.round((parseInt(propMgmtPct) / 100) * newTotalMonthlyIncomeProjection);
    let vacancyProjection = Math.round((parseInt(vacancyPct) / 100) * newTotalMonthlyIncomeProjection);
    let miscExpensesProjection = Math.round(miscExpenses * newExpenseAppr);
    let newOperatingExpensesProjection = taxesProjection + insuranceProjection + hoaProjection + repairsProjection + capexProjection
        + propMgmtProjection + vacancyProjection + miscExpensesProjection + (utilitiesPaid ? 0 : totalUtilitiesProjection)
    let newNetOperatingIncomeProjection = newTotalMonthlyIncomeProjection - newOperatingExpensesProjection;
    let newAnnualNetOperatingIncomeProjection = newNetOperatingIncomeProjection * 12;
    let newTotalMonthlyExpensesProjection = newOperatingExpensesProjection + totalMonthlyMortgage + parseInt(mortgageInsurance)
    
    // cash flow, cap rate, cash on cash
    let newMonthlyCashFlowProjection = newTotalMonthlyIncomeProjection - newTotalMonthlyExpensesProjection;
    let newAnnualCashFlowProjection = newTotalMonthlyIncomeProjection - newTotalMonthlyExpensesProjection;
    let capRateDenominator = (yearsProjected === 0) ? (parseInt(purchasePrice) + totalOtherCosts) : (newPriceProjection);
    let newCapRateProjection = +parseFloat((newAnnualNetOperatingIncomeProjection / capRateDenominator) * 100).toFixed(1);
    let newCashOnCashProjection = +parseFloat((newAnnualCashFlowProjection / totalInitialInvestment) * 100).toFixed(1);
    
    newMonthlyCashFlowProjection = newMonthlyCashFlowProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (newMonthlyCashFlowProjection.substring(0, 1) === "-") {
        newMonthlyCashFlowProjection = `-$${newMonthlyCashFlowProjection.substring(1, newMonthlyCashFlowProjection.length)}`;
    } else {
        newMonthlyCashFlowProjection = `$${newMonthlyCashFlowProjection}`;
    }

    // total return
    let numMonths = 12 * yearsProjected;
    // equity
    let totalEquityProjection = allFunctions.calcEquity(parseInt(purchasePrice), principal, parseFloat(interestRate), (parseInt(loanTerm) * 12), numMonths)
    let addedEquityProjection = totalEquityProjection - downpayment;
    // appreciation
    let newAppreciationProjection = newPriceProjection - parseInt(purchasePrice);
    // interest
    let totalInterestProjection = (yearsProjected === 0) ? 0 : Math.round(allFunctions.calcInterest(parseInt(principal), parseInt(interestRate), parseInt(loanTerm * 12), (yearsProjected === 30 ? numMonths - 1 : numMonths)));
    let totalInterestTaxSavingsProjection = Math.round(parseInt(totalInterestProjection) * (parseFloat(taxBracket) / 100));
    // depreciation
    let totalDepreciationProjection = Math.round((parseInt(purchasePrice) - parseInt(landValue)) / parseFloat(deprPeriod)) * yearsProjected;
    let depreciationDeducationSavingsProjection = Math.round(parseInt(totalDepreciationProjection) * (parseFloat(taxBracket) / 100));
    // total & selling costs
    let totalAppreciationandTaxesProjection = Math.round(newAppreciationProjection + totalInterestTaxSavingsProjection + depreciationDeducationSavingsProjection);
    let estSellingCostsProjection = newPriceProjection * ((parseFloat(defaults.buyersAgent) + parseFloat(defaults.sellersAgent)) / 100);
    
    // over the year values
    let allTotalReturns = 0;
    let cummulativeCashFlow = 0;
    for (let year = 1; year < yearsProjected; year++) {
        // appreciations
        let newMarketAppr = Math.pow((1 + (parseFloat(marketAppr) / 100)), year);
        let newRentAppr = Math.pow((1 + (parseFloat(rentAppr) / 100)), year);
        let newExpenseAppr = Math.pow((1 + (parseFloat(expensesAppr) / 100)), year);

        // income
        let totalMonthlyIncomeTemp = 0;
        let unitsProjection = [];
        units.forEach((unit, index) => {
            unitsProjection.push({
                unitNum: index === 0 ? 'Rent' : `Unit ${index + 1} Rent`,
                unitRent: unit.unitRent,
                unitRentProjected: Math.round(unit.unitRent * newRentAppr),
            })
            totalMonthlyIncomeTemp += Math.round(unit.unitRent * newRentAppr)
        })
        let laundryTemp = Math.round(laundry * newRentAppr);
        let storageTemp = Math.round(storage * newRentAppr);
        let miscIncomeTemp = Math.round(miscIncome * newRentAppr);
        totalMonthlyIncomeTemp += (laundryTemp + storageTemp + miscIncomeTemp);
        
        // expenses
        let taxesTemp = Math.round(taxes * newExpenseAppr);
        let insuranceTemp = Math.round(insurance * newExpenseAppr);
        let hoaTemp = Math.round(hoa * newExpenseAppr);
        let lawnTemp = Math.round(lawn * newExpenseAppr);
        let electricityTemp = Math.round(electricity * newExpenseAppr);
        let gasTemp = Math.round(gas * newExpenseAppr);
        let sewageTemp = Math.round(sewage * newExpenseAppr);
        let garbageTemp = Math.round(garbage * newExpenseAppr);
        let totalUtilitiesTemp = lawnTemp + electricityTemp + gasTemp + sewageTemp + garbageTemp;
        let repairsTemp = Math.round((parseInt(repairsPct) / 100) * totalMonthlyIncomeTemp);
        let capexTemp = Math.round((parseInt(capexPct) / 100) * totalMonthlyIncomeTemp);
        let propMgmtTemp = Math.round((parseInt(propMgmtPct) / 100) * totalMonthlyIncomeTemp);
        let vacancyTemp = Math.round((parseInt(vacancyPct) / 100) * totalMonthlyIncomeTemp);
        let miscExpensesTemp = Math.round(miscExpenses * newExpenseAppr);
        let operatingExpensesTemp = taxesTemp + insuranceTemp + hoaTemp + repairsTemp + repairsTemp
            + capexTemp + propMgmtTemp + vacancyTemp + miscExpensesTemp + (utilitiesPaid ? 0 : totalUtilitiesTemp)
        let totalMonthlyExpensesTemp = operatingExpensesTemp + totalMonthlyMortgage + parseInt(mortgageInsurance)
        
        // cash flow, cap rate, cash on cash
        let yearCashFlow = (totalMonthlyIncomeTemp - totalMonthlyExpensesTemp) * 12;

        // cumulative cash flow
        cummulativeCashFlow += yearCashFlow;

        // total return
        let priceProjectionTemp = purchasePrice * newMarketAppr;
        numMonths = 12 * year;
        // equity
        // UPDATE TO TEMP ONLY
        let totalEquityProjectionTemp = allFunctions.calcEquity(parseInt(purchasePrice), principal, parseFloat(interestRate), (parseInt(loanTerm) * 12), numMonths)
        let addedEquityProjectionTemp = totalEquityProjectionTemp - downpayment;
        // appreciation
        let newAppreciationProjectionTemp = priceProjectionTemp - parseInt(purchasePrice);
        // interest
        let totalInterestProjectionTemp = (year === 0) ? 0 : Math.round(allFunctions.calcInterest(parseInt(principal), parseInt(interestRate), parseInt(loanTerm * 12), (year === 30 ? numMonths - 1 : numMonths)));
        let totalInterestTaxSavingsProjectionTemp = Math.round(parseInt(totalInterestProjectionTemp) * (parseFloat(taxBracket) / 100));
        // depreciation
        let totalDepreciationProjectionTemp = Math.round((parseInt(purchasePrice) - parseInt(landValue)) / parseFloat(deprPeriod)) * year;
        let depreciationDeducationSavingsProjectionTemp = Math.round(parseInt(totalDepreciationProjectionTemp) * (parseFloat(taxBracket) / 100));
        // total return
        let totalAppreciationandTaxesProjectionTemp = Math.round(newAppreciationProjectionTemp + totalInterestTaxSavingsProjectionTemp + depreciationDeducationSavingsProjectionTemp);
        let estSellingCostsProjectionTemp = (year === yearsProjected) ? priceProjectionTemp * ((parseFloat(defaults.buyersAgent) + parseFloat(defaults.sellersAgent)) / 100) : 0;
        let totalReturnCashProjectionTemp = +parseFloat((cummulativeCashFlow + addedEquityProjectionTemp + totalAppreciationandTaxesProjectionTemp - estSellingCostsProjectionTemp).toFixed(1));
        let totalReturnTemp = parseFloat(totalReturnCashProjectionTemp / totalInitialInvestment) * 100;
        allTotalReturns += totalReturnTemp;
    }
    // total
    let newTotalReturnProjection = (yearsProjected === 0)
        ? 'N/A'
        : +parseFloat((allTotalReturns / yearsProjected).toFixed(1));

    newPriceProjection = newPriceProjection.toLocaleString(undefined, { maximumFractionDigits: 2 });
    
    // icons
    const [initArrow, setInitArrow] = useState(false);
    const [incomeArrow, setIncomeArrow] = useState(false);
    const [otherIncomeArrow, setOtherIncomeArrow] = useState(false);
    const [expenseArrow, setExpenseArrow] = useState(false);
    const [operatingExpenseArrow, setOperatingExpenseArrow] = useState(false);
    const [utilitiesArrow, setUtilitiesArrow] = useState(false);
    const [mortgageArrow, setMortgageArrow] = useState(false);
    const [apprBenefitsArrow, setApprBenefitsArrow] = useState(false);
    const [appreciationArrow, setAppreciationArrow] = useState(false);
    const [taxBenefitsArrow, setTaxBenefitsArrow] = useState(false);
    const [depreciationDeductionArrow, setDepreciationDeductionArrow] = useState(false);
    
    // collapsibles
    const [initInvestmentCard, setInitInvestmentCard] = useState(false);
    const [incomeCard, setIncomeCard] = useState(false);
    const [expenseCard, setExpenseCard] = useState(false);
    const [operatingExpenseCard, setOperatingExpenseCard] = useState(false);
    const [mortgageCard, setMortgageCard] = useState(false);
    const [appreciationCard, setAppreciationCard] = useState(false);
    const [otherIncome, setOtherIncome] = useState(false);
    const [utilities, setUtilities] = useState(false);
    const [taxBenefits, setTaxBenefits] = useState(false);
    const [assetAppreciation, setAssetAppreciation] = useState(false);
    const [depreciationDeducation, setDepreciationDeducation] = useState(false);
    
    // Modals
    const [openAgentModal, setOpenAgentModal] = useState(false);
    const [openCompsModal, setOpenCompsModal] = useState(false);
    const [openRentalComps, setOpenRentalComps] = useState(false);
    const [photoModal, setPhotoModal] = useState(false);
    const [openAdjustsProjectionsModal, setOpenAdjustsProjectionsModal] = useState(false);
    const [openClientFavorites, setOpenClientFavorites] = useState(false);
    const [openConversationModal, setOpenConversationModal] = useState(false);
    
    // photos
    const [photoNum, setPhotoNum] = useState(0);
    const [numPhotos, setNumPhotos] = useState(null);

    // listing agent
    let listingAgentPhone = ''
    if (property.listingAgentPhone !== null) {
        listingAgentPhone = `: ${property.listingAgentPhone}`;
    }

    const notifySuccessMsg = (msg) => toast.success(<p className='font-semibold text-gray'>{msg}</p>, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const copyValue = (value, msg) => {
        navigator.clipboard.writeText(value);
        notifySuccessMsg(msg);
    }

    // last updated
    let lastUpdated = '';
    if (property && property.hasOwnProperty('lastUpdated')) {
        if (property.lastUpdated !== undefined && property.lastUpdated !== null && property.lastUpdated !== '') {
            lastUpdated = new Date(`${property.lastUpdated}`).toString().split(" ");
            lastUpdated = `${lastUpdated[1]} ${lastUpdated[2]}, ${lastUpdated[3]}`;
        }
    }
    
    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    // update property edits
    const updatePropertyEdits = async (key, value) => {
        if (session || (propertyId && userId)) {
            setStartSavingChanges(true);
            setSavingChanges(true);
            // suggest set preferences five seconds after
            if (prefModalShown === false) {
                setTimeout(function() { handleOpenExploreFeaturesModal() }, 500);
            }

            let newPropertyEdits = (propertyEdits && propertyEdits.hasOwnProperty('properties')) ? propertyEdits : {};

            if (newPropertyEdits && newPropertyEdits.hasOwnProperty('properties')) {
                if (newPropertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                    newPropertyEdits.properties[`${property.zpid}`][key] = value;
                } else {
                    newPropertyEdits.properties[`${property.zpid}`] = {};
                    newPropertyEdits.properties[`${property.zpid}`][key] = value;
                }
            } else {
                newPropertyEdits['properties'] = {};
                newPropertyEdits.properties[`${property.zpid}`] = {};
                newPropertyEdits.properties[`${property.zpid}`][key] = value;
            }

            // save both value and percentage
            if (key === "downpayment" || key === "downpaymentPct") {
                newPropertyEdits.properties[`${property.zpid}`]['downpayment'] = downpayment;
                newPropertyEdits.properties[`${property.zpid}`]['downpaymentPct'] = downpaymentPct;
            } else if (key === "closingCosts" || key === "closingCostsPct") {
                newPropertyEdits.properties[`${property.zpid}`]['closingCosts'] = closingCosts;
                newPropertyEdits.properties[`${property.zpid}`]['closingCostsPct'] = closingCostsPct;
            } else if (key === "repairs" || key === "repairsPct") {
                newPropertyEdits.properties[`${property.zpid}`]['repairs'] = repairs;
                newPropertyEdits.properties[`${property.zpid}`]['repairsPct'] = repairsPct;
            } else if (key === "vacancy" || key === "vacancyPct") {
                newPropertyEdits.properties[`${property.zpid}`]['vacancy'] = vacancy;
                newPropertyEdits.properties[`${property.zpid}`]['vacancyPct'] = vacancyPct;
            } else if (key === "capex" || key === "capexPct") {
                newPropertyEdits.properties[`${property.zpid}`]['capex'] = capex;
                newPropertyEdits.properties[`${property.zpid}`]['capexPct'] = capexPct;
            } else if (key === "propMgmt" || key === "propMgmtPct") {
                newPropertyEdits.properties[`${property.zpid}`]['propMgmt'] = propMgmt;
                newPropertyEdits.properties[`${property.zpid}`]['propMgmtPct'] = propMgmtPct;
            }
            
            // set new property edits to supabase
            let newId = session.user.id;
            if (userId) {
                newId = userId;
            } else if (activeClient) {
                newId = activeClient.id
            }

            try {
                const updates = {
                    id: newId,
                    updated_at: new Date(),
                    property_edits: newPropertyEdits
                }

                let { error } = await supabase.from(activeClient ? 'client' : 'profiles').upsert(updates);
                setPropertyEdits(newPropertyEdits);

                if (error) throw error
            } catch (error) {
                console.error(error.message)
            }
            setSavingChanges(false);
        } else {
            notifyLoginMsg()
        }
    }
    
    const handleChangeInput = (index, event) => {
        event = (event === undefined) ? 0 : event;
        const values = [...units];
        values[index].unitRent = parseInt(event);
        setUnits(values);
        
        // set initial rents
        let rents = [];
        values.forEach(unit => {
            rents.push(unit.unitRent)
        })
        yearZeroPropertyValues.rent = rents;
        setYearZeroPropertyValues(yearZeroPropertyValues);
        
        // recalculate income
        let totalMonthlyRent = 0
        values.forEach((units) => {
            totalMonthlyRent += parseInt(units.unitRent)
        })
        
        // update repairs
        let newRepairs = +((parseFloat(repairsPct) / 100) * totalMonthlyRent).toFixed(1);
        setRepairs((repairsPct === 0) ? 0 : newRepairs);
        
        // update capex
        let newCapex = +((capexPct === 0) ? 0 : ((capexPct / 100) * totalMonthlyRent)).toFixed(1);
        setCapex(newCapex);

        // update property management
        let newPropMgnt = +((parseFloat(propMgmtPct) / 100) * totalMonthlyRent).toFixed(0);
        setpropMgmt((propMgmtPct === 0) ? 0 : newPropMgnt);
        
        // update vacancy
        let newVacancy = +((vacancyPct === 0) ? 0 : ((vacancyPct / 100) * totalMonthlyRent)).toFixed(0);
        setVacancy(newVacancy);
    }

    const handleCollapse = (valueCollapse, changeCollapse, valueArrow, changeArrow) => {
        changeCollapse(!valueCollapse);
        changeArrow(!valueArrow);
    }
    
    const afterSetUnitsFinished = () => {
        // focus on the newly created input box
        let incomeInputs = document.getElementsByClassName('w-24 pl-2 mt-3 border-one rounded-sm unique');
        incomeInputs[incomeInputs.length - 1].focus();
        incomeInputs[incomeInputs.length - 1].select();
    }

    const handleAddUnits = () => {
        setUnitNumber(unitNumber + 1)
        let newUnitNumber = unitNumber + 1
        setUnits([...units, { unitNum: `Unit ${newUnitNumber} Rent`, unitRent: 0 }], () => {
            afterSetUnitsFinished();
        });
    }

    const handleRemoveUnits = () => {
        if (unitNumber > 1) {
            setUnitNumber(unitNumber - 1);
            const values = [...units];
            values.pop();
            setUnits(values);
            updatePropertyEdits('rentEstimate', values);
        }
    }

    // update purchase price and all values that depend on it
    const handlePurchasePrice = (e) => {
        let newPurchasePrice = (e === 0) ? 0 : Number(e.replace(/[^0-9.-]+/g,""));
        setPurchasePrice(newPurchasePrice);
        yearZeroPropertyValues.price = newPurchasePrice;
        setYearZeroPropertyValues(yearZeroPropertyValues);
        // update closing costs
        let newClosingCosts = +((closingCostsPct / 100) * newPurchasePrice).toFixed(0);
        setClosingCosts(newClosingCosts);
        // update downpayment pct
        let newDownpaymentPct = +parseFloat(downpaymentPct).toFixed(1)
        setDownpaymentPct(newDownpaymentPct);
        // update downpayment
        let newDownpayment = +((downpaymentPct === 0) ? 0 : parseFloat(downpaymentPct / 100) * newPurchasePrice).toFixed(0);
        setDownpayment(newDownpayment);
        // update insurance
        let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(newPurchasePrice), (parseFloat(downpaymentPct / 100) * newPurchasePrice), parseFloat(interestRate), parseInt(loanTerm))));
        let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * defaults.mortgageInsurancePct) * 12);
        newMortgageInsurance = (parseInt(downpaymentPct) < 20) ? newMortgageInsurance : 0
        setMortgageInsurance(newMortgageInsurance);
        // update property taxes
        let newTaxes = Math.round(parseFloat(parseInt(newPurchasePrice) * (((property.propertyTaxRate === null) ? 0.0061 : property.propertyTaxRate) / 100)) / 12)
        setTaxes(newTaxes);
    }

    const handleDownpayment = (e) => {
        let newDownpayment = parseInt(e);
        setDownpayment(newDownpayment);
        let newDownpaymentPct = (e === 0) ? 0 : +(parseFloat(e / parseInt(purchasePrice)) * 100).toFixed(3);
        setDownpaymentPct(newDownpaymentPct);
        let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), parseInt(e), parseFloat(interestRate), parseInt(loanTerm))));
        let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * defaults.mortgageInsurancePct) * 12);
        newMortgageInsurance = (parseInt(parseFloat(e / parseInt(purchasePrice)) * 100) < 20) ? newMortgageInsurance : 0
        setMortgageInsurance(newMortgageInsurance);
    }
    
    const handleDownpaymentPct = (e) => {
        let newDownpaymentPct = e;
        setDownpaymentPct(newDownpaymentPct);
        if (e.charAt(e.length - 1) !== ".") {
            newDownpaymentPct = parseFloat(newDownpaymentPct);
            let newDownpayment = +((e === 0) ? 0 : parseFloat(e / 100) * purchasePrice).toFixed(0);
            setDownpayment(newDownpayment);
            let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), (parseFloat(e / 100) * purchasePrice), parseFloat(interestRate), parseInt(loanTerm))));
            let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * defaults.mortgageInsurancePct) * 12);
            newMortgageInsurance = (parseInt(e) < 20) ? newMortgageInsurance : 0
            setMortgageInsurance(newMortgageInsurance);
        }
    }

    const handleClosingCosts = (e) => {
        setClosingCosts(e);
        let newClosingCostsPct = +((parseFloat(e) / purchasePrice) * 100).toFixed(1);
        setClosingCostsPct(+((e === 0) ? 0 : newClosingCostsPct).toFixed(1));
    }
    
    const handleClosingCostsPct = (e) => {
        setClosingCostsPct(e);
        if (e.charAt(e.length - 1) !== ".") {
            let newClosingCosts = +((parseFloat(e) / 100) * purchasePrice).toFixed(0);
            setClosingCosts((e === 0) ? 0 : newClosingCosts);
        }
    }
    
    const handleRepairs = (e) => {
        setRepairs(e);
        let newRepairsPct = +((parseFloat(e) / totalMonthlyRent) * 100).toFixed(1);
        setRepairsPct(+((e === 0) ? 0 : newRepairsPct).toFixed(1));
    }
    
    const handleRepairsPct = (e) => {
        setRepairsPct(e);
        if (e.charAt(e.length - 1) !== ".") {
            let newRepairs = +((parseFloat(e) / 100) * totalMonthlyRent).toFixed(0);
            setRepairs((e === 0) ? 0 : newRepairs);
        }
    }

    const handleCapex = (e) => {
        setCapex(e);
        let newCapexPct = +((parseFloat(e) / totalMonthlyRent) * 100).toFixed(1);
        setCapexPct(+((e === 0) ? 0 : newCapexPct).toFixed(1));
    }
    
    const handleCapexPct = (e) => {
        setCapexPct(e);
        if (e.charAt(e.length - 1) !== ".") {
            let newCapex = +((parseFloat(e) / 100) * totalMonthlyRent).toFixed(0);
            setCapex((e === 0) ? 0 : newCapex);
        }
    }

    const handlePropMgmt = (e) => {
        setpropMgmt(e);
        let newPropMgntPct = +((parseFloat(e) / totalMonthlyRent) * 100).toFixed(1);
        setpropMgmtPct(+((e === 0) ? 0 : newPropMgntPct).toFixed(1));
    }
    
    const handlePropMgmtPct = (e) => {
        setpropMgmtPct(e);
        if (e.charAt(e.length - 1) !== ".") {
            let newPropMgnt = +((parseFloat(e) / 100) * totalMonthlyRent).toFixed(0);
            setpropMgmt((e === 0) ? 0 : newPropMgnt);
        }
    }
    
    const handleVacancy = (e) => {
        setVacancy(e);
        setVacancyPct(+((e === 0) ? 0 : ((e / totalMonthlyRent) * 100)).toFixed(1));
    }
    
    const handleVacancyPct = (e) => {
        setVacancyPct(e);
        if (e.charAt(e.length - 1) !== ".") {
            setVacancy(+((e === 0) ? 0 : ((e / 100) * totalMonthlyRent)).toFixed(0));
        }
    }
    
    const handlePhotoNum = (addOrRemove) => {
        if ((photoNum < 1) && (addOrRemove === -1)) {
            setPhotoNum(numPhotos - 1);
        } else if ((photoNum >= (numPhotos - 1) && (addOrRemove === 1))) {
            setPhotoNum(0);
        } else {
            setPhotoNum(photoNum + addOrRemove);
        }
    }
    
    const handlePhotoModal = (isMobile) => {
        if (isMobile === false) {
            setPhotoModal(true);
        }
    }

    const handleFilingStatus = (value) => {
        setFilingStatus(value);
        updatePropertyEdits('filingStatus', value);
    }

    const handleUtilitiesPaid = () => {
        setUtilitiesPaid(!utilitiesPaid);
        updatePropertyEdits('utilitiesPaid', !utilitiesPaid);
    }
    
    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    const handleSetValues = (value, setValue, label) => {
        setValue(value);
        yearZeroPropertyValues[label] = value;
        setYearZeroPropertyValues(yearZeroPropertyValues);
    }

    const handleExpandCollapse = (newExpand) => {
        setInitInvestmentCard(newExpand)
        setIncomeCard(newExpand)
        setExpenseCard(newExpand)
        setOperatingExpenseCard(newExpand)
        setMortgageCard(newExpand)
        setAppreciationCard(newExpand)
        setOtherIncome(newExpand)
        setUtilities(newExpand)
        setTaxBenefits(newExpand)
        setAssetAppreciation(newExpand)
        setDepreciationDeducation(newExpand)
        setExpand(newExpand);
    }

    const copyPropertyLink = () => {
        let urlToProperty = window.location.href;
        if (session && !urlToProperty.includes('&user=')) {
            urlToProperty += `&user=${activeClient ? activeClient.id : session.user.id}`;
        }
        urlToProperty = urlToProperty.replace('dashboard', 'search');
        navigator.clipboard.writeText(urlToProperty);
        notifySuccessMsg('Link copied!');
    }

    const handleResetEdits = async () => {
        const RESET_YEARS_PROJECTED = parseInt(defaults.yearsProjected);
        // set all properties to their original values
        setYearsProjected(RESET_YEARS_PROJECTED);
        setRentAppr(defaults.rentAppr);
        // initial investment
        let newPurchasePrice = parseInt((property.price === null) ? 0 : property.price);
        let newClosingCost = (defaults.closingCostsType === '%')
            ? +parseFloat((parseFloat(defaults.closingCostsPct) / 100) * newPurchasePrice).toFixed(3)
            : +parseInt(defaults.closingCosts)
        let newClosingCostPct = (defaults.closingCostsType === '%')
            ? +parseFloat(defaults.closingCostsPct).toFixed(3)
            : +parseFloat(parseInt(defaults.closingCosts) / newPurchasePrice)
        setClosingCosts(newClosingCost);
        setClosingCostsPct(newClosingCostPct);
        setRehabCosts(defaults.rehab);
        setMiscCosts(defaults.miscCosts);
        // income
        setUnitNumber(1)
        setUnits([{
            unitNum: 'Rent',
            unitRent: property.rentEstimate,
            unitRentProjected: Math.round(property.rentEstimate)
        }])
        let newTotalMonthlyRent = Math.round(property.rentEstimate)
        setLaundry(parseInt(defaults.laundry))
        setStorage(parseInt(defaults.storage))
        setMiscIncome(parseInt(defaults.miscIncome))
        // mortgage
        setPurchasePrice(newPurchasePrice)
        let newDownpaymentPct = (defaults.downpaymentType === '%')
            ? +parseFloat(defaults.downpaymentPct).toFixed(3)
            : +parseFloat(parseInt(defaults.downpayment) / parseInt(newPurchasePrice) * 100).toFixed(3)
        setDownpaymentPct(newDownpaymentPct)
        let newDownpayment = (defaults.downpaymentType === '%')
            ? +parseInt(parseInt(newPurchasePrice) * (parseFloat(newDownpaymentPct) / 100)).toFixed(1)
            : +parseInt(defaults.downpayment).toFixed(1)
        setDownpayment(newDownpayment)
        setInterestRate(+parseFloat(defaults.interestRate).toFixed(3))
        setLoanTerm(parseInt(defaults.loanTerm))
        let newTotalMonthlyMortgage = +parseInt(allFunctions.calcMortgage(newPurchasePrice, newDownpayment, +parseFloat(defaults.interestRate).toFixed(3), parseInt(defaults.loanTerm))).toFixed(0);
        // expenses
        setExpensesAppr(defaults.expensesAppr);
        setTaxes((defaults.taxes !== null && defaults.taxes !== '' && defaults.taxes !== undefined)
            ? parseInt(defaults.taxes)
            : parseInt(parseInt(newPurchasePrice) * (((property.propertyTaxRate === null) ? 0.0061 : property.propertyTaxRate) / 100) / 12))
        setInsurance(parseInt(defaults.insurance))
        // PMI
        let mortgageInsurancePct = parseFloat(defaults.mortgageInsurancePct);
        let initMortgageInsurance = (newDownpaymentPct < 20) ? (parseInt(newTotalMonthlyMortgage * mortgageInsurancePct) * 12) : 0;
        setMortgageInsurance(initMortgageInsurance)
        setHOA(Math.round(parseInt((property.monthlyHoaFee > 0) ? property.monthlyHoaFee : 0)))
        setUtilitiesPaid(defaults.utilitiesPaid)
        setElectricity(parseInt(defaults.electricity))
        setGas(parseInt(defaults.gas))
        setSewage(parseInt(defaults.sewage))
        setGarbage(parseInt(defaults.garbage))
        setLawn(parseInt(defaults.lawn))
        setRepairsPct((defaults.repairsType === '%')
            ? +parseFloat(defaults.repairsPct).toFixed(1)
            : +parseFloat((parseFloat(defaults.repairs / newTotalMonthlyRent) * 100).toFixed(1)))
        setRepairs((defaults.repairsType === '%')
            ? +parseFloat(newTotalMonthlyRent * (parseFloat(repairsPct) / 100)).toFixed(1)
            : +parseFloat(defaults.repairs).toFixed(1))
        setCapexPct((defaults.capexType === '%')
            ? +parseFloat(defaults.capexPct).toFixed(1)
            : +parseFloat((parseFloat(defaults.capex / newTotalMonthlyRent) * 100).toFixed(1)))
        setCapex((defaults.capexType === '%')
            ? +parseFloat(newTotalMonthlyRent * (capexPct / 100)).toFixed(1)
            : +parseFloat(defaults.capex).toFixed(1))
        setpropMgmtPct(parseFloat(defaults.propMgnt))
        setpropMgmt(parseFloat(newTotalMonthlyRent * (propMgmtPct / 100)))
        setVacancyPct(parseFloat(defaults.vacancy))
        setVacancy(parseFloat(newTotalMonthlyRent * (vacancyPct / 100)))
        setMiscExpenses(parseInt(defaults.miscExpenses))
        // appreciation & taxes
        setMarketAppr(defaults.cityAppr)
        setAnnualIncome(parseInt(defaults.personalAnnualIncome))
        setFilingStatus(defaults.filingStatus)
        setLandValue((newPurchasePrice * 0.20))
        setDeprPeriod(parseFloat(defaults.deprPeriod))

        // reset year zero values
        let newYearZeroValues = {
            "price": newPurchasePrice,
            "closingCosts": parseInt(defaults.closingCosts),
            "rehabCosts": parseInt(defaults.rehab),
            "miscCosts": parseInt(defaults.miscCosts),
            "rent": [parseInt((property.rentEstimate === null) ? 0 : property.rentEstimate)],
            "laundry": parseInt(defaults.laundry),
            "storage": parseInt(defaults.storage),
            "miscIncome": parseInt(defaults.miscIncome),
            "taxes": parseInt(parseInt(newPurchasePrice) * (((property.propertyTaxRate === null)
                ? 0.0061
                : property.propertyTaxRate) / 100) / 12),
            "insurance": parseInt(defaults.insurance),
            "mortgageInsurance": initMortgageInsurance,
            "hoa": parseInt((property.monthlyHoaFee > 0) ? property.monthlyHoaFee : 0),
            "utilitiesPaid": defaults.utilitiesPaid,
            "electricity": parseInt(defaults.electricity),
            "gas": parseInt(defaults.gas),
            "sewage": parseInt(defaults.sewage),
            "garbage": parseInt(defaults.garbage),
            "lawn": parseInt(defaults.lawn),
            "repairs": parseFloat(defaults.repairs),
            "capex": parseFloat(defaults.capex),
            "propMgmtPct": parseFloat(defaults.propMgnt),
            "propMgmt": parseFloat(totalMonthlyRent * (propMgmtPct / 100)),
            "vacancyPct": parseFloat(defaults.vacancy),
            "vacancy": parseInt(totalMonthlyRent * (vacancyPct / 100)),
            "miscExpenses": parseInt(defaults.miscExpenses),
            "annualIncome": parseInt(defaults.personalAnnualIncome),
        }
        setYearZeroPropertyValues(newYearZeroValues);

        // reset property edit/edits
        setPropertyEdit(null);
        delete propertyEdits.properties[`${selectedProperty.zpid}`];
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                property_edits: propertyEdits,
            }

            let { error } = await supabase.from('profiles').upsert(updates);

            if (error) throw error
        } catch (error) {
            console.error(error.message)
        }
    }
    
    const handleYearsProjected = (e) => {
        let newYearsProjected = e;
        if (/[0-9](([0-8](\.[0-9]*)?)|[0-9])?/.test(newYearsProjected)) {
            newYearsProjected = parseInt(newYearsProjected);
            if (newYearsProjected < 100) {
                setYearsProjected(newYearsProjected);
            }
        }
    }

    const handleOpenProjectionsModal = () => {
        setOpenAdjustsProjectionsModal(true);
        setTimeout(function() { handleSelectInput("years-projected") }, 200);
    }
    
    const [convoMessage, setConvoMessage] = useState('');
    const handleRequestTour = () => {
        if (session && activeClient) {
            setConvoMessage('Hi, I would like to tour this property. Thanks!');
            setTimeout(() => { setOpenConversationModal(true); }, 50)
        } else if (session === null) {
            setTourHome(true);
            setOpenAgentModal(true);
        }
    }
    
    const handleMessageAgent = () => {
        if (session && activeClient) {
            setOpenConversationModal(true);
        } else if (session === null) {
            setTourHome(false);
            setOpenAgentModal(true);
        }
    }

    const handleFavoriteProp = (property, status, setStatus) => {
        if (session) {
            if (window.location.href.includes('/dashboard')) {
                handleFavorite(property, status, setStatus);
            } else {
                if (activeClient) {
                    // agent subdomain --> set to client
                    handleFavorite(property, status, setStatus, activeClient.id);
                } else {
                    handleFavorite(property, status, setStatus);
                }
            }
        } else {
            setLoginHeader('Login or signup to save a property')
            setOpenLogin(true);
        }
    }

    const handlePersonalFav = () => {
        handleFavorite(property, !saveProperty, setSaveProperty);
        setSaveProperty(!saveProperty);
    }

    // conversations
    const [conversation, setConversation] = useState([]);
    const [loadingConversation, setLoadingConversation] = useState(false);

    const handleCloseConversation = () => {
        setOpenConversationModal(false);
        setTimeout(function() { setConversation([]); }, 50)
        setTimeout(function() { setConvoMessage(''); }, 50)
    }

    const handleClickConversation = async () => {
        if (activeClient && selectedProperty) {
            setLoadingConversation(true);
            setOpenConversationModal(true);
            // get conversation (if there is one) from supabase
            try {
                let { data, error, status } = await supabase
                    .from('client_property')
                    .select(`conversation`)
                    .eq('id', `${activeClient.id}`)
                    .eq('prop_id', `${selectedProperty.zpid}`)

                if (error && status !== 406) throw error

                if (data && data[0].conversation !== null && data[0].conversation.length > 0) {
                    setConversation(data[0].conversation);
                    setLoadingConversation(false);
                } else {
                    setConversation([]);
                    setTimeout(function() { setLoadingConversation(false); }, 500)
                }
            } catch (error) {
                console.error(error.message);
                setLoadingConversation(false);
            }
        } else if (session === null) {
            setLoginHeader('Login or signup to message an agent')
            setOpenLogin(true);
        }
    }

    const handleClearConversation = async () => {
        setConversation([]);
        // set conversation to supabase
        try {
            const updates = {
                id: activeClient.id,
                prop_id: selectedProperty.zpid,
                updated_at: new Date(),
                conversation: []
            }
    
            let { error } = await supabase.from('client_property').upsert(updates);
    
            if (error) throw error;
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleAddMessage = async (msg) => {
        const date = new Date();
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const dateString = date.toLocaleString('en-US', options);
        let newMessage = {
            "user": clients.length > 0 ? "agent" : "client",
            "message": msg,
            "timeSent": dateString
        }
        const newConversation = [...conversation, newMessage];
        setConversation(newConversation);
        // set conversation to supabase
        try {
            const updates = {
                id: activeClient.id,
                prop_id: selectedProperty.zpid,
                updated_at: new Date(),
                conversation: newConversation
            }
    
            let { error } = await supabase.from('client_property').upsert(updates);
    
            if (error) throw error;
        } catch (error) {
            console.error(error.message);
        }
    }

    const openedChatFromURL = useRef(false)
    if (
        activeClient &&
        selectedProperty &&
        openChatFromURL &&
        openChatFromURL === 'true' &&
        openedChatFromURL.current === false
    ) {
        handleClickConversation();
        openedChatFromURL.current = true;
    }


    return (
        <>
            {(loading) ? (
                <p className='hidden'></p>
            ) : (
                <>
                    {/* Conversation modal */}
                    <Modal size={'lg'} centered={true} show={openConversationModal} onHide={() => handleCloseConversation()} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Body>
                            <Conversation
                                activeClient={activeClient}
                                selectedProperty={selectedProperty}
                                conversation={conversation}
                                setConversation={setConversation}
                                loadingConversation={loadingConversation}
                                handleAddMessage={handleAddMessage}
                                handleClearConversation={handleClearConversation}
                                handleCloseConversation={handleCloseConversation}
                                clients={clients}
                                session={session}
                                handleClickConversation={handleClickConversation}
                                convoMessage={convoMessage}
                            />
                        </Modal.Body>
                    </Modal>

                    {/* Save client favorites modal */}
                    <Modal size={'sm'} centered={true} show={openClientFavorites} onHide={() => setOpenClientFavorites(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Body>
                            <div className='mx-2 flex flex-col justify-center items-center mt-1.5'>
                                <p className='text-2xl font-bold tracking-tight'>Select multiple</p>
                                <div className='flex flex-col justify-start items-start mt-3 mb-2 text-lg'>
                                    <div className='flex justify-start items-center gap-3 mt-2' onClick={() => handlePersonalFav()}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox size="medium" checked={saveProperty} />
                                            }
                                            label={<p className='text-lg'>Personal</p>}
                                            className="text-lg"
                                        />
                                    </div>
                                    {clients.map(function(client) {
                                        return (
                                            <AddClientFavorite
                                                property={property}
                                                client={client}
                                                favoritesList={favoritesList}
                                                handleFavorite={handleFavorite}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='flex justify-end text-lg'>
                                <button
                                    className='px-3 py-1.5 rounded-xl text-gray hover:bg-light-gray'
                                    onClick={() => setOpenClientFavorites(false)}
                                >
                                    Close
                                </button>
                                <button
                                    className='px-3 py-1.5 rounded-xl text-bot-blue hover:bg-light-bot-blue-0'
                                    onClick={() => setOpenClientFavorites(false)}
                                >
                                    Save
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* Future projections button */}
                    <div className='flex justify-center items-center'>
                        <div className='fixed bottom-0 z-50'>
                            <div
                                onClick={() => handleOpenProjectionsModal()}
                                className={"flex justify-center gap-2 mb-5 px-3 py-2 text-base bg-black hover:bg-[#252525] text-white cursor-pointer rounded-full shadow-md shadow-gray"}
                            >
                                <div className="flex justify-center gap-0.5 p-1.5">
                                    <p>{yearsProjected} year projection</p>
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        className="text-sm ml-2 mt-1 text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Adjust projections modal */}
                    <Modal size={'md'} centered={true} show={openAdjustsProjectionsModal} onHide={() => setOpenAdjustsProjectionsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Header closeButton>
                            <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                        </Modal.Header>
                        <Modal.Body>
                            <div className='flex flex-col justify-start items-center mb-3'>
                                <p className='text-center text-3xl font-extrabold tracking-tight mb-4'>Adjust projections</p>
                                <div className={`bg-light-gray rounded-xl p-3 ${isMobile ? 'w-80' : 'w-96'}`}>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex justify-center gap-3'>
                                            <p className='text-lg'>Years projected:</p>
                                            <img data-tip data-for="yearsProjected" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                            <ReactTooltip id='yearsProjected' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                <div className='w-64 text-sm'>
                                                    <p className='text-xl text-center text-bot-blue mb-2'>Years projected</p>
                                                    <p className='mt-3'>The number of years you want to project into the future.For example, cash flow today could be $100 but a 10-year projection shows cash flow at $1,000</p>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                        <CurrencyInput
                                            id="years-projected"
                                            onClick={() => handleSelectInput("years-projected")}
                                            className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                            value={yearsProjected}
                                            decimalsLimit={1}
                                            onValueChange={(e) => handleYearsProjected(e)}
                                            onBlur={() => updatePropertyEdits('yearsProjected', parseInt(yearsProjected))}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center mt-3'>
                                        <div className='flex justify-center gap-3'>
                                            <p className='text-lg'>{isMobile ? 'Rent appr. rate:' : 'Rent appreciation rate:'}</p>
                                            <img data-tip data-for="rentAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                            <ReactTooltip id='rentAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                <div className='w-64 text-sm'>
                                                    <p className='text-xl text-center text-bot-blue mb-2'>Rent Appreciation Rate</p>
                                                    <p className='mt-3'>The rent appreciation rate is the rate at which rent increase year-over-year. Between 2010 and 2020, rents increased by 2.6% annually. Between 2020 and 2022, rents rose 10.5%.</p>
                                                    <p className='text-gray mt-3'>
                                                        Ex: With a rent appreciation rate of {rentAppr}%, rent will go from $1,000 this year to ${Math.round(1000 * (1 + (defaults.rentAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                                    </p>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                        <CurrencyInput
                                            id="rent-appreciation"
                                            onClick={() => handleSelectInput("rent-appreciation")}
                                            className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                            suffix='%'
                                            value={rentAppr}
                                            decimalsLimit={1}
                                            onValueChange={(e) => setRentAppr(e)}
                                            onBlur={() => updatePropertyEdits('rentAppr', parseInt(rentAppr))}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center mt-3'>
                                        <div className='flex justify-center gap-3'>
                                            <p className='text-lg'>{isMobile ? 'Expense appr. rate:' : 'Expense appreciation rate:'}</p>
                                            <img data-tip data-for="expensesAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                            <ReactTooltip id='expensesAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                <div className='w-64 text-sm'>
                                                    <p className='text-xl text-center text-bot-blue mb-2'>Expense Appreciation Rate</p>
                                                    <p className='mt-3'>The expense appreciation rate is the rate at which expenses (operating expenses) increase year-over-year. Your mortgage will stay the same unless you refinance. Operating expenses will increase 2-3% on average closely following inflation.</p>
                                                    <p className='text-gray mt-3'>
                                                        Ex: With an expense appreciation rate of {expensesAppr}%, your operating expenses will go from $500 this year to ${Math.round(500 * (1 + (defaults.expensesAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                                    </p>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                        <CurrencyInput
                                            id="expense-appreciation"
                                            onClick={() => handleSelectInput("expense-appreciation")}
                                            className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                            suffix='%'
                                            value={expensesAppr}
                                            decimalsLimit={1}
                                            onValueChange={(e) => setExpensesAppr(e)}
                                            onBlur={() => updatePropertyEdits('expensesAppr', parseInt(expensesAppr))}
                                        />
                                    </div>
                                </div>
                                <div className={`flex justify-center items-center mt-4`}>
                                    <button
                                        onClick={() => setOpenAdjustsProjectionsModal(false)}
                                        className={`${isMobile ? 'w-80' : 'w-96'} py-2.5 text-center text-white text-xl rounded-xl bg-bot-blue hover:bg-dark-bot-blue`}
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                   
                    {/* Agent modal */}
                    <Modal size={'md'} centered={true} show={openAgentModal} onHide={() => setOpenAgentModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Header closeButton>
                            <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                        </Modal.Header>
                        <Modal.Body>
                            <ContactForm agentName={agentName} property={property} button="Agent" role="an Agent" interest={`interested in${tourHome ? ' touring' : ''}`} />
                        </Modal.Body>
                    </Modal>

                    {/* Comps modal */}
                    <Modal size={'lg'} centered={true} show={openCompsModal} onHide={() => setOpenCompsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Header closeButton>
                            <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                        </Modal.Header>
                        <Modal.Body>
                            <CompAnalysis compProperties={compProperties} defaults={defaults} />
                        </Modal.Body>
                    </Modal>
                    
                    {/* Rental Comps modal */}
                    <Modal size={'lg'} centered={true} show={openRentalComps} onHide={() => setOpenRentalComps(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Header closeButton>
                            <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                        </Modal.Header>
                        <Modal.Body>
                            <RentalComps compProperties={compProperties} defaults={defaults} propertyEdits={propertyEdits} />
                        </Modal.Body>
                    </Modal>
                    
                    {/* Photos modal */}
                    <Modal size={'xl'} centered={true} show={photoModal} onHide={() => setPhotoModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                        <Modal.Body>
                            <div className="flex justify-between">
                                <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                                {/* Close modal */}
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    onClick={() => setPhotoModal(false)}
                                    className='text-xl px-2.5 py-1.5 -mt-1 cursor-pointer rounded-full hover:bg-light-gray-2'
                                />
                            </div>
                            <div className='mb-5 mt-10'>
                                <div className='flex items-center mt-4 lg:pl-14 -mb-16 ml-3'>
                                    <p className='text-center px-3 py-1 bg-light-gray text-gray font-semibold rounded-full z-50'>
                                        {photoNum + 1}/{numPhotos}
                                    </p>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <div className='flex justify-center -mr-20 z-50'>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="text-3xl py-10 px-3 mt-1 rounded-xl text-gray bg-light-gray-2 cursor-pointer opacity-60 hover:opacity-90 hover:bg-light-gray-3"
                                            onClick={() => handlePhotoNum(-1)}
                                        />
                                    </div>
                                    <img
                                        alt="Home"
                                        src={property.photos[photoNum]}
                                        className="flex justify-center mt-2.5 inline-grid rounded-xl w-11/12"
                                    />
                                    <div className='flex justify-center -ml-20 z-50'>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="text-3xl py-10 px-3 mt-1 rounded-xl text-gray bg-light-gray-2 cursor-pointer opacity-60 hover:opacity-90 hover:bg-light-gray-3"
                                            onClick={() => handlePhotoNum(1)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
            
            {/* Actions bar */}
            <div className='flex justify-between mb-2'>
                <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                <div className={`flex justify-right mr-2 ${isMobile ? 'gap-3' : 'gap-5'}`}>
                    {/* Saved icon */}
                    <div className={startSavingChanges ? '' : 'hidden'}>
                        {savingChanges  ? (
                            <div className='flex justify-center text-gray mt-1'>
                                <img src={loading_spinner} className='h-5 mr-2' alt='Loading...' />
                                <p className='text-sm font-normal'>{isMobile ? 'Saving' : 'Saving changes'}</p>
                            </div>
                        ) : (
                            <div className='flex justify-center text-gray mt-1'>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className='text-light-gray-4 mr-1.5 mt-0.5'
                                />
                                <p className='text-sm font-normal'>{isMobile ? 'Saved' : 'Changes saved'}</p>
                            </div>
                        )}
                    </div>
                    
                    {/* Share property */}
                    {isMobile ? (
                        <div
                            data-tip data-for='shareProperty'
                            className='flex justify-center gap-2 -mt-1 px-2 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl'
                            onClick={() =>
                                shareOnMobile({
                                    text: "Check out this property!",
                                    url: session ? `${window.location.href}&user=${activeClient ? activeClient.id : session.user.id}` : `${window.location.href}`,
                                    title: property ? property.address : '',
                                    images: property ? property.imgSrc : '',
                                })
                            }
                        >
                            <FontAwesomeIcon
                                icon={faArrowUpFromBracket}
                                className='text-xl p-1 -mt-0.5 hover:cursor-pointer hover:text-bot-blue'
                            />
                            <p className={isMobile ? 'hidden' : 'text-sm font-normal'}>Share</p>
                        </div>
                    ) : (
                        <Tooltip title="Share this property and your edits with anyone">
                            <div
                                data-tip data-for='shareProperty'
                                onClick={() => copyPropertyLink()}
                                className='flex justify-center gap-2 -mt-1 mb-1 px-2 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl cursor-pointer'
                            >
                                <FontAwesomeIcon
                                    icon={faShare}
                                    className='text-xl p-1 -mt-0.5'
                                />
                                <p className={isMobile ? 'hidden' : 'text-sm font-normal mt-0.5'}>Share</p>
                            </div>
                        </Tooltip>
                    )}
                    {/* Conversation */}
                    <Tooltip title={`Message your ${clients.length > 0 ? 'client' : 'agent'}`}>
                        <div
                            onClick={() => handleClickConversation()}
                            className={`flex justify-center gap-2 -mt-1 mb-1 px-2 py-1.5 ${isMobile ? '-mr-1' : ''} hover:bg-light-bot-blue-0 hover:rounded-xl cursor-pointer`}
                        >
                            <FontAwesomeIcon
                                icon={faComment}
                                className='text-xl p-1 -mt-0.5'
                            />
                            <p className={isMobile ? 'hidden' : 'text-sm font-normal mt-0.5'}>Chat</p>
                        </div>
                    </Tooltip>
                    {/* Reset edits */}
                    <Tooltip title="Reset all edits back to its original data and defaults">
                        <div className='-mt-1'>
                            <div
                                onClick={() => handleResetEdits()}
                                className='flex justify-center gap-1.5 px-2 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl cursor-pointer'
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeftRotate}
                                    className='text-xl p-1 -mt-0.5'
                                />
                                <p className={isMobile ? 'hidden' : 'text-sm font-normal mt-0.5'}>Reset</p>
                            </div>
                        </div>
                    </Tooltip>
                    {/* Save a property */}
                    <Tooltip title="Save/unsave a property to your favorites">
                        <div className='-mt-1'>
                            <HeartIcon
                                property={property}
                                favoritesList={favoritesList}
                                handleFavorite={handleFavoriteProp}
                            />
                        </div>
                    </Tooltip>
                    {/* Close modal */}
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={() => setShow(false)}
                        className={`text-xl px-2.5 py-1.5 cursor-pointer hover:rounded-full hover:bg-light-gray-2 ${isMobile ? '-mt-0.5 ml-2' : '-mt-1'}`}
                    />
                </div>
            </div>

            {/* Property details & analysis here */}
            <div className={`lg:grid lg:grid-cols-2 lg:gap-4 text-center lg:ml-5 sm:mx-1 custom-scroll ${isMobile ? 'mt-4' : ''}`}>
                <div className='lg:col-span-1 property-details-height lg:overflow-scroll'>
                    <div className='lg:ml-2 lg:mr-3'>
                        {(loading) ? (
                            <Skeleton
                                width={isMobile ? 335 : 495}
                                height={isMobile ? 235 : 370}
                                animation="wave"
                                variant="rectangular"
                                className='mt-2 rounded-xl'
                            />
                        ) : (
                            <div>
                                <div className={`flex justify-start items-center ml-3 ${isMobile ? '-mb-14' : '-mb-12'} mt-2`}>
                                    <p className='text-center px-3 py-1 bg-light-gray-2 text-gray font-semibold rounded-full z-50'>
                                        {photoNum + 1}/{numPhotos}
                                    </p>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <div className='flex justify-center opacity-70 hover:opacity-80'>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="-mr-14 z-50 text-xl py-4 px-2.5 mt-1 rounded-xl text-gray bg-light-gray-2 cursor-pointer hover:bg-light-gray"
                                            onClick={() => handlePhotoNum(-1)}
                                        />
                                    </div>
                                    <img
                                        alt="Home"
                                        src={property.photos[photoNum]}
                                        onClick={() => handlePhotoModal(isMobile)}
                                        className="flex justify-center mt-2.5 inline-grid rounded-xl cursor-pointer"
                                    />
                                    <div className='flex justify-center opacity-70 hover:opacity-80'>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="-ml-14 z-50 text-xl py-4 px-2.5 mt-1 rounded-xl text-gray bg-light-gray-2 cursor-pointer hover:bg-light-gray"
                                            onClick={() => handlePhotoNum(1)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <Card className={`flex justify-center rounded-xl ${isMobile ? 'mt-2' : 'mt-2.5'}`}>
                            <Card
                                bg="light-gray"
                                className='m-2 p-2.5 rounded-xl border-none'
                            >
                                <div className='text-left mx-4'>
                                    <p className="order-first text-bot-blue font-bold">Property Details</p>
                                </div>
                            </Card>
                            <div className='mt-1 lg:mx-5 mx-3'>
                                <div className='flex justify-start gap-3'>
                                    <CurrencyInput
                                        id="purchase-price"
                                        className="flex text-xl font-semibold w-36 h-9 pl-1 border-one rounded-sm"
                                        prefix='$'
                                        onClick={() => handleSelectInput("purchase-price")}
                                        value={purchasePrice}
                                        decimalsLimit={0}
                                        onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                        onBlur={() => updatePropertyEdits('price', purchasePrice)}
                                    />
                                    <Tooltip title={`Future value in ${yearsProjected} years`}>
                                        <div className={yearsProjected === 0 ? "hidden" : "-mb-1.5 -mt-1"}>
                                            <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>Future value</p>
                                            <p className='text-base text-gray text-start -mt-1'>${newPriceProjection}</p>
                                        </div>
                                    </Tooltip>
                                </div>
                                <p className='flex text-base text-left text-lg mt-1.5'>
                                    <span className='font-semibold pr-1'>{property.bedrooms}</span> Bed,
                                    <span className='font-semibold px-1'>{property.bathrooms}</span> Bath,
                                    <span className='font-semibold px-1'>{(livingArea !== null) ? livingArea.toLocaleString(undefined, { maximumFractionDigits: 2 }) : ''}</span> sq. ft.
                                </p>
                                <p className='text-base text-left'>
                                    {property.address}
                                    <FontAwesomeIcon
                                        hidden={property.address === null}
                                        icon={faCopy}
                                        onClick={() => copyValue(property.address, "Address copied")}
                                        className='text-base text-gray mt-1 ml-2.5 cursor-pointer hover:text-bot-blue'
                                    />
                                </p>
                                <p className='flex text-lg font-bold mt-4'>About</p>
                                {(loading) ? (
                                    <div>
                                        <Skeleton
                                            width={420}
                                            height={80}
                                            animation="wave"
                                            variant="rectangular"
                                            className='mt-2 rounded-xl'
                                        />
                                        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', marginTop: '20px' }} />
                                        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                        <p className='flex text-lg font-bold mt-4'>Features</p>
                                        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                    </div>
                                ) : (
                                    <div>
                                        <AboutDescription limit={150} description={(property.description !== null) ? property.description : ''} />
                                        <p className='text-left'>
                                            Listed by {(property.listingAgent === null) ? '' : property.listingAgent}{clients.length === 0 ? '' : listingAgentPhone}
                                            <FontAwesomeIcon
                                                hidden={property.listingAgentPhone === null || clients.length === 0}
                                                icon={faCopy}
                                                onClick={() => copyValue(property.listingAgentPhone, "Phone number copied")}
                                                className='text-base text-gray ml-2.5 cursor-pointer hover:text-bot-blue'
                                            />
                                        </p>
                                        <p className='text-left'>{(property.brokerageName === null) ? '' : property.brokerageName}</p>
                                        <p className='text-left'>MLS#: {(property.mlsId === null) ? '' : property.mlsId}, {(property.mlsName === null) ? '' : property.mlsName}</p>
                                        <p className='text-left text-base'>{`Listing updated: ${lastUpdated}`}</p>
                                        <PropertyDetails property={property} livingArea={livingArea} pricePerSqFt={pricePerSqFt} />
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
                <div className={`lg:-mr-2 lg:col-span-1 place-self-center lg:w-11/12`}>
                    {/* Investment Returns */}
                    <Card className={`mb-1.5 rounded-xl ${isMobile ? 'mt-2' : 'w-11/12'}`}>
                        <Card className='m-2 p-2.5 border-none rounded-xl' bg="light-gray">
                            <div className='flex justify-center'>
                                <div className="flex justify-center gap-2 ml-3 text-bot-blue font-bold">
                                    <p>Investment Returns</p>
                                    <img data-tip data-for="returnsInfo" className="w-4 mb-2 mt-1 ml-1" src={info_popup} alt="info box"/>
                                    <div className='flex justify-center'>
                                        <ReactTooltip id='returnsInfo' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                            className='shadow-lg p-3 bg-white opaque w-64 mr-5 font-normal text-base'
                                        >
                                            <p className='text-base text-bot-blue mb-2'>Investment Returns</p>
                                            <p className='text-start'>All investment returns are based on projected future values, which right now is projecting {yearsProjected} years down the road.</p>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Form.Group>
                            <div className='flex justify-around'>
                                
                                {/* Monthly Cash Flow */}
                                <div>
                                    <div className='flex'>
                                        {(loading) ? (
                                            <Skeleton
                                                width={70}
                                                height={33}
                                                animation="wave"
                                                variant="rectangular"
                                                className='ml-3 mt-2 rounded-xl'
                                            />
                                        ) : (
                                            <div>
                                                <p data-tip data-for="cashflowNum" className="font-bold text-2xl ml-3 mt-1 text-black">{newMonthlyCashFlowProjection}</p>
                                                <ReactTooltip id='cashflowNum' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                    <p className='text-base text-bot-blue mb-2'>Cash Flow Breakdown</p>
                                                    <p className='text-gray text-sm'>
                                                        NOI: <b className='text-black'>${Math.round(newNetOperatingIncomeProjection).toLocaleString(undefined, { maximumFractionDigits: 2 })}</b>
                                                        - Mortgage: <b className='text-black'>${totalMonthlyMortgage.toLocaleString(undefined, { maximumFractionDigits: 2 })}</b>
                                                    </p>
                                                </ReactTooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex'>
                                        <p className="font-normal mt-1">Cash Flow</p>
                                        <img data-tip data-for="cashFlowPopup" className="w-4 mb-3 mt-1 ml-1" src={info_popup} alt="info box"/>
                                        <ReactTooltip id='cashFlowPopup' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                            <p className='text-base text-bot-blue mb-2'>Cash Flow</p>
                                            <p className='text-start'>Net operating income minus<br/>any mortgage payment.</p><br/>
                                            <p className='text-gray'>NOI <b>-</b> Mortgage</p>
                                        </ReactTooltip>
                                    </div>
                                </div>

                                {/* Cap Rate */}
                                <div className='mr-3'>
                                    <div className='flex justify-center mr-3'>
                                        {(loading) ? (
                                            <Skeleton
                                                width={70}
                                                height={33}
                                                animation="wave"
                                                variant="rectangular"
                                                className='ml-3 mt-2 rounded-xl'
                                            />
                                        ) : (
                                            <div>
                                                <p data-tip data-for="capRateNum" className="font-bold text-2xl ml-2 mt-1 text-black">{newCapRateProjection}%</p>
                                                <ReactTooltip id='capRateNum' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                    <p className='text-base text-bot-blue mb-2'>Cap Rate Breakdown</p>
                                                    <p className='text-gray'>
                                                        Annual NOI: <span className='text-black font-bold'>${newAnnualNetOperatingIncomeProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                    </p>
                                                    <hr className='mx-2 my-1 border-gray'/>
                                                    {yearsProjected === 0 ? (
                                                        <p className='text-gray'>
                                                            Property Value: <span className='text-black font-bold'>${purchasePrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                            + Rehab: <span className='text-black font-bold'>${rehabCosts.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                            + Closing costs: <span className='text-black font-bold'>${closingCosts.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                                        </p>
                                                    ) : (
                                                        <p className='text-gray'>Property Value: <span className='text-black font-bold'>${newPriceProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                                                    )}
                                                </ReactTooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex justify-center'>
                                        <p className="font-normal mt-1">Cap Rate</p>
                                        <img data-tip data-for="capRatePopup" className="w-4 mb-3 mt-1 ml-1" src={info_popup} alt="info box"/>
                                        <ReactTooltip id='capRatePopup' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque w-80'>
                                            <p className='text-base text-bot-blue mb-2'>Cap Rate</p>
                                            <p className='text-start'>Annual net operating income divided by the purchase price. For the first year's cap rate, include the initial rehab and closing costs for acquiring the property, but not for subsequent years.</p><br/>
                                            <p className='text-gray'>Annual NOI</p>
                                            <hr className='mx-16 border-gray'/>
                                            {(yearsProjected === 0) ? (
                                                <p className='text-gray'>Purchase price + Rehab + Closing costs</p>
                                            ) : (
                                                <p className='text-gray'>Purchase price</p>
                                            )}
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-around -mb-2'>
                                {/* Cash on Cash Return */}
                                <div className='ml-2'>
                                    <div className='flex justify-center mr-2'>
                                        {(loading) ? (
                                            <Skeleton
                                                width={70}
                                                height={33}
                                                animation="wave"
                                                variant="rectangular"
                                                className='ml-3 mt-2 rounded-xl'
                                            />
                                        ) : (
                                            <div>
                                                <p data-tip data-for="cocNum" className="font-bold text-2xl ml-1 mt-1 text-black">{newCashOnCashProjection}%</p>
                                                <ReactTooltip id='cocNum' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                    <p className='text-base text-bot-blue mb-2'>Cash on Cash Breakdown</p>
                                                    <p className='text-gray'>
                                                        Annual Cash Flow: <span className='text-black font-bold'>${newAnnualCashFlowProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                    </p>
                                                    <hr className='mx-2 my-1 border-gray'/>
                                                    <p className='text-gray'>Initial Investment: <span className='text-black font-bold'>${totalInitialInvestment.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                                                </ReactTooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex justify-center'>
                                        <p className="font-normal mt-1">Cash on Cash</p>
                                        <img data-tip data-for="cashOnCashPopup" className="w-4 mb-3 mt-1 ml-1" src={info_popup} alt="info box"/>
                                        <ReactTooltip id='cashOnCashPopup' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                            <p className='text-base text-bot-blue mb-2'>Cash on Cash Return</p>
                                            <p className='text-start'>Annual cash flow divided<br/>by the total investment.</p><br/>
                                            <p className='text-gray'>Annual Cash Flow</p>
                                            <hr className='mx-3 border-gray'/>
                                            <p className='text-gray'>Total Investment</p>
                                        </ReactTooltip>
                                    </div>
                                </div>

                                {/* Total Return */}
                                <div className='mr-2'>
                                    <div className='flex justify-center mr-2'>
                                        {(loading) ? (
                                            <Skeleton
                                                width={70}
                                                height={33}
                                                animation="wave"
                                                variant="rectangular"
                                                className='mt-2 rounded-xl'
                                            />
                                        ) : (
                                            <div>
                                                <p data-tip data-for="totalReturnNum" className="font-bold text-2xl mt-1 mr-1 text-black">{newTotalReturnProjection}{yearsProjected === 0 ? '' : '%'}</p>
                                                <ReactTooltip id='totalReturnNum' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                    <p className='text-base text-bot-blue mb-2'>Total Return Breakdown</p>
                                                    <p className='text-gray'>
                                                        (Added Equity (Principal): <span className='text-black font-bold'>${addedEquityProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                        + Appreciation & Savings <span className='text-black font-bold'>${totalAppreciationandTaxesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                        + Cumulative Cash Flow: <span className='text-black font-bold'>${cummulativeCashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                        + Rehabs: <span className='text-black font-bold'>${rehabCosts.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span><br/>
                                                        - Selling Costs: <span className='text-black font-bold'>${estSellingCostsProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>)<br/>
                                                    </p>
                                                    <hr className='mx-2 my-1 border-gray'/>
                                                    <p className='text-gray'>Initial Investment: <span className='text-black font-bold'>${totalInitialInvestment.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                                                </ReactTooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex justify-center'>
                                        <p className="font-normal mt-1">Total Return</p>
                                        <img data-tip data-for="totalReturnPopup" className="w-4 mb-3 mt-1 ml-1" src={info_popup} alt="info box"/>
                                        <div className='flex justify-center'>
                                            <ReactTooltip id='totalReturnPopup' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                className='shadow-lg p-3 bg-white opaque lg:w-80 w-64 mr-5'
                                            >
                                                <p className='text-base text-bot-blue mb-2'>Total Return</p>
                                                <p className='text-start'>
                                                    The annualized total return on your investment. In other words, when you sell the property how much you make from your initial investment.
                                                    This is calculated by adding the earned equity (principal), property appreciation, cumulative cash flow, and rehab. Finally,
                                                    subtract the selling costs and divide by the initial investment.
                                                </p>
                                                <p className='mt-2 text-start'>
                                                    Note, the total return represents the average return over the full holding period,
                                                    which is currently {yearsProjected} {yearsProjected === 1 ? 'year' : 'years'}.
                                                </p>
                                                <p className='text-gray mt-2'>Added Equity (Principal) + Appreciation<br/>+ Total Cash Flow + Rehabs - Selling Costs</p>
                                                <hr className='w-64 ml-3 border-gray'/>
                                                <p className='text-gray'>Initial Investment</p>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={`mt-2.5 -mb-2 ${isMobile ? 'mx-3' : 'mx-7'}`}>
                                <div className='flex justify-center items-center'>
                                    <div className='flex justify-center mb-1 text-gray'>
                                        {/* <img src={loading_spinner} className={loadingProjection ? 'h-6 text-center' : 'hidden'} alt='Loading...' /> */}
                                        <p>{yearsProjected === 0 ? 'Year 0' : `${yearsProjected} year projection`}</p>
                                        <FontAwesomeIcon
                                            icon={faPencil}
                                            onClick={() => handleOpenProjectionsModal()}
                                            className="text-sm -mt-1 text-bot-blue cursor-pointer p-2.5 rounded-full hover:bg-light-gray-2"
                                        />
                                    </div>
                                </div>
                                <div className='-my-1'>
                                    <Slider
                                        value={yearsProjected}
                                        min={0}
                                        step={1}
                                        max={30}
                                        onChange={(e) => setYearsProjected(e.target.value)}
                                        onBlur={() => updatePropertyEdits('yearsProjected', parseInt(yearsProjected))}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Card>
                    <div className={isMobile ? '' : 'numbers-height overflow-scroll'}>

                        <div className={`flex justify-center ${isMobile ? 'gap-1 -mt-1' : 'gap-4'}`} style={{width: isMobile ? '100%' : '95%'}}>
                            <Button
                                block size="lg"
                                onClick={() => handleRequestTour()}
                                className={`bg-bot-blue h-13 text-white small-btn rounded-xl mt-2 ${isMobile ? 'ml-0' : 'ml-2'}`}
                            >
                                Request tour
                            </Button>
                            <Button
                                block size="lg"
                                onClick={() => handleMessageAgent()}
                                className={`bg-bot-blue h-13 text-white small-btn rounded-xl mt-2 ${isMobile ? 'ml-0' : 'ml-2'}`}
                            >
                                Message {agentName}
                            </Button>
                        </div>

                        <div
                            onClick={() => handleExpandCollapse(!expand)}
                            className={`w-20 ml-2 py-1.5 px-2 font-semibold my-1.5 cursor-pointer rounded-xl hover:bg-light-gray text-sm`}
                        >
                            <p className='flex justify-center text-center items-center'>{expand ? "Collapse" : "Expand"}</p>
                        </div>
                        
                        {/* Initial Investment */}
                        {(loading) ? (
                            <Skeleton
                                width={440}
                                height={62}
                                animation="wave"
                                variant="rectangular"
                                className='rounded'
                            />
                        ) : (
                            <Card className="drop-shadow-lg shadow-slate-200 rounded-xl" style={{width: isMobile ? '100%' : '95%'}}>
                                <Card
                                    id='step-seven'
                                    bg="light-gray"
                                    className='m-2 py-2.5 lg:px-2.5 cursor-pointer rounded-xl hover:bg-light-gray-2 border-none'
                                    onClick={() => handleCollapse(initInvestmentCard, setInitInvestmentCard, initArrow, setInitArrow)}
                                >
                                    <div className={`flex justify-between font-bold ml-3 ${isMobile ? 'mr-3' : 'mr-5'}`}>
                                        <div className='flex justify-center'>
                                            <ArrowIcon status={initArrow} color={"black"} />
                                            <p className="color-dark-gray text-dark-gray text-left">Initial Investment</p>
                                        </div>
                                        <p className="color-dark-gray text-dark-gray">${totalInitialInvestment.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                    </div>
                                </Card>
                                <Collapse in={initInvestmentCard}>
                                    <Form.Group className={`pb-2 -mt-1.5 ${isMobile ? 'px-1' : 'px-3'}`}>
                                        <div className='flex justify-between'>
                                            <Form.Label className={`font-normal mt-4 ${isMobile ? 'ml-2.5' : 'ml-4 mr-3'}`}>Down payment:</Form.Label>
                                            <div className="flex mr-2">
                                                <CurrencyInput
                                                    id="downpayment-pct-one"
                                                    className={`lg:ml-4 mt-3 p-1 pl-2 ${isMobile ? 'w-16' : 'w-20'} border-one rounded-l`}
                                                    suffix='%'
                                                    value={downpaymentPct}
                                                    onClick={() => handleSelectInput("downpayment-pct-one")}
                                                    decimalsLimit={2}
                                                    onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                    onBlur={() => updatePropertyEdits('downpaymentPct', downpaymentPct)}
                                                />
                                                <CurrencyInput
                                                    id="downpayment-one"
                                                    className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r"
                                                    prefix='$'
                                                    value={downpayment}
                                                    onClick={() => handleSelectInput("downpayment-one")}
                                                    decimalsLimit={2}
                                                    onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                    onBlur={() => updatePropertyEdits('downpayment', downpayment)}
                                                />
                                            </div>
                                        </div>
                                        <div className='pl-4 pr-3 rounded-xl'>
                                            <div className="flex justify-between">
                                                <Form.Label className="font-normal mt-4">Closing costs:</Form.Label>
                                                <div className="flex">
                                                    <CurrencyInput
                                                        id="closing-costs-pct"
                                                        className={`lg:ml-4 mt-3 p-1 pl-2 ${isMobile ? 'w-16' : 'w-16'} border-one rounded-l`}
                                                        suffix='%'
                                                        value={closingCostsPct}
                                                        onClick={() => handleSelectInput("closing-costs-pct")}
                                                        decimalsLimit={2}
                                                        onValueChange={(e) => handleClosingCostsPct((e === undefined) ? 0 : e)}
                                                        onBlur={() => updatePropertyEdits('closingCostsPct', closingCostsPct)}
                                                    />
                                                    <CurrencyInput
                                                        id="closing-costs"
                                                        className="mt-3 p-1 lg:w-24 w-24 pl-2 border-one rounded-r"
                                                        prefix='$'
                                                        value={closingCosts}
                                                        onClick={() => handleSelectInput("closing-costs")}
                                                        decimalsLimit={2}
                                                        onValueChange={e => handleClosingCosts((e === undefined) ? 0 : e)}
                                                        onBlur={() => updatePropertyEdits('closingCosts', closingCosts)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <Form.Label className="font-normal mt-4">Rehab costs:</Form.Label>
                                                <CurrencyInput
                                                    id="rehab-costs"
                                                    onClick={() => handleSelectInput("rehab-costs")}
                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                    prefix='$'
                                                    value={rehabCosts}
                                                    decimalsLimit={2}
                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : e), setRehabCosts, "rehab" )}
                                                    onBlur={() => updatePropertyEdits("rehab", parseInt(rehabCosts))}
                                                />
                                            </div>
                                            <div className="flex justify-between">
                                                <Form.Label className="font-normal mt-4">Misc costs:</Form.Label>
                                                <CurrencyInput
                                                    id="misc-costs"
                                                    onClick={() => handleSelectInput("misc-costs")}
                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                    prefix='$'
                                                    value={miscCosts}
                                                    decimalsLimit={2}
                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : e), setMiscCosts, "miscCosts" )}
                                                    onBlur={() => updatePropertyEdits("miscCosts", parseInt(miscCosts))}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Collapse>
                            </Card>
                        )}
                        <hr className='my-3 lg:w-11/12 border-solid border-gray' />

                        {/* Income / mo */}
                        {(loading) ? (
                            <Skeleton
                                width={440}
                                height={62}
                                animation="wave"
                                variant="rectangular"
                                className='rounded'
                            />
                        ) : (
                            <>
                                <Card className="rounded-xl drop-shadow-lg shadow-slate-200 mt-3" style={{width: isMobile ? '100%' : '95%'}}>
                                    <Card
                                        className='m-2 py-2.5 lg:px-2.5 cursor-pointer bg-light-green hover:bg-light-green-2 border-none rounded-xl'
                                        onClick={() => handleCollapse(incomeCard, setIncomeCard, incomeArrow, setIncomeArrow)}
                                    >
                                        <div className={`flex justify-between font-bold ml-3 ${isMobile ? 'mr-3' : 'mr-5'}`}>
                                            <div className='flex justify-center'>
                                                <ArrowIcon status={incomeArrow} />
                                                <p className="color-dark-green text-dark-green">Monthly Income</p>
                                            </div>
                                            <p className="color-dark-green text-dark-green">${newTotalMonthlyIncomeProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                        </div>
                                    </Card>
                                    <Collapse in={incomeCard}>
                                        <Form.Group className="lg:mx-5 pb-2">
                                            <div className='pb-2 pr-3 rounded-xl'>
                                                <div>
                                                    { unitsProjection.map((unit, index) => (
                                                        <div className='flex justify-between'>
                                                            <Form.Label className="font-normal mt-4 ml-4">{unit.unitNum}:</Form.Label>
                                                            <div className='flex justify-center gap-2'>
                                                                <Tooltip title="View rental comps">
                                                                    <div>
                                                                        <FontAwesomeIcon
                                                                            icon={faUpRightFromSquare}
                                                                            data-tip data-for="seeRentalComps"
                                                                            className="text-sm p-2 rounded-xl text-bot-blue cursor-pointer hover:bg-light-gray-2"
                                                                            style={{marginTop: '20px'}}
                                                                            onClick={() => setOpenRentalComps(true)}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                                <CurrencyInput
                                                                    id={`rent-${unit.unitNum}`}
                                                                    className="w-24 pl-2 mt-3 border-one rounded-sm unique"
                                                                    prefix='$'
                                                                    onClick={() => handleSelectInput(`rent-${unit.unitNum}`)}
                                                                    value={unit.unitRent}
                                                                    decimalsLimit={0}
                                                                    onValueChange={event => handleChangeInput(index, event)}
                                                                    onBlur={() => updatePropertyEdits('rentEstimate', units)}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={unit.unitRentProjected === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${unit.unitRentProjected.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='flex'>
                                                    <Button
                                                        size="sm"
                                                        variant="link"
                                                        onClick={() => handleAddUnits()}
                                                        className='bg-light-gray border-none py-2 px-3 rounded-xl hover:bg-light-gray-2 mt-3 hover:no-underline text-bot-blue hover:text-bot-blue mx-3'
                                                    >
                                                        Add Unit
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="link"
                                                        onClick={() => handleRemoveUnits()}
                                                        className='bg-light-gray border-none py-2 px-3 rounded-xl hover:bg-light-gray-2 mt-3 hover:no-underline text-dark-red hover:text-dark-red'
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='flex justify-center text-center'>
                                                <p data-tip data-for="howEstimateRent"
                                                    className='text-sm w-48 border-none py-2 px-2 rounded-xl hover:bg-light-gray hover:no-underline text-gray hover:text-bot-blue cursor-pointer'
                                                >
                                                    How do we estimate rent?
                                                </p>
                                            </div>
                                            <Card className='mt-2 mb-1 mx-2 p-2.5 pl-3 cursor-pointer hover:bg-light-gray border-none rounded-xl'
                                                onClick={() => handleCollapse(otherIncome, setOtherIncome, otherIncomeArrow, setOtherIncomeArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex justify-center'>
                                                        <ArrowIcon status={otherIncomeArrow} color={"black"} />
                                                        Other income:
                                                    </div>
                                                    <p className="color-gray mr-4">
                                                        ${totalOtherIncomeProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={otherIncome}>
                                                <div className='bg-light-gray mt-1 pb-3 pr-3 mx-2 rounded-xl'>
                                                    <div className="pl-3">
                                                        <div className="flex justify-between">
                                                            <Form.Label className="font-normal mt-4">Laundry:</Form.Label>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    id="laundry"
                                                                    onClick={() => handleSelectInput("laundry")}
                                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={laundry}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : parseInt(e)), setLaundry, "laundry" )}
                                                                    onBlur={() => updatePropertyEdits("laundry", parseInt(laundry))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || laundryProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${laundryProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <Form.Label className="font-normal mt-4">Storage:</Form.Label>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    id="storage"
                                                                    onClick={() => handleSelectInput("storage")}
                                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={storage}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : parseInt(e)), setStorage, "storage" )}
                                                                    onBlur={() => updatePropertyEdits("storage", parseInt(storage))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || storageProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${storageProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <Form.Label className="font-normal mt-4">Misc. income:</Form.Label>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    id="misc-income"
                                                                    onClick={() => handleSelectInput("misc-income")}
                                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={miscIncome}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : parseInt(e)), setMiscIncome, "miscIncome" )}
                                                                    onBlur={() => updatePropertyEdits("miscIncome", parseInt(miscIncome))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || miscIncomeProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${miscIncomeProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Form.Group>
                                    </Collapse>
                                </Card>
                                <div className='flex justify-center mr-5'>
                                    <ReactTooltip id='howEstimateRent' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque z-50 flex justify-center w-96'
                                    >
                                        <p className='text-base text-bot-blue mb-2'>Estimating Rent</p>
                                        <div className='text-sm mt-3'>
                                            <p>
                                                We estimate rents using data from public real estate sources such as Zillow and RentCast
                                                based on bed/bath, distance, square footage, and age of the property.
                                            </p>
                                            <p className='mt-3'>We encourage you to do your own due diligence and verify rent estimates as you look to invest.</p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center mr-5'>
                                    <ReactTooltip id='seeRentalComps' event="mouseover" eventOff="mouseleave mouseout scroll" effect='solid' place={'top'} backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque z-50 flex justify-center w-96'
                                    >
                                        <p className='text-base text-bot-blue mb-2'>See Rental Comps</p>
                                        <p className='text-sm'>Click the button to see how we estimated this property's rent and details on each rental comp.</p>
                                    </ReactTooltip>
                                </div>
                            </>
                        )}

                        {/* Expenses / mo */}
                        {(loading) ? (
                            <Skeleton
                                width={440}
                                height={62}
                                animation="wave"
                                variant="rectangular"
                                className='rounded mt-3'
                            />
                        ) : (
                            <>
                                <Card className="drop-shadow-lg shadow-slate-200 mt-3 rounded-xl" style={{width: isMobile ? '100%' : '95%'}}>
                                    <Card className='m-2 py-2.5 lg:px-2.5 cursor-pointer bg-light-red hover:bg-light-red-2 border-none rounded-xl'
                                        onClick={() => handleCollapse(expenseCard, setExpenseCard, expenseArrow, setExpenseArrow)}
                                    >
                                        <div className={`flex justify-between font-bold ml-3 ${isMobile ? 'mr-3' : 'mr-5'}`}>
                                            <div className='flex justify-center'>
                                                <ArrowIcon status={expenseArrow} />
                                                <p className="color-gray text-dark-red">Monthly Expenses</p>
                                            </div>
                                            <div className="flex justify-center gap-2 color-gray text-dark-red ml-3">
                                                <FontAwesomeIcon icon={faMinus} className="text-sm mt-1.5 text-dark-red" />
                                                <p>${newTotalMonthlyExpensesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </div>
                                    </Card>
                                    <Collapse in={expenseCard}>
                                        <Form.Group className={`pb-2 ${isMobile ? 'px-2': 'px-4'}`}>
                                            <Card className='my-2 p-2.5 pl-3 cursor-pointer hover:bg-light-gray border-none rounded-xl'
                                                onClick={() => handleCollapse(operatingExpenseCard, setOperatingExpenseCard, operatingExpenseArrow, setOperatingExpenseArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex justify-center'>
                                                        <p><ArrowIcon status={operatingExpenseArrow} /></p>
                                                        <p className="color-gray">Operating expenses</p>
                                                    </div>
                                                    <p className="color-gray mr-8">
                                                        ${newOperatingExpensesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={operatingExpenseCard}>
                                                <div className='px-2.5 pb-3 rounded-xl bg-light-gray'>
                                                    <div className='lg:pl-10 pl-1'>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4">{isMobile ? 'Prop taxes:' : 'Property taxes:'}</Form.Label>
                                                                <img data-tip data-for="taxEstimate" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className='flex justify-start gap-2'>
                                                                <CurrencyInput
                                                                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={taxes}
                                                                    id="property-taxes"
                                                                    onClick={() => handleSelectInput("property-taxes")}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setTaxes, "taxes")}
                                                                    onBlur={() => updatePropertyEdits('taxes', parseInt(taxes))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || taxesProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${taxesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Insurance:' : 'Home insurance:'}</Form.Label>
                                                                <img data-tip data-for="homeownersInsurance" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={insurance}
                                                                    id="home-insurance"
                                                                    onClick={() => handleSelectInput("home-insurance")}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setInsurance, "insurance")}
                                                                    onBlur={() => updatePropertyEdits('insurance', parseInt(insurance))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || insuranceProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${insuranceProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">HOA:</Form.Label>
                                                                <img data-tip data-for="hoa" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={hoa}
                                                                    id="hoa-expenses"
                                                                    onClick={() => handleSelectInput("hoa-expenses")}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setHOA, "hoa")}
                                                                    onBlur={() => updatePropertyEdits('monthlyHoaFee', parseInt(hoa))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || hoaProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${hoaProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='pt-1'>
                                                        <Card className='mt-2 p-2.5 pl-2 cursor-pointer bg-light-gray hover:bg-light-gray-2 border-none rounded-xl'
                                                            onClick={() => handleCollapse(utilities, setUtilities, utilitiesArrow, setUtilitiesArrow)}
                                                        >
                                                            <div className='flex justify-between'>
                                                                <div className='flex justify-center pl-2'>
                                                                    <p><ArrowIcon status={utilitiesArrow} color={(utilitiesPaid) ? 'gray': ''} /></p>
                                                                    <p className={`${(utilitiesPaid) ? 'text-gray' : ''}`}>Utilities:</p>
                                                                </div>
                                                                <p className={`${(utilitiesPaid) ? 'text-gray' : ''} mr-4`}>
                                                                    ${totalUtilitiesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                                </p>
                                                            </div>
                                                        </Card>
                                                        <Collapse in={utilities}>
                                                            <div className='bg-light-gray-2 mt-2 pb-1 px-3 rounded-xl'>
                                                                <div className="flex justify-between">
                                                                    <Form.Label className="font-normal mt-4">Internet:</Form.Label>
                                                                    <div className='flex justify-center gap-2'>
                                                                        <CurrencyInput
                                                                            id="internet"
                                                                            onClick={() => handleSelectInput("internet")}
                                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                            prefix='$'
                                                                            value={lawn}
                                                                            decimalsLimit={2}
                                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setLawn, "lawn")}
                                                                            onBlur={() => updatePropertyEdits("lawn", parseInt(lawn))}
                                                                        />
                                                                        <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                            <div style={{marginTop: '14px'}} className={yearsProjected === 0 || lawnProjection === 0 ? "hidden" : ""}>
                                                                                <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                                <p className='text-base text-gray text-start -mt-1'>${lawnProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between">
                                                                    <Form.Label className="font-normal mt-4">Electricity:</Form.Label>
                                                                    <div className='flex justify-center gap-2'>
                                                                        <CurrencyInput
                                                                            id="electricity"
                                                                            onClick={() => handleSelectInput("electricity")}
                                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                            prefix='$'
                                                                            value={electricity}
                                                                            decimalsLimit={2}
                                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setElectricity, "electricity")}
                                                                            onBlur={() => updatePropertyEdits("electricity", parseInt(electricity))}
                                                                        />
                                                                        <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                            <div style={{marginTop: '14px'}} className={yearsProjected === 0 || electricityProjection === 0 ? "hidden" : ""}>
                                                                                <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                                <p className='text-base text-gray text-start -mt-1'>${electricityProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between">
                                                                    <Form.Label className="font-normal mt-4">Gas:</Form.Label>
                                                                    <div className='flex justify-center gap-2'>
                                                                        <CurrencyInput
                                                                            id="gas"
                                                                            onClick={() => handleSelectInput("gas")}
                                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                            prefix='$'
                                                                            value={gas}
                                                                            decimalsLimit={2}
                                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setGas, "gas")}
                                                                            onBlur={() => updatePropertyEdits("gas", parseInt(gas))}
                                                                        />
                                                                        <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                            <div style={{marginTop: '14px'}} className={yearsProjected === 0 || gasProjection === 0 ? "hidden" : ""}>
                                                                                <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                                <p className='text-base text-gray text-start -mt-1'>${gasProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between">
                                                                    <Form.Label className="font-normal mt-4">Sewage:</Form.Label>
                                                                    <div className='flex justify-center gap-2'>
                                                                        <CurrencyInput
                                                                            id="sewage"
                                                                            onClick={() => handleSelectInput("sewage")}
                                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                            prefix='$'
                                                                            value={sewage}
                                                                            decimalsLimit={2}
                                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setSewage, "sewage")}
                                                                            onBlur={() => updatePropertyEdits("sewage", parseInt(sewage))}
                                                                        />
                                                                        <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                            <div style={{marginTop: '14px'}} className={yearsProjected === 0 || sewageProjection === 0 ? "hidden" : ""}>
                                                                                <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                                <p className='text-base text-gray text-start -mt-1'>${sewageProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between">
                                                                    <Form.Label className="font-normal mt-4">Garbage:</Form.Label>
                                                                    <div className='flex justify-center gap-2'>
                                                                        <CurrencyInput
                                                                            id="garbage"
                                                                            onClick={() => handleSelectInput("garbage")}
                                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                            prefix='$'
                                                                            value={garbage}
                                                                            decimalsLimit={2}
                                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setGarbage, "garbage")}
                                                                            onBlur={() => updatePropertyEdits("garbage", parseInt(garbage))}
                                                                        />
                                                                        <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                            <div style={{marginTop: '14px'}} className={yearsProjected === 0 || garbageProjection === 0 ? "hidden" : ""}>
                                                                                <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                                <p className='text-base text-gray text-start -mt-1'>${garbageProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox checked={utilitiesPaid} />
                                                                        }
                                                                        label="Paid by renters"
                                                                        onClick={() => handleUtilitiesPaid()}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                    <div className='lg:ml-3 ml-1 -pt-3'>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">Repairs</Form.Label>
                                                                <img data-tip data-for="repairs" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 w-12 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={repairsPct}
                                                                    id="repairs-pct"
                                                                    onClick={() => handleSelectInput("repairs-pct")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleRepairsPct((e === undefined) ? 0 : e)}
                                                                    onBlur={() => updatePropertyEdits('repairsPct', parseFloat(repairsPct))}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={repairs}
                                                                    id="repairs-num"
                                                                    onClick={() => handleSelectInput("repairs-num")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={e => handleRepairs((e === undefined) ? 0 : e)}
                                                                    onBlur={() => updatePropertyEdits('repairsPct', parseFloat(repairsPct))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || repairsProjection === 0 ? "hidden" : "ml-2"}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${repairsProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'CapEx:' : 'Capital expenditures:'}</Form.Label>
                                                                <img data-tip data-for="capex" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 w-12 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={capexPct}
                                                                    id="capex-pct"
                                                                    onClick={() => handleSelectInput("capex-pct")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleCapexPct((e === undefined) ? 0 : e)}
                                                                    onBlur={() => updatePropertyEdits('capexPct', parseFloat(capexPct))}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={capex}
                                                                    id="capex-num"
                                                                    onClick={() => handleSelectInput("capex-num")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={e => handleCapex((e === undefined) ? 0 : e)}
                                                                    onBlur={() => updatePropertyEdits('capexPct', parseFloat(capexPct))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || capexProjection === 0 ? "hidden" : "ml-2"}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${capexProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Prop mgnt:' : 'Prop management:'}</Form.Label>
                                                                <img data-tip data-for="propMgnt" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 w-12 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={propMgmtPct}
                                                                    id="prop-mgnt-pct"
                                                                    onClick={() => handleSelectInput("prop-mgnt-pct")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), handlePropMgmtPct, "propMgmtPct")}
                                                                    onBlur={() => updatePropertyEdits('propMgmtPct', parseInt(propMgmtPct))}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={propMgmt}
                                                                    id="prop-mgnt-num"
                                                                    onClick={() => handleSelectInput("prop-mgnt-num")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), handlePropMgmt, "propMgmt")}
                                                                    onBlur={() => updatePropertyEdits('propMgmtPct', parseInt(propMgmtPct))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || propMgmtProjection === 0 ? "hidden" : "ml-2"}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${propMgmtProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">Vacancy:</Form.Label>
                                                                <img data-tip data-for="vacancy" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 w-12 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={vacancyPct}
                                                                    id="vacancy-pct"
                                                                    onClick={() => handleSelectInput("vacancy-pct")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), handleVacancyPct, "vacancyPct")}
                                                                    onBlur={() => updatePropertyEdits('vacancyPct', parseInt(vacancyPct))}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={vacancy}
                                                                    id="vacancy-num"
                                                                    onClick={() => handleSelectInput("vacancy-num")}
                                                                    decimalsLimit={1}
                                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), handleVacancy, "vacancy")}
                                                                    onBlur={() => updatePropertyEdits('vacancyPct', parseInt(vacancyPct))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || vacancyProjection === 0 ? "hidden" : "ml-2"}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${vacancyProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <Form.Label className="font-normal mt-4">Misc expenses:</Form.Label>
                                                            <div className='flex justify-center gap-2'>
                                                                <CurrencyInput
                                                                    id="misc-expenses"
                                                                    onClick={() => handleSelectInput("misc-expenses")}
                                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={miscExpenses}
                                                                    decimalsLimit={1}
                                                                    onValueChange={e => handleSetValues((e === undefined ? 0 : e), setMiscExpenses, "miscExpenses" )}
                                                                    onBlur={() => updatePropertyEdits("miscExpenses", parseInt(miscExpenses))}
                                                                />
                                                                <Tooltip title={`Future value in ${yearsProjected} years`}>
                                                                    <div style={{marginTop: '14px'}} className={yearsProjected === 0 || miscExpensesProjection === 0 ? "hidden" : ""}>
                                                                        <p className='text-gray text-start font-thin' style={{fontSize: '13px'}}>FV</p>
                                                                        <p className='text-base text-gray text-start -mt-1'>${miscExpensesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <div className='my-3 flex justify-center'>
                                                <p className="ml-3 color-gray text-gray text-sm">
                                                    Net Operating Income: ${newNetOperatingIncomeProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                            <Card className='mb-2 p-2.5 pl-3 cursor-pointer hover:bg-light-gray border-none rounded-xl'
                                                onClick={() => handleCollapse(mortgageCard, setMortgageCard, mortgageArrow, setMortgageArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex justify-center'>
                                                        <p><ArrowIcon status={mortgageArrow} /></p>
                                                        <p className="color-gray">Mortgage</p>
                                                    </div>
                                                    <p className="color-gray mr-8">
                                                        ${totalMonthlyMortgage.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={mortgageCard}>
                                                <div className={`bg-light-gray mt-1 pb-3 rounded-xl ${isMobile ? 'px-2' : 'px-3'}`}>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Purchase price:</Form.Label>
                                                        <CurrencyInput
                                                            className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={purchasePrice}
                                                            id="purchase-price-two"
                                                            onClick={() => handleSelectInput("purchase-price-two")}
                                                            decimalsLimit={0}
                                                            onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                                            onBlur={() => updatePropertyEdits('price', parseInt(purchasePrice))}
                                                        />
                                                    </div>
                                                    <div className='flex justify-between'>
                                                        <Form.Label className={`font-normal mt-4 ${isMobile ? '' : 'mr-3'}`}>Down payment:</Form.Label>
                                                        <div className="flex">
                                                            <CurrencyInput
                                                                className={`lg:ml-4 mt-3 p-1 pl-2 ${isMobile ? 'w-16' : 'w-20'} border-one rounded-l`}
                                                                suffix='%'
                                                                value={downpaymentPct}
                                                                id="downpayment-pct-two"
                                                                onClick={() => handleSelectInput("downpayment-pct-two")}
                                                                decimalsLimit={0}
                                                                onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                                onBlur={() => updatePropertyEdits('downpaymentPct', downpaymentPct)}
                                                            />
                                                            <CurrencyInput
                                                                className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r"
                                                                prefix='$'
                                                                value={downpayment}
                                                                id="downpayment-num-two"
                                                                onClick={() => handleSelectInput("downpayment-num-two")}
                                                                decimalsLimit={2}
                                                                onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                                onBlur={() => updatePropertyEdits('downpayment', downpayment)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Interest rate:</Form.Label>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-16 pl-2 mt-3 border-one rounded-sm"
                                                            suffix='%'
                                                            defaultValue={interestRate}
                                                            id="interest-rate"
                                                            onClick={() => handleSelectInput("interest-rate")}
                                                            decimalsLimit={3}
                                                            onValueChange={e => handleSetValues((e === undefined ? 0 : e), setInterestRate, "interestRate" )}
                                                            onBlur={() => updatePropertyEdits(interestRate, parseFloat(interestRate))}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Loan term:</Form.Label>
                                                        <CurrencyInput
                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                            suffix=' years'
                                                            value={loanTerm}
                                                            decimalsLimit={2}
                                                            onValueChange={e => handleSetValues((e === undefined ? 0 : e), setLoanTerm, "loanTerm" )}
                                                            onBlur={() => updatePropertyEdits("loanTerm", parseInt(loanTerm))}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <div className="flex justify-between mt-3 mx-3">
                                                <div className='flex justify-center gap-1'>
                                                    <Form.Label className="font-normal mt-1 text-left">PMI:</Form.Label>
                                                    <img data-tip data-for="mortgageEstimate" className="w-4 mb-3 mt-1 ml-1" src={info_popup} alt="info box"/>
                                                </div>
                                                <CurrencyInput
                                                    className="w-20 pl-2 border-one rounded-sm"
                                                    prefix='$'
                                                    value={mortgageInsurance}
                                                    id="private-mortgage-insurance"
                                                    onClick={() => handleSelectInput("private-mortgage-insurance")}
                                                    decimalsLimit={2}
                                                    onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setMortgageInsurance, "mortgageInsurance")}
                                                    onBlur={() => updatePropertyEdits('mortgageInsurance', parseInt(mortgageInsurance))}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Collapse>
                                </Card>
                                
                                {/* React Tooltips */}
                                <div className='flex justify-center'>
                                    <ReactTooltip id='homeownersInsurance' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Homeowners insurance</p>
                                        <p className='text-sm text-start'>Homeowners insurance is a form of property insurance that covers losses and damages to an individual's residence, along with furnishings and other assets in the home. The annual US average is $1,383 ($138/month) for a policy with $250,000 in dwelling coverage.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='hoa' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Homeowners Association Fee (HOA)</p>
                                        <p className='text-sm text-start'>A monthly fee paid to the homeowner's association who makes and enforces rules for a subdivision, planned community, or condominium building.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='repairs' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Repairs</p>
                                        <p className='text-sm text-start'>The average monthly cost of repairs made to the property. According to Go Banking Rates, the average annual cost for maintaining a home is $1,204 ($120/month). Repairs can either be calculated as a flat value or percentage of total monthly rent.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='capex' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Capital Expenditures (CapEx)</p>
                                        <p className='text-sm text-start'>Capital expenditures are the big purchases you have to make once every 10-20 years such as replacing the roof or water heater. BiggerPockets suggests putting aside $100/month is a fair estimate. CapEx can either be calculated as a flat value or percentage of total monthly rent.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='propMgnt' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Property management</p>
                                        <p className='text-sm text-start'>The property management fee is on average 10% of the monthly rent. Property managers take care of all your management needs such as sourcing and screening potential renters, maintenance repairs, and any problems with tenants.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='vacancy' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Vacancy</p>
                                        <p className='text-sm text-start'>Vacancy is the percentage of time in a year that no tenants are renting. The national average rental vacancy rate for 2021 was 6.1%.</p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='taxEstimate' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <p className='text-base text-bot-blue font-bold mb-2'>Property taxes</p>
                                        <div className='w-80'>
                                            <p>Property taxes are calculated by multiplying the property's tax rate (on average between 0.58% and 1.58%) by the value of the home.</p>
                                            <p className='mt-3'>Taxes are typically paid annually. Some finanicial institutions, however, prefer a monthly payment. Either way, we have included property taxes into the monthly payment.</p>
                                            <p className='mt-3 text-gray'>Property value: (<span className='font-bold'>${purchasePrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>) x property tax rate: (<span className='font-bold'>{property.propertyTaxRate}%</span>)</p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='mortgageEstimate' effect='solid' arrowColor='transparent' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque ml-5'
                                    >
                                        <p className='text-base text-bot-blue font-bold mb-2'>Private Mortgage Insurance (PMI)</p>
                                        <div className='w-72'>
                                            <p>
                                                The average cost of private mortgage insurance, or PMI, for a conventional home loan ranges from 0.58% to 1.86%
                                                of the original loan amount per year, according to Genworth Mortgage Insurance, Ginnie Mae and the Urban Institute.
                                                We estimated a 1.00% of the original loan amount per year.
                                            </p>
                                            <p className='mt-2 text-gray'>We encourage you to find an exact amount by connecting with a lender above.</p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                            </>
                        )}
                        <hr className='my-3 lg:w-11/12 border-solid border-gray' />

                        {/* Appreciation & Taxes */}
                        {(loading) ? (
                            <Skeleton
                                width={440}
                                height={62}
                                animation="wave"
                                variant="rectangular"
                                className='rounded'
                            />
                        ) : (
                            <>
                                <Card className="drop-shadow-lg shadow-slate-200 my-3 rounded-xl" style={{width: isMobile ? '100%' : '95%'}}>
                                    <Card className='m-2 py-2.5 lg:px-2.5 cursor-pointer rounded-xl hover:bg-light-gray-2 border-none' bg="light-gray"
                                        onClick={() => handleCollapse(appreciationCard, setAppreciationCard, apprBenefitsArrow, setApprBenefitsArrow)}
                                    >
                                        <div className={`flex justify-between font-bold ml-3 ${isMobile ? 'mr-3' : 'mr-5'}`}>
                                            <div className='flex justify-center'>
                                                <ArrowIcon status={apprBenefitsArrow} color={"black"} />
                                                <p className="color-dark-gray text-dark-gray text-left">Appreciation & Savings</p>
                                            </div>
                                            <p className="color-dark-gray text-dark-gray">${totalAppreciationandTaxesProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                        </div>
                                    </Card>
                                    <Collapse in={appreciationCard}>
                                        <Form.Group className={`pb-2 ${isMobile ? 'px-2' : 'px-4'}`}>
                                            
                                            {/* Market Appreciation */}
                                            <Card className={`my-2 lg:px-2.5 pt-2.5 pb-0.5 rounded-xl ${isMobile ? 'pl-2' : 'pl-3'} cursor-pointer hover:bg-light-gray border-none`}
                                                onClick={() => handleCollapse(assetAppreciation, setAssetAppreciation, appreciationArrow, setAppreciationArrow)}
                                            >
                                                <div className='flex justify-between text-left'>
                                                    <div className='flex flex-row justify-center text-start cursor-pointer'>
                                                        <ArrowIcon status={appreciationArrow} color={"black"} isMobile={isMobile} />
                                                        <Form.Label className="font-normal text-left cursor-pointer">Appreciation</Form.Label>
                                                        <img data-tip data-for="appreciation" className="w-4 mb-3 ml-1 cursor-pointer" src={info_popup} alt="info box"/>
                                                    </div>
                                                    <p className="color-gray lg:mr-4 mr-2">
                                                        ${newAppreciationProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={assetAppreciation}>
                                                <div className='bg-light-gray mt-1 pb-3 lg:px-4 px-3 rounded-xl'>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Appreciation rate:</Form.Label>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-16 pl-2 mt-3 border-one rounded-sm"
                                                            suffix='%'
                                                            id="appreciation-rate"
                                                            onClick={() => handleSelectInput("appreciation-rate")}
                                                            value={marketAppr}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setMarketAppr, "marketAppr")}
                                                            onBlur={() => updatePropertyEdits("marketAppr", marketAppr)}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>

                                            {/* Interest Deduction */}
                                            <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 rounded-xl ${isMobile ? 'pl-2' : 'pl-3'} cursor-pointer text-left hover:bg-light-gray border-none`}
                                                onClick={() => handleCollapse(taxBenefits, setTaxBenefits, taxBenefitsArrow, setTaxBenefitsArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex flex-row justify-center cursor-pointer'>
                                                        <ArrowIcon status={taxBenefitsArrow} color={"black"} />
                                                        <Form.Label className="font-normal text-left cursor-pointer">Interest savings:</Form.Label>
                                                        <img data-tip data-for="interestDeduction" className="w-4 mb-3 ml-1 cursor-pointer" src={info_popup} alt="info box"/>
                                                    </div>
                                                    <p className="lg:mr-4 mr-0">
                                                        ${totalInterestTaxSavingsProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={taxBenefits}>
                                                <div className='bg-light-gray mt-1 pb-3 lg:px-3 rounded-xl'>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4 lg:ml-3 ml-2">Annual income:</Form.Label>
                                                        <CurrencyInput
                                                            className="lg:w-28 w-24 pl-2 mt-3 mr-2 border-one rounded-sm"
                                                            prefix='$'
                                                            id="annual-income"
                                                            onClick={() => handleSelectInput("annual-income")}
                                                            value={annualIncome}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setAnnualIncome, "annualIncome")}
                                                            onBlur={() => updatePropertyEdits("annualIncome", parseInt(annualIncome))}
                                                        />
                                                    </div>
                                                    <div className='flex justify-between text-start lg:ml-3 ml-2 pt-3'>
                                                        <p>Filing Status:</p>
                                                        <DropdownButton size="md" variant='secondary-outline' title={filingStatus} className="-mt-1.5 drop-shadow-2xl rounded-xl z-50 border-none text-black hover:text-black">
                                                            <Dropdown.Item onSelect={() => handleFilingStatus("Single")}>Single</Dropdown.Item>
                                                            <Dropdown.Item onSelect={() => handleFilingStatus("Head of Household")}>Head of Household</Dropdown.Item>
                                                            <Dropdown.Item onSelect={() => handleFilingStatus("Married Filing Jointly")}>Married Filing Jointly</Dropdown.Item>
                                                            <Dropdown.Item onSelect={() => handleFilingStatus("Married Filing Separately")}>Married Filing Separately</Dropdown.Item>
                                                            <Dropdown.Item onSelect={() => handleFilingStatus("Qualified Widow")}>Qualified Widow</Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                    <div className='flex justify-between pt-3 lg:mx-3 mx-2'>
                                                        <div className='flex justify-center'>
                                                            <p>Tax Bracket: </p>
                                                            <a href="https://www.bankrate.com/taxes/tax-brackets/" target="__blank">
                                                                <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm mb-0.5 ml-3 text-bot-blue cursor-pointer" />
                                                            </a>
                                                        </div>
                                                        <p>{allFunctions.calcTaxBracket(annualIncome, filingStatus)}%</p>
                                                    </div>
                                                    <hr className='mx-2 my-3 border-gray'/>
                                                    <div className='flex justify-between lg:mx-3 mx-2'>
                                                        <p className='text-left'>Deductable interest:</p>
                                                        <p>${totalInterestProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                    </div>
                                                </div>
                                            </Collapse>

                                            {/* Depreciation Deduction */}
                                            <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 rounded-xl ${isMobile ? 'pl-2' : 'pl-3'} cursor-pointer text-left hover:bg-light-gray border-none`}
                                                onClick={() => handleCollapse(depreciationDeducation, setDepreciationDeducation, depreciationDeductionArrow, setDepreciationDeductionArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex flex-row justify-center cursor-pointer'>
                                                        <ArrowIcon status={depreciationDeductionArrow} color={"black"} />
                                                        <Form.Label className="font-normal text-left cursor-pointer">{isMobile ? 'Depr. savings:' : 'Depreciation savings:'}</Form.Label>
                                                        <img data-tip data-for="depreciationDeduction" className="w-4 mb-3 ml-1 cursor-pointer" src={info_popup} alt="info box"/>
                                                    </div>
                                                    <p className="color-gray lg:mr-4 mr-0">
                                                        ${depreciationDeducationSavingsProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={depreciationDeducation}>
                                                <div className={`bg-light-gray mt-1 pb-3 rounded-xl ${isMobile ? 'pl-3 pr-2' : 'pl-4 pr-3'}`}>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Purchase price:</Form.Label>
                                                        <CurrencyInput
                                                            id="property-value"
                                                            onClick={() => handleSelectInput("property-value")}
                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={purchasePrice}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setPurchasePrice, "price")}
                                                            onBlur={() => updatePropertyEdits("price", parseInt(purchasePrice))}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">- Land value:</Form.Label>
                                                        <CurrencyInput
                                                            id="land-value"
                                                            onClick={() => handleSelectInput("land-value")}
                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={landValue}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => handleSetValues((e === undefined ? 0 : e), setLandValue, "landValue")}
                                                            onBlur={() => updatePropertyEdits("landValue", parseInt(landValue))}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">&#247; {isMobile ? 'Depr. period:' : 'Depreciation period:'}</Form.Label>
                                                        <CurrencyInput
                                                            id="depr-period"
                                                            onClick={() => handleSelectInput("depr-period")}
                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                            suffix=' years'
                                                            value={deprPeriod}
                                                            decimalsLimit={2}
                                                            onValueChange={e => handleSetValues((e === undefined ? 0 : e), setLoanTerm, "deprPeriod" )}
                                                            onBlur={() => updatePropertyEdits("deprPeriod", parseFloat(deprPeriod))}
                                                        />
                                                    </div>
                                                    <hr className='border-2 border-light-gray-3 my-3' />
                                                    <div className='flex justify-between mr-4'>
                                                        <p>Total depreciation:</p>
                                                        <p>${totalDepreciationProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Form.Group>
                                    </Collapse>
                                </Card>

                                {/* React Tooltips */}
                                <div className='flex justify-center'>
                                    <ReactTooltip id='appreciation' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Market Appreciation (1 Yr)</p>
                                        <p className='text-sm text-start'>
                                            The amount a property's value will have appreciated in a given time frame. Since 1991, real estate in the US has appreciated
                                            4.3% annually. Given your estimated appreciation of {marketAppr}%, this property will have appreciated
                                            ${newAppreciationProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00 over the next {yearsProjected} {yearsProjected === 1 ? 'year' : 'years'}.
                                        </p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='interestDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Interest Tax Deducation Savings</p>
                                        <p className='text-sm text-start'>
                                            You don't have to pay any taxes on the money that went towards the interest on your loan. Thus, your interest tax deducation
                                            savings is calculated by multiplying what you won't be paying in taxes (your payments on interest) by your current tax bracket.
                                        </p>
                                    </ReactTooltip>
                                </div>
                                <div className='flex justify-center'>
                                    <ReactTooltip id='depreciationDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                        className='shadow-lg p-3 bg-white opaque w-80'
                                    >
                                        <p className='text-base text-bot-blue mb-2 text-center'>Depreciation Tax Deducation Savings</p>
                                        <p className='text-sm text-start'>
                                            Real estate is a physical asset that depreciates over time. Every year you can write off that year's depreciation on a
                                            property. Thus, your tax savings from the depreciation deduction on this property (based on your current tax bracket)
                                            would be ${depreciationDeducationSavingsProjection.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00 over the next {yearsProjected} {yearsProjected === 1 ? 'year' : 'years'} .
                                        </p>
                                        <p className='text-sm text-start mt-2'>
                                            A property's annual depreciation is calculated by subtracting the land value from the property value (since land doesn't depreciate)
                                            and then divide that by 27.5 years (the number of years determined by convention for a residential property to fully depreciate).
                                        </p>
                                    </ReactTooltip>
                                </div>
                            </>
                        )}

                        <div className="mt-4 mb-3">
                            <p className='text-gray text-xl font-bold tracking-tight mx-3 lg:w-96'>Comps</p>
                            <p className='text-gray text-sm tracking-tight mx-3 lg:w-96'>{(compProperties.length === 0) ? <i>No comps available for this property</i> : ''}</p>
                            <div className={`flex justify-end lg:-mt-3 ml-5 lg:w-96 ${(compProperties.length === 0) ? 'hidden' : ''}`}>
                                <p className='text-bot-blue font-bold tracking-tight px-2.5 py-1 rounded-xl cursor-pointer hover:bg-light-gray' onClick={() => setOpenCompsModal(true)}>
                                    <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm text-bot-blue cursor-pointer mr-2" />
                                    See more
                                </p>
                            </div>
                            <div className="pt-3 pb-1 margin-right lg:w-11/12 overflow-auto">
                                {(loadingComps) ? (
                                    <div className='flex'>
                                        <Skeleton
                                            width={250}
                                            height={200}
                                            animation="wave"
                                            variant="rectangular"
                                            className='rounded-xl mr-3'
                                        />
                                        <Skeleton
                                            width={150}
                                            height={200}
                                            animation="wave"
                                            variant="rectangular"
                                            className='rounded-xl mr-3'
                                        />
                                    </div>
                                ) : (
                                    <div className='flex h-48'>
                                        {compProperties.map(function (compProperty, index) {
                                            return (
                                                <CompCard
                                                    property={compProperty}
                                                    pricePerSqFt={pricePerSqFt}
                                                    setSelectedProperty={setSelectedProperty}
                                                    setShow={setShow}
                                                    scrollToTop={scrollToTop}
                                                    defaults={defaults}
                                                    propertyEdits={propertyEdits}
                                                    setPropertyEdit={setPropertyEdit}
                                                />
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}