import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export default function SelectHomeType(props) {
    const { allHomeTypes, homeTypes, setHomeTypes, type, setSelectAllButton, handleFilter, setHomeTypeTitle } = props

    const handleHomeTypes = () => {
        let newList = [];
        let homeListLength = 0;

        if (homeTypes.includes(type)) {
            // drop from list
            newList = homeTypes.filter(types => types !== type)
            setHomeTypes(newList);
            homeListLength = newList.length;
            handleFilter(newList, '', 'home type')
            // update title
            if (newList.length === 0) {
                setHomeTypeTitle('Home Type')
            } else if (newList.length === 1) {
                setHomeTypeTitle(newList.toString());
            } else {
                setHomeTypeTitle(`${(newList.toString()).slice(0, 10)}..`);
            }
        } else {
            // add to list
            homeTypes.push(type)
            homeListLength = homeTypes.length;
            setHomeTypes(homeTypes);
            handleFilter(homeTypes, '', 'home type')
            // update title
            if (homeTypes.length === allHomeTypes.length) {
                setHomeTypeTitle('Home Type');
            } else if (homeTypes.length === 1) {
                setHomeTypeTitle(homeTypes.toString());
            } else {
                setHomeTypeTitle(`${(homeTypes.toString()).slice(0, 10)}..`);
            }
        }

        if (homeListLength === allHomeTypes.length) {
            setSelectAllButton("Deselect All")
        } else {
            setSelectAllButton("Select All")
        }
    }
    
    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox checked={(homeTypes.includes(type)) ? true : false} />
                }
                label={type}
                className='lg:pl-4'
                onClick={handleHomeTypes}
            />
        </div>
    )
}
