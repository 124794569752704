import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

export default function ArrowIcon(props) {
    const { status, color } = props;
    let arrowClass = ""

    if (color === "blue") {
        arrowClass = "text-lg lg:mr-3 mr-1.5 mt-1 text-bot-blue"
    } else if (color === "gray") {
        arrowClass = "text-lg lg:mr-3 mr-1.5 mt-1 text-gray"
    } else {
        arrowClass = "text-lg lg:mr-3 mr-1.5 mt-1 text-black"
    }
    
    if (status === false) {
        return (
            <FontAwesomeIcon
                icon={faAngleDown}
                className={arrowClass}
            />
        )
    } else {
        return (
            <FontAwesomeIcon
                icon={faAngleUp}
                className={arrowClass}
            />
        )
    }
}