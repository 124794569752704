import { useContext, createContext } from "react";

// logos
import logo from '../assets/logo.png';
import crunch_logo from '../assets/crunch_logo.png';
import kasby_logo from '../assets/kasby_logo.png';
import luke_logo from '../assets/luke_logo.png';
import redsign_logo from '../assets/redsign_logo.png';
import burnt_ships from '../assets/burnt_ships.png';
import elite_utah_living from '../assets/logos/elite_fathom.png';
import sternteam from '../assets/logos/sternteam.png';
import reinexus from '../assets/logos/reinexus.png';
import wildeteam from '../assets/logos/wildeteam.png';
import moveutahrealestate from '../assets/logos/moveutahrealestate.png';
import tbrealestate from '../assets/logos/tbrealestate.png';
import berkshire from '../assets/logos/berkshire.png';
import alltrustrealty from '../assets/logos/alltrustrealty.png';
import jeantanner from '../assets/logos/jeantanner.png';
import kwwestfield from '../assets/logos/kwwestfield.png';
import stitch from '../assets/logos/stitch.png';
import tylerpincock from '../assets/logos/tylerpincock.png';

export const AppContext = createContext(null);

export function useAppContext() {
    return useContext(AppContext);
}

export const getLogo = () => {
    let company_logo = logo;
    const webURL = window.location.href;
    if (webURL.includes("lukemocke")) {
      company_logo = luke_logo;
    } else if (webURL.includes("kasby")) {
      company_logo = kasby_logo
    } else if (webURL.includes("redsign")) {
      company_logo = redsign_logo
    } else if (webURL.includes("crunch.deals")) {
      company_logo = crunch_logo
    } else if (webURL.includes("burntshipsrealty")) {
      company_logo = burnt_ships
    } else if (webURL.includes("eliteutahliving")) {
      company_logo = elite_utah_living
    } else if (webURL.includes("sternteam")) {
      company_logo = sternteam
    } else if (webURL.includes("reinexus")) {
      company_logo = reinexus
    } else if (webURL.includes("wildeteam")) {
      company_logo = wildeteam
    } else if (webURL.includes("moveutahrealestate")) {
      company_logo = moveutahrealestate
    } else if (webURL.includes("tbrealestate")) {
      company_logo = tbrealestate
    } else if (webURL.includes("berkshirehathaway")) {
      company_logo = berkshire
    } else if (webURL.includes("alltrustrealty")) {
      company_logo = alltrustrealty
    } else if (webURL.includes("jeantanner")) {
      company_logo = jeantanner
    } else if (webURL.includes("kwwestfield")) {
      company_logo = kwwestfield
    } else if (webURL.includes("stitch")) {
      company_logo = stitch
    } else if (webURL.includes("tylerpincock")) {
      company_logo = tylerpincock
    }
    
    return company_logo
}