import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function SortDropdown(props) {

    const { sortTitle, setSortTitle, sortProperties } = props;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = (value) => {
        setAnchorEl(null);
        setSortTitle(value);
    };

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    
    return (
        <div>
            <div className='flex justify-center'>
                <p>Sort:</p>
                <p
                    id="demo-positioned-button"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={`${isMobile ? 'px-1.5' : 'px-2.5'} py-1.5 -mt-1.5 ml-1 text-base rounded-xl hover:bg-light-gray-2 cursor-pointer text-black font-semi-bold`}
                >
                    <span>
                        {(isMobile && (sortTitle === 'Price (low to high)' || sortTitle === 'Price (high to low)')) ? 'Price' : sortTitle}
                        <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                    </span>
                </p>
            </div>
            <Menu
                id="demo-positioned-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(sortTitle)}
                onBlur={() => handleClose(sortTitle)}
                className='mt-1.5 ml-1.5'
            >
                <MenuItem key='cash-flow' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Cash Flow")}>Cash Flow</MenuItem>
                <MenuItem key='cap-rate' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Cap Rate")}>Cap Rate</MenuItem>
                <MenuItem key='total-return' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Total Return")}>Total Return</MenuItem>
                <MenuItem key='price-per-sf' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Price/Sq Ft")}>Price/Sq Ft</MenuItem>
                <MenuItem key='price-asc' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Price (low to high)")}>Price (low to high)</MenuItem>
                <MenuItem key='price-desc' sx={{fontSize: isMobile ? '15px' : '17px', width: '180px'}} onClick={() => sortProperties("Price (high to low)")}>Price (high to low)</MenuItem>
            </Menu>
        </div>
    )
}
