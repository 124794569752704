import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUp, faClock, faLink } from '@fortawesome/free-solid-svg-icons';
import articles from '../assets/Learn/articles.json';
import ReactTooltip from 'react-tooltip';

export default function Article() {
    // get the blog from articles
    const url = window.location.href;
    let titleArr = url.split("/");
    let title = titleArr[titleArr.length - 1];
    const blog = articles[title];

    useEffect(() => {
        // scroll to the top
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, []);

    const notifySuccessMsg = (msg) => toast.success(<p className='text-lg font-bold'>{msg}</p>, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
      
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_xsn87gk', 'template_investorbot', e.target, '-zkNxR_jnyyJKePEU')
            .then((result) => {
                notifySuccessMsg('Successfully added to the beta list.')
            }, (error) => {
                notifyErrorMsg(error.text);
            });
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        notifySuccessMsg('Link copied!');
    }

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    }


    return (
        <div className='flex justify-center items-center flex-col'>
            <div className={`mb-10 ${isMobile ? 'px-4' : ''}`} style={{width: isMobile ? '100%' : '650px', fontFamily: 'source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif'}}>
                <p onClick={() => window.history.back()}>
                    <FontAwesomeIcon icon={faArrowLeft} className='text-center text-3xl p-2.5 rounded-full cursor-pointer hover:bg-light-gray-2 hover:text-black' />
                </p>
                <div className='flex justify-start gap-3 ml-2 mt-2'>
                    <p className='flex justify-start gap-2 text-left text-gray text-lg'>
                        <span className='mt-0.5'><FontAwesomeIcon icon={faClock} className='text-center text-lg' /></span>
                        <span>2 min read</span>
                    </p>
                    <p className='text-gray mt-0.5'>&#x2022;</p>
                    <div data-tip data-for='copyLink' className='flex justify-center text-lg'>
                        <FontAwesomeIcon
                            icon={faLink}
                            className='text-center text-gray cursor-pointer mt-1 hover:text-black'
                            onClick={() => handleCopyLink()}
                        />
                    </div>
                    <div className='flex justify-center'>
                      <ReactTooltip id='copyLink' effect='solid' place='bottom' arrowColor='transparent' delayHide={100} backgroundColor="black" borderColor="black" type="dark" className='shadow-xl p-2 bg-black opaque'>
                          <p className='text-sm text-white px-1 font-landingSans'>Copy link</p>
                      </ReactTooltip>
                    </div>
                </div>
                <p className='text-left text-3xl font-extrabold font-landingSans mt-4 tracking-tight'>{blog.title}</p>
                <img src={require(`../assets/Learn/${blog.image}.jpg`)} alt='blog img' className='rounded-xl mt-4' />
                <p className='text-sm text-gray text-center mt-2'>Image by Freepik</p>
                <div className='text-dark-gray text-xl leading-10'>
                    <p className='mt-4'>{blog.introduction}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.header_one}</p>
                    <p className='mt-2'>{blog.paragraph_one}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_two') ? blog.header_two : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_two') ? blog.paragraph_two : ''}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_three') ? blog.header_three : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_three') ? blog.paragraph_three : ''}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_four') ? blog.header_four : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_four') ? blog.paragraph_four : ''}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_five') ? blog.header_five : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_five') ? blog.paragraph_five : ''}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_six') ? blog.header_six : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_six') ? blog.paragraph_six : ''}</p>
                    <p className='mt-5 font-extrabold text-2xl font-landingSans tracking-tight'>{blog.hasOwnProperty('header_seven') ? blog.header_seven : ''}</p>
                    <p className='mt-2'>{blog.hasOwnProperty('paragraph_seven') ? blog.paragraph_seven : ''}</p>
                    <p className='mt-5'>{blog.hasOwnProperty('conclusion') ? blog.conclusion : ''}</p>
                </div>
                <p
                    style={{width: '160px'}}
                    onClick={() => handleScrollToTop()}
                    className='flex justify-center gap-3 text-left text-xl p-2.5 mt-5 rounded-xl cursor-pointer bg-light-gray hover:bg-light-gray-2 hover:text-black'
                >
                    <FontAwesomeIcon icon={faArrowUp} className='mt-1' />
                    <span>Back to top</span>
                </p>
            </div>
            <hr className='border-2 border-gray w-100' />

            {/* Footer */}
            <hr className='border-gray' />
            <div className='text-center lg:mx-80' style={{width: '100%'}}>
                <div className='lg:flex lg:justify-around lg:gap-32 pt-16 pb-12'>
                <div className='flex justify-center mb-5'>
                    <img src={logo} alt="logo" className='h-10' />
                </div>
                <div className='flex justify-center text-gray mb-5'>
                    <div>
                    <p className='font-bold mb-3 w-32'>Quick Links</p>
                    <Link to='/' className='hover:text-bot-blue hover:no-underline'>
                        <p className='mb-3'>Home</p>
                    </Link>
                    <Link to='/search' className='hover:text-bot-blue hover:no-underline'>
                        <p className='mb-3'>Map</p>
                    </Link>
                    </div>
                </div>
                <form onSubmit={sendEmail} className='mx-5 beta-top-m'>
                    <p className='text-3xl font-black tracking-tighter text-gray text-left mb-3'>Join the beta</p>
                    <div className='flex justify-center h-12 text-black'>
                    <input
                        id='email'
                        name='from_email'
                        placeholder='Your email'
                        className='w-64 px-3 border-2 border-gray rounded-l-md hover:border-black'
                    />
                    <button
                        type='submit'
                        className='flex justify-center items-center w-32 text-white bg-bot-blue hover:bg-dark-bot-blue text-black rounded-r-md font-bold text-lg'
                    >
                        Join
                    </button>
                    </div>
                </form>
                </div>
                <div className='flex justify-center mx-5 pb-2 text-gray'>
                <p>© 2022 Investorbot, LLC. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}
