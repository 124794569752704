import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'

export default function ArticleFilter(props) {
    const { title, icon, category, handleArticleFilter } = props;
    const [highlight, setHighlight] = useState(false);

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
  
    return (
        <>
            {isMobile ? (
                <div
                    onMouseOver={() => setHighlight(!highlight)}
                    onClick={() => handleArticleFilter(title)}
                    className={`flex justify-center gap-3 p-3 border-2 border-black rounded-md font-semibold cursor-pointer ${category === title ? 'bg-black text-white' : 'hover:bg-light-gray-2'}`}
                >
                    <FontAwesomeIcon icon={icon} className={`text-center text-xl mt-0.5 ${category === title ? 'text-white' : 'text-black'}`} />
                    <p className='text-base'>{title}</p>
                </div>
            ) : (
                <p
                    onMouseOver={() => setHighlight(!highlight)}
                    onClick={() => handleArticleFilter(title)}
                    className={`flex justify-center gap-3 p-3 border-2 border-black rounded-md font-semibold cursor-pointer ${category === title ? 'bg-black text-white' : 'hover:bg-light-gray-2'}`}
                >
                    <FontAwesomeIcon icon={icon} className={`text-center text-2xl mt-0.5 ${category === title ? 'text-white' : 'text-black'}`} />
                    <span className='text-lg'>{title}</span>
                </p>
            )}
        </>
    )
}
