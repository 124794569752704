import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { supabase } from './supabase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';
import Nav from 'react-bootstrap/Nav';
import ProfileBtn from './components/ProfileBtn';
import ReactGA from 'react-ga';
// images
import your_logo from './assets/your_logo.png';
import logo_icon from './assets/logo_icon.png';
import SavedSearchesModal from './components/SavedSearchesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Drawer } from '@mui/material';
import SignupComponent from './components/SignupComponent';
import { getLogo } from './lib/contextLib';

ReactGA.initialize('G-B0PBJX89Q7');

function App() {
  const currentURL = window.location.href;
  const [session, setSession] = useState(null);
  // modals
  const [openLogin, setOpenLogin] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState('Login');
  const [loginHeader, setLoginHeader] = useState('Login');
  const [openSavedSearchesModal, setOpenSavedSearchesModal] = useState(false);
  const webURL = window.location.href;
  const COMPANY_LOGO = getLogo();
  // URL parameter queries
  const queryParameters = new URLSearchParams(window.location.search);
  const agentIdFromURL = queryParameters.get("agent");
  const clientFromURL = queryParameters.get("client");
  const inviteCodeFromURL = queryParameters.get("invitecode");
  const agentSignupCodeFromURL = queryParameters.get("agentsignup");
  const portfolioCodeFromURL = queryParameters.get("portfoliocode");

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      if (agentSignupCodeFromURL !== null) {
        // set user_type to "agent"
        checkCode(session);
      }
      // if there are no parameters being passed in, check the 
      if (agentIdFromURL === null &&
        clientFromURL === null &&
        inviteCodeFromURL === null &&
        agentSignupCodeFromURL === null &&
        portfolioCodeFromURL === null
      ) {
        checkDomain(session);
      }
    })

    const checkDomain = async (session) => {
      try {
        let { data, error } = await supabase
          .from('profiles')
          .select(`id, user_type, domain_login`)
          .eq('id', session.user.id)
            
        if (error) throw error

        let urlArray = window.location.href.split("/");
        let domain = urlArray[2];
        // set domain login if not yet set
        if (data && data.length > 0 && data[0].domain_login === null && data[0].user_type === null) {
          try {
            const updates = {
              id: session.user.id,
              updated_at: new Date(),
              domain_login: domain
            }
    
            let { error } = await supabase.from('profiles').upsert(updates)
    
            if (error) throw error
          } catch (error) {
              console.error(error.message);
          }
        } else if (data && data.length === 0) {
          // likely first time logging in for a lead after signup
          try {
            const updates = {
              id: session.user.id,
              updated_at: new Date(),
              email: session.user.email,
              domain_login: domain,
              user_type: "lead"
            }
    
            let { error } = await supabase.from('profiles').upsert(updates)
    
            if (error) throw error
          } catch (error) {
              console.error(error.message);
          }
        }

      } catch (error) {
        console.log(error.message);
      }
    }

    const checkCode = async (session) => {
      try {
        let { data, error } = await supabase
          .from('team')
          .select(`id, invite_code, domain, agent_emails`)
          .eq('invite_code', agentSignupCodeFromURL)

        if (error) throw error

        if (data && data[0].invite_code === agentSignupCodeFromURL && window.location.href.includes(data[0].domain)) {
          if (data[0].agent_emails === null) {
            setAgentStatus(session);
            setAgentStatusInDB(session, data[0].agent_emails, data[0].id);
          } else if (!data[0].agent_emails.includes(session.user.email)) {
            setAgentStatus(session);
            setAgentStatusInDB(session, data[0].agent_emails, data[0].id);
          } else {
            setAgentStatus(session);
          }
        }

      } catch (error) {
        console.log(error.message);
      }
    }

    const setAgentStatusInDB = async (session, agentEmails, teamId) => {
      if (agentEmails && agentEmails.length > 0) {
        agentEmails.push(session.user.email);
      } else {
        agentEmails = [session.user.email];
      }
      try {
        const updates = {
          id: teamId,
          agent_emails: agentEmails,
        }

        let { error } = await supabase.from('team').upsert(updates)

        if (error) throw error
      } catch (error) {
          console.error(error.message)
      }
    }

    const setAgentStatus = async (session) => {
      try {
        const updates = {
          id: session.user.id,
          created_at: new Date(),
          updated_at: new Date(),
          email: session.user.email,
          user_type: "agent"
        }

        let { error } = await supabase.from('profiles').upsert(updates)

        if (error) throw error
      } catch (error) {
          console.log(error.message)
      }
    }

    // fetch('/api')
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data.users);
    //   })
    //   .catch(error => console.error('Error fetching data:', error))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setTimeout(function() { window.location.reload(true) }, 100);
  }

  // drawer for mobile
  const [drawer, setDrawer] = useState({
    top: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  // determines if the screen is a mobile device
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;

  const handleLoginBtn = () => {
    setLoginOrSignup('Login');
    setLoginHeader('Login');
    toggleDrawer('top', false);
    setTimeout(function() { setOpenLogin(true); }, 100);
  }
  
  const handleSignupBtn = () => {
    setLoginOrSignup('Sign up');
    setLoginHeader('Sign up');
    setOpenLogin(true);
  }

  const handleCloseLogin = () => {
    setOpenLogin(false);
    setTimeout(() => { setLoginHeader("Sign up"); }, 200)
  }

  return (
    <div className="mb-3">
      <div className='flex justify-center'>
        <ToastContainer
          style={{ width: isMobile ? '' : '385px' }}
        />
      </div>
      
      {/* Login modal */}
      <Modal size={'md'} centered={true} show={openLogin} onHide={() => handleCloseLogin()} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
        <Modal.Body>
          <SignupComponent
            setOpenLogin={setOpenLogin}
            loginOrSignup={loginOrSignup}
            setLoginOrSignup={setLoginOrSignup}
            handleCloseLogin={handleCloseLogin}
            loginHeader={loginHeader}
          />
        </Modal.Body>
      </Modal>
      
      {/* Saved searches modal */}
      <Modal size={'lg'} centered={true} show={openSavedSearchesModal} onHide={() => setOpenSavedSearchesModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
        <Modal.Header closeButton>
          <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
        </Modal.Header>
        <Modal.Body>
          <SavedSearchesModal session={session} />
        </Modal.Body>
      </Modal>

      {/* Nav bar */}
      <div>
        <Navbar collapseOnSelect bg="light-gray" expand="md" className="lg:flex lg:justify-between mb-3">
          <div className='flex justify-start items-center'>
            <Navbar.Brand className="font-weight-bold text-muted">
              <LinkContainer to="/">
                {currentURL.includes("/investorbot.us") ? (
                  <Nav.Link className="text-bot-blue">
                    <div className={`flex justify-center ${isMobile ? 'gap-2' : 'gap-5'}`}>
                      <img src={your_logo} alt="your logo" className={`rounded-md ${isMobile ? 'h-7' : 'h-9'}`} />
                      <p className={`font-thin text-xl text-gray ${isMobile ? '-mt-0.5' : 'mt-1'}`}>|</p>
                      <img src={logo_icon} alt="investorbot logo" className={`${isMobile ? 'h-7' : 'h-7 mt-1'}`} />
                    </div>
                  </Nav.Link>
                ) : (
                  <Nav.Link className="text-bot-blue">
                    <div className='flex justify-center gap-5'>
                      <img src={COMPANY_LOGO} alt="company logo" className={isMobile ? 'h-7 mt-0.5' : 'h-9 ml-3 mt-0.5'} />
                      <p className='font-thin text-xl text-gray lg:mt-1'>|</p>
                      <img src={logo_icon} alt="investorbot logo" className={`mt-1 ${isMobile ? 'h-6' : 'h-7'}`} />
                    </div>
                  </Nav.Link>
                )}
              </LinkContainer>
            </Navbar.Brand>
            <div className={isMobile ? 'hidden' : 'flex justify-start items-center gap-4'}>
              <Link to="/dashboard" className='hover:no-underline'>
                <p className='px-3 py-2.5 hover:bg-light-gray-2 hover:text-black font-semibold rounded-xl hover:no-underline ml-3'>
                  Dashboard
                </p>
              </Link>
              <Link to="/search" className='hover:no-underline'>
                <p className='px-3 py-2.5 hover:bg-light-gray-2 hover:text-black font-semibold rounded-xl hover:no-underline'>
                  Search
                </p>
              </Link>
              <Link to="/deal-calculator" className='hover:no-underline'>
                <p className='px-3 py-2.5 hover:bg-light-gray-2 hover:text-black font-semibold rounded-xl hover:no-underline'>
                  Calculator
                </p>
              </Link>
              <Link to="/learn" className='hover:no-underline'>
                <p className='px-3 py-2.5 hover:bg-light-gray-2 hover:text-black font-semibold rounded-xl hover:no-underline'>
                  Learn
                </p>
              </Link>
            </div>
          </div>
          {isMobile ? (
            <div className='flex justify-end -mb-2.5'>
              {session ? (
                <div className='mr-1'>
                  <ProfileBtn
                    session={session}
                    handleSignOut={handleSignOut}
                    setOpenSavedSearchesModal={setOpenSavedSearchesModal}
                  />
                </div>
              ) : (
                <div className='hidden'></div>
              )}
              <Fragment key={'top'}>
                <button
                    onClick={toggleDrawer('top', true)}
                    className='bg-light-gray mt-1 hover:bg-light-gray-2 px-3 py-2 rounded-xl mb-3'
                >
                    <FontAwesomeIcon icon={faBars} className="text-2xl text-gray" />
                </button>
                <Drawer
                  anchor={'top'}
                  open={drawer['top']}
                  onClose={toggleDrawer('top', false)}
                >
                  <div
                    onClick={toggleDrawer('top', false)}
                    className='px-4 pt-3 pr-2 flex flex-col gap-5'
                  >
                    <div className='flex justify-between'>
                      <div className="mt-2.5 ml-2">
                        {currentURL.includes("agents.investorbot") || currentURL.includes("agentslandingpage") ? (
                          <div className={`flex justify-center ${isMobile ? 'gap-2' : 'gap-5'}`}>
                            <img src={your_logo} alt="your logo" className={`rounded-md ${isMobile ? 'h-7' : 'h-9'}`} />
                            <p className={`font-thin text-xl text-gray ${isMobile ? '-mt-0.5' : 'mt-1'}`}>|</p>
                            <img src={logo_icon} alt="investorbot logo" className={`${isMobile ? 'h-7' : 'h-7 mt-1'}`} />
                          </div>
                        ) : (
                          <div className='flex justify-center gap-5'>
                            <img src={COMPANY_LOGO} alt="company logo" className={isMobile ? `${!webURL.includes('.investorbot') ? 'h-5' : 'h-7'} mt-1` : 'h-9 ml-3 mt-0.5'} />
                            <p className={webURL.includes('.investorbot') ? 'font-thin text-xl text-gray lg:mt-1' : 'hidden'}>|</p>
                            <img src={logo_icon} alt="investorbot logo" className={webURL.includes('.investorbot') ? `mt-1 ${isMobile ? 'h-6' : 'h-7'}` : 'hidden'} />
                          </div>
                        )}
                      </div>
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ paddingRight: '13px', paddingLeft: '13px' }}
                        className="text-2xl text-gray pt-2 py-2 rounded-full hover:bg-light-gray mt-1"
                      />
                    </div>
                    <div className='flex flex-col items-start gap-1 mt-2 text-xl' style={{width: '100%'}}>
                      <Link to="/dashboard" className='hover:no-underline' style={{width: '100%'}}>
                        <p className='px-3 py-2 hover:bg-light-gray-2 text-darkgray hover:text-darkgray font-semibold rounded-xl hover:no-underline'>
                          Dashboard
                        </p>
                      </Link>
                      <Link to="/search" className='hover:no-underline' style={{width: '100%'}}>
                        <p className='px-3 py-2 hover:bg-light-gray-2 text-darkgray hover:text-darkgray font-semibold rounded-xl hover:no-underline'>
                          Search
                        </p>
                      </Link>
                      <Link to="/deal-calculator" className='hover:no-underline' style={{width: '100%'}}>
                        <p className='px-3 py-2 hover:bg-light-gray-2 text-darkgray hover:text-darkgray font-semibold rounded-xl hover:no-underline'>
                          Calculator
                        </p>
                      </Link>
                      <Link to="/learn" className='hover:no-underline' style={{width: '100%'}}>
                        <p className='px-3 py-2 hover:bg-light-gray-2 text-darkgray hover:text-darkgray font-semibold rounded-xl hover:no-underline'>
                          Learn
                        </p>
                      </Link>
                    </div>
                    <div className='mx-4 mt-3 flex justify-center mb-4'>
                      {session ? (
                        <div className='w-80' onClick={() => handleSignOut()}>
                          <p className='w-full text-white text-center font-bold py-2.5 bg-bot-blue hover:bg-dark-bot-blue rounded-xl'>Log out</p>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className='w-80 py-2.5 bg-bot-blue text-white font-semibold rounded-xl hover:bg-dark-bot-blue border-none'
                          onClick={() => handleLoginBtn()}
                        >
                          Log in
                        </button>
                      )}
                    </div>
                  </div>
                </Drawer>
              </Fragment>
            </div>
          ) : (
            <div>
              {(!session) ? (
                <div className='ml-3 flex justify-end gap-2'>
                  <button
                    type="button"
                    className='px-4 mt-2 h-10 bg-light-gray-2 font-semibold rounded-xl hover:text-black hover:bg-light-gray-3 border-none'
                    onClick={() => handleLoginBtn()}
                  >
                    Log in
                  </button>
                  <button
                    type="button"
                    className='px-4 mt-2 h-10 bg-bot-blue text-white font-semibold rounded-xl hover:bg-dark-bot-blue border-none'
                    onClick={() => handleSignupBtn()}
                  >
                    Sign up
                  </button>
                </div>
              ) : (
                <div className={isMobile ? 'hidden' : 'mr-4 mt-1'}>
                  <ProfileBtn
                    session={session}
                    handleSignOut={handleSignOut}
                    setOpenSavedSearchesModal={setOpenSavedSearchesModal}
                  />
                </div>
              )}
            </div>
          )}
        </Navbar>
      </div>

      <Routes />
    </div>
  );
}

export default App;