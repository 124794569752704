const MAP_CACHE = "MAP_CACHE"
const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14

// TODO: PASS IN MAP_CENTER() NOT MAP_BOUNDS

export const getMapCache = () => {
    let mapCache = null;
    
    try {
        const data = localStorage.getItem(MAP_CACHE)
        
        if (data) {
            mapCache = JSON.parse(data);
        }
    }
    catch (e) {
        console.error(e.message)
    }
    
    return mapCache
}

export const setMapCache = (defaults, selectedProperty, newMapCenter, newMapZoom) => {

    if (defaults !== null) {

        // TODO: Add mapBounds to local storage (and defaults on home.js)
        const mapCache = {
            expiry: new Date().getTime() + TWO_WEEKS,
            mapCenter: newMapCenter,
            mapBounds: defaults.mapBounds,
            mapZoom: newMapZoom,
            selectedProperty: selectedProperty
        }

        try{
            localStorage.setItem(MAP_CACHE, JSON.stringify(mapCache))
        }
        catch (e) {
            // cleanUpStorage(data)
            console.error('Clean up storage')
        }
    }

}