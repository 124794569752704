import React from 'react';

export default function PropertyDetail(props) {
    let { label, value, suffix, currency } = props;

    if (currency === true && (value !== null && value !== '')) {
        // re-doing the negative sign
        value = parseInt(value);
        value = value.toLocaleString(undefined, { maximumFractionDigits: 2 })
        if (value.substring(0, 1) === "-") {
            value = `-$${value.substring(1, value.length)}`;
        } else {
            value = `$${value}`;
        }
    }

    
    return (
        <>
            {(value === null || value === '') ? (
                <p className='hidden'></p>
            ) : (
                <p className={`text-base text-left mt-1.5`}>
                    {label}:
                    <span className='font-semibold ml-1'>{(value)}</span>
                    <span className={`${(suffix) ? 'ml-1' : 'hidden'}`}>
                        {(suffix === "square feet") ? 'sq ft' : suffix}
                    </span>
                </p>
            )}
        </>
    )
}
