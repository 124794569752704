import React from 'react'
import allFunctions from '../containers/Functions';
import RentalCompRow from './RentalCompRow';

export default function RentalComp(props) {
    let { property, last, defaults, propertyEdits } = props;

    let propertyEdit = null;
    let pricePerSqFt = parseInt(property.price / property.livingArea)
    let rentEstimate = (property.rentEstimate !== null) ? (
        `$${property.rentEstimate.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
    ) : (
        '-'
    )
    if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
        if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
            propertyEdit = propertyEdits.properties[`${property.zpid}`];
            if (propertyEdit.hasOwnProperty('price')) {
                pricePerSqFt = parseInt(propertyEdit.price / property.livingArea)
            }
            if (propertyEdit.hasOwnProperty('price')) {
                pricePerSqFt = parseInt(propertyEdit.price / property.livingArea)
            }
            if (propertyEdit.hasOwnProperty('rentEstimate')) {
                rentEstimate = `$${propertyEdit.rentEstimate.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
            }
        }
    }
    property = allFunctions.AddReturnsToProperties([property], defaults, propertyEdits)[0];

    // re-doing the negative sign
    let cashFlow = property.cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (cashFlow.substring(0, 1) === "-") {
        cashFlow = `-$${cashFlow.substring(1, cashFlow.length)}`;
    } else {
        cashFlow = `$${cashFlow}`;
    }
    
    // type
    let propertyType = property.propertyTypeDimension;
    if (property.propertyTypeDimension === "SINGLE_FAMILY") {
        propertyType = "Single Family"
    } else if (property.propertyTypeDimension === "CONDO") {
        propertyType = "Condo"
    } else if (property.propertyTypeDimension === "TOWNHOUSE") {
        propertyType = "Townhouse"
    } else if (property.propertyTypeDimension === "MULTI_FAMILY") {
        propertyType = "Multi-Family"
    } else if (property.propertyTypeDimension === "LOT") {
        propertyType = "Lot"
    } else if (property.propertyTypeDimension === "MANUFACTURED") {
        propertyType = "Manufactured"
    } else if (property.propertyTypeDimension === "MANUFACTURED") {
        propertyType = "Manufactured"
    }

    // status
    let homeStatus = property.homeStatus;
    if (homeStatus === "FOR_SALE") {
        homeStatus = "For Sale";
    } else if (homeStatus === "SOLD") {
        homeStatus = "Sold";
    } else if (homeStatus === "OTHER") {
        homeStatus = "Other";
    } else if (homeStatus === "RECENTLY_SOLD") {
        homeStatus = "Recently Sold";
    } else if (homeStatus === "PENDING") {
        homeStatus = "Pending";
    } else if (homeStatus === "FOR_RENT") {
        homeStatus = "For Rent";
    }

    // date diff from today
    const today = new Date();
    const datePosted = new Date(property.datePostedString)
    let difference = today.getTime() - datePosted.getTime();
    let daysDiff = Math.ceil(difference / (1000 * 3600 * 24))

    const convertDate = (date) => {
        let newDate = '';
        if (date !== undefined && date !== null && date !== '') {
            newDate = new Date(`${date}`).toString().split(" ");
            newDate = `${newDate[1]} ${newDate[2]}, ${newDate[3]}`;
        }
        return newDate
    }

    // dates
    let datePostedString = convertDate(property.hasOwnProperty('datePostedString') ? property.datePostedString : '');
    
    return (
        <div
            className='text-center'
        >
            <div className='flex justify-center'>
                <img src={property.imgSrc} alt="property" className="rounded-md" style={{height: '120px'}} />
            </div>
            <div className='flex justify-center mb-2'>
                <p className='pt-2 px-1 bg-light-gray w-44 text-xs rounded-md h-14'>{(property.address !== null) ? property.address : '-'}</p>
            </div>
            <p className={(last) ? 'py-2.5 px-2 text-white font-bold bg-light-bot-blue rounded-r-md' : 'py-2.5 px-2 text-white font-bold bg-light-bot-blue'}>
                {rentEstimate}
            </p>
            <p className={(last) ? 'py-2.5 px-2 rounded-r-md' : 'py-2.5 px-2'}>{'-'}</p>
            <RentalCompRow last={last} gray={true} value={property.price} currency={true} />
            <RentalCompRow last={last} gray={false} value={property.bedrooms} />
            <RentalCompRow last={last} gray={true} value={property.bathrooms} />
            <RentalCompRow last={last} gray={false} value={property.livingArea} toLocaleString={true} />
            <RentalCompRow last={last} gray={true} value={pricePerSqFt} currency={true} />
            <RentalCompRow last={last} gray={false} value={propertyType} />
            <RentalCompRow last={last} gray={true} value={property.yearBuilt} />
            <RentalCompRow last={last} gray={false} value={homeStatus} />
            <RentalCompRow last={last} gray={true} value={datePostedString} />
            <RentalCompRow last={last} gray={false} value={daysDiff} />
        </div>
    )
}
