import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import { supabase } from '../supabase';

export default function ContactForm(props) {
    const { property, button, interest, agentName } = props;
    const [agentEmail, setAgentEmail] = useState('info@investorbot.us');

    useEffect(() => {
        const getAgentEmail = async () => {
            let currentURL = window.location.href;
            let baseURL = currentURL.split("/search");
            let newBaseURL = baseURL[0].slice(8);
            try {
                let { data, error } = await supabase
                    .from('team')
                    .select(`admin_email`)
                    .like('domain', `%${newBaseURL}%`)
                    
                if (error) throw error
        
                if (data && data.length > 0) {
                  setAgentEmail(data[0].admin_email);
                }
        
            } catch (error) {
                console.log(error.message);
            }
        }
        
        getAgentEmail();
    }, [])

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    const notifySuccessMsg = (errorMsg) => toast.success(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_xsn87gk', 'template_contact_form', e.target, '-zkNxR_jnyyJKePEU')
            .then((result) => {
                notifySuccessMsg("Success! Message has been sent.");
            }, (error) => {
                console.log(error.text);
                notifyErrorMsg("Sign up failed. Please try again later.");
            })
    }

    let disclosure = "";
    if (button === "Agent") {
        disclosure =
        `
            By proceeding, you consent to receive calls, texts and voicemails
            at the number you provided, and email, from investorbot.us and their
            network of service providers about your inquiry and other home-related
            matters. Msg/data rates may apply.
        `
    } else if (button === "Lender") {
        disclosure = 
        `
            By proceeding, you consent to receive calls, texts and voicemails
            at the number you provided, and email, from investorbot.us, JMJ
            Mortgage, and their network of service providers about your inquiry
            and other home-related matters. Msg/data rates may apply.
        `
    } else {
        disclosure = 
        `
            By proceeding, you consent to receive calls, texts and voicemails
            at the number you provided, and email, from investorbot.us and their
            network of service providers about your inquiry and other home-related
            matters. Msg/data rates may apply.
        `
    }

    return (
        <form className='px-4' onSubmit={sendEmail}>
            <div className='lg:flex lg:justify-center lg:items-center text-sm text-white bg-white -mt-4 cursor-auto'>
                <input
                    id='agentEmail'
                    name='agentEmail'
                    className='w-1'
                    value={agentEmail}
                />
            </div>
            <div className='flex justify-center gap-2 mb-8 -mt-2'>
                <p className='text-center text-2xl font-bold mt-1'>
                    {interest.includes('touring') ? (
                        `Tour with ${agentName === "agent" ? `an ${agentName}` : `${agentName}`}`
                    ) : (
                        `Message ${agentName === "agent" ? `${agentName}` : `${agentName === undefined ? 'agent' : agentName}`}`
                    )}
                </p>
                <p className='text-2xl'>📨</p>
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='name'
                    name='name'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Name'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='email'
                    name='email'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Email'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='phone'
                    name='phone'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Phone'
                />
            </div>
            <div className='flex justify-center my-3'>
                <textarea
                    id='message' name='message'
                    defaultValue={`Hi, I'm ${interest} ${(property.address !== undefined) ? property.address : property}`}
                    className='h-24 w-96 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                />
            </div>
            <div className='flex justify-center mt-4 mb-3'>
                <Button type="submit" block size="lg" className='bg-bot-blue text-white text-xl w-96 py-2.5 font-semibold small-btn'>Send message</Button>
            </div>
            <div className='flex justify-center mb-3'>
                <p className='text-sm text-gray w-96'>{disclosure}</p>
            </div>
        </form>
    )
}
