import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { supabase } from '../supabase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditClient(props) {
    const { activeClient, setActiveClient, clients, setClients, setOpenEditClientModal} = props;
    const [name, setName] = useState(activeClient.name);
    const [email, setEmail] = useState(activeClient.email);
    const [phone, setPhone] = useState(activeClient.phone);
    const [location, setLocation] = useState(activeClient.location);

    const notifySuccessMsg = (errorMsg) => toast.success(<p>{errorMsg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const handleEditProfile = async () => {
        // update active client
        activeClient.name = name;
        activeClient.email = email;
        activeClient.phone = phone;
        activeClient.location = location;
        // remove client then add them back
        clients.forEach((client, index) => {
            if (client.id === activeClient.id) {
                clients[index].name = name;
                clients[index].email = email;
                clients[index].phone = phone;
                clients[index].location = location;
            }
        })
        setClients(clients);
        setActiveClient(activeClient);

        // initialize client in supabase
        try {
            const updates = {
                id: activeClient.id,
                name: name,
                email: email,
                phone: phone,
                location: location,
            }
            
            let { error } = await supabase.from('client').upsert(updates)

            if (error) {
                throw error
            } else {
                setOpenEditClientModal(false);
                notifySuccessMsg('Successfully updated profile.')
            }
        } catch (error) {
            console.error(error.message)
        }
    }
    
    return (
        <div>
            <div className='flex justify-center'>
                <p className='text-center text-3xl font-black tracking-tight mt-2 mb-3'>{name}'s Profile</p>
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="John Doe"
                    style={{width: '100%'}}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="john.doe@gmail.com"
                    style={{width: '100%'}}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    placeholder="123-456-7890"
                    style={{width: '100%'}}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="location"
                    name="location"
                    label="Location"
                    placeholder="Memphis, TN"
                    style={{width: '100%'}}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />
            </div>
            <div className='flex justify-between gap-2 text-xl mt-5 mb-3'>
                <button
                    onClick={() => setOpenEditClientModal(false)}
                    className='px-4 py-2.5 font-semibold rounded-xl text-gray hover:bg-light-gray cursor-pointer'
                >
                    Cancel
                </button>
                <button
                    onClick={() => handleEditProfile()}
                    className='px-4 py-2.5 font-semibold rounded-xl text-white bg-bot-blue hover:bg-dark-bot-blue cursor-pointer'
                >
                    Save
                </button>
            </div>
        </div>
    )
}
