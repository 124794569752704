import React from 'react';

function CircleImage({ imageUrl, width, height }) {
    const imageStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    };

    const containerStyle = {
        width: `${width}px`,
        height: `${height}px`,
        overflow: 'hidden',
        borderRadius: '50%',
    };

    return (
        <div style={containerStyle}>
            <div style={imageStyle}></div>
        </div>
    );
}

export default CircleImage;
