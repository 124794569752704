import React from 'react'
import allFunctions from '../containers/Functions';
import { setGlobalState } from '../state';

export default function MapIcon(props) {
    const {property, selectedProperty, setSelectedProperty, defaults, propertyEdits} = props;
    
    let metric = 0;
    let propertyEdit = null;
    let newBackgoundColor = '';
    
    if (defaults.mapZoomedOut) {
        if (defaults.showBy === "Cash Flow") {
            // check for property edit
            if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
                if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                    propertyEdit = propertyEdits.properties[`${property.zpid}`];
                }
            }
            let cashFlow = allFunctions.CalcReturns(property, defaults, propertyEdit).cashFlow;
            newBackgoundColor = (cashFlow > 0) ? "green" : "#D2042D"
        } else {
            newBackgoundColor = "#2F7ADA"
        }
        metric = '\u00A0';
    } else {
        // check for property edit
        if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
            if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                propertyEdit = propertyEdits.properties[`${property.zpid}`];
            }
        }
        if (defaults.showBy === "Cash Flow") {
            let cashFlow = allFunctions.CalcReturns(property, defaults, propertyEdit).cashFlow;
            metric = cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
            if (metric.substring(0, 1) === "-") {
                metric = `-$${metric.substring(1, metric.length)}`;
            } else {
                metric = `$${metric}`;
            }
            newBackgoundColor = (property.cashFlow > 0) ? "green" : "#D2042D"
        } else if (defaults.showBy === "Cap Rate") {
            let capRate = allFunctions.CalcReturns(property, defaults, propertyEdit).capRate
            metric = `${capRate}%`;
            newBackgoundColor = "#2F7ADA";
        } else if (defaults.showBy === "Total Return") {
            if (defaults.yearsProjected < 1 || defaults.yearsProjected > 29) {
                metric = `N/A`;
            } else {
                let totalReturn = allFunctions.CalcReturns(property, defaults, propertyEdit).totalReturn
                metric = `${totalReturn}%`;
            }
            newBackgoundColor = "#2F7ADA";
        }
    }

    const handleClick = () => {
        setGlobalState('property', property);
        setSelectedProperty(property);
    }

    let showBorder = false;
    if (selectedProperty && selectedProperty.zpid === property.zpid) {
        showBorder = true;
    }

    return (
        <div
            className={`flex justify-center px-1.5 cursor-pointer ${(defaults.mapZoomedOut) ? 'rounded-full h-5 border-2' : 'rounded-md'} ${showBorder ? 'border-2 border-black' : 'border-white'}`}
            style={{backgroundColor: newBackgoundColor}}
            onClick={handleClick}
        >
            <h1 className="font-bold text-white text-sm">{metric}</h1>
        </div>
    )
}
