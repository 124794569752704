import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps(props) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  autocompleteService.current = new window.google.maps.places.AutocompleteService();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDpXppfyrCJhg8A07o21JchP3zOhY_PF4o&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const handleSearchButton = () => {
    // set URL
    let currentURL = window.location.href;
    if (currentURL.charAt(currentURL.length - 1) === "/") {
      currentURL = currentURL.slice(0, currentURL.length - 1)
    }
    let newURL = `${currentURL}/search`;
    window.history.pushState('page2', 'Title', newURL);
    setTimeout(function() { window.location.reload(true) }, 100);
  }

  const handleSelect = (value) => {
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // set URL
        let currentURL = window.location.href;
        if (currentURL.charAt(currentURL.length - 1) === "/") {
          currentURL = currentURL.slice(0, currentURL.length - 1)
        }
        let newURL = `${currentURL}/search?lat=${latLng.lat}&lng=${latLng.lng}`;
        window.history.pushState('page2', 'Title', newURL);
        setTimeout(function() { window.location.reload(true) }, 100);
      })
      .catch(error => console.error('Error', error));
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  
  // set isMobile bool
  const [width, setWidth] = React.useState(window.innerWidth); 
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;
  

  return (
    <div className="flex justify-center">
      <Autocomplete
        id="google-maps"
        size="small"
        className="bg-white shadow"
        sx={{
          width: isMobile ? 300 : 490
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleSelect(newValue.description);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="large"
            label="Enter an address, city, or ZIP code"
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <button
        onClick={() => handleSearchButton()}
        className="bg-bot-blue px-2 rounded-r-md text-xl text-white px-3 -ml-1 shadow"
        style={{zIndex: 0}}
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
  );
}
