import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loading_spinner from '../assets/loading_spinner.gif';

export default function SendBulkEmail(props) {
    const { session, clients, agentName, setOpenBulkEmailsModal } = props;
    const [name, setName] = useState(agentName);
    const [emails, setEmails] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const SERVER_URL = process.env.REACT_APP_SERVER_URL || ''
    
    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-semibold'>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    const addClients = () => {
        let allClients = emails.length > 0 ? emails : '';
        allClients = allClients.trim();
        if (allClients.charAt(allClients.length - 1) === ',') {
            allClients = allClients.substring(0, allClients.length - 1);
        }
        clients.forEach(client => {
            allClients += (allClients.length > 1 ? `, ${client.email}` : client.email);
        })
        setEmails(allClients);
    }

    const handleEmails = (value) => {
        setEmails(value);
    }
    
    function sendEmail(e) {
        setLoading(true);
        e.preventDefault();

        const allEmails = emails.split(/,|\s/).filter(item => item.length > 2);
        const newMessage = message.replace(/\n/g, "<br>");

        // deduplicate a list
        let emailList = [...new Set(allEmails)];
        
        // send email
        fetch(`${SERVER_URL}/send-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              recipients: emailList,
              subject: subject,
              message: newMessage,
              agentName: name,
              agentEmail: session.user.email
            })
          })
            .then(response => {
              if (response.ok) {
                notifySuccessMsg('Successfully sent email.');
                setLoading(false);
                setOpenBulkEmailsModal(false);
              }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                notifyErrorMsg(error.message);
                setLoading(false);
            })
    }

    return (
        <form className='px-3' onSubmit={sendEmail}>
            <div className='flex justify-center gap-2 mb-7'>
                <p className='text-center text-2xl font-extrabold tracking-tight mt-1'>
                    Send a bulk email
                </p>
                <p className='text-2xl'>📨📨</p>
            </div>
            <div className='flex justify-center my-3'>
                <div>
                    <p className='ml-2 mb-1'>Name</p>
                    <input
                        id='name'
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='h-11 lg:w-[430px] w-80 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                        placeholder='Your name'
                    />
                </div>
            </div>
            <div className='flex justify-center my-3'>
                <div>
                    <div className='flex justify-between items-center'>
                        <p className='ml-2 mb-1'>Recipients (comma separated)</p>
                        <div
                            onClick={() => addClients()}
                            className='flex justify-center items-center gap-1 p-1 px-2 rounded-xl text-bot-blue cursor-pointer hover:bg-light-bot-blue-0'
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            <p>Clients</p>
                        </div>
                    </div>
                    <textarea
                        id='emails'
                        name='emails'
                        value={emails}
                        onChange={(e) => handleEmails(e.target.value)}
                        className='h-24 lg:w-[430px] w-80 pl-3 pr-2 py-2 border-light-gray-3 text-dark-gray border-2 rounded-md'
                        placeholder='client@gmail.com, client2@gmail.com'
                    />
                </div>
            </div>
            <div className='flex justify-center my-3'>
                <div>
                    <p className='ml-2 mb-1'>Subject</p>
                    <input
                        id='subject'
                        name='subject'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className='h-11 lg:w-[430px] w-80 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                        placeholder='Check out these deals!'
                    />
                </div>
            </div>
            <div className='flex justify-center my-3'>
                <div>
                    <p className='ml-2 mb-1'>Message</p>
                    <textarea
                        id='message'
                        name='message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Message"
                        className='h-48 lg:w-[430px] w-80 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex justify-center mt-4 mb-3'>
                <Button
                    disabled={emails.length === 0}
                    type="submit"
                    block size="lg"
                    className={`bg-bot-blue text-white rounded-lg text-xl lg:w-[430px] w-80 py-2.5 font-semibold small-btn`}
                >
                    {loading ? (
                        <div className='flex justify-center items-center'>
                            <img src={loading_spinner} className='h-7 text-center text-white' alt='Loading...' />
                        </div>
                    ) : (
                        <p>Send message</p>
                    )}
                </Button>
            </div>
        </form>
    )
}
