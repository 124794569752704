import React from 'react';
import { Button, Card } from 'react-bootstrap';
import no_homes from '../assets/no_homes.png';

export default function SuggestNewMarket(props) {
    const { disableSuggestNewMarket, setOpenContactTeamModal } = props;

    return (
        <div className='flex justify-center mt-5'>
            <Card
                className="lg:flex w-property-tile m-1 pb-2 drop-shadow-md shadow-slate-200 rounded-3xl"
            >
                <div className='h-48 w-auto' style={{
                        backgroundImage: `url(${no_homes})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        opacity: '0.2'
                    }}
                />
                <div className='my-3 mx-2'>
                    <div>
                        <p className='flex justify-center text-center text-3xl font-black tracking-tighter'>Want this market?</p>
                        <p className='flex justify-center text-center text-base font-semibold mt-3'>
                            <span className='text-dark-gray'>Contact us to get started 🚀</span>
                        </p>
                        <div className='flex justify-center mx-4'>
                            {disableSuggestNewMarket ? (
                                <p className='flex justify-center text-center text-2xl text-gray font-bold tracking-tighter mt-8 mb-2'>Zoom in to request!</p>
                            ) : (
                                <Button
                                    type="submit"
                                    block size="lg"
                                    onClick={() => setOpenContactTeamModal(true)}
                                    className='mt-4 bg-bot-blue text-white rounded-xl font-semibold tracking-wide'
                                >
                                    Contact us
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
