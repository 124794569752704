import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { supabase } from "../supabase";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps(props) {
  const { session, setDefaults, memberStatus,
    propertyCreditList, propertyEdits, setShow,
    setSelectedProperty, setPropertyCreditList,
    setPropertyEdit, setSearching
  } = props;
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("Test");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  autocompleteService.current = new window.google.maps.places.AutocompleteService();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDpXppfyrCJhg8A07o21JchP3zOhY_PF4o&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const hasThreeOrMoreCommas = (str) => {
    const arr = str.split(',');
    return arr.length > 3;
  }

  const checkForAddress = async (address) => {
    // delete "USA" from end
    if (address.substring(address.length - 3, address.length) === "USA") {
      address = address.substring(0, address.length - 5)
    }
    // search database to set property
    try {
      let { data, error, status } = await supabase
        .from('propertyData')
        .select(`
          zpid, address, bedrooms, bathrooms, price, imgSrc,
          latitude, longitude, rentEstimate, rentEstimateSource,
          monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
          livingArea, listingAgent, brokerageName, homeStatus, yearBuilt,
          daysOnZillow
        `)
        .eq('address', address)
        .single()

      if (error && status !== 406) {
        throw error
      }
      
      if (data !== null && status !== 406) {
        let propertyEdit = null;
        if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
          if (propertyEdits.properties.hasOwnProperty(`${data.zpid}`)) {
            propertyEdit = propertyEdits.properties[`${data.zpid}`];
          }
        }
        
        // set URL
        let newURL = null
        if (window.location.href.includes("localhost:3000")) {
          newURL = `http://localhost:3000/search?id=${data.zpid}`;
        } else {
          newURL = `https://investorbot.us/search?id=${data.zpid}`;
        }
        window.history.pushState('page2', 'Title', newURL);

        // increment credit counter
        if (session && (memberStatus === 'free') && (propertyCreditList.includes(data.zpid) === false)) {
          // save property in supabase
          propertyCreditList.push(data.zpid);
          try {
            const updates = {
              id: session.user.id,
              property_credits: propertyCreditList
            }

            let { error } = await supabase
              .from('profiles')
              .upsert(updates)

            if (error) throw error
          } catch (error) {
            console.error(error.message)
          }
          setPropertyCreditList(propertyCreditList);
        }
        
        setSelectedProperty(data);
        setPropertyEdit(propertyEdit);
        setShow(true);
      }
    } catch (error) {
      console.error('Error getting address.')
    }
  }

  const handleSelect = async (address) => {
    if (isMobile) {
      setSearching(false);
    }
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const isAddress = hasThreeOrMoreCommas(address);
        setDefaults((prevState) => { return { ...prevState, mapZoom: isAddress ? 19 : 12 } })
        setDefaults((prevState) => { return { ...prevState, mapCenter: latLng } })
        if (isAddress) {
          checkForAddress(address);
        } else {
          setSelectedProperty(null);
        }
      })
      .catch(error => console.error('Error', error));
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  
  // set isMobile bool
  const [width, setWidth] = React.useState(window.innerWidth); 
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;


  return (
    <Autocomplete
      id="google-maps"
      size="small"
      className="-mt-1.5 mx-2"
      sx={{ width: isMobile ? '100%' : 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleSelect(newValue.description);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={() => setSearching(true)}
          label={isMobile ? 'City, address, ZIP' : "Enter a city, address, or ZIP code"}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
