import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import magnifying_glass from '../assets/magnifying_glass.jpg'
import magnifying_glass_arrow from '../assets/magnifying_glass_arrow.jpg'
import { setGlobalState } from '../state';

export default function SetDefaults() {

    function Mobile() {
        const [width, setWidth] = useState(window.innerWidth);
    
        function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
        
        const isMobile = width <= 768;
    
        return isMobile
    }

    const handleDefaultModal = () => {
        setGlobalState('openDefaultReminderModal', false);
        setGlobalState('openDefaultModal', true);
    }

    const isMobile = Mobile();
    let magnifying_glass_img = isMobile ? magnifying_glass : magnifying_glass_arrow;

    return (
        <div>
            <p className='flex justify-center text-md my-3'>Want to customize appreciation, expenses, and rent?</p>
            <h1 className='flex justify-center text-4xl'>Set your defaults now!</h1>
            <div className='flex justify-center mb-5'>
                <img className='w-10/12 mx-5 mt-5' src={magnifying_glass_img} alt="Magnifying glass"/>
            </div>
            <div className='flex justify-center mb-5'>
                <Button className='w-80 mt-4 bg-bot-blue text-white rounded-lg' block size="lg" onClick={handleDefaultModal}>Set Defaults</Button>
            </div>
            <p className='flex justify-center text-md my-3 italic'>Setting your defaults will change the assumptions for every property on the map</p>
        </div>
    )
}
