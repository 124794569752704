import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendUp, faBook, faCalculator, faChess, faCircleCheck,
  faCircleDollarToSlot, faClock, faDollarSign, faExclamationCircle, faExclamationTriangle,
  faFileContract, faFireFlameCurved, faFolderOpen, faForward, faGavel, faHammer,
  faHandHoldingDollar, faHandshake, faHome, faHomeLg, faHomeLgAlt, faHomeUser, faHouseUser,
  faMagnifyingGlass, faMagnifyingGlassDollar, faMoneyBill, faMoneyBill1Wave, faMoneyBills,
  faPen, faPeopleGroup, faPeopleRoof, faPerson, faPersonCircleExclamation, faRedo,
  faSackDollar, faSackXmark, faSearch, faUmbrella, faUserCheck, faLightbulb, faArrowUp }
  from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import man_searching from '../assets/man_searching.png';
import logo from '../assets/logo.png';
import bg_learn from '../assets/Learn/bg_learn.png';
import ArticleFilter from '../components/ArticleFilter';


export default function LearnPage() {

  const allArticles = [
    // WHY REAL ESTATE
    {
      title: 'Top 5 Tips for New Real Estate Investors',
      category: 'Why Real Estate',
      description: "Real estate investing can be a great way to build wealth and generate passive income. However, it can also be challenging...",
      icon: faLightbulb,
      link: '/learn/top-five-tips-for-new-real-estate-investors'
    },
    {
      title: 'What are the Top 4 Rookie Mistakes?',
      category: 'Why Real Estate',
      description: 'Real estate investing can be a highly profitable venture, but it is not without its challenges. As a new investor...',
      icon: faExclamationCircle,
      link: '/learn/what-are-the-top-4-rookie-mistakes'
    },
    {
      title: 'What is Passive Income?',
      category: 'Why Real Estate',
      description: 'Passive income is a type of income that is generated without the need for active involvement or effort on the part of the individual...',
      icon: faHome,
      link: '/learn/what-is-passive-income'
    },
    {
      title: 'Top 4 Tax Benefits of Real Estate Investing',
      category: 'Why Real Estate',
      description: 'Real estate investing can provide a number of tax benefits that can help you save money and increase your overall return on investment...',
      icon: faSackDollar,
      link: '/learn/top-4-tax-benefits-of-real-estate-investing'
    },
    {
      title: 'Who are the Key Players in Real Estate Investing?',
      category: 'Why Real Estate',
      description: 'Real estate investing involves a number of different players, each with their own unique role and responsibilities...',
      icon: faPerson,
      link: '/learn/who-are-the-key-players-in-real-estate-investing'
    },
    {
      title: 'Earning Potential of Real Estate Investing',
      category: 'Why Real Estate',
      description: 'The earning potential of real estate investing can vary greatly, depending on a number of factors such as the type of property...',
      icon: faSackDollar,
      link: '/learn/earning-potential-of-real-estate-investing'
    },
    {
      title: 'How to Leverage Debt in Real Estate Investing?',
      category: 'Why Real Estate',
      description: 'Debt can be a powerful tool in real estate investing, as it allows investors to leverage their capital and potentially...',
      icon: faSackDollar,
      link: '/learn/how-to-leverage-debt-in-real-estate-investing'
    },
    {
      title: 'The Different Types of Real Estate Investing',
      category: 'Why Real Estate',
      description: 'Real estate investing can take many different forms, and there are a number of different investment types to choose from...',
      icon: faHomeLg,
      link: '/learn/the-different-types-of-real-estate-investing'
    },
    {
      title: 'What are the Risks of Real Estate Investing?',
      category: 'Why Real Estate',
      description: 'Real estate investing can be a life-changing venture, but it is important to understand that it also carries a certain amount of risk...',
      icon: faExclamationTriangle,
      link: '/learn/what-are-the-risks-of-real-estate-investing'
    },
    {
      title: 'Top 5 Traits of a Good Real Estate Investor',
      category: 'Why Real Estate',
      description: 'It takes more than just a desire to earn money to be successful. Here are some of the top traits that make a good...',
      icon: faPersonCircleExclamation,
      link: '/learn/top-5-traits-of-a-good-real-estate-investor'
    },
    {
      title: "What's the Hype Around Real Estate Investing?",
      category: 'Why Real Estate',
      description: 'Real estate investing has gained a lot of popularity in recent years, and there are a number of reasons why it has...',
      icon: faHomeLgAlt,
      link: '/learn/whats-the-hype-around-real-estate-investing'
    },

    // START SEARCHING
    {
      title: 'How do you Calculate Cash Flow?',
      category: 'Start Searching',
      description: "Calculating cash flow is an essential part of real estate investing, as it allows you to determine the profitability...",
      icon: faCalculator,
      link: '/learn/how-to-calculate-cash-flow'
    },
    {
      title: 'How to Analyze a Good Deal',
      category: 'Start Searching',
      description: "Real estate investing can be a great way to build wealth and generate passive income. However, it's important to carefully analyze...",
      icon: faArrowTrendUp,
      link: '/learn/how-to-analyze-a-good-deal'
    },
    {
      title: 'Real Estate Investing Terms Clarified',
      category: 'Start Searching',
      description: "When it comes to real estate investing, there are many terms and concepts that can be confusing for new investors...",
      icon: faPen,
      link: '/learn/real-estate-investing-terms-clarified'
    },
    {
      title: 'What Resources are Available for Investors?',
      category: 'Start Searching',
      description: "When it comes to real estate investing, there are many resources available to help investors make informed decisions...",
      icon: faHome,
      link: '/learn/what-resources-are-available-for-investors'
    },
    {
      title: 'Can I Buy a Home with No Money Down?',
      category: 'Start Searching',
      description: "Buying a home with no money down can be possible, but it's not always easy. In this article, we'll discuss some of the options...",
      icon: faSackXmark,
      link: '/learn/can-I-buy-a-home-with-no-money-down'
    },
    {
      title: 'How Big Should My Down Payment Be?',
      category: 'Start Searching',
      description: "The size of the down payment required for a real estate investment can vary depending on a number of factors...",
      icon: faMoneyBill1Wave,
      link: '/learn/how-big-should-my-down-payment-be'
    },
    {
      title: 'Everything to Know About Co-Borrowing',
      category: 'Start Searching',
      description: "Co-borrowing, also known as co-signing, is a common practice in the mortgage industry. In this article, we'll discuss everything...",
      icon: faPeopleGroup,
      link: '/learn/everything-to-know-about-co-borrowing'
    },
    {
      title: "Co-Borrowing on a Multi-Unit Property",
      category: 'Start Searching',
      description: "If you are a co-borrower for a multi-unit property, you may be wondering what you need to put down as a down payment. In this article...",
      icon: faMoneyBills,
      link: '/learn/co-borrowing-on-a-multi-unit-property'
    },
    {
      title: "How to Get Started with House-Hacking",
      category: 'Start Searching',
      description: "House-hacking is a popular strategy among real estate investors, as it allows them to live in their investment property and...",
      icon: faHomeUser,
      link: '/learn/how-to-get-started-with-house-hacking'
    },
    {
      title: "What Factors Make a Good Airbnb?",
      category: 'Start Searching',
      description: "Airbnb is a popular platform for renting out properties on a short-term basis. If you're considering listing your property...",
      icon: faHome,
      link: '/learn/what-factors-make-a-good-airbnb'
    },
    {
      title: "What Factors Affect a Hot Real Estate Market?",
      category: 'Start Searching',
      description: "A hot real estate market is one in which there is high demand for properties and rapid appreciation in property values...",
      icon: faFireFlameCurved,
      link: '/learn/what-factors-affect-a-hot-real-estate-market'
    },
    {
      title: "Where to Find Real Estate Deals",
      category: 'Start Searching',
      description: "Finding good real estate deals can be challenging, but there are many resources and strategies that can help investors...",
      icon: faMagnifyingGlassDollar,
      link: '/learn/where-to-find-real-estate-deals'
    },
    {
      title: "Does the Neighborhood Matter?",
      category: 'Start Searching',
      description: "When it comes to real estate investing, the neighborhood in which a property is located can be an important factor...",
      icon: faHouseUser,
      link: '/learn/does-the-neighborhood-matter'
    },

    // READY TO BUY
    {
      title: "What's the BRRRR Strategy?",
      category: 'Ready to Buy',
      description: 'The BRRR (buy, rehab, rent, refinance, and repeat) strategy is a popular approach among real estate investors...',
      icon: faRedo,
      link: '/learn/whats-the-brrrr-strategy'
    },
    {
      title: "What to Know About Medium-term Rentals",
      category: 'Ready to Buy',
      description: 'Medium-term rentals, also known as extended-stay rentals, are a type of rental property that is typically occupied...',
      icon: faHome,
      link: '/learn/what-to-know-about-medium-term-rentals'
    },
    {
      title: "What to Know About Long-term Rentals",
      category: 'Ready to Buy',
      description: 'Long-term rentals are a type of rental property that is typically occupied by a tenant for a period of one year or more...',
      icon: faHome,
      link: '/learn/what-to-know-about-long-term-rentals'
    },
    {
      title: "What to Know About Home Inspections",
      category: 'Ready to Buy',
      description: 'A home inspection is an important step in the process of purchasing a property. This thorough examination...',
      icon: faMagnifyingGlass,
      link: '/learn/what-to-know-about-home-inspections'
    },
    {
      title: "Tactics for Real Estate Negotiations",
      category: 'Ready to Buy',
      description: 'Real estate negotiations are an important part of the home buying process. This is the process of agreeing...',
      icon: faHandshake,
      link: '/learn/tactics-for-real-estate-negotiations'
    },
    {
      title: "How to Get Preapproved for a Mortgage",
      category: 'Ready to Buy',
      description: 'Getting preapproved for a mortgage is an important step in the process of buying an investment property...',
      icon: faCircleCheck,
      link: '/learn/how-to-get-preapproved-for-a-mortgage'
    },
    {
      title: "Financing Options for Real Estate Investors",
      category: 'Ready to Buy',
      description: 'When it comes to real estate investing, there are many different financing options available to investors...',
      icon: faDollarSign,
      link: '/learn/financing-options-for-real-estate-investors'
    },
    {
      title: "How to Raise Money for Real Estate Investing",
      category: 'Ready to Buy',
      description: 'Raising money for real estate investing can be a challenge, but there are many different strategies and approaches...',
      icon: faSackDollar,
      link: '/learn/how-to-raise-money-for-real-estate-investing'
    },
    {
      title: "Successful Real Estate Investing Strategies",
      category: 'Ready to Buy',
      description: 'Successful real estate investing requires a combination of knowledge, experience, and a solid strategy...',
      icon: faChess,
      link: '/learn/successful-real-estate-investing-strategies'
    },
    {
      title: "What is ARV (After Repair Value)?",
      category: 'Ready to Buy',
      description: 'After repair value, or ARV, is a term used in real estate investing to refer to the estimated value of a property...',
      icon: faHome,
      link: '/learn/what-is-arv'
    },
    {
      title: "How Can I Finance my First Real Estate Deal?",
      category: 'Ready to Buy',
      description: 'Financing your first real estate deal can be a challenge, but there are many different options and strategies...',
      icon: faMoneyBill,
      link: '/learn/how-can-I-finance-my-first-real-estate-deal'
    },
    {
      title: "Everything to know about Zoning and Laws",
      category: 'Ready to Buy',
      description: 'Zoning and laws are an important aspect of real estate investing, as they can impact the value and potential uses...',
      icon: faGavel,
      link: '/learn/everything-to-know-about-zoning-and-laws'
    },
    {
      title: "What is private equity in real estate investing?",
      category: 'Ready to Buy',
      description: 'Private equity in real estate investing refers to the use of private investment capital to acquire, develop, and manage...',
      icon: faMoneyBills,
      link: '/learn/what-is-private-equity-in-real-estate-investing'
    },
    
    // AFTER THE BUY
    {
      title: "How to Save Money on Your Next Fix & Flip",
      category: 'After the Buy',
      description: 'Fix and flip real estate investing can be a profitable strategy, but it can also be expensive, particularly...',
      icon: faHammer,
      link: '/learn/how-to-save-money-on-your-next-fix-and-flip'
    },
    {
      title: "How do I Price Rent?",
      category: 'After the Buy',
      description: 'Pricing rents for an investment property can be a challenge, but it is an important aspect of real estate investing...',
      icon: faHandHoldingDollar,
      link: '/learn/how-do-I-price-rent'
    },
    {
      title: "Terms to Know After Buying a Property",
      category: 'After the Buy',
      description: 'If you have recently bought an investment property, there are a number of real estate terms that you...',
      icon: faBook,
      link: '/learn/terms-to-know-after-buying-a-property'
    },
    {
      title: "What to Know About Lease Agreements",
      category: 'After the Buy',
      description: "As a landlord, it's important to have a thorough understanding of lease agreements. A lease agreement is...",
      icon: faFileContract,
      link: '/learn/what-to-know-about-lease-agreements'
    },
    {
      title: "Documents to Have After Buying a Property",
      category: 'After the Buy',
      description: 'After you buy an investment property, there are several important documents that you will need to have...',
      icon: faFolderOpen,
      link: '/learn/documents-to-have-after-buying-a-property'
    },
    {
      title: "How Much Should I Charge For My Airbnb?",
      category: 'After the Buy',
      description: "If you are planning to rent out your property on Airbnb, it's important to determine the right price...",
      icon: faCircleDollarToSlot,
      link: '/learn/how-much-should-I-charge-for-my-airbnb'
    },
    {
      title: "5 Ways to Double your Airbnb Income",
      category: 'After the Buy',
      description: 'If you are a landlord who rents out a property on Airbnb, you may be looking for ways to increase your income...',
      icon: faForward,
      link: '/learn/five-ways-to-double-your-airbnb-income'
    },
    {
      title: "Do I need Home insurance?",
      category: 'After the Buy',
      description: 'If you have recently bought an investment property, you may be wondering whether you need home insurance...',
      icon: faUmbrella,
      link: '/learn/do-I-need-home-insurance'
    },
    {
      title: "Should I do Tenant Background Checks?",
      category: 'After the Buy',
      description: 'As a landlord, conducting tenant background checks is an important part of the rental process...',
      icon: faUserCheck,
      link: '/learn/should-I-do-tenant-background-checks'
    },
    {
      title: "What to Know About Property Management",
      category: 'After the Buy',
      description: 'Property management is a crucial aspect of owning and maintaining rental properties. In this article...',
      icon: faPeopleRoof,
      link: '/learn/what-to-know-about-property-management'
    },
    {
      title: "How to Find the Best Property Manager",
      category: 'After the Buy',
      description: "If you're a property owner, finding a reputable and reliable property management company can be a daunting task...",
      icon: faMagnifyingGlass,
      link: '/learn/how-to-find-the-best-property-manager'
    },
  ]
  
  const allFilters = [
    {
      title: 'All',
      icon: faSearch
    },
    {
      title: 'Why Real Estate',
      icon: faHome
    },
    {
      title: 'Start Searching',
      icon: faSearch
    },
    {
      title: 'Ready to Buy',
      icon: faDollarSign
    },
    {
      title: 'After the Buy',
      icon: faHandshake
    }
  ]

  const [articles, setArticles] = useState(allArticles);
  const [category, setCategory] = useState('All');

  const handleArticleFilter = (category) => {
    setCategory(category);
    let newArticles = []

    if (category === "All") {
      newArticles = allArticles;
    } else {
      allArticles.forEach(blog => {
        if (blog.category === category) {
          newArticles.push(blog)
        }
      })
    }
    
    setArticles(newArticles);
  }

  const notifyBetaSignup = () => toast.success(<p><span className='text-lg font-bold'>Success!</span><br/>You have been added to the Beta list.</p>, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

  const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
    toastId: 'signupError',
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
});
  
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_xsn87gk', 'template_investorbot', e.target, '-zkNxR_jnyyJKePEU')
      .then((result) => {
          notifyBetaSignup()
      }, (error) => {
          notifyErrorMsg(error.text);
      });
  }

  // determines if the screen is a mobile device
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div>

      <div
        className='lg:flex lg:justify-center gap-44 -mt-4'
        style={{
          backgroundImage: `url(${bg_learn})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className='flex justify-center'>
          <div className='lg:py-32 pt-28 mx-3' style={{width: '500px'}}>
            <div className='tracking-tight font-landingSans'>
              {isMobile ? (
                <p className='font-bold text-left tracking-normal text-white leading-tight text-5xl'>Learn the art of real estate investing</p>
              ) : (
                <p className='font-bold text-left tracking-normal text-white leading-tight text-5xl'>Learn the art of<br/>real estate investing</p>
              )}
            </div>
            <div className='flex flex-col justify-center gap-3 mt-12'>
              <p className='text-2xl text-white font-semibold text-left leading-10'>New at investing? From finding your first deal to closing your 10th, these articles were made for you.</p>
            </div>
          </div>
        </div>
        <div className='lg:py-28 pb-10 image-padding-small'>
          <img src={man_searching} className='lg:h-96 lg:rounded-xl lg:shadow-xl lg:shadow-gray hover:shadow-gray-2' alt='cashflowing homes' />
        </div>
      </div>

      {/* Filters */}
      {isMobile ? (
        <div className='overflow-x-scroll'>
          <div className='flex justify-start gap-4 pt-16 pb-1 pl-3 bg-light-gray' style={{width: '885px'}}>
            {allFilters.map(filter => {
              return (
                <ArticleFilter
                  title={filter.title}
                  icon={filter.icon}
                  category={category}
                  handleArticleFilter={handleArticleFilter}
                />
              )
            })}
          </div>
        </div>
      ) : (
        <div className='flex justify-center items-center gap-4 pt-24 pb-16 bg-light-gray'>
          {allFilters.map(filter => {
            return (
              <ArticleFilter
                title={filter.title}
                icon={filter.icon}
                category={category}
                handleArticleFilter={handleArticleFilter}
              />
            )
          })}
        </div>
      )}

      {/* Blogs */}
      <div className='pb-24 transition bg-light-gray'>
        <div className='lg:flex lg:justify-center lg:gap-4 mb-5'>
          <div className='flex justify-center flex-wrap' style={{width: isMobile ? '100%' : '1500px'}}>
            {articles.map(blog => {
              return (
                <Card className='flex justify-center flex-col mx-3 mt-4 py-4 px-8 shadow-md shadow-light-gray-3 rounded-xl hover:shadow-gray transition' style={{width: '450px'}}>
                  <div className='lg:flex lg:justify-start lg:items-center lg:gap-3'>
                    <FontAwesomeIcon
                      icon={blog.icon}
                      className={`text-center text-2xl bg-light-bot-blue-2 px-3 py-3 rounded-xl text-${blog.color}`}
                    />
                    <p className='text-left font-bold font-landingSans tracking-tight' style={{fontSize: '24px'}}>{blog.title}</p>
                  </div>
                  <p className='text-left mt-3 text-lg text-gray'>{blog.description}</p>
                  <p className='flex justify-start gap-2 text-left mt-3 ml-2 text-gray text-lg'>
                    <span className='mt-0.5'>
                      <FontAwesomeIcon
                        icon={faClock}
                        className='text-center text-lg'
                      />
                    </span>
                    <span>2 min read</span>
                  </p>
                  <Link
                    to={blog.link}
                    className='flex justify-center gap-3 mt-7 px-4 py-3 w-48 border-2 border-black text-lg font-semibold rounded-2xl cursor-pointer hover:font-extrabold hover:no-underline hover:text-black hover:bg-light-gray-2'
                  >
                    <span>Read more</span>
                    <FontAwesomeIcon icon={faArrowRight} className='mt-1.5' />
                  </Link>
                </Card>
              )
            })}
          </div>
        </div>
        <div className='lg:flex lg:justify-center lg:gap-4 mb-5'>
          <div className='flex justify-start flex-wrap' style={{width: isMobile ? '100%' : '1500px'}}>
            <p
              onClick={() => handleScrollToTop()}
              className={`flex justify-start gap-3 text-xl p-2.5 mt-5 rounded-xl cursor-pointer bg-light-gray hover:bg-light-gray-2 hover:text-black ${isMobile ? 'ml-3' : 'ml-5'}`}
            >
                <FontAwesomeIcon icon={faArrowUp} className='mt-1' />
                <span>Back to top</span>
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <hr className='border-gray' />
      <div className='text-center lg:mx-80'>
        <div className='lg:flex lg:justify-around lg:gap-32 pt-16 pb-12'>
          <div className='flex justify-center mb-5'>
            <img src={logo} alt="logo" className='h-10' />
          </div>
          <div className='flex justify-center text-gray mb-5'>
            <div>
              <p className='font-bold mb-3 w-32'>Quick Links</p>
              <Link to='/' className='hover:text-bot-blue hover:no-underline'>
                <p className='mb-3'>Home</p>
              </Link>
              <Link to='/search' className='hover:text-bot-blue hover:no-underline'>
                <p className='mb-3'>Map</p>
              </Link>
            </div>
          </div>
          <form onSubmit={sendEmail} className='mx-5 beta-top-m'>
            <p className='text-3xl font-black tracking-tighter text-gray text-left mb-3'>Join the beta</p>
            <div className='flex justify-center h-12 text-black'>
              <input
                id='email'
                name='from_email'
                placeholder='Your email'
                className='w-64 px-3 border-2 border-gray rounded-l-md hover:border-black'
              />
              <button
                type='submit'
                className='flex justify-center items-center w-32 text-white bg-bot-blue hover:bg-dark-bot-blue text-black rounded-r-md font-bold text-lg'
              >
                Join
              </button>
            </div>
          </form>
        </div>
        <div className='flex justify-center mx-5 pb-2 text-gray'>
          <p>© 2022 Investorbot, LLC. All rights reserved.</p>
        </div>
      </div>

    </div>
  )
}
