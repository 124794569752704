import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
// import google_g from '../assets/google_g.png';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import { supabase } from '../supabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// images
import loading_spinner from '../assets/loading_spinner.gif';
import info_popup from '../assets/info_popup.jpg';
import ReactTooltip from 'react-tooltip';

export default function SignupComponent(props) {
    const { setOpenLogin, loginOrSignup, setLoginOrSignup, handleCloseLogin,
        loginHeader, setLoginHeader, clientSignup, clientSignupEmail, initAgentSignup
    } = props;
    const [email, setEmail] = useState(clientSignupEmail ? clientSignupEmail : '');
    const [password, setPassword] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [currLoginHeader, setCurrLoginHeader] = useState(loginHeader ? loginHeader : 'Sign up');
    const [seePasswords, setSeePasswords] = useState(false);
    const [agentSignup, setAgentSignup] = useState(initAgentSignup !== undefined ? initAgentSignup : false);
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

    useEffect(() => {
        if (loginOrSignup === 'Login') {
            setTimeout(function() { handleSelectInput('email'); }, 200)
        } else {
            setTimeout(function() { handleSelectInput('invite-code'); }, 200)
        }
        // eslint-disable-next-line
    }, [])

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    let loginHeaderWords = loginHeader ? loginHeader : 'Sign up';
    if (clientSignup) {
        loginHeaderWords = (loginHeader.includes('portfolio') || loginHeader.includes('free')) ? loginHeader : `Sign up to get started`
    } else if (currLoginHeader === 'Login') {
        loginHeaderWords = 'Login'
    }
  
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    const handleEmail = () => {
        if (/(.+)@(.+){2,}\.(.+){2,}/.test(email) || email === '') {
            setEmailErrorMsg('');
            return true;
        }
        setEmailErrorMsg('Email is invalid');
        return false;
    }
    
    const handlePassword = () => {
        if (password.length > 5) {
            setPasswordErrorMsg('');
            return true;
        }
        setPasswordErrorMsg('Password must be at least 6 characters');
        return false;
    }

    const handleSignUpBtn = () => {
        if ((/(.+)@(.+){2,}\.(.+){2,}/.test(email)) && password.length > 5) {
            if (agentSignup && loginOrSignup !== 'Login') {
                return inviteCode.length > 0 ? false : true;
            } else {
                return false; // don't disable button
            }
        }
        return true; // disable button
    }

    const handleLoginSignup = async (e, email, password) => {
        e.preventDefault();
        setLoading(true);

        if (loginOrSignup === 'Login') {
            // login
            try {
                const { error } = await supabase.auth.signInWithPassword({
                    email: email,
                    password: password,
                })
    
                if (error) {
                    throw error
                } else {
                    window.location.reload(true);
                }
            } catch (error) {
                notifyErrorMsg(error.error_description || error.message)
                setTimeout(function() { setLoading(false) }, 200);
            }
        } else {
            // sign up
            // if agent, verify code
            if (agentSignup) {
                try {
                    let { data, error, status } = await supabase
                        .from('team')
                        .select('domain, invite_code, agent_emails')
                        .eq('invite_code', inviteCode);
        
                    if (error && status !== 406) {
                        throw error
                    } else if (status === 406) {
                        notifyErrorMsg("Invite code is incorrect.")
                    }
                    
                    if (data) {
                        if (data.length === 0) {
                            notifyErrorMsg("Invite code is incorrect.");
                            setTimeout(function() { setLoading(false) }, 200);
                        } else if (data.length > 0 && (window.location.href.includes(data[0].domain))) {
                            // agent sign up for the first time
                            handleSignupAgent(email, password);
                        } else if (data.length > 0 && !window.location.href.includes(data[0].domain)) {
                            notifyErrorMsg("You are signing up on the wrong URL.");
                            setTimeout(function() { setLoading(false) }, 200);
                        }
                    }
                } catch (error) {
                    console.error(error.message);
                }
            } else {
                handleSignup(email, password)
            }
        }
    }

    const handleSignupAgent = async (email, password) => {
        try {
            const { error } = await supabase.auth.signUp({
                email: email,
                password: password,
            })
            if (error === null) {
                let newURL = window.location.href;
                if (newURL.includes('?')) {
                    newURL = `${newURL}&agentsignup=${inviteCode}`
                } else {
                    newURL = `${newURL}?agentsignup=${inviteCode}`
                }
                setTimeout(function() { setLoading(false) }, 500);
                setTimeout(function() { setOpenLogin(false) }, 500);
                setTimeout(function() { window.location.href = newURL; }, 450);
            }
            if (error) throw error
        } catch (error) {
            notifyErrorMsg(error.error_description || error.message);
            setLoading(false);
        }
    }
    
    const handleSignup = async (email, password) => {
        try {
            const { error } = await supabase.auth.signUp({
                email: email,
                password: password,
            })
            if (error === null) {
                setTimeout(function() { setLoading(false) }, 500);
                setTimeout(function() { setOpenLogin(false) }, 500);
                setTimeout(function() { window.location.reload(true) }, 450);
            }
            if (error) throw error
        } catch (error) {
            notifyErrorMsg(error.error_description || error.message);
            setLoading(false);
        }
    }

    // async function signInWithGoogle() {
    //     try {
    //         const { error } = await supabase.auth.signInWithOAuth({
    //             provider: 'google',
    //         })

    //         if (error) {
    //             throw error
    //         }
    //     } catch (error) {
    //         notifyErrorMsg(error.error_description || error.message)
    //     }
    // }

     // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleLoginOrSignup = (newHeader) => {
        setCurrLoginHeader(newHeader);
        setLoginOrSignup(newHeader);
        setLoginHeader(newHeader);
    }
  
    return (
        <div className='mb-2'>
            <div className='flex justify-end'>
                <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => handleCloseLogin()}
                    style={{paddingTop: '12px', paddingBottom: '12px'}}
                    className={`text-xl px-3 cursor-pointer hover:rounded-full hover:bg-light-gray-2 ${isMobile ? '-mt-0.5' : '-mt-1'}`}
                />
            </div>
            {loginHeaderWords === "Sign up for free to view more" ? (
                <p className={`text-center ${loginHeaderWords.length > 15 ? 'text-2xl' : 'text-3xl'} font-bold tracking-tighter -mt-2 mb-7`}>
                    Sign up for <u>free</u> to view more
                </p>
            ) : (
                <p className={`text-center ${loginHeaderWords.length > 15 ? 'text-2xl' : 'text-3xl'} font-bold tracking-tighter -mt-2 mb-7`}>
                    {loginHeaderWords}
                </p>
            )}
            <div className='flex justify-center gap-5 text-xl cursor-pointer pb-4'>
                <div
                    className='flex flex-col justify-center items-center'
                    onClick={() => setAgentSignup(!agentSignup)}
                >
                    <p className={`mb-2 px-4`}>Agent</p>
                    <hr className={agentSignup ? 'border-bot-blue border-2 w-24' : 'hidden'} />
                </div>
                <div
                    className='flex flex-col justify-center items-center'
                    onClick={() => setAgentSignup(!agentSignup)}
                >
                    <p className={`mb-2 px-4`}>Buyer</p>
                    <hr className={agentSignup ? 'hidden' : 'border-bot-blue border-2 w-24'} />
                </div>
            </div>
            {/* <div className={window.location.href.includes('/investorbot.us') ? '' : 'hidden'}>
                <div className='flex justify-center mt-2.5'>
                    <button
                        onClick={signInWithGoogle}
                        style={{borderWidth: 1}}
                        className='w-96 py-2 text-black text-base border-gray rounded-md hover:bg-light-gray-2'
                    >
                        <div className="flex justify-center">
                            <img className="w-8 ml-3" src={google_g} alt="Google logo"/>
                            <span className="mx-3 mt-1">Continue with Google</span>
                        </div>
                    </button>
                </div>
                <div className='flex justify-center gap-3 mt-8 mb-4'>
                    <hr className='border-2 border-light-gray-4 w-40' />
                    <p className='text-base text-light-gray-4 -mt-3'>or</p>
                    <hr className='border-2 border-light-gray-4 w-40' />
                </div>
            </div> */}
            <div className={`flex justify-center ${window.location.href.includes('.investorbot.us') ? 'mt-5' : ''}`}>
                <Form className='max-auto text-base w-96' onSubmit={(e) => handleLoginSignup(e, email, password)}>
                    <Form.Group id="agent-code" className={agentSignup && loginOrSignup === 'Sign up' ? 'flex justify-center items-center gap-2' : 'hidden'}>
                        <Form.Control
                            className='h-12'
                            disabled={(clientSignupEmail === '' || clientSignupEmail === null || clientSignupEmail === undefined) ? false : true}
                            id="invite-code"
                            name="invite-code"
                            type="text"
                            placeholder="Paste invite code"
                            value={inviteCode}
                            onChange={(e) => setInviteCode(e.target.value)}
                        />
                        <img data-tip data-for='agentCodeTooltip' className="w-5 mb-2 mt-2" src={info_popup} alt="info box"/>
                        <ReactTooltip id={'agentCodeTooltip'} effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light" className='shadow-xl p-3 bg-white opaque z-50 w-80'>
                            <p className='flex justify-center text-base text-bot-blue mb-2'>Agent invite code</p>
                            <p className='text-sm text-dark-gray mb-2'>When signing up as an agent, you need an invite code to link your account to your team. If you don't have this code, please reach out to your team lead/admin and they will provide you with one. If you want to get your brokerage onboarded, please reach out to info@investorbot.us.</p>
                        </ReactTooltip>
                    </Form.Group>
                    <Form.Group id="email" className='-mt-2'>
                        <Form.Control
                            className='h-12'
                            disabled={(clientSignupEmail === '' || clientSignupEmail === null || clientSignupEmail === undefined) ? false : true}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleEmail}
                        />
                    </Form.Group>
                    <div className={(emailErrorMsg === '') ? 'hidden' : 'flex justify-left -mt-3 mb-3'}>
                        <p className='text-red ml-2 text-left'>{emailErrorMsg}</p>
                    </div>
                    <div>
                        <Form.Group className='flex justify-center -mt-2'>
                            <Form.Control
                                className='h-12'
                                id="password"
                                type={(seePasswords ? 'text': 'password')}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={handlePassword}
                            />
                        </Form.Group>
                        <div className={(passwordErrorMsg === '') ? 'hidden' : 'flex justify-left -mt-3 mb-3'}>
                            <p className='text-red ml-2 text-left'>{passwordErrorMsg}</p>
                        </div>
                        <div className='ml-2'>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={seePasswords} />
                                }
                                label="See passwords"
                                onClick={() => setSeePasswords(!seePasswords)}
                            />
                        </div>
                        <div className='flex justify-center'>
                            <Button
                                disabled={handleSignUpBtn()}
                                size="lg"
                                type="submit"
                                aria-live='polite'
                                className='mt-1 bg-bot-blue text-white w-full'
                            >
                                {loading ? (
                                    <div className='flex justify-center items-center'>
                                        <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                                    </div>
                                ) : (
                                    <p>{loginOrSignup}</p>
                                )}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
            {loginOrSignup === 'Login' ? (
                <div className='flex justify-center gap-2 mt-3 text-base'>
                    <p className='font-semibold'>Don't have an account?</p>
                    <p className='text-bot-blue cursor-pointer hover:bg-light-gray px-2 py-1.5 -mt-1.5 rounded-md' onClick={() => handleLoginOrSignup('Sign up')}>Sign up</p>
                </div>
            ) : (
                <div className='flex justify-center gap-2 mt-3 text-base'>
                    <p className='font-semibold'>Already have an account?</p>
                    <p className='text-bot-blue cursor-pointer hover:bg-light-gray px-2 py-1.5 -mt-1.5 rounded-md' onClick={() => handleLoginOrSignup('Login')}>Login</p>
                </div>
            )}
        </div>
    )
}
