import React from 'react'
import allFunctions from '../containers/Functions';

export default function InfoWindowReturns(props) {
    const { property, defaults, propertyEdits } = props;

    let propertyEdit = null;
    if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
        if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
            propertyEdit = propertyEdits.properties[`${property.zpid}`];
        }
    }
    
    let cashFlow = allFunctions.CalcReturns(property, defaults, propertyEdit).cashFlow;
    let capRate = allFunctions.CalcReturns(property, defaults, propertyEdit).capRate;

    cashFlow = cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (cashFlow.substring(0, 1) === "-") {
        cashFlow = `-$${cashFlow.substring(1, cashFlow.length)}`;
    } else {
        cashFlow = `$${cashFlow}`;
    }
  
    return (
        <div>
            <p className="text-sm">Cash Flow: <span className="text-base font-bold">{cashFlow}</span></p>
            <p className="text-sm">Cap Rate: <span className="text-base font-bold">{capRate}%</span></p>
        </div>
  )
}
