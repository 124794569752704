import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import loading_spinner from '../assets/loading_spinner.gif';

export default function ContactAgent(props) {
    const { session, agentName, agentEmail, clients } = props;
    const [name, setName] = useState('');
    const [email, setEmail] = useState(clients.length > 0 ? '' : session.user.email);
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    const notifySuccessMsg = (errorMsg) => toast.success(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    function sendEmail(e) {
        setLoading(true);
        e.preventDefault();
    
        emailjs.sendForm('service_xsn87gk', 'template_contact_form', e.target, '-zkNxR_jnyyJKePEU')
            .then((result) => {
                notifySuccessMsg("Success! Message has been sent.");
                setTimeout(() => { setLoading(false); }, 300);
            }, (error) => {
                console.log(error.text);
                notifyErrorMsg("Sign up failed. Please try again later.");
                setTimeout(() => { setLoading(false); }, 300);
            })
    }
    
    let disclosure = "";

    return (
        <form className='px-4' onSubmit={sendEmail}>
            <div className='lg:flex lg:justify-center lg:items-center text-sm text-white bg-white -mt-4 cursor-auto'>
                <input
                    id='agentEmail'
                    name='agentEmail'
                    className='w-1'
                    value={agentEmail}
                />
            </div>
            <div className='flex justify-center gap-2 mb-7'>
                <p className='text-center text-2xl font-bold mt-1'>
                    {`Message ${agentName === "agent" ? `${agentName}` : `${agentName === undefined ? 'agent' : agentName}`}`}
                </p>
                <p className='text-2xl'>📨</p>
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='name'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Name'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='email'
                    name='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Email'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='phone'
                    name='phone'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Phone'
                />
            </div>
            <div className='flex justify-center my-3'>
                <textarea
                    id='message'
                    name='message'
                    placeholder="Message"
                    className='h-24 w-96 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                />
            </div>
            <div className='flex justify-center mt-4 mb-3'>
                <Button type="submit" block size="lg" className='bg-bot-blue text-white rounded-lg text-xl w-96 py-2.5 font-semibold small-btn'>
                    {loading ? (
                        <div className='flex justify-center items-center'>
                            <img src={loading_spinner} className='h-7 text-center text-white' alt='Loading...' />
                        </div>
                    ) : (
                        <p>Send message</p>
                    )}
                </Button>
            </div>
            <div className='flex justify-center mb-3'>
                <p className='text-sm text-gray w-96'>{disclosure}</p>
            </div>
        </form>
    )
}
