import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

export default function HeartIcon(props) {
    const { property, favoritesList, handleFavorite, white } = props;
    
    let initStatus = false;
    if (favoritesList !== null) {
        favoritesList.forEach((zpid) => {
            if (zpid === property.zpid) {
                initStatus = true;
            }
        })
    }
    const [status, setStatus] = useState(initStatus);
    
    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    
    if (status) {
        return (
            <div
                onClick={() => handleFavorite(property, status, setStatus)}
                className='flex justify-center gap-1.5 lg:px-2 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl cursor-pointer lg:mr-2'
            >
                <AiFillHeart
                    className="cursor-pointer text-2xl text-red"
                />
                <p className={isMobile ? 'hidden' : 'text-sm font-normal mt-0.5'}>Unsave</p>
                <ReactTooltip id='unsaveProperty' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light" className='shadow-xl p-3 bg-white opaque'>
                    <p className='text-base text-bot-blue mb-2'>Unsave Property</p>
                    <p className='text-sm text-gray mb-2 w-48'>Unsave this property from your favorites page.</p>
                </ReactTooltip>
            </div>
        )
    } else {
        return (
            <div
                onClick={() => handleFavorite(property, status, setStatus)}
                className='flex justify-center gap-1.5 lg:px-2 py-1.5 hover:bg-light-bot-blue-0 hover:rounded-xl cursor-pointer lg:mr-2'
            >
                <AiOutlineHeart
                    className={(white) ? "cursor-pointer text-2xl text-white" : "cursor-pointer text-2xl text-black"}
                />
                <p className={isMobile ? 'hidden' : 'text-sm font-normal mt-0.5'}>Save</p>
                <ReactTooltip id='saveProperty' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light" className='shadow-xl p-3 bg-white opaque'>
                    <p className='text-base text-bot-blue mb-2'>Save Property</p>
                    <p className='text-sm text-gray mb-2 w-48'>Save this property to your favorites page.</p>
                </ReactTooltip>
            </div>
        )
    }
}