import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import loading_spinner from '../assets/loading_spinner.gif';

export default function ContactForm() {

    const [loading, setLoading] = useState(false);
    
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
    
    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-semibold'>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
    
    function sendEmail(e) {
        e.preventDefault();
        setLoading(true);
    
        emailjs.sendForm('service_xsn87gk', 'template_contact_form', e.target, '-zkNxR_jnyyJKePEU')
            .then((result) => {
                notifySuccessMsg("Success! Message has been sent.");
                setLoading(false);
            }, (error) => {
                console.log(error.text);
                setLoading(false);
                notifyErrorMsg("Sign up failed. Please try again later.");
            })
    }

    return (
        <form className='px-4' onSubmit={sendEmail}>
            <div className='lg:flex lg:justify-center lg:items-center text-sm text-white bg-white -mt-4 cursor-auto'>
                <input
                    id='agentEmail'
                    name='agentEmail'
                    className='w-0'
                    value={'info@investorbot.us'}
                />
            </div>
            <div className='flex flex-col justify-center'>
                <p className='text-center text-3xl font-extrabold tracking-tight mb-2'>Contact us</p>
                <p className='text-center text-lg mb-2'>Let's open this market🔥</p>
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='name'
                    name='name'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Name'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='email'
                    name='email'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Email'
                />
            </div>
            <div className='flex justify-center my-3'>
                <input
                    id='phone'
                    name='phone'
                    className='h-11 w-96 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                    placeholder='Phone'
                />
            </div>
            <div className='flex justify-center my-3'>
                <textarea
                    id='message' name='message'
                    defaultValue={`Hi, I'm interested in opening up a new market.`}
                    className='h-24 w-96 py-2 text-wrap px-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                />
            </div>
            <div className='flex justify-center my-4'>
                <Button
                    type="submit"
                    block size="lg"
                    className='bg-bot-blue text-white text-xl w-96 py-2.5 font-semibold small-btn'
                >
                    {loading ? (
                        <div className='flex justify-center items-center'>
                            <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                        </div>
                    ) : (
                        <p>Send message</p>
                    )}
                </Button>
            </div>
        </form>
    )
}
