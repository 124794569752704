import React, { useEffect, useRef, useState } from 'react';
import { DropdownButton, Modal, Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCodeCompare, faPencil,
    faSliders, faSortAmountDown, faXmark }
    from '@fortawesome/free-solid-svg-icons';
import { Typography, Slider, Box, Checkbox, FormControlLabel,
    FormGroup, Skeleton, Tooltip, Menu, MenuItem, Switch }
    from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joyride from 'react-joyride';
import { supabase } from '../supabase';
import "../index.css";
// global state
import logo from '../assets/logo_icon.png';
import { setGlobalState, useGlobalState } from '../state';
// images
import set_defaults from '../assets/set_defaults.png';
import info_popup from '../assets/info_popup.jpg';
import loading_spinner from '../assets/loading_spinner.gif';
// components
import PropertyTile from '../components/PropertyTile';
import SearchBar from '../components/SearchBar';
import FavoriteCard from '../components/FavoriteCard';
import NoHomes from '../components/NoHomes';
import ContactForm from './ContactForm';
import ContactTeam from './ContactTeam';
import SetDefaults from './SetDefaults';
import allFunctions from './Functions';
import Map from '../components/Map';
import Default from './Default';
import Property from '../containers/Property';
import BedButtons from '../components/BedButtons';
import BathButtons from '../components/BathButtons';
import SelectHomeType from '../components/SelectHomeType';
import SuggestNewMarket from '../components/SuggestNewMarket';
import { setMapCache } from '../lib/mapCache';
import SortDropdown from '../components/SortDropdown';
import CompAnalysis from './CompAnalysis';
import SavedSearchesModal from '../components/SavedSearchesModal';
import CurrencyInput from 'react-currency-input-field';
import ReactTooltip from 'react-tooltip';
import SignupComponent from '../components/SignupComponent';
import ClientDropdown from '../components/ClientDropdown';


export default function Home() {

    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const [session, setSession] = useState(null);
    const [favoritesList, setFavoritesList] = useState([]);
    const [favoritesListFull, setFavoritesListFull] = useState([]);
    const [propertyEdit, setPropertyEdit] = useState(null);
    const [propertyEdits, setPropertyEdits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [prefModalShown, setPrefModalShown] = useState(true);
    const [searching, setSearching] = useState(false);
    const [includePending, setIncludePending] = useState(false);
    // agent clients
    const [clients, setClients] = useState([]);
    const [activeClient, setActiveClient] = useState(null);
    const [loadingClient, setLoadingClient] = useState(false);
    
    // At what point the icons turn to top 75 dots
    const MAX_LAT_LNG_DISTANCE = 0.05;
    // scroll to top
    const scrollToTop = useRef();

    const queryParameters = new URLSearchParams(window.location.search);
    const propertyIdFromURL = queryParameters.get("id");
    const userIdFromURL = queryParameters.get("user");
    const clientNameFromURL = queryParameters.get("clientname");
    const latFromURL = queryParameters.get("lat");
    const lngFromURL = queryParameters.get("lng");

    // check local cache for map center and map bounds (on load)
    let currentMapCenter = { // Utah County
        lat: latFromURL ? parseFloat(latFromURL) : 40.312576260874614,
        lng: lngFromURL ? parseFloat(lngFromURL) : -111.71656594695311,
    };
    let currentMapBounds = { // Utah County
        'latitude': {
            'lo': latFromURL ? parseFloat(latFromURL) - 0.052 : 40.205549306017886,
            'hi': latFromURL ? parseFloat(latFromURL) + 0.042 : 40.41943386132337,
        },
        'longitude': {
            'lo': lngFromURL ? parseFloat(lngFromURL) - 0.085 : -111.92547784270506,
            'hi': lngFromURL ? parseFloat(lngFromURL) + 0.0803 : -111.50765405120116,
        }
    }
    let currentMapZoom = 12;
    let currentSelectedProperty = null;
    try {
        let cacheMapCenter = localStorage.getItem('MAP_CACHE');
        if (cacheMapCenter !== null && (latFromURL === null && lngFromURL === null)) {
            let tempJSON = JSON.parse(cacheMapCenter);
            if (tempJSON.hasOwnProperty('mapBounds') && tempJSON.hasOwnProperty('mapCenter') && tempJSON.hasOwnProperty('mapZoom')) {
                if (
                    tempJSON.mapBounds.hasOwnProperty('latitude') && tempJSON.mapBounds.latitude !== null &&
                    tempJSON.mapCenter.hasOwnProperty('lat') && tempJSON.mapCenter.lat !== null &&
                    tempJSON.mapZoom !== null
                ) {
                    currentMapCenter = tempJSON.mapCenter;
                    currentMapBounds = tempJSON.mapBounds;
                    currentMapZoom = tempJSON.mapZoom;
                    currentSelectedProperty = tempJSON.selectedProperty;
                }
            }
        }
    } catch (e) {
        console.error('No map center in local storage');
    }

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    const notifyMobile = () => toast.info(<p className='font-semibold text-base'>For a better browsing experience check it out on desktop!</p>, {
        toastId: 'defaultReminder',
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    useEffect(() => {
        // get data from supabase
        const getData = async () => {
            // set longitude and latitude low and high
            let lat_lo = defaults.mapBounds.latitude.lo;
            let lng_lo = defaults.mapBounds.longitude.lo;
            let lat_hi = defaults.mapBounds.latitude.hi;
            let lng_hi = defaults.mapBounds.longitude.hi;
            
            try {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, address, bedrooms, bathrooms, price, imgSrc,
                        latitude, longitude, rentEstimate, rentEstimateSource,
                        monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                        livingArea, listingAgent, brokerageName, homeStatus, yearBuilt,
                        daysOnZillow ${filterDescription ? ', description' : ''}
                    `)
                    .gt('latitude', lat_lo)
                    .gt('longitude', lng_lo)
                    .lt('latitude', lat_hi)
                    .lt('longitude', lng_hi)

                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    if (data.length === 0) {
                        setFilteredPropertiesLength(0);
                        setSuggestNewMarket(true);
                        setDefaults((prevState) => { return { ...prevState, propertiesInBounds: [] } });
                        setLoading(false);
                    } else {
                        let newinitPropertiesInBounds = [];
                        if (defaults.mapBounds !== null) {
                            data.forEach((property) => {
                                if ( property.bedrooms !== null
                                    && property.bathrooms !== null
                                    && property.bedrooms > 0
                                    && property.bathrooms > 0
                                    && property.price !== 0
                                    && (includePending
                                        ? (property.homeStatus === 'PENDING' || property.homeStatus === 'FOR_SALE')
                                        : property.homeStatus === 'FOR_SALE'
                                    )
                                ) {
                                    newinitPropertiesInBounds.push(property);
                                }
                            })
                        }
                    
                        // initial sort
                        let initPropertiesInBounds = allFunctions.AddReturnsToProperties(newinitPropertiesInBounds, defaults, propertyEdits);
                        initPropertiesInBounds = allFunctions.MasterQuicksort(initPropertiesInBounds, sortTitle);
                        setDefaults((prevState) => { return { ...prevState, propertiesInBounds: initPropertiesInBounds } });
                        setFilteredPropertiesLength(initPropertiesInBounds.length);
                        let initShowProperties = initPropertiesInBounds.length < 74 ? initPropertiesInBounds : initPropertiesInBounds.splice(0, 75);
                        setProperties(initShowProperties);
                    }
                } else {
                    console.log("No data!")
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        
        // get property edits
        const getProfileDetails = async (userId) => {
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select(`property_edits, user_type, shown_pref_modal, domain_login`)
                    .eq('id', userId)
    
                if (error && status !== 406) {
                    throw error
                }
                if (data && status !== 406) {
                    if (data[0].hasOwnProperty('property_edits')) {
                        setPropertyEdits(data[0].property_edits);
                    }
                    if (data[0].hasOwnProperty('shown_pref_modal') && data[0].shown_pref_modal === false) {
                        setPrefModalShown(false);
                    }
                    // if (data[0].hasOwnProperty('user_type')) {
                    //     setUserType(data[0].user_type)
                    // }
                }
            } catch (error) {
                console.log(error.message);
            }
        }

        const getPropertyData = async (selectedProperty, propertyId) => {
            let zpid = propertyId
                ? propertyId
                : selectedProperty ? selectedProperty.zpid : 0;

            try {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, created_at, state, zipcode, community, neighborhood,
                        subdivision, bedrooms, bathrooms, brokerageName, compsZPIDs,
                        datePostedString, dateSoldString, daysOnZillow, description,
                        homeStatus, homeType, isListedByOwner, isNonOwnerOccupied,
                        isPreforeclosureAuction, isRecentStatusChange, brokerageName,
                        listingAgent, listingAgentPhone, listing_agent_profile_url,
                        livingArea, latitude, longitude, lotAreaValue, lotAreaUnits,
                        mlsId, monthlyHoaFee, moveInReady, photos, price, priceChange, mlsName,
                        propertyTaxRate, propertyTypeDimension, rentEstimateSource, taxAssessedValue,
                        taxAssessedYear, yearBuilt, zestimate, address, basement, mortgageRates,
                        priceChangeDate, rentEstimate, taxAnnualAmount, tenantPays, zoning,
                        zoningDescription, listingSubType, streetAddress, city, imgSrc, lastUpdated
                    `)
                    .eq('zpid', zpid)
                    .single()
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    setSelectedProperty(data);
                    setLoading(false);
                    setShow(true);
                } else {
                    console.log("No data!");
                }
            } catch (error) {
                console.log(error.message);
            }
        }

        // client data
        const getClients = async (session) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, budget, property_types,
                        property_edits, timeline, shared_with, user_id,
                        agent_email
                    `)
                    .eq('agent_id', `${session.user.id}`)

                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    let initClients = [];
                    let showClient = 0;
                    data.forEach((client, index) => {
                        if (userIdFromURL && client.user_id === userIdFromURL) {
                            showClient = index;
                        }
                        if (clientNameFromURL && client.name === clientNameFromURL.replace("%20", " ")) {
                            showClient = index;
                        }
                        initClients.push({
                            'id': client.id,
                            'name': client.name,
                            'email': client.email,
                            'phone': client.phone,
                            'budget': client.budget,
                            'propertyTypes': client.property_types,
                            'timeline': client.timeline,
                            'sharedWith': client.shared_with,
                            'userId': client.user_id,
                            'propertyEdits': client.property_edits,
                            'agentEmail': client.agent_email
                        })
                    })
                    setClients(initClients);
                    setActiveClient(initClients[showClient]);
                    setPropertyEdits(initClients[showClient].propertyEdits);
                    getDefaults(initClients[showClient].id);
                    getFavoritesInit(initClients[showClient].id);
                    // getProfileDetails(initClients[0].userId); --> getting property_edits from this function now
                } else {
                    checkClient(session)
                }
                
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const checkClient = async (session) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`id, name, email, phone, budget, property_types, timeline, shared_with, agent_email`)
                    .eq('user_id', `${session.user.id}`)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    // client
                    let initClient = {
                        'id': data[0].id,
                        'name': data[0].name,
                        'email': data[0].email,
                        'phone': data[0].phone,
                        'budget': data[0].budget,
                        'propertyTypes': data[0].property_types,
                        'timeline': data[0].timeline,
                        'sharedWith': data[0].shared_with,
                        'propertyEdits': data[0].property_edits,
                        'agentEmail': data[0].agent_email
                    }
                    setActiveClient(initClient);
                    setPropertyEdits(initClient.propertyEdits);
                    getDefaults(initClient.id);
                    getFavoritesInit(initClient.id);
                    // getProfileDetails(session.user.id); --> getting property_edits from this function 
                } else {
                    // just an investor (not agent or client)
                    getDefaults(session.user.id);
                    getFavoritesInit(session.user.id);
                    getProfileDetails(session.user.id);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const handleGetClientPortfolio = async (clientId) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, budget, property_types,
                        timeline, shared_with, property_edits, agent_email
                    `)
                    .eq('id', clientId)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    let initClient = {
                        'id': data[0].id,
                        'name': data[0].name,
                        'email': data[0].email,
                        'phone': data[0].phone,
                        'budget': data[0].budget,
                        'propertyTypes': data[0].property_types,
                        'timeline': data[0].timeline,
                        'sharedWith': data[0].shared_with,
                        'propertyEdits': data[0].property_edits,
                        'agentEmail': data[0].agent_email
                    }
                    setActiveClient(initClient);
                    setPropertyEdits(initClient.propertyEdits);
                    getDefaults(initClient.id);
                    getFavoritesInit(initClient.id);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const getSharedUserSession = async (session) => {
            // check against the db
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select(`id, client_id, user_id, user_email, invite_code, role, confirmed`)
                    .eq('user_email', session.user.email)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    handleGetClientPortfolio(data[0].client_id);
                    if (data[0].confirmed === false) {
                        handleSharedUserId(data[0].id, session.user.id);
                    }
                }
                
            } catch (error) {
                console.error(error.message);
            }
        }

        const handleSharedUserId = async (shareId, userId) => {
            try {
                const updates = {
                    id: shareId,
                    user_id: userId,
                    confirmed: true
                }

                let { error } = await supabase.from('client_shares').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }

        const getProfile = async (session) => {
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select(`user_type`)
                    .eq('id', session.user.id)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    if (data[0].user_type === "agent") {
                        getClients(session);
                    } else if (data[0].user_type === "client") {
                        checkClient(session);
                    } else if (data[0].user_type === "shared") {
                        getSharedUserSession(session);
                    } else {
                        // likely a new lead/investor
                        checkClient(session);
                    }
                } else {
                    // if no data, then it's the first time signing up, so start down the rabbit hole
                    getClients(session);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }
        
        // set session
        supabase.auth.getSession().then(({ data: { session } }) => {
            if (session) {
                setSession(session);
                getProfile(session);
                
                // check for a property in the URL
                if (propertyIdFromURL) {
                    getPropertyData(selectedProperty, propertyIdFromURL);
                }
            } else {
                // check for a property in the URL
                if (propertyIdFromURL) {
                    getPropertyData(selectedProperty, propertyIdFromURL);
                }
                setLoading(false);
            }
        })

        // get all properties from supabase
        getData();

        // notify if mobile
        if (isMobile) {
            notifyMobile();
        }

        // eslint-disable-next-line
    }, [])

    // get favorites
    const getFavoritesInit = async (userId) => {
        try {
            let table = userId.includes('client-id') ? 'client_favorites' : 'favorites';
            let { data, error, status } = await supabase
                .from(table)
                .select(`saved_properties`)
                .eq('id', userId)
                .single()

            if (error && status !== 406) {
                throw error
            }
            if (data !== null && status !== 406) {
                let tempFavList = (data.saved_properties === '[]') ? [] : data.saved_properties;
                if (tempFavList.length > 0) {
                    if (data.saved_properties.slice(0, 12) === '[{"dateSold"') {
                        tempFavList = [];
                        let jsonFavList = JSON.parse(data.saved_properties);
                        jsonFavList.map(function (property) {
                            return (
                                tempFavList.push(parseInt(property.zpid))
                            )
                        })
                    }
                    verifyFavoritesList(tempFavList);
                } else {
                    setFavoritesList([])
                    setFavoritesListFull([])
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // set defaults
    const [defaults, setDefaults] = useState({
        // init investment
        closingCosts: 3000,
        closingCostsPct: 2,
        closingCostsType: "%",
        rehab: 1000,
        miscCosts: 0,
        // income
        rentAppr: 3,
        laundry: 0,
        storage: 0,
        miscIncome: 0,
        // expenses
        expensesAppr: 2.5,
        taxes: null,
        insurance: 100,
        mortgageInsurancePct: 0.01,
        repairs: 100,
        repairsPct: 5,
        repairsType: "%",
        capex: 100,
        capexPct: 5,
        capexType: "%",
        propMgnt: 0,
        vacancy: 3,
        miscExpenses: 0,
        utilitiesPaid: true,
        electricity: 40,
        gas: 20,
        sewage: 0,
        garbage: 0,
        lawn: 40,
        HOA: 0,
        // selling expenses
        sellersAgent: 3,
        buyersAgent: 3,
        // mortgage
        downpayment: 30000,
        downpaymentPct: 25,
        downpaymentType: '%', // or '$'
        interestRate: 6.500,
        loanTerm: 30,
        // appr & taxes
        city: "Provo, UT",
        cityAppr: 3,
        personalAnnualIncome: 0,
        taxBracket: 0,
        filingStatus: "Single",
        deprPeriod: 27.5,
        // map
        mapCenter: currentMapCenter,
        mapBounds: currentMapBounds,
        mapZoom: currentMapZoom,
        mapZoomedOut: (currentMapZoom > 13) ? false : true,
        signUpReason: '',
        showBy: "Cash Flow",
        propertiesInBounds: null,
        setDefaultsFromSupabase: false,
        // share
        sharePropertyEdits: true,
        // projection
        yearsProjected: 10
    });
    
    // get defaults (if any) from supabase
    const getDefaults = async (userId) => {
        try {
            let { data, error, status } = await supabase
                .from(userId.includes('client-id') ? 'client_defaults' : 'defaults')
                .select(`
                    downpayment, downpayment_pct, interest_rate, loan_term, closing_costs, rehab,
                    laundry, storage, misc_income, insurance, mortgage_insurance_pct, repairs,
                    capital_expenditures, property_management_pct, vacancy_pct, misc_costs,
                    misc_expenses, electricity, gas, sewage, garbage, lawn, sellers_agent_pct,
                    buyers_agent_pct, city, city_appreciation, personal_annual_income, tax_bracket,
                    filing_status, land_value, depreciation_period, downpayment_type, rent_appreciation,
                    expenses_appreciation, years_projected
                `)
                .eq('id', userId)
                .single()

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                // override data if any
                if (data.downpayment !== null) {setDefaults((prevState) => { return { ...prevState, downpayment: data.downpayment } })}
                if (data.downpayment_pct !== null) {setDefaults((prevState) => { return { ...prevState, downpaymentPct: data.downpayment_pct } })}
                if (data.interest_rate !== null) {setDefaults((prevState) => { return { ...prevState, interestRate: data.interest_rate } })}
                if (data.loan_term !== null) {setDefaults((prevState) => { return { ...prevState, loanTerm: data.loan_term } })}
                if (data.closing_costs !== null) {setDefaults((prevState) => { return { ...prevState, closingCosts: data.closing_costs } })}
                if (data.rehab !== null) {setDefaults((prevState) => { return { ...prevState, rehab: data.rehab } })}
                if (data.laundry !== null) {setDefaults((prevState) => { return { ...prevState, laundry: data.laundry } })}
                if (data.storage !== null) {setDefaults((prevState) => { return { ...prevState, storage: data.storage } })}
                if (data.misc_income !== null) {setDefaults((prevState) => { return { ...prevState, miscIncome: data.misc_income } })}
                if (data.insurance !== null) {setDefaults((prevState) => { return { ...prevState, insurance: data.insurance } })}
                if (data.mortgage_insurance_pct !== null) {setDefaults((prevState) => { return { ...prevState, mortgageInsurancePct: data.mortgage_insurance_pct } })}
                if (data.repairs !== null) {setDefaults((prevState) => { return { ...prevState, repairs: data.repairs } })}
                if (data.capital_expenditures !== null) {setDefaults((prevState) => { return { ...prevState, capex: data.capital_expenditures } })}
                if (data.property_management_pct !== null) {setDefaults((prevState) => { return { ...prevState, propMgnt: data.property_management_pct } })}
                if (data.vacancy_pct !== null) {setDefaults((prevState) => { return { ...prevState, vacancy: data.vacancy_pct } })}
                if (data.misc_costs !== null) {setDefaults((prevState) => { return { ...prevState, miscCosts: data.misc_costs } })}
                if (data.misc_expenses !== null) {setDefaults((prevState) => { return { ...prevState, miscExpenses: data.misc_expenses } })}
                if (data.electricity !== null) {setDefaults((prevState) => { return { ...prevState, electricity: data.electricity } })}
                if (data.gas !== null) {setDefaults((prevState) => { return { ...prevState, gas: data.gas } })}
                if (data.sewage !== null) {setDefaults((prevState) => { return { ...prevState, sewage: data.sewage } })}
                if (data.garbage !== null) {setDefaults((prevState) => { return { ...prevState, garbage: data.garbage } })}
                if (data.lawn !== null) {setDefaults((prevState) => { return { ...prevState, lawn: data.lawn } })}
                if (data.sellers_agent_pct !== null) {setDefaults((prevState) => { return { ...prevState, sellersAgent: data.sellers_agent_pct } })}
                if (data.buyers_agent_pct !== null) {setDefaults((prevState) => { return { ...prevState, buyersAgent: data.buyers_agent_pct } })}
                if (data.city !== null) {setDefaults((prevState) => { return { ...prevState, city: data.city } })}
                if (data.city_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, cityAppr: data.city_appreciation } })}
                if (data.personal_annual_income !== null) {setDefaults((prevState) => { return { ...prevState, personalAnnualIncome: data.personal_annual_income } })}
                if (data.tax_bracket !== null) {setDefaults((prevState) => { return { ...prevState, taxBracket: data.tax_bracket } })}
                if (data.filing_status !== null) {setDefaults((prevState) => { return { ...prevState, filingStatus: data.filing_status } })}
                if (data.land_value !== null) {setDefaults((prevState) => { return { ...prevState, landValue: data.land_value } })}
                if (data.depreciation_period !== null) {setDefaults((prevState) => { return { ...prevState, deprPeriod: data.depreciation_period } })}
                if (data.downpayment_type !== null) {setDefaults((prevState) => { return { ...prevState, downpaymentType: data.downpayment_type } })}
                if (data.rent_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, rentAppr: data.rent_appreciation } })}
                if (data.expenses_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, expensesAppr: data.expenses_appreciation } })}
                if (data.years_projected !== null) {setDefaults((prevState) => { return { ...prevState, yearsProjected: data.years_projected } })}
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // verify all properties on the favorites list are still on the market
    const verifyFavoritesList = async (data) => {
        // create zpid .or() string for supabase sql pull
        let zpidString = '';
        if (data.length === 1) {
            zpidString = `zpid.eq.${data[0]}`;
        } else if (data.length > 1) {
            zpidString = `zpid.eq.${data[0]}`;
            data.map(function (zpid) {
                return (
                    zpidString = `${zpidString},zpid.eq.${zpid}`
                )
            })
        }
        
        // pull from database
        try {
            if (zpidString !== '') {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, address, bedrooms, bathrooms, price, imgSrc,
                        latitude, longitude, rentEstimate, rentEstimateSource,
                        monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                        livingArea, homeStatus, yearBuilt, datePostedString,
                        listingAgent, brokerageName
                    `)
                    .or(zpidString);
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    // pass data to favorites
                    setFavoritesListFull(data);

                    // get just zpid's
                    let newFavList = [];
                    data.forEach((prop) => {
                        newFavList.push(prop.zpid);
                    })
                    setFavoritesList(newFavList);
                    updateFavorites(newFavList);
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleOpenExploreFeaturesModal = async () => {
        if (prefModalShown === false) {
            // save to supabase
            try {
                const updates = {
                    id: session.user.id,
                    updated_at: new Date(),
                    shown_pref_modal: true
                }

                let { error } = await supabase.from('profiles').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
            
            setPrefModalShown(true);
            setOpenExploreFeaturesModal(true);
        }
    }
    
    // product tour
    const steps = [
        {
            content: (
                <div className='mt-4 mb-3'>
                    <p className='text-3xl font-black tracking-tight'>Let's begin the tour!</p>
                    <p className='text-lg mt-4'>Learn how to fully utilize Investorbot</p>
                </div>
            ),
            placement: "center",
            target: "body"
        },
        {
            placement: "bottom",
            target: "#step-one",
            content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Preferences</p>
                    <p className='text-lg mt-4'>Set your desired downpayment, interest rate, monthly expenses, and more!</p>
                </div>
            ),
            spotlightPadding: 5
        },
        {
            placement: "bottom",
            target: "#step-two",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Filters</p>
                    <p className='text-lg mt-4'>Filter by cash flow, bed/bath, etc.</p>
                </div>
            ),
            spotlightPadding: 5
        },
        {
            placement: "bottom",
            target: "#step-three",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Map Pins</p>
                    <p className='text-lg mt-4'>Change the pins on the map to show cash flow, cap rate, and more.</p>
                </div>
            ),
            spotlightPadding: 10
        },
        {
            placement: "bottom",
            target: "#step-four",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Investment Strategy</p>
                    <p className='text-lg mt-4'>Choose between long-term, short-term, commercial, and off-market properties.</p>
                </div>
            ),
            spotlightPadding: 0
        },
        {
            placement: "bottom",
            target: "#step-five",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Sort</p>
                    <p className='text-lg mt-4'>Sort by cash flow, price, and more.</p>
                </div>
            ),
            spotlightPadding: 5
        },
        {
            placement: "bottom",
            target: "#step-six",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Favorites</p>
                    <p className='text-lg mt-4'>View your favorites here.</p>
                </div>
            ),
            spotlightPadding: 5
        },
        {
            placement: "bottom",
            target: "#step-seven",
             content: (
                <div>
                    <p className='text-3xl font-black tracking-tight'>Analyze a Property</p>
                    <p className='text-lg mt-4'>Change the numbers inside to recalculate your potential return.</p>
                </div>
            ),
            spotlightPadding: 2
        }
    ]

    // local state
    const [sortTitle, setSortTitle] = useState("Cash Flow");
    const [loginHeader, setLoginHeader] = useState("Sign up");
    const [suggestNewMarket, setSuggestNewMarket] = useState(false);
    const [loginOrSignup, setLoginOrSignup] = useState('Sign up');
    // cash flow filter
    const [returnsTitle, setReturnsTitle] = useState('Returns');
    const [cashFlowText, setCashFlowText] = useState('Any');
    const [cashFlowValue, setCashFlowValue] = useState(-1000);
    // price filter
    const [allFiltersTitle, setAllFiltersTitle] = useState('Filters');
    const [priceTitle, setPriceTitle] = useState('Price');
    const [priceText, setPriceText] = useState('Any');
    const MAX_PRICE_RANGE = 1500000
    const [priceValue, setPriceValue] = useState([0, MAX_PRICE_RANGE]);
    // cap rate filter
    const [capRateText, setCapRateText] = useState('Any');
    const [capRateValue, setCapRateValue] = useState(0);
    // total return filter
    const [totalReturnText, setTotalReturnText] = useState('Any');
    const [totalReturnValue, setTotalReturnValue] = useState(0);
    // keywords filter
    const [filterDescription, setFilterDescription] = useState(false);
    const [keywords, setKeywords] = useState('');
    // bed filter
    const [useExact, setUseExact] = useState(false);
    const [bedBathTitle, setBedBathTitle] = useState('Bed/Bath');
    const [bed, setBed] = useState(0);
    const [bath, setBath] = useState(0);
    const [numBeds, setNumBeds] = useState(['1+', '2+', '3+', '4+', '5+'])
    const numBaths = ['1+', '1.5+', '2+', '3+', '4+']
    // home type filter
    const [homeTypeTitle, setHomeTypeTitle] = useState('Home Type');
    const allHomeTypes = ['Single Family', 'Townhouse', 'Condo', 'Multi Family']
    const [homeTypes, setHomeTypes] = useState(allHomeTypes)
    const [selectAllButton, setSelectAllButton] = useState('Deselect All')
    // hoa filter
    const [numMoreFiltersTitle, setNumMoreFiltersTitle] = useState('More');
    const [maxHOAValue, setMaxHOAValue] = useState(0);
    const [maxHOAText, setMaxHOAText] = useState('Any');
    const [noHOA, setNoHOA] = useState(false);
    // days on market filter
    const MAX_DAYS_ON_MARKET_RANGE = 365;
    const [daysOnMarketValue, setDaysOnMarketValue] = useState([0, MAX_DAYS_ON_MARKET_RANGE]);
    const [daysOnMarketText, setDaysOnMarketText] = useState('Any');
    // days on market filter
    const YEAR_TODAY = 2023 // TODO: make this dynamic
    const MAX_AGE_OF_PROPERTY_RANGE = 100;
    const [ageOfPropertyValue, setAgeOfPropertyValue] = useState([0, MAX_AGE_OF_PROPERTY_RANGE]);
    const [ageOfPropertyText, setAgeOfPropertyText] = useState('Any');
    // modals
    const [openLogin, setOpenLogin] = useState(false);
    const [show, setShow] = useState(false);
    const [openAgentModal, setOpenAgentModal] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [openContactTeamModal, setOpenContactTeamModal] = useState(false);
    const [openCompareFavsModal, setOpenCompareFavsModal] = useState(false);
    const [openAllFiltersMobile, setOpenAllFiltersMobile] = useState(false);
    const [openSavedSearchesModal, setOpenSavedSearchesModal] = useState(false);
    const [openExploreFeaturesModal, setOpenExploreFeaturesModal] = useState(false);
    const [openAdjustsProjectionsModal, setOpenAdjustsProjectionsModal] = useState(false);
    // show/hide returns
    const [hideCashFlow] = useState(false);
    const [hideCapRate] = useState(false);
    const [hideCoCReturn] = useState(true);
    const [hideTotalReturn] = useState(true);
    // request markets
    const [disableSuggestNewMarket, setDisableSuggestNewMarket] = useState(false);
    // set property data & selected property
    const [properties, setProperties] = useState([]);
    const [filteredPropertiesLength, setFilteredPropertiesLength] = useState(0);
    const [selectedProperty, setSelectedProperty] = useState(currentSelectedProperty);
    // favorites modal
    const [loadingComps, setLoadingComps] = useState(true);
    const eight = [1,2,3,4,5,6,7,8];
    const [sortCompareFavoritesTitle, setSortCompareFavoritesTitle] = useState('');
    const [compareFavorites, setCompareFavorites] = useState(false);
    const [compareFavoritesList, setCompareFavoritesList] = useState([]);
    const [compareFavoritesListLength, setCompareFavoritesListLength] = useState(0);
    const [compFavListFull, setCompFavListFull] = useState([]);

    if (defaults.setDefaultsFromSupabase === false && properties.length > 0 && session !== null) {
        // recalculate returns with defaults from Supabase
        let initPropertiesInBounds = allFunctions.AddReturnsToProperties(defaults.propertiesInBounds, defaults, propertyEdits);
        initPropertiesInBounds = allFunctions.MasterQuicksort(initPropertiesInBounds, sortTitle);
        setDefaults((prevState) => { return { ...prevState, propertiesInBounds: initPropertiesInBounds } });
        setFilteredPropertiesLength(initPropertiesInBounds.length)
        let initShowProperties = initPropertiesInBounds.splice(0, 75);
        setProperties(initShowProperties)
        setDefaults((prevState) => { return { ...prevState, setDefaultsFromSupabase: true } });
        setLoading(false);
    }

    const sortProperties = (metric) => {
        let propertiesOnMap = defaults.propertiesInBounds;
        if (metric === "Price/Sq Ft") {
            propertiesOnMap = allFunctions.AddPricePerSqFt(propertiesOnMap);
        } else {
            propertiesOnMap = allFunctions.AddReturnsToProperties(propertiesOnMap, defaults, propertyEdits);
        }
        let sortedProperties = allFunctions.MasterQuicksort(propertiesOnMap, metric);
        // If zoomed out, only show 75 properties on the map
        if ((defaults.mapBounds.latitude.hi - defaults.mapBounds.latitude.lo) > MAX_LAT_LNG_DISTANCE) {
            if (sortedProperties.length > 75) {
                sortedProperties = sortedProperties.splice(0, 75);
            }
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: true } });
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }
        setProperties(sortedProperties);
        setSortTitle(metric);
    }

    // Sort favorites list
    const [anchorEl, setAnchorEl] = useState(null);
    const openSortFavs = Boolean(anchorEl);
    
    const handleClickSortFavs = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const sortFavorites = (metric) => {
        setSortCompareFavoritesTitle(metric);
        setAnchorEl(null);
        let sortedFavList = favoritesListFull;
        if (metric === "Price/Sq Ft") {
            sortedFavList = allFunctions.AddPricePerSqFt(sortedFavList);
        }
        sortedFavList = allFunctions.MasterQuicksort(sortedFavList, metric);
        setFavoritesListFull(sortedFavList);
        setCompFavListFull(sortedFavList);
    }

    const handleBoundsChange = (allProperties, mapBounds, mapCenter, mapZoom) => {
        let newPropertiesInBounds = [];
        let selectedPropertyInBounds = false;
        let propertiesWithReturns = [];
        let sortedProperties = [];
        
        // set map default for lng/lat
        let latitude = Object.values(mapBounds)[0];
        let longitude = Object.values(mapBounds)[1];
        let newMapBounds = {
            "latitude": {
                "lo": latitude.lo,
                "hi": latitude.hi
            },
            "longitude": {
                "lo": longitude.lo,
                "hi": longitude.hi
            }
        }

        let newMapCenter = mapCenter;
        if (mapCenter.hasOwnProperty("lat")) {
            if (typeof mapCenter.lat !== "number") {
                newMapCenter = {
                    'lat': mapCenter.lat(),
                    'lng': mapCenter.lng(),
                }
            }
            
        } else {
        }
        
        setDefaults((prevState) => { return { ...prevState, mapBounds: newMapBounds } });
        
        if (allProperties.length > 0) {
            // US boundaries
            const NorthOfUS = 75
            const SouthOfUS = 15
            const EastOfUS = -60
            const WestOfUS = -180
            
            // 1. Ensure map is not greater than the size of the US (error)
            if (
                latitude.hi < NorthOfUS &&
                latitude.lo > SouthOfUS &&
                longitude.hi < EastOfUS &&
                longitude.lo > WestOfUS
                ) {
                    // 2. Filter in-bounds, filters out null bed/baths
                    newPropertiesInBounds = [];
                    allProperties.forEach((property) => {
                        if ( property.bedrooms !== null
                            && property.bathrooms !== null
                            && property.bedrooms > 0
                            && property.bathrooms > 0
                            && property.price !== 0
                            && (includePending
                                ? (property.homeStatus === 'PENDING' || property.homeStatus === 'FOR_SALE')
                                : property.homeStatus === 'FOR_SALE'
                            )
                        ) {
                            if (selectedProperty !== null) {
                                if (selectedProperty.address === property.address) {
                                    selectedPropertyInBounds = true;
                                }
                            }
                            newPropertiesInBounds.push(property);
                        }
                    });
                } else {
                    notifyErrorMsg("Zoom in closer to view properties.");
                }
                
                
            // sort
            propertiesWithReturns = allFunctions.AddReturnsToProperties(newPropertiesInBounds, defaults, propertyEdits);
            sortedProperties = allFunctions.MasterQuicksort(propertiesWithReturns, sortTitle);
            // set properties in bounds
            setDefaults((prevState) => { return { ...prevState, propertiesInBounds: sortedProperties } });
                        
            // 3. Filter cash flow
            let newList = [];
            if (cashFlowValue !== -1000) {
                sortedProperties.forEach((property, index) => {
                    if (property.cashFlow > cashFlowValue) {
                        newList.push(property);
                    }
                })
                sortedProperties = newList;
            }
            
            // Filter cap rate & total return
            newList = [];
            if (capRateValue > 0 || totalReturnValue > 0) {
                sortedProperties.forEach((property, index) => {
                    if (property.capRate > capRateValue && property.totalReturn > totalReturnValue) {
                        newList.push(property);
                    }
                })
                sortedProperties = newList;
            }
            
            // Filter price
            newList = [];
            let min_price = priceValue[0];
            let max_price = priceValue[1];
            if (min_price > 0 || max_price < MAX_PRICE_RANGE) {
                sortedProperties.forEach((property, index) => {
                    if (property.price > min_price && property.price < max_price) {
                        newList.push(property);
                    }
                })
                sortedProperties = newList;
            }

            // 4. Filter bed/bath
            let minBeds = (isNaN(bed)) ? parseInt(bed.slice(0, 1)) : bed;
            let minBaths = (isNaN(bath)) ? parseInt(bath.slice(0, bath.length - 1)) : bath;

            newList = [];
            if (useExact) {
                sortedProperties.forEach((property) => {
                    if (property.bedrooms === minBeds && property.bathrooms >= minBaths) {
                        newList.push(property);
                    }
                })
            } else {
                sortedProperties.forEach((property) => {
                    if (property.bedrooms >= minBeds && property.bathrooms >= minBaths) {
                        newList.push(property);
                    }
                })
            }
            sortedProperties = newList;
            
            // 5. Filter home type
            newList = [];
            sortedProperties.forEach((property) => {
                if (homeTypes.includes(property.propertyTypeDimension)) {
                    newList.push(property);
                }
            })
            let newSortedProperties = newList;

            // Filter HOA
            newList = [];
            if (maxHOAText === "Any" & (noHOA === false)) {
                newList = newSortedProperties;
            } else {
                if (noHOA) {
                    // all properties with no HOA fees (0 or null)
                    newSortedProperties.forEach((property) => {
                        if (property.monthlyHoaFee === 0 || property.monthlyHoaFee === null) {
                            newList.push(property);
                        }
                    })
                } else if (maxHOAValue > 0) {
                    // all properties with HOA fees below the specified value (including null)
                    newSortedProperties.forEach((property) => {
                        if (property.monthlyHoaFee < maxHOAValue || property.monthlyHoaFee === null) {
                            newList.push(property);
                        }
                    })
                }
            }
            let newestSortedProperties = newList;

            // Filter description
            newList = [];
            if (filterDescription && keywords.length > 0) {
                let keywordsArr = keywords.split(',');
                newestSortedProperties.forEach((property, index) => {
                    let hasKeywords = true;
                    keywordsArr.forEach(keyword => {
                        if (!property.description.toLowerCase().includes(keyword.toLowerCase())) {
                            hasKeywords = false;
                        }
                    })
                    if (hasKeywords) {
                        newList.push(property);
                    }
                })
                newestSortedProperties = newList;
            }

            // Filter days on market
            newList = [];
            let min_daysOnMarket = daysOnMarketValue[0];
            let max_daysOnMarket = daysOnMarketValue[1];
            if (min_daysOnMarket > 0 || max_daysOnMarket < MAX_DAYS_ON_MARKET_RANGE) {
                newestSortedProperties.forEach((property, index) => {
                    if (property.daysOnZillow > min_daysOnMarket) {
                        if (max_daysOnMarket < MAX_DAYS_ON_MARKET_RANGE) {
                            if (property.daysOnZillow < max_daysOnMarket) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                newestSortedProperties = newList;
            }

            // Filter age of property
            newList = [];
            let min_ageOfProperty = ageOfPropertyValue[0];
            let max_ageOfProperty = ageOfPropertyValue[1];
            if (min_ageOfProperty > 0 || max_ageOfProperty < MAX_AGE_OF_PROPERTY_RANGE) {
                newestSortedProperties.forEach((property, index) => {
                    let propertyAge = YEAR_TODAY - parseInt(property.yearBuilt)
                    if (propertyAge > min_ageOfProperty) {
                        if (max_ageOfProperty < MAX_AGE_OF_PROPERTY_RANGE) {
                            if (propertyAge < max_ageOfProperty) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                newestSortedProperties = newList;
            }

            setFilteredPropertiesLength(newestSortedProperties.length);
            // 6. If zoomed out, only show 75 properties.
            if ((latitude.hi - latitude.lo) > MAX_LAT_LNG_DISTANCE) {
                if (newestSortedProperties.length > 75) {
                    newestSortedProperties = newestSortedProperties.splice(0, 75);
                }
                setDefaults((prevState) => { return { ...prevState, mapZoomedOut: true } });
            } else {
                setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
            }

            // set properties
            if (selectedPropertyInBounds === false) {
                setSelectedProperty(null);
            }
            setProperties(newestSortedProperties);
            setSuggestNewMarket(false);
        } else {
            setFilteredPropertiesLength(0);
            setSuggestNewMarket(true);
            if ((latitude.hi - latitude.lo) < 0.70) {
                setDisableSuggestNewMarket(false);
            } else {
                setDisableSuggestNewMarket(true);
            }
            setDefaults((prevState) => { return { ...prevState, propertiesInBounds: [] } });
        }
        setMapCache(defaults, selectedProperty, newMapCenter, mapZoom);
    }

    const handleFilter = (e, label, filter) => {
        
        // first set the filters
        if (filter === "ageOfProperty") {
            let ageOfProperty = e.target.value;
            let min_ageOfProperty = ageOfProperty[0];
            let max_ageOfProperty = ageOfProperty[1];
            // set text
            let ageOfPropertyText = '';
            if (min_ageOfProperty === 0 && max_ageOfProperty === 100) {
                ageOfPropertyText = 'Any';
            } else {
                ageOfPropertyText = `${ageOfProperty[0].toLocaleString(undefined, { maximumFractionDigits: 2 })}${ageOfProperty[1] === MAX_AGE_OF_PROPERTY_RANGE ? '+' : ' - ' + ageOfProperty[1].toLocaleString(undefined, { maximumFractionDigits: 2 })} years old`;
            }
            setAgeOfPropertyValue(ageOfProperty);
            setAgeOfPropertyText(ageOfPropertyText);
        }
        
        let filteredProperties = defaults.propertiesInBounds;
        let numReturnFilters = 0;
        let numAllFilters = 0;
        let numMoreFilters = 0;

        // CASH FLOW
        let newList = [];
        if (filter === "cashFlow") {
            // filter cashflow specifically
            let cashflow = e.target.value;
            if (cashflow === -1000) {
                setCashFlowText('Any')
            } else {
                setCashFlowValue(cashflow);
                numReturnFilters += 1
                
                if (cashflow > 0) {
                    setCashFlowText(`$${(parseFloat(cashflow).toLocaleString(undefined, { maximumFractionDigits: 2 }))}+`);
                } else {
                    setCashFlowText(`-$${(parseFloat(Math.abs(cashflow)).toLocaleString(undefined, { maximumFractionDigits: 2 }))}+`);
                }
                
                // filter cash flow
                if (cashflow !== null) {
                    filteredProperties.forEach((property, index) => {
                        if (property.cashFlow > cashflow) {
                            newList.push(property);
                        }
                    })
                    filteredProperties = newList;
                }
            }

        } else {
            // filter cashflow only if cashFlowValue > -1000
            if (cashFlowValue > -1000) {
                numReturnFilters += 1
                filteredProperties.forEach((property, index) => {
                    if (property.cashFlow > cashFlowValue) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        }
        
        // CAP RATE
        newList = [];
        if (filter === "capRate") {
            // filter cap rate specifically
            let capRate = e.target.value;
            if (capRate === 0) {
                setCapRateValue(capRate);
                setCapRateText('Any')
            } else {
                numReturnFilters += 1
                setCapRateValue(capRate);
                setCapRateText(`${capRate}% +`);
    
                // filter cap rate
                if (capRate !== null) {
                    filteredProperties.forEach((property, index) => {
                        if (property.capRate > capRate) {
                            newList.push(property);
                        }
                    })
                    filteredProperties = newList;
                }
            }
        } else {
            // filter capRate only if capRateValue !== null
            if (capRateValue > 0) {
                numReturnFilters += 1
                filteredProperties.forEach((property, index) => {
                    if (property.capRate > capRateValue) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        }
        
        // TOTAL RETURN
        newList = [];
        if (filter === "totalReturn") {
            // filter total return specifically
            let totalReturn = e.target.value;
            if (totalReturn === 0) {
                setTotalReturnValue(totalReturn);
                setTotalReturnText('Any')
            } else {
                numReturnFilters += 1
                setTotalReturnValue(totalReturn);
                setTotalReturnText(`${totalReturn}% +`);
    
                // filter total return
                if (totalReturn !== null) {
                    filteredProperties.forEach((property, index) => {
                        if (property.totalReturn > totalReturn) {
                            newList.push(property);
                        }
                    })
                    filteredProperties = newList;
                }
            }
        } else {
            // filter totalReturn only if totalReturnValue !== null
            if (totalReturnValue > 0) {
                numReturnFilters += 1;
                filteredProperties.forEach((property, index) => {
                    if (property.totalReturn > totalReturnValue) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        }
        
        if (numReturnFilters === 0 && (filter === "cashFlow" || filter === "capRate" || filter === "totalReturn")) {
            setReturnsTitle('Returns');
        } else if (numReturnFilters > 0) {
            setReturnsTitle(`Returns (${numReturnFilters})`);
        }
        
        // PRICE
        newList = [];
        if (filter === "price") {
            // filter total return specifically
            numAllFilters += 1;
            let price = e.target.value;
            let min_price = price[0];
            let max_price = price[1];
            let priceText = `$${price[0].toLocaleString(undefined, { maximumFractionDigits: 2 })} - $${price[1].toLocaleString(undefined, { maximumFractionDigits: 2 })}`
            setPriceValue(price);
            setPriceText(priceText);
            setPriceTitle('Price (1)')

            // filter total return
            if (price !== null) {
                filteredProperties.forEach((property, index) => {
                    if (property.price > min_price && property.price < max_price) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        } else {
            // filter price only if priceValue !== null
            let min_price = priceValue[0];
            let max_price = priceValue[1];
            if (min_price > 0 || max_price < MAX_PRICE_RANGE) {
                numAllFilters += 1;
                filteredProperties.forEach((property, index) => {
                    if (property.price > min_price && property.price < max_price) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        }
        
        
        // BEDS/BATHS
        let minBeds = (isNaN(bed)) ? parseInt(bed.slice(0, 1)) : bed;
        let minBaths = (isNaN(bath)) ? parseInt(bath.slice(0, bath.length - 1)) : bath;
        
        if (minBeds > 0) { numAllFilters += 1; }
        if (minBaths > 0) { numAllFilters += 1; }

        if (filter === "bed") {
            minBeds = (useExact || label === "use exact") ? e : parseInt(e.slice(0, 1));
            numAllFilters += 1;
        } else if (filter === "bath") {
            minBaths = parseInt(e.slice(0, e.length - 1));
            numAllFilters += 1;
        }
        

        newList = [];
        if (label === "not exact") {
            filteredProperties.forEach((property) => {
                if (property.bedrooms >= minBeds && property.bathrooms >= minBaths) {
                    newList.push(property);
                }
            })
        } else {
            if (useExact || label === "use exact") {
                filteredProperties.forEach((property) => {
                    if (property.bedrooms === minBeds && property.bathrooms >= minBaths) {
                        newList.push(property);
                    }
                })
            } else {
                filteredProperties.forEach((property) => {
                    if (property.bedrooms >= minBeds && property.bathrooms >= minBaths) {
                        newList.push(property);
                    }
                })
            }
        }
        filteredProperties = newList;

        // HOME TYPE
        let currentHomeTypes = homeTypes
        if (filter === "home type") {
            currentHomeTypes = e;
        }
        newList = [];
        filteredProperties.forEach((property) => {
            if (currentHomeTypes.includes(property.propertyTypeDimension)) {
                newList.push(property);
            }
        })
        filteredProperties = newList;
        
        if (currentHomeTypes.length < 4) {
            numAllFilters += 1;
        }
        
        // HOA
        if (filter === 'noHoa') {
            numAllFilters += 1;
            numMoreFilters += 1;
            setNoHOA(e);
            if (e) {
                newList = [];
                // all properties with no HOA fees (0 or null)
                filteredProperties.forEach((property) => {
                    if (property.monthlyHoaFee === 0 || property.monthlyHoaFee === null) {
                        newList.push(property);
                    }
                })
            } else if (maxHOAValue > 0) {
                newList = [];
                // all properties with HOA fees below the specified value (including null)
                filteredProperties.forEach((property) => {
                    if (property.monthlyHoaFee < maxHOAValue || property.monthlyHoaFee === null) {
                        newList.push(property);
                    }
                })
            }
        } else if (filter === 'hoa') {
            numAllFilters += 1;
            numMoreFilters += 1;
            let hoa = null
            if (e) {
                hoa = e.target.value;
            }
            handleMaxHOA(hoa)
            if (noHOA) {
                newList = [];
                // all properties with no HOA fees (0 or null)
                filteredProperties.forEach((property) => {
                    if (property.monthlyHoaFee === 0 || property.monthlyHoaFee === null) {
                        newList.push(property);
                    }
                })
            } else if (hoa > 0) {
                newList = [];
                // all properties with HOA fees below the specified value (including null)
                filteredProperties.forEach((property) => {
                    if (property.monthlyHoaFee < hoa || property.monthlyHoaFee === null) {
                        newList.push(property);
                    }
                })
            }
        } else if (filter === 'resetHoa') {
            newList = filteredProperties;
        } else {
            if (maxHOAText === "Any" & (noHOA === false)) {
                newList = filteredProperties;
            } else {
                numAllFilters += 1;
                numMoreFilters += 1;
                if (noHOA) {
                    newList = [];
                    // all properties with no HOA fees (0 or null)
                    filteredProperties.forEach((property) => {
                        if (property.monthlyHoaFee === 0 || property.monthlyHoaFee === null) {
                            newList.push(property);
                        }
                    })
                } else if (maxHOAValue > 0) {
                    newList = [];
                    // all properties with HOA fees below the specified value (including null)
                    filteredProperties.forEach((property) => {
                        if (property.monthlyHoaFee < maxHOAValue || property.monthlyHoaFee === null) {
                            newList.push(property);
                        }
                    })
                }
            }
        }
        filteredProperties = newList;

        // KEYWORDS
        newList = [];
        if (filter === "keywords") {
            numAllFilters += 1;
            numMoreFilters += 1;
            let newKeywords = e.target.value;
            setKeywords(newKeywords);
            // filter keywords
            if (newKeywords !== null) {
                let keywordsArr = newKeywords.split(',');
                filteredProperties.forEach((property, index) => {
                    let hasKeywords = true;
                    keywordsArr.forEach(keyword => {
                        if (!property.description.toLowerCase().includes(keyword.toLowerCase())) {
                            hasKeywords = false;
                        }
                    })
                    if (hasKeywords) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        } else {
            // filter keywords only if value is not null
            if (filterDescription && keywords !== null) {
                numAllFilters += 1;
                numMoreFilters += 1;
                let keywordsArr = keywords.split(',');
                filteredProperties.forEach((property, index) => {
                    let hasKeywords = true;
                    keywordsArr.forEach(keyword => {
                        if (!property.description.toLowerCase().includes(keyword.toLowerCase())) {
                            hasKeywords = false;
                        }
                    })
                    if (hasKeywords) {
                        newList.push(property);
                    }
                })
                filteredProperties = newList;
            }
        }

        // DAYS ON MARKET
        newList = [];
        if (filter === "daysOnMarket") {
            numAllFilters += 1;
            numMoreFilters += 1;
            let daysOnMarket = e.target.value;
            let min_daysOnMarket = daysOnMarket[0];
            let max_daysOnMarket = daysOnMarket[1];
            // set text
            let daysOnMarketText = '';
            if (daysOnMarket === [0, 365]) {
                daysOnMarketText = 'Any';
            } else {
                daysOnMarketText = `${daysOnMarket[0].toLocaleString(undefined, { maximumFractionDigits: 2 })} - ${daysOnMarket[1] === 365 ? 'Any' : daysOnMarket[1].toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
            }
            setDaysOnMarketValue(daysOnMarket);
            setDaysOnMarketText(daysOnMarketText);
            // filter days on market
            if (daysOnMarket !== null) {
                filteredProperties.forEach((property, index) => {
                    if (property.daysOnZillow > min_daysOnMarket) {
                        if (max_daysOnMarket < 365) {
                            if (property.daysOnZillow < max_daysOnMarket) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                filteredProperties = newList;
            }
        } else {
            // filter daysOnMarket only if value is not null
            let min_daysOnMarket = daysOnMarketValue[0];
            let max_daysOnMarket = daysOnMarketValue[1];
            if (min_daysOnMarket > 0 || max_daysOnMarket < MAX_DAYS_ON_MARKET_RANGE) {
                numAllFilters += 1;
                numMoreFilters += 1;
                filteredProperties.forEach((property, index) => {
                    if (property.daysOnZillow > min_daysOnMarket) {
                        if (max_daysOnMarket < MAX_DAYS_ON_MARKET_RANGE) {
                            if (property.daysOnZillow < max_daysOnMarket) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                filteredProperties = newList;
            }
        }

        // AGE OF PROPERTY
        newList = [];
        if (filter === "ageOfProperty") {
            // increment filter counters
            numAllFilters += 1;
            numMoreFilters += 1;
            let ageOfProperty = e.target.value;
            let min_ageOfProperty = ageOfProperty[0];
            let max_ageOfProperty = ageOfProperty[1];
            // set text
            let ageOfPropertyText = '';
            if (min_ageOfProperty === 0 && max_ageOfProperty === 100) {
                ageOfPropertyText = 'Any';
            } else {
                ageOfPropertyText = `${ageOfProperty[0].toLocaleString(undefined, { maximumFractionDigits: 2 })}${ageOfProperty[1] === MAX_AGE_OF_PROPERTY_RANGE ? '+' : ' - ' + ageOfProperty[1].toLocaleString(undefined, { maximumFractionDigits: 2 })} years old`;
            }
            setAgeOfPropertyValue(ageOfProperty);
            setAgeOfPropertyText(ageOfPropertyText);
            // filter days on market
            if (ageOfProperty !== null) {
                filteredProperties.forEach((property, index) => {
                    let propertyAge = YEAR_TODAY - parseInt(property.yearBuilt)
                    if (propertyAge > min_ageOfProperty) {
                        if (max_ageOfProperty < MAX_AGE_OF_PROPERTY_RANGE) {
                            if (propertyAge < max_ageOfProperty) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                filteredProperties = newList;
            }
        } else {
            // filter ageOfProperty only if value is not null
            let min_ageOfProperty = ageOfPropertyValue[0];
            let max_ageOfProperty = ageOfPropertyValue[1];
            if (min_ageOfProperty > 0 || max_ageOfProperty < MAX_AGE_OF_PROPERTY_RANGE) {
                numAllFilters += 1;
                numMoreFilters += 1;
                filteredProperties.forEach((property, index) => {
                    if (property.daysOnZillow > min_ageOfProperty) {
                        if (max_ageOfProperty < MAX_AGE_OF_PROPERTY_RANGE) {
                            if (property.daysOnZillow < max_ageOfProperty) {
                                newList.push(property);
                            }
                        } else {
                            newList.push(property);
                        }
                    }
                })
                filteredProperties = newList;
            }
        }

        if (numAllFilters > 0) {
            setAllFiltersTitle(`Filters (${numAllFilters})`);
        } else {
            setAllFiltersTitle('Filters');
        }
        
        if (numMoreFilters > 0) {
            setNumMoreFiltersTitle(`More (${numMoreFilters})`);
        } else {
            setNumMoreFiltersTitle('More');
        }

        setFilteredPropertiesLength(filteredProperties.length);
        
        if (label !== "mobile") {
            // reduce latency: only show returns if close up but STILL show the TOP returning properties
            if (defaults.mapZoomedOut === true) {
                if (filteredProperties.length > 75) {
                    filteredProperties = filteredProperties.splice(0, 75);
                }
            } else {
                setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
            }

            // sort & set properties
            setProperties(filteredProperties);
            setSelectedProperty(null);
        }
    }

    const handleMaxHOA = (maxHoa) => {
        setMaxHOAValue(maxHoa);
        // set text
        if (maxHoa === 0) {
            setMaxHOAText('Any');
        } else if (maxHoa === 500) {
            setMaxHOAText('$500+');
        } else {
            setMaxHOAText(`$${maxHoa}`);
        }
    }

    const handleResetReturns = () => {
        setReturnsTitle(`Returns`);
        setCashFlowText('Any');
        setCashFlowValue(-1000);
        setCapRateText('Any');
        setCapRateValue(0);
        setTotalReturnText('Any');
        setTotalReturnValue(0);
        
        let newPropertyList = allFunctions.MasterQuicksort(defaults.propertiesInBounds, sortTitle);
        
        // Filter bed/bath
        let minBeds = (isNaN(bed)) ? parseInt(bed.slice(0, 1)) : bed;
        let minBaths = (isNaN(bath)) ? parseInt(bath.slice(0, bath.length - 1)) : bath;

        let newList = [];
        if (useExact) {
            newPropertyList.forEach((property) => {
                if (property.bedrooms === minBeds && property.bathrooms >= minBaths) {
                    newList.push(property);
                }
            })
        } else {
            newPropertyList.forEach((property) => {
                if (property.bedrooms >= minBeds && property.bathrooms >= minBaths) {
                    newList.push(property);
                }
            })
        }
        newPropertyList = newList;
        
        // filter home type
        if (homeTypeTitle !== "Home Type") {
            newList = [];
            newPropertyList.forEach((property) => {
                if (homeTypes.includes(property.propertyTypeDimension)) {
                    newList.push(property);
                }
            })
            newPropertyList = newList;
        };

        setFilteredPropertiesLength(newPropertyList.length);

        // reduce latency: only show returns if close up but STILL show the TOP returning properties
        if (defaults.mapZoomedOut === true) {
            if (newPropertyList.length > 75) {
                newPropertyList = newPropertyList.splice(0, 75);
            }
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }

        setProperties(newPropertyList);
    }
    
    const handleResetAllFiltersMobile = () => {
        // reset returns
        setReturnsTitle(`Returns`);
        setCashFlowText('Any');
        setCashFlowValue(-1000);
        setCapRateText('Any');
        setCapRateValue(0);
        setTotalReturnText('Any');
        setTotalReturnValue(0);
        // reset title
        setAllFiltersTitle('Filters');
        // reset price
        setPriceTitle('Price');
        setPriceValue([0, 1500000]);
        setPriceText('Any');
        // reset bed bath
        setBed(0);
        setBath(0);
        setUseExact(false);
        // reset property type
        setHomeTypes(allHomeTypes);
        setSelectAllButton("Deselect All");
        handleFilter(allHomeTypes, '', 'home type');
        setHomeTypeTitle('Home Type');
        // reset keywords
        setKeywords('');
        setFilterDescription(false);
        // reset hoa
        setMaxHOAValue(0);
        setMaxHOAText('Any');
        setNoHOA(false);
        // reset days on market
        setDaysOnMarketValue([0, MAX_DAYS_ON_MARKET_RANGE]);
        setDaysOnMarketText('Any');
        // reset days on market
        setAgeOfPropertyValue([0, MAX_AGE_OF_PROPERTY_RANGE]);
        setAgeOfPropertyText('Any');
        // reset include pending
        setIncludePending(false);
        
        // Sort properties
        let newPropertyList = allFunctions.MasterQuicksort(defaults.propertiesInBounds, sortTitle);
        setFilteredPropertiesLength(newPropertyList.length);

        // reduce latency: only show returns if close up but STILL show the TOP returning properties
        if (defaults.mapZoomedOut === true) {
            if (newPropertyList.length > 75) {
                newPropertyList = newPropertyList.splice(0, 75);
            }
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }

        setProperties(newPropertyList);
    }
    
    const handleResetPrice = () => {
        setPriceTitle('Price');
        setPriceValue([0, 1500000]);
        setPriceText('Any');
        
        // Sort properties
        let newPropertyList = allFunctions.MasterQuicksort(defaults.propertiesInBounds, sortTitle);

        // Filter cash flow
        let newList = [];
        if (cashFlowValue !== -1000) {
            newPropertyList.forEach((property, index) => {
                if (property.cashFlow > cashFlowValue) {
                    newList.push(property);
                }
            })
            newPropertyList = newList;
        }
        
        // Filter cap rate
        newList = [];
        if (capRateValue > 0) {
            newPropertyList.forEach((property, index) => {
                if (property.capRate > capRateValue) {
                    newList.push(property);
                }
            })
            newPropertyList = newList;
        }
        
        // Filter total return
        newList = [];
        if (totalReturnValue > 0) {
            newPropertyList.forEach((property, index) => {
                if (property.totalReturn > totalReturnValue) {
                    newList.push(property);
                }
            })
            newPropertyList = newList;
        }
        
        // Filter bed/bath
        let minBeds = (isNaN(bed)) ? parseInt(bed.slice(0, 1)) : bed;
        let minBaths = (isNaN(bath)) ? parseInt(bath.slice(0, bath.length - 1)) : bath;

        newList = [];
        if (useExact) {
            newPropertyList.forEach((property) => {
                if (property.bedrooms === minBeds && property.bathrooms >= minBaths) {
                    newList.push(property);
                }
            })
        } else {
            newPropertyList.forEach((property) => {
                if (property.bedrooms >= minBeds && property.bathrooms >= minBaths) {
                    newList.push(property);
                }
            })
        }
        newPropertyList = newList;
        
        // filter home type
        if (homeTypeTitle !== "Home Type") {
            newList = [];
            newPropertyList.forEach((property) => {
                if (homeTypes.includes(property.propertyTypeDimension)) {
                    newList.push(property);
                }
            })
            newPropertyList = newList;
        };

        setFilteredPropertiesLength(newPropertyList.length);

        // reduce latency: only show returns if close up but STILL show the TOP returning properties
        if (defaults.mapZoomedOut === true) {
            if (newPropertyList.length > 75) {
                newPropertyList = newPropertyList.splice(0, 75);
            }
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }

        setProperties(newPropertyList);
    }

    const handleResetBedBath = () => {
        let filteredProperties = defaults.propertiesInBounds;
        setBedBathTitle('Bed/Bath');
        setBed(0);
        setBath(0);
        setUseExact(false);
        
        // sort & filter cash flow
        let newList = [];
        if (cashFlowValue !== -1000) {
            filteredProperties.forEach((property, index) => {
                if (property.cashFlow > cashFlowValue) {
                    newList.push(property);
                }
            })
            filteredProperties = newList;
        }

        // Filter cap rate & total return
        newList = [];
        if (capRateValue !== null) {
            filteredProperties.forEach((property, index) => {
                if (property.capRate > capRateValue && property.totalReturn > totalReturnValue) {
                    newList.push(property);
                }
            })
            filteredProperties = newList;
        }

        // filter home type
        if (homeTypeTitle !== "Home Type") {
            newList = [];
            filteredProperties.forEach((property) => {
                if (homeTypes.includes(property.propertyTypeDimension)) {
                    newList.push(property);
                }
            })
            filteredProperties = newList;
        };

        setFilteredPropertiesLength(filteredProperties.length);
        
        // reduce latency: only show returns if close up but STILL show the TOP returning properties
        if (defaults.mapZoomedOut === true) {
            if (filteredProperties.length > 75) {
                filteredProperties = filteredProperties.splice(0, 75);
            }
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }
        
        // set properties
        setProperties(filteredProperties);
    }

    function Mobile() {
        const [width, setWidth] = useState(window.innerWidth);

        function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        const isMobile = width <= 768;
        return isMobile
    }

    const handleFilterDescription = async (filterDescription) => {
        let newFilterDescription = null;
        if (filterDescription) {
            newFilterDescription = false
            setFilterDescription(newFilterDescription);
        } else {
            newFilterDescription = true
            setFilterDescription(newFilterDescription);
        }

        let allProperties = [];
        
        // get low and high lat/lng
        let latitude = defaults.mapBounds.latitude;
        let longitude = defaults.mapBounds.longitude;

        try {
            let { data, error, status } = await supabase
                .from('propertyData')
                .select(`
                    zpid, address, bedrooms, bathrooms, price, imgSrc,
                    latitude, longitude, rentEstimate, rentEstimateSource,
                    monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                    livingArea, listingAgent, brokerageName, homeStatus, yearBuilt,
                    daysOnZillow ${newFilterDescription ? ', description' : ''}
                `)
                .gt('latitude', latitude.lo)
                .gt('longitude', longitude.lo)
                .lt('latitude', latitude.hi)
                .lt('longitude', longitude.hi)

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                allProperties = data
            } else {
                console.log("No data!")
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            handleBoundsChange(allProperties, defaults.mapBounds, defaults.mapCenter, defaults.mapZoom)
        }
    }
    
    const updateFavorites = async (newFavList, userId) => {
        // set favorites from supabase
        try {
            const updates = {
                id: userId ? userId : session.user.id,
                updated_at: new Date(),
                saved_properties: newFavList,
            }
            let table = userId && userId.includes('client-id') ? 'client_favorites' : 'favorites';
            let { error } = await supabase.from(table).upsert(updates);

            if (error) throw error
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleFavorite = async (property, status, setStatus, userId) => {
        // show modal if not logged in/signed up
        if (session === null) {
            setLoginHeader('Sign up to save a property');
            setLoginOrSignup('Sign up');
            setOpenLogin(true);
        } else {
            let newFavList = favoritesList;
            let newFavListFull = favoritesListFull;
            if (status) {
                // removes from favorites
                newFavList = newFavList.filter(data => data !== property.zpid); // remove property
                newFavListFull = newFavListFull.filter(data => data.zpid !== property.zpid); // remove property
            } else {
                // adds to favorites
                if (newFavList.length === 0) {
                    newFavList.push(property.zpid);
                    newFavListFull.push(property);
                } else {    
                    let exists = newFavList.filter(data => data === property.zpid);
                    if (exists.length === 0) {
                        newFavList.push(property.zpid);
                        newFavListFull.push(property);
                    }
                }
            }
            // set icon and data
            setFavoritesList(newFavList);
            setFavoritesListFull(newFavListFull);
            if (setStatus) {
                setStatus(!status);
            }
            updateFavorites(newFavList, userId);
        }
    }

    const handleUseExact = () => {
        if (useExact) {
            setUseExact(false);
            setNumBeds(['1+', '2+', '3+', '4+', '5+'])
            let beds = bed;
            setBed(`${beds}+`)
            setBedBathTitle(`Bd: ${beds}, Bth: ${bath}`);
            handleFilter(beds, 'not exact', 'bed');
        } else {
            setUseExact(true);
            setNumBeds([1, 2, 3, 4, 5]);
            let beds = parseInt(bed.slice(0, 1));
            setBed(beds);
            setBedBathTitle(`Bd: ${beds}, Bth: ${bath}`);
            handleFilter(beds, 'use exact', 'bed');
        }
    }

    const handleSelectAll = () => {
        if (selectAllButton === "Select All") {
            setHomeTypes(allHomeTypes);
            setSelectAllButton("Deselect All");
            handleFilter(allHomeTypes, '', 'home type')
            setHomeTypeTitle('Home Type')
        } else {
            setHomeTypes([]);
            setSelectAllButton("Select All");
            handleFilter([], '', 'home type')
            setHomeTypeTitle('Home Type')
        }
    }

    const handleFavoritesBtn = async () => {
        // create zpid .or() string for supabase sql pull
        let zpidString = '';
        if (favoritesList.length === 1) {
            zpidString = `zpid.eq.${favoritesList[0]}`;
        } else if (favoritesList.length > 1) {
            zpidString = `zpid.eq.${favoritesList[0]}`;
            favoritesList.map(function (zpid) {
                return (
                    zpidString = `${zpidString},zpid.eq.${zpid}`
                )
            })
        }
        
        setLoadingComps(true);
        setShowFavorites(true);
        // pull from database
        try {
            if (zpidString !== '') {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, address, bedrooms, bathrooms, price, imgSrc,
                        latitude, longitude, rentEstimate, rentEstimateSource,
                        monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                        livingArea, homeStatus, yearBuilt, datePostedString,
                        listingAgent, brokerageName
                    `)
                    .or(zpidString);
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    // add returns to properties
                    let compProperties = allFunctions.AddReturnsToProperties(data, defaults, propertyEdits);
                    compProperties = allFunctions.AddPricePerSqFt(compProperties);
                    compProperties = allFunctions.MasterQuicksort(compProperties, sortTitle);
                    // set full data
                    setFavoritesListFull(compProperties);
                    setCompFavListFull(compProperties);
                }
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingComps(false);
        }
    }
    
    const handleCompareFavorites = async () => {
        // only show the selected properties
        setOpenCompareFavsModal(true);
        let allFavProperties = favoritesListFull;
        let compareFavoritesListFull = []
        allFavProperties.forEach(prop => {
            if (compareFavoritesList.includes(prop.zpid)) {
                compareFavoritesListFull.push(prop);
            }
        })
        setCompFavListFull(compareFavoritesListFull);
    }

    const handleResetAllFilters = () => {
        // reset cash flow
        setReturnsTitle('Returns');
        setCashFlowText('Any');
        setCashFlowValue(-1000);
        // reset cap rate
        setCapRateText('Any');
        setCapRateValue(0);
        // reset total return
        setTotalReturnText('Any');
        setTotalReturnValue(0);
        // reset price
        setPriceTitle('Price');
        setPriceValue([0, 1500000]);
        setPriceText('Any');
        // reset bed bath
        setUseExact(false);
        setBedBathTitle('Bed/Bath');
        setBed(0);
        setBath(0);
        setNumBeds(['1+', '2+', '3+', '4+', '5+'])
        // reset property type
        setHomeTypes(allHomeTypes);
        setSelectAllButton("Deselect All");
        handleFilter(allHomeTypes, '', 'home type');
        setHomeTypeTitle('Home Type');
        // reset keywords
        setKeywords('');
        setFilterDescription(false);
        // reset hoa
        setNumMoreFiltersTitle('More');
        setMaxHOAValue(0);
        setMaxHOAText('Any');
        setNoHOA(false);
        // reset days on market
        setDaysOnMarketValue([0, MAX_DAYS_ON_MARKET_RANGE]);
        setDaysOnMarketText('Any');

        // Reset all properties
        let newPropertyList = allFunctions.MasterQuicksort(defaults.propertiesInBounds, sortTitle);
        setFilteredPropertiesLength(newPropertyList.length);

        // reduce latency: only show returns if close up but STILL show the TOP returning properties
        if (defaults.mapZoomedOut === true) {
            if (newPropertyList.length > 75) {
                newPropertyList = newPropertyList.splice(0, 75);
            }
        } else {
            setDefaults((prevState) => { return { ...prevState, mapZoomedOut: false } });
        }

        setProperties(newPropertyList);
        setReturnsTitle('Returns');
    }

    const handleCloseProperty = () => {
        let currentURL = window.location.href;
            let urlArray = currentURL.split("/search");
            let newURL = `${urlArray[0]}/search`;
            window.history.pushState('page2', 'Title', newURL);
        setShow(false);
    }

    // Current filters for "Save Search"
    class HotMarkets {
        constructor(cityName, latLng) {
            this.cityName = cityName;
            this.latLng = latLng;
        }
    }

    const columbus = new HotMarkets("Columbus, OH", { "lat": 39.961175,"lng": -82.998794 });
    const memphis = new HotMarkets("Memphis, TN", { "lat": 35.1495343, "lng": -90.0489801 });
    const oklahomaCity = new HotMarkets("OKC, OK", { "lat": 35.4675602, "lng": -97.5164276 });
    const indianapolis = new HotMarkets("Indianapolis, IN", { "lat": 39.768403, "lng": -86.158068 });
    const hotMarkets = [columbus, memphis, oklahomaCity, indianapolis];

    // Current filters for "Save Search"
    class CurrentFilters {
        constructor(priceValue, bed, bath, homeTypes, noHOA, maxHOAValue, keywords, daysOnMarketText, daysOnMarketValue, ageOfPropertyText, ageOfPropertyValue) {
          this.price = (priceValue[0] === 0 && priceValue[1] === 1500000) ? 'Any' : priceValue;
          this.bedBath = (bed === 0 && bath === 0) ? 'Any' : [bed, bath];
          this.propertyType = homeTypes.length === 4 ? 'Any' : homeTypes;
          this.hoa = noHOA ? 'noHOA' : (maxHOAValue === 0 ? 'Any' : maxHOAValue);
          this.keywords = keywords === '' ? 'Any' : keywords;
          this.daysOnMarket = daysOnMarketText === 'Any' ? 'Any' : daysOnMarketValue;
          this.ageOfProperty = ageOfPropertyText === 'Any' ? 'Any' : ageOfPropertyValue;
        }
    }

    const getCurrentFilters = () => {
        // load into class
        const currentFilters = new CurrentFilters(priceValue, bed, bath, homeTypes, noHOA, maxHOAValue, keywords, daysOnMarketText, daysOnMarketValue, ageOfPropertyText, ageOfPropertyValue);
        return currentFilters;
    }

    const handleOpenPreferences = async () => {
        setShow(false);
        setOpenExploreFeaturesModal(false);
        setGlobalState('runTour', false);
        setGlobalState('openDefaultModal', true);
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                shown_pref_modal: true,
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) throw error
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleCloseFeaturesModal = async () => {
        setOpenExploreFeaturesModal(false);
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                shown_pref_modal: true,
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) throw error
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleApplyYearsProjected = async () => {
        if (session) {
            // save to supabase
            try {
                const updates = {
                    id: session.user.id,
                    years_projected: parseFloat(defaults.yearsProjected)
                }

                let { error } = await supabase.from('defaults').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        } else {
            notifyErrorMsg('User must be logged in to save changes.');
        }
        // save new cash flow/cap rate/total return to property object

    }

    const handleYearsProjected = (e) => {
        let newYearsProjected = e;
        if (/[0-9](([0-8](\.[0-9]*)?)|[0-9])?/.test(newYearsProjected)) {
            newYearsProjected = parseInt(newYearsProjected);
            if (newYearsProjected < 100) {
                setDefaults((prevState) => { return { ...prevState, yearsProjected: e } });
            }
        }
    }

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    const handleSaveAppreciation = async () => {
        if (session) {
            // save to supabase
            try {
                const updates = {
                    id: session.user.id,
                    rent_appreciation: parseFloat(defaults.rentAppr),
                    expenses_appreciation: parseFloat(defaults.expensesAppr)
                }

                let { error } = await supabase.from('defaults').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        } else {
            notifyErrorMsg('User must be logged in to save changes.');
        }
    }

    const handleSaveAppreciationChanges = () => {
        setOpenAdjustsProjectionsModal(false);
        handleApplyYearsProjected();
    }

    const handleOpenProjectionsModal = () => {
        setOpenAdjustsProjectionsModal(true);
        setTimeout(function() { handleSelectInput("years-projected") }, 200);
    }

    const handleViewProperties = () => {
        // filters properties
        handleFilter('', '', '');
        setOpenAllFiltersMobile(false);
    }

    const handleCloseLogin = () => {
        setOpenLogin(false);
        setTimeout(() => { setLoginHeader("Sign up"); }, 200)
    }

    const getClientPropertyEdits = async (userId) => {
        try {
            let { data, error, status } = await supabase
                .from('client')
                .select(`property_edits`)
                .eq('id', userId)

            if (error && status !== 406) throw error

            if (data && status !== 406) {
                if (data[0].hasOwnProperty('property_edits')) {
                    setPropertyEdits(data[0].property_edits);
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleSetClient = (newClient) => {
        // loading to true
        setLoading(true);
        setLoadingClient(true);
        setActiveClient(newClient);
        // loading to false
        setTimeout(function() { setLoadingClient(false); }, 800);
        setTimeout(function() { setLoading(false); }, 800);
        // pull & set edits, defaults, and favorites
        getClientPropertyEdits(newClient.id);
        getFavoritesInit(newClient.id);
        getDefaults(newClient.id);
    }


    return (
        <div>

            {/* Login modal */}
            <Modal size={'md'} centered={true} show={openLogin} onHide={() => handleCloseLogin()} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Body>
                    <SignupComponent
                        setOpenLogin={setOpenLogin}
                        loginOrSignup={loginOrSignup}
                        setLoginOrSignup={setLoginOrSignup}
                        handleCloseLogin={handleCloseLogin}
                        loginHeader={loginHeader}
                        setLoginHeader={setLoginHeader}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Explore features modal */}
            <Modal size={'md'} centered={true} show={openExploreFeaturesModal} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header>
                    <img src={logo} alt="logo_icon" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col justify-center items-center mt-3'>
                        <p className='text-3xl font-black tracking-tighter'>Did you know?</p>
                        <p className='text-xl text-center font-semibold mt-8'>You can set your default values for all properties on the map?</p>
                        <div className='mt-6 flex justify-center cursor-pointer'>
                            <img
                                alt="logo"
                                src={set_defaults}
                                onClick={() => handleOpenPreferences()}
                                style={{width: '90%'}}
                            />
                        </div>
                        <div className='lg:flex lg:justify-center lg:gap-1 mx-4 my-3'>
                            <button
                                style={{width: isMobile ? '100%' : '200px'}}
                                onClick={() => handleCloseFeaturesModal()}
                                className='mt-4 py-2.5 text-lg rounded-lg font-semibold hover:bg-light-gray cursor-pointer'
                            >
                                Not now
                            </button>
                            <button
                                style={{width: isMobile ? '100%' : '200px'}}
                                onClick={() => handleOpenPreferences()}
                                className='mt-4 py-2.5 bg-bot-blue text-lg text-white rounded-lg font-semibold hover:bg-dark-bot-blue cursor-pointer'
                            >
                                Set preferences
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            
            {/* Agent modal */}
            <Modal size={'md'} centered={true} show={openAgentModal} onHide={() => setOpenAgentModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <ContactForm property="____" button="Agent" role="an Agent" interest="interested in" />
                </Modal.Body>
            </Modal>
            
            {/* Contact team modal */}
            <Modal size={'md'} centered={true} show={openContactTeamModal} onHide={() => setOpenContactTeamModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <ContactTeam />
                </Modal.Body>
            </Modal>
            
            {/* All filters for mobile */}
            <Modal size={'lg'} centered={true} show={openAllFiltersMobile} onHide={() => setOpenAllFiltersMobile(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className='pt-2 px-2.5'>
                        <div className='flex justify-between'>
                            <p className='text-3xl font-bold tracking-tighter'>All Filters</p>
                            <Button
                                variant='outline-secondary'
                                onClick={handleResetAllFiltersMobile}
                                className='rounded-md py-2 px-3 bg-light-gray text-black hover:text-black hover:bg-light-gray-2'
                            >
                                Reset All
                            </Button>
                        </div>
                        <hr className='border-gray mt-2' style={{borderWidth: '1px'}} />
                        <div className='flex flex-col justify-center items-start gap-8 mb-16'>
                            <div className='px-1 mt-8 flex justify-start gap-3' style={{width: '100%'}}>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='font-bold mt-1'
                                >
                                    <span className='text-lg font-semibold'>Cash flow min:</span>
                                </Typography>
                                <div className='px-1 mt-1'>
                                    <CurrencyInput
                                        id="cash-flow"
                                        onClick={() => handleSelectInput("cash-flow")}
                                        className="flex text-xl w-20 pl-1 border-one rounded-sm"
                                        prefix="$"
                                        value={cashFlowValue}
                                        decimalsLimit={0}
                                        onValueChange={(e) => setCashFlowValue((e === undefined) ? 0 : parseInt(e))}
                                        onBlur={() => handleFilter()}
                                    />
                                </div>
                            </div>
                            <div className='px-1 flex justify-start gap-3' style={{width: '100%'}}>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='font-bold mt-1'
                                >
                                    <span className='text-lg font-semibold'>Cap rate min:</span>
                                </Typography>
                                <div className='px-1 mt-1'>
                                    <CurrencyInput
                                        id="cap-rate"
                                        onClick={() => handleSelectInput("cap-rate")}
                                        className="flex text-xl w-20 pl-1 border-one rounded-sm"
                                        suffix="%"
                                        value={capRateValue}
                                        decimalsLimit={2}
                                        onValueChange={(e) => setCapRateValue((e === undefined) ? 0 : parseFloat(e))}
                                        onBlur={() => handleFilter()}
                                    />
                                </div>
                            </div>
                            <div className='px-1 flex justify-start gap-3' style={{width: '100%'}}>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='font-bold mt-1'
                                >
                                    <span className='text-lg font-semibold'>Total return min:</span>
                                </Typography>
                                <div className='px-1 mt-1'>
                                    <CurrencyInput
                                        id="total-return"
                                        onClick={() => handleSelectInput("total-return")}
                                        className="flex text-xl w-20 pl-1 border-one rounded-sm"
                                        suffix="%"
                                        value={totalReturnValue}
                                        decimalsLimit={2}
                                        onValueChange={(e) => setTotalReturnValue((e === undefined) ? 0 : parseFloat(e))}
                                        onBlur={() => handleFilter()}
                                    />
                                </div>
                            </div>
                            <div className='px-1 -mt-6' style={{width: '100%'}}>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='font-bold mt-3'
                                >
                                    <span className='text-lg'><span className='font-semibold'>Price</span>: {priceText}</span>
                                </Typography>
                                <div className='px-1'>
                                    <Slider
                                        value={priceValue}
                                        min={0}
                                        step={20000}
                                        max={MAX_PRICE_RANGE}
                                        onChange={e => handleFilter(e, "mobile", "price")}
                                    />
                                </div>
                            </div>
                            <div className='-mt-4'>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                >
                                    <span className='text-lg'><span className='font-semibold'>Bedrooms</span></span>
                                </Typography>
                                <ButtonGroup className="me-2" aria-label="First group">
                                    {numBeds.map(function (numBed) {
                                        return (
                                            <BedButtons
                                                numBed={numBed}
                                                bed={bed}
                                                setBed={setBed}
                                                bath={bath}
                                                setBedBathTitle={setBedBathTitle}
                                                handleFilter={handleFilter}
                                            />
                                        )
                                    })}
                                </ButtonGroup>
                                <div className='ml-2'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={useExact} />
                                        }
                                        label="Use exact beds"
                                        onClick={handleUseExact}
                                    />
                                </div>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='mt-1'
                                >
                                    <span className='text-lg'><span className='font-semibold'>Bathrooms</span></span>
                                </Typography>
                                <ButtonGroup className="me-2" aria-label="First group">
                                    {numBaths.map(function (numBath) {
                                        return (
                                            <BathButtons
                                                numBath={numBath}
                                                bath={bath}
                                                setBath={setBath}
                                                bed={bed}
                                                setBedBathTitle={setBedBathTitle}
                                                handleFilter={handleFilter}
                                            />
                                        )
                                    })}
                                </ButtonGroup>
                            </div>
                            <div>
                                <Typography
                                    gutterBottom
                                    className='mb-2'
                                >
                                    <span className='text-lg'><span className='font-semibold'>Property Type</span></span>
                                </Typography>
                                <FormGroup>
                                    <Button
                                        size="sm"
                                        onClick={handleSelectAll}
                                        className='text-bot-blue w-40 py-2 mb-2'
                                    >
                                        {selectAllButton}
                                    </Button>
                                    {allHomeTypes.map(function (type) {
                                        return (
                                            <SelectHomeType
                                                allHomeTypes={allHomeTypes}
                                                homeTypes={homeTypes}
                                                setHomeTypes={setHomeTypes}
                                                type={type}
                                                setSelectAllButton={setSelectAllButton}
                                                handleFilter={handleFilter}
                                                setHomeTypeTitle={setHomeTypeTitle}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>
                            <div className='-mt-2' style={{width: '100%'}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={filterDescription}
                                            onChange={() => handleFilterDescription(filterDescription)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={<p className={`font-semibold text-lg ${filterDescription ? 'text-black' : 'text-gray'}`}>Keywords in description</p>}
                                />
                                <Form.Control
                                    type="text"
                                    style={{width: '100%'}}
                                    disabled={!filterDescription}
                                    className='h-12 text-xl border-dark-gray text-dark-gray'
                                    placeholder='e.g. mother-in-law, unfinished basement'
                                    value={keywords}
                                    onChange={e => handleFilter(e, 'mobile', "keywords")}
                                />
                                <p className={`pr-1 mt-1 -mb-1 text-gray text-base ${filterDescription ? '' : 'hidden'}`}>
                                    Separate multiple keywords with commas
                                </p>
                            </div>
                            <div className='mt-2' style={{width: '100%'}}>
                                <div>
                                    <Typography
                                        id="non-linear-slider"
                                        gutterBottom
                                        className={(noHOA) ? 'text-light-gray-4 w-60' : 'text-black w-60'}
                                    >
                                        <span className="text-lg"><span className='font-semibold'>Max HOA</span>: {maxHOAText}</span>
                                    </Typography>
                                    <div className='px-2'>
                                        <Slider
                                            disabled={noHOA}
                                            value={typeof maxHOAValue === 'number' ? maxHOAValue : 0}
                                            min={0}
                                            step={25}
                                            max={500}
                                            onChange={e => handleFilter(e, 'mobile', "hoa")}
                                            aria-labelledby="input-slider"
                                        />
                                    </div>
                                </div>
                                <div className='ml-4 -mt-2'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={noHOA} size="small" />
                                        }
                                        label="No HOA"
                                        onChange={() => handleFilter(!noHOA, '', "noHoa")}
                                    />
                                </div>
                            </div>
                            {/* Days on market */}
                            <div className='-mt-3 mb-4' style={{width: '100%'}}>
                                <Typography
                                    gutterBottom
                                    className='mb-2'
                                >
                                    <span className="text-lg"><span className='font-semibold'>Days on market</span>: {daysOnMarketText}</span>
                                </Typography>
                                <div className='px-2'>
                                    <Slider
                                        value={daysOnMarketValue}
                                        min={0}
                                        step={5}
                                        max={MAX_DAYS_ON_MARKET_RANGE}
                                        onChange={e => handleFilter(e, 'mobile', "daysOnMarket")}
                                    />
                                </div>
                            </div>
                            {/* Age of property */}
                            <div className='mb-4 -mt-5' style={{width: '100%'}}>
                                <Typography
                                    gutterBottom
                                    className='mb-2'
                                >
                                    <span className="text-lg"><span className='font-semibold'>Age of property</span>: {ageOfPropertyText}</span>
                                </Typography>
                                <div className='px-2'>
                                    <Slider
                                        value={ageOfPropertyValue}
                                        min={0}
                                        step={5}
                                        max={MAX_AGE_OF_PROPERTY_RANGE}
                                        onChange={e => handleFilter(e, 'mobile', "ageOfProperty")}
                                    />
                                </div>
                            </div>
                            {/* Age of property */}
                            <div className='mb-7 -mt-5' style={{width: '100%'}}>
                                <Typography
                                    id="non-linear-slider"
                                    gutterBottom
                                    className='text-black'
                                >
                                    <span className='font-semibold text-lg'>Property status</span>
                                </Typography>
                                <div className='px-2 text-lg'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={includePending}
                                                onChange={() => setIncludePending(!includePending)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={<p>Include pending</p>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`flex justify-center items-center`}>
                        <div className='fixed bottom-0 lg:mb-16' style={{ width: '95%' }}>
                            <div className='pb-4 bg-white'>
                                <hr className='border-gray mb-2' style={{ borderWidth: '1px' }} />
                                <div className='px-2'>
                                    <Button
                                        onClick={() => handleViewProperties()}
                                        style={{width: '100%'}}
                                        className='rounded-md py-3 bg-bot-blue text-white hover:text-white hover:bg-dark-bot-blue'
                                    >
                                        View {filteredPropertiesLength} properties
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Property modal */}
            <Modal className='lg:-mt-3' size={'xl'} centered={true} show={show} onHide={() => handleCloseProperty()} contentClassName={(allFunctions.Mobile) ? "" : "property-modal-height"}>
                <Modal.Body className={(Mobile) ? "" : "h-screen"}>
                    <Property
                        properties={properties}
                        selectedProperty={selectedProperty}
                        defaults={defaults}
                        setSelectedProperty={setSelectedProperty}
                        setShow={setShow}
                        scrollToTop={scrollToTop}
                        propertyEdit={propertyEdit}
                        propertyEdits={propertyEdits}
                        setPropertyEdits={setPropertyEdits}
                        setPropertyEdit={setPropertyEdit}
                        session={session}
                        userId={userIdFromURL}
                        propertyId={propertyIdFromURL}
                        prefModalShown={prefModalShown}
                        handleOpenExploreFeaturesModal={handleOpenExploreFeaturesModal}
                        favoritesList={favoritesList}
                        handleFavorite={handleFavorite}
                        setOpenLogin={setOpenLogin}
                        setLoginHeader={setLoginHeader}
                        activeClient={activeClient}
                        clients={clients}
                    />
                </Modal.Body>
            </Modal>

            {/* Defaults modal */}
            <Modal size={'lg'} show={useGlobalState('openDefaultModal')[0]} onHide={() => setGlobalState('openDefaultModal', false)}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <Default
                        defaults={defaults}
                        setDefaults={setDefaults}
                        setOpenLogin={setOpenLogin}
                        setLoginHeader={setLoginHeader}
                        client={activeClient}
                        setClient={setActiveClient}
                    />
                </Modal.Body>
            </Modal>

            {/* Compare Favorites Modal */}
            <Modal size={'lg'} centered={true} show={openCompareFavsModal} onHide={() => setOpenCompareFavsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <CompAnalysis compProperties={compFavListFull} defaults={defaults} />
                </Modal.Body>
            </Modal>

            {/* Favorites modal */}
            <Modal size={'xl'} centered={true} className='lg:-mt-3' show={showFavorites} onHide={() => setShowFavorites(false)}>
                <Modal.Header closeButton className='mt-header'>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body style={{height: (Mobile) ? '' : '90vh'}}>
                    <div className={isMobile ? 'm-1' : 'm-4'}>
                        <div className='lg:pl-2'>
                            <div className='flex justify-start items-center text-3xl font-bold tracking-tighter mb-3'>
                                <p className='mr-2'>{activeClient && activeClient.name !== 'Personal' ? `${activeClient.name}'s` : 'My'}</p>
                                <p>Favorites</p>
                            </div>
                            <div className='flex justify-start gap-3 my-3'>
                                <Tooltip title="Compare favorite properties">
                                    <p
                                        onClick={() => setCompareFavorites(!compareFavorites)}
                                        className={`py-2 px-3 rounded-xl hover:bg-light-gray-2 cursor-pointer text-lg ${compareFavorites ? 'bg-light-gray-2' : ''}`}
                                    >
                                        <FontAwesomeIcon icon={faCodeCompare} className={`text-xl font-black pr-2.5`} />
                                        Compare
                                    </p>
                                </Tooltip>
                                <div>
                                    <Tooltip title="Sort favorite properties">
                                        <p
                                            id="demo-positioned-button"
                                            aria-haspopup="true"
                                            className={`py-2 px-3 rounded-xl hover:bg-light-gray-2 cursor-pointer text-lg`}
                                            onClick={handleClickSortFavs}
                                        >
                                            <FontAwesomeIcon icon={faSortAmountDown} className={`text-xl font-black pr-2.5`} />
                                            {sortCompareFavoritesTitle === '' ? 'Sort' : 'Sort: '}
                                            {(isMobile && sortCompareFavoritesTitle !== '') ? '(1)' : sortCompareFavoritesTitle}
                                        </p>
                                    </Tooltip>
                                    <Menu
                                        id="demo-positioned-menu"
                                        anchorEl={anchorEl}
                                        open={openSortFavs}
                                        onClose={() => setAnchorEl(null)}
                                        className='mt-1.5 ml-1.5'
                                    >
                                        <MenuItem key='cash-flow' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Cash Flow")}>Cash Flow</MenuItem>
                                        <MenuItem key='cap-rate' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Cap Rate')}>Cap Rate</MenuItem>
                                        <MenuItem key='total-return' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Total Return')}>Total Return</MenuItem>
                                        <MenuItem key='price-per-sf' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price/Sq Ft")}>Price/Sq Ft</MenuItem>
                                        <MenuItem key='price-asc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (low to high)")}>Price (low to high)</MenuItem>
                                        <MenuItem key='price-desc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (high to low)")}>Price (high to low)</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        {(favoritesList.length !== 0)
                        ?   <div>
                                <div className='flex justify-start gap-8 mt-8 flex-wrap width-favorites'>
                                    {(loadingComps) ? (
                                        favoritesList.map(function (index) {
                                            return (
                                                <Skeleton
                                                    width={320}
                                                    height={230}
                                                    animation="wave"
                                                    variant="rectangular"
                                                    className='rounded mt-2'
                                                />
                                            )
                                        })
                                    ) : (
                                        (favoritesListFull.length === 0) ? (
                                            <NoHomes page="Favorites" />
                                        ) : (
                                            favoritesListFull.map(function (property) {
                                                return (
                                                    <FavoriteCard
                                                        property={property}
                                                        favoritesList={favoritesList}
                                                        setFavoritesList={setFavoritesList}
                                                        setSelectedProperty={setSelectedProperty}
                                                        setShowFavorites={setShowFavorites}
                                                        setShow={setShow}
                                                        updateFavorites={updateFavorites}
                                                        defaults={defaults}
                                                        favoritesListFull={favoritesListFull}
                                                        setFavoritesListFull={setFavoritesListFull}
                                                        propertyEdits={propertyEdits}
                                                        setPropertyEdit={setPropertyEdit}
                                                        compareFavorites={compareFavorites}
                                                        compareFavoritesList={compareFavoritesList}
                                                        setCompareFavoritesList={setCompareFavoritesList}
                                                        compareFavoritesListLength={compareFavoritesListLength}
                                                        setCompareFavoritesListLength={setCompareFavoritesListLength}
                                                    />
                                                )
                                            })
                                        )
                                    )}
                                </div>
                                <div className={`flex justify-center items-center ${compareFavorites ? '' : 'hidden'}`}>
                                    <div className='fixed bottom-0 lg:mb-16 shadow-xl shadow-gray rounded-xl'>
                                        {compareFavoritesListLength > 1 ? (
                                            <p
                                                onClick={() => handleCompareFavorites()}
                                                className='text-center w-96 px-3 py-4 bg-bot-blue hover:bg-dark-bot-blue cursor-pointer text-2xl font-bold rounded-xl text-white'
                                            >
                                                <FontAwesomeIcon icon={faCodeCompare} className={`font-black pr-3`} />
                                                Compare {compareFavoritesListLength} properties
                                            </p>
                                        ) : (
                                            <p className='text-center w-96 px-3 py-4 bg-light-bot-blue text-xl font-bold rounded-xl text-white'>
                                                Select 2+ properties to compare
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        : <NoHomes page="Favorites" />
                        }
                    </div>
                </Modal.Body>
            </Modal>

            {/* Saved searches modal */}
            <Modal size={'lg'} centered={true} show={openSavedSearchesModal} onHide={() => setOpenSavedSearchesModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <SavedSearchesModal session={session} />
                </Modal.Body>
            </Modal>

            {/* Set defaults modal - pops up after 10 seconds! */}
            {/* Look more into getting/setting the cookie: https://stackoverflow.com/questions/45214686/show-a-website-entry-modal-only-one-time */}
            <Modal size={'lg'} centered={true} show={useGlobalState('openDefaultReminderModal')[0]} onHide={() => setGlobalState('openDefaultReminderModal', false)}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <SetDefaults />
                </Modal.Body>
            </Modal>

            {/* Product Tour */}
            <Joyride
                continuous
                hideCloseButton
                scrollToFirstStep
                showProgress
                showSkipButton
                run={useGlobalState('runTour')[0]}
                steps={steps}
                styles={{
                    options: {
                        textColor: 'black'
                    }
                }}
            />

            {/* Adjust projections modal */}
            <Modal size={'md'} centered={true} show={openAdjustsProjectionsModal} onHide={() => setOpenAdjustsProjectionsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col justify-start items-center mb-3'>
                        <p className='text-center text-3xl font-extrabold tracking-tight mb-4'>Adjust projections</p>
                        <div className={`bg-light-gray rounded-xl p-3 ${isMobile ? 'w-80' : 'w-96'}`}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>Years projected:</p>
                                    <img data-tip data-for="yearsProjected" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='yearsProjected' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Years projected</p>
                                            <p className='mt-3'>The number of years you want to project into the future. For example, cash flow today could be $100 but a 10-year projection shows cash flow at $1,000</p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="years-projected"
                                    onClick={() => handleSelectInput("years-projected")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    value={defaults.yearsProjected}
                                    decimalsLimit={1}
                                    onValueChange={(e) => handleYearsProjected(e)}
                                    onBlur={() => handleApplyYearsProjected()}
                                />
                            </div>
                            <div className='flex justify-between items-center mt-3'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>{isMobile ? 'Rent appr. rate:' : 'Rent appreciation rate:'}</p>
                                    <img data-tip data-for="rentAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='rentAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Rent Appreciation Rate</p>
                                            <p className='mt-3'>The rent appreciation rate is the rate at which rent increase year-over-year. Between 2010 and 2020, rents increased by 2.6% annually. Between 2020 and 2022, rents rose 10.5%.</p>
                                            <p className='text-gray mt-3'>
                                                Ex: With a rent appreciation rate of {defaults.rentAppr}%, rent will go from $1,000 this year to ${Math.round(1000 * (1 + (defaults.rentAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="rent-appreciation"
                                    onClick={() => handleSelectInput("rent-appreciation")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    suffix='%'
                                    value={defaults.rentAppr}
                                    decimalsLimit={1}
                                    onValueChange={(e) => setDefaults((prevState) => { return { ...prevState, rentAppr: (e === undefined) ? 0 : e } })}
                                    onBlur={() => handleSaveAppreciation()}
                                />
                            </div>
                            <div className='flex justify-between items-center mt-3'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>{isMobile ? 'Expense appr. rate:' : 'Expense appreciation rate:'}</p>
                                    <img data-tip data-for="expensesAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='expensesAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Expense Appreciation Rate</p>
                                            <p className='mt-3'>The expense appreciation rate is the rate at which expenses (operating expenses) increase year-over-year. Your mortgage will stay the same unless you refinance. Operating expenses will increase 2-3% on average closely following inflation.</p>
                                            <p className='text-gray mt-3'>
                                                Ex: With an expense appreciation rate of {defaults.expensesAppr}%, your operating expenses will go from $500 this year to ${Math.round(500 * (1 + (defaults.expensesAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="expense-appreciation"
                                    onClick={() => handleSelectInput("expense-appreciation")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    suffix='%'
                                    value={defaults.expensesAppr}
                                    decimalsLimit={1}
                                    onValueChange={(e) => setDefaults((prevState) => { return { ...prevState, expensesAppr: (e === undefined) ? 0 : e } })}
                                    onBlur={() => handleSaveAppreciation()}
                                />
                            </div>
                        </div>
                        <div className={`flex justify-center items-center mt-4`}>
                            <button
                                onClick={() => {handleSaveAppreciationChanges()}}
                                className={`${isMobile ? 'w-80' : 'w-96'} py-2.5 text-center text-white text-xl rounded-xl bg-bot-blue hover:bg-dark-bot-blue`}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            
            {/* Future projections button */}
            <div className='flex justify-center items-center'>
                <div className='fixed bottom-0 z-50'>
                    <div
                        onClick={() => handleOpenProjectionsModal()}
                        className={isMobile ? "flex justify-center gap-2 mb-5 px-3 py-2 text-base bg-black hover:bg-[#252525] text-white cursor-pointer rounded-full shadow-md shadow-gray" : "hidden"}
                    >
                        <div className="flex justify-center gap-0.5 p-1.5">
                            <p>{defaults.yearsProjected} year projection</p>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-sm ml-2 mt-1 text-white"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Home page */}
            <div className='hide-element mb-2 lg:mx-4 small-ml flex justify-between'>
                <div className='flex justify-start'>
                    <div className='hide-element flex'>
                        <SearchBar
                            session={session}
                            setDefaults={setDefaults}
                            setOpenSignUp={setOpenLogin}
                            propertyEdits={propertyEdits}
                            defaults={defaults}
                            setShow={setShow}
                            setMapCache={setMapCache}
                            setSelectedProperty={setSelectedProperty}
                            setPropertyEdit={setPropertyEdit}
                        />
                    </div>
                    {/* Filters */}
                    <div className='flex lift-on-mobile lg:-mt-1.5 lg:ml-3'>
                        <div className='flex' id={isMobile ? 'not-step-two' : 'step-two'}>
                            {/* Returns filter */}
                            <div>
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    variant='secondary-outline'
                                    title={
                                        <span className='text-dark-gray hover:underline'>
                                            {returnsTitle}
                                            <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                                        </span>
                                    }
                                    className="drop-shadow-2xl rounded-md z-50"
                                >
                                    <Box sx={{ width: 250 }} className="pt-1 pb-1">
                                        <div className='px-4 pt-2'>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                            >
                                                Cash Flow: {cashFlowText}
                                            </Typography>
                                            <div className='px-1'>
                                                <Slider
                                                    value={typeof cashFlowValue === 'number' ? cashFlowValue : 0}
                                                    min={-1000}
                                                    step={30}
                                                    max={1000}
                                                    onChange={e => handleFilter(e, '', "cashFlow")}
                                                    aria-labelledby="input-slider"
                                                />
                                            </div>
                                        </div>
                                        <div className='px-4 pt-2'>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                            >
                                                Cap Rate: {capRateText}
                                            </Typography>
                                            <div className='px-1'>
                                                <Slider
                                                    value={typeof capRateValue === 'number' ? capRateValue : 0}
                                                    min={0}
                                                    step={0.2}
                                                    max={20}
                                                    onChange={e => handleFilter(e, '', "capRate")}
                                                    aria-labelledby="input-slider"
                                                />
                                            </div>
                                        </div>
                                        <div className='px-4 pt-2'>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                            >
                                                Total Return: {totalReturnText}
                                            </Typography>
                                            <div className='px-1'>
                                                <Slider
                                                    value={typeof totalReturnValue === 'number' ? totalReturnValue : 0}
                                                    min={0}
                                                    step={0.5}
                                                    max={50}
                                                    onChange={e => handleFilter(e, '', "totalReturn")}
                                                    aria-labelledby="input-slider"
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            onClick={handleResetReturns}
                                            className='border-none py-2 px-3 mt-3 ml-3 rounded-md text-black font-medium hover:bg-light-gray-2 hover:text-black hover:no-underline'
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                </DropdownButton>
                            </div>
                            {/* Price filter */}
                            <div className='ml-2'>
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    variant='secondary-outline'
                                    title={
                                        <span className='text-dark-gray hover:underline'>
                                            {priceTitle}
                                            <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                                        </span>
                                    }
                                    className="border-bot-blue drop-shadow-2xl rounded-md z-50">
                                    <Box sx={{ width: 275 }} className="pt-1 pb-1">
                                        <div className='px-4 pt-2.5'>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                            >
                                                Price: {priceText}
                                            </Typography>
                                            <div className='px-1'>
                                                <Slider
                                                    value={priceValue}
                                                    min={0}
                                                    step={20000}
                                                    max={MAX_PRICE_RANGE}
                                                    onChange={e => handleFilter(e, '', "price")}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            onClick={handleResetPrice}
                                            className='border-none py-2 px-3 mt-3 ml-3 rounded-md text-black font-medium hover:bg-light-gray-2 hover:text-black hover:no-underline'
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                </DropdownButton>
                            </div>
                            {/* Bed/Bath filter */}
                            <div className='hide-element'>
                                <p className='flex justify-center text-sm lg:hidden'>Bed/Bath</p>
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    variant='secondary-outline'
                                    title={
                                        <span className='text-dark-gray hover:underline'>
                                            {bedBathTitle}
                                            <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                                        </span>
                                    }
                                    className="lg:ml-2 border-bot-blue drop-shadow-2xl rounded-md z-50"
                                >
                                    <Box sx={{ width: 275 }} className="pt-1 pb-1">
                                        <div className='pl-4 pt-2'>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                            >
                                                Bedrooms
                                            </Typography>
                                            <ButtonGroup className="me-2" aria-label="First group">
                                                {numBeds.map(function (numBed) {
                                                    return (
                                                        <BedButtons
                                                            numBed={numBed}
                                                            bed={bed}
                                                            setBed={setBed}
                                                            bath={bath}
                                                            setBedBathTitle={setBedBathTitle}
                                                            handleFilter={handleFilter}
                                                        />
                                                    )
                                                })}
                                            </ButtonGroup>
                                            <div className='ml-2'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={useExact} />
                                                    }
                                                    label="Use exact beds"
                                                    onClick={handleUseExact}
                                                />
                                            </div>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                                className='mt-2'
                                            >
                                                Bathrooms
                                            </Typography>
                                            <ButtonGroup className="me-2" aria-label="First group">
                                                {numBaths.map(function (numBath) {
                                                    return (
                                                        <BathButtons
                                                            numBath={numBath}
                                                            bath={bath}
                                                            setBath={setBath}
                                                            bed={bed}
                                                            setBedBathTitle={setBedBathTitle}
                                                            handleFilter={handleFilter}
                                                        />
                                                    )
                                                })}
                                            </ButtonGroup>
                                        </div>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            onClick={handleResetBedBath}
                                            className='border-none py-2 px-3 mt-3 ml-3 rounded-md text-black font-medium hover:bg-light-gray-2 hover:text-black hover:no-underline'
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                </DropdownButton>
                            </div>
                            <div>
                                <p className='flex justify-center text-sm lg:hidden'>Property Type</p>
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    variant='secondary-outline'
                                    title={
                                        <span className='text-dark-gray hover:underline'>
                                            {homeTypeTitle}
                                            <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                                        </span>
                                    }
                                    className="ml-2 border-bot-blue drop-shadow-2xl rounded-md z-50"
                                >
                                    <Box sx={{ width: 200 }} className="pt-1 pb-1">
                                        <div className='pt-2'>
                                            <FormGroup>
                                                <Button
                                                    size="sm"
                                                    onClick={handleSelectAll}
                                                    className='mx-4 text-bot-blue py-2 mb-3'
                                                >
                                                    {selectAllButton}
                                                </Button>
                                                <div className='pl-2.5'>
                                                    {allHomeTypes.map(function (type) {
                                                        return (
                                                            <SelectHomeType
                                                                allHomeTypes={allHomeTypes}
                                                                homeTypes={homeTypes}
                                                                setHomeTypes={setHomeTypes}
                                                                type={type}
                                                                setSelectAllButton={setSelectAllButton}
                                                                handleFilter={handleFilter}
                                                                setHomeTypeTitle={setHomeTypeTitle}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Box>
                                </DropdownButton>
                            </div>
                            {/* More filters */}
                            <DropdownButton
                                title={
                                    <span className='text-dark-gray hover:underline'>
                                        {numMoreFiltersTitle}
                                        <FontAwesomeIcon icon={faAngleDown} className="text-md ml-2 mt-1 text-dark-gray" />
                                    </span>
                                }
                                id="dropdown-basic-button"
                                variant='secondary-outline'
                                className="ml-2 drop-shadow-2xl rounded-md z-50 hide-element border-none"
                            >
                                <Box sx={{ width: 340 }} className="pt-2 pb-1 px-1.5">
                                    <p className='text-xl font-bold tracking-tight text-center text-dark-gray mb-2'>More filters</p>
                                    <hr className='text-xl text-gray mb-3 mx-2' />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={filterDescription}
                                                onChange={() => handleFilterDescription(filterDescription)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={<p className={`font-semibold ${filterDescription ? 'text-black' : 'text-gray'}`}>Keywords in description</p>}
                                        className='ml-2'
                                    />
                                    <Form.Control
                                        disabled={!filterDescription}
                                        className='h-9 w-72 pl-2.5 ml-1 mx-3 text-xl border-dark-gray text-dark-gray'
                                        type="text"
                                        placeholder='e.g. mother-in-law, unfinished basement'
                                        value={keywords}
                                        onChange={e => handleFilter(e, '', "keywords")}
                                    />
                                    <p className={`mt-1 -mb-2 px-1 pl-2 mx-2.5 text-gray text-sm ${filterDescription ? '' : 'hidden'}`}>
                                        Separate multiple keywords with commas
                                    </p>
                                    <hr className='text-xl text-gray mt-4 mb-2 mx-2' />
                                    <div className='px-3 py-2'>
                                        <div>
                                            <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                                className={(noHOA) ? 'text-light-gray-4 w-60' : 'text-black w-60'}
                                            >
                                                <span className='font-semibold'>Max HOA</span>: {maxHOAText}
                                            </Typography>
                                            <div className='px-2'>
                                                <Slider
                                                    disabled={noHOA}
                                                    value={typeof maxHOAValue === 'number' ? maxHOAValue : 0}
                                                    min={0}
                                                    step={25}
                                                    max={500}
                                                    onChange={e => handleFilter(e, '', "hoa")}
                                                    aria-labelledby="input-slider"
                                                />
                                            </div>
                                        </div>
                                        <div className='ml-4 -mt-1'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={noHOA} size="small" />
                                                }
                                                label="No HOA"
                                                onChange={() => handleFilter(!noHOA, '', "noHoa")}
                                            />
                                        </div>
                                    </div>
                                    <hr className='text-xl text-gray mb-3 mx-2' />
                                    {/* Days on market */}
                                    <div className='mx-3'>
                                        <Typography
                                            id="non-linear-slider"
                                            gutterBottom
                                            className='text-black'
                                        >
                                            <span className='font-semibold'>Days on market</span>: {daysOnMarketText}
                                        </Typography>
                                        <div className='px-2'>
                                            <Slider
                                                value={daysOnMarketValue}
                                                min={0}
                                                step={5}
                                                max={MAX_DAYS_ON_MARKET_RANGE}
                                                onChange={e => handleFilter(e, '', "daysOnMarket")}
                                            />
                                        </div>
                                    </div>
                                    <hr className='text-xl text-gray mt-3 mb-4 mx-2' />
                                    {/* Age of property */}
                                    <div className='mx-3 mt-3'>
                                        <Typography
                                            id="non-linear-slider"
                                            gutterBottom
                                            className='text-black'
                                        >
                                            <span className='font-semibold'>Age of property</span>: {ageOfPropertyText}
                                        </Typography>
                                        <div className='px-2'>
                                            <Slider
                                                value={ageOfPropertyValue}
                                                min={0}
                                                step={5}
                                                max={MAX_AGE_OF_PROPERTY_RANGE}
                                                onChange={e => handleFilter(e, '', "ageOfProperty")}
                                            />
                                        </div>
                                    </div>
                                    <hr className='text-xl text-gray mt-3 mb-4 mx-2' />
                                    {/* Property status */}
                                    <div className='mx-3 mt-3'>
                                        <Typography
                                            id="non-linear-slider"
                                            gutterBottom
                                            className='text-black'
                                        >
                                            <span className='font-semibold'>Property status</span>
                                        </Typography>
                                        <div className='px-2'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={includePending}
                                                        onChange={() => setIncludePending(!includePending)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                label={<p>Include pending</p>}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex justify-center items-center'>
                                        <Button
                                            size="md"
                                            variant="link"
                                            style={{width: '100%'}}
                                            onClick={() => handleResetAllFilters()}
                                            className='py-2 mx-3 mt-4 mb-2.5 bg-light-gray-2 rounded-md text-black font-medium hover:bg-light-gray-3 hover:text-black hover:no-underline'
                                        >
                                            Reset All Filters
                                        </Button>
                                    </div>
                                </Box>
                            </DropdownButton>
                        </div>
                        <p className='text-2xl font-thin text-gray mx-3'>|</p>
                        <p
                            id={isMobile ? 'not-step-one' : 'step-one'}
                            style={{height: '38px'}}
                            className={`px-3 pt-1.5 text-white rounded-xl lg:text-base font-bold cursor-pointer bg-bot-blue hover:bg-dark-bot-blue`}
                            onClick={() => setGlobalState('openDefaultModal', true)}
                        >
                            <FontAwesomeIcon icon={faSliders} className='mr-2' />
                            Preferences
                        </p>
                    </div>
                </div>
                <div className='flex justify-center font-semibold -mt-1'>
                    <div className={clients.length > 0 ? 'flex justify-center items-center' : 'hidden'}>
                        <ClientDropdown
                            session={session}
                            clients={clients}
                            activeClient={activeClient}
                            handleSetClient={handleSetClient}
                        />
                    </div>
                    <div className={loadingClient ? 'flex justify-center items-center' : 'hidden'}>
                        <img src={loading_spinner} className='h-8 text-center text-white' alt='Loading...' />
                    </div>
                </div>
            </div>

            {/* For Mobile View */}
            <div className='lg:hidden flex justify-between items-center -mt-4' id={isMobile ? 'step-two' : 'not-step-two'}>
                <p
                    id={isMobile ? 'step-one' : 'not-step-one'}
                    style={{height: '38px'}}
                    className={`${searching ? 'hidden' : ''} px-1.5 ml-1 pt-1.5 -mr-1.5 rounded-sm font-semibold text-base cursor-pointer hover:bg-light-gray-2 ${allFiltersTitle.length > 8 ? 'underline decoration-4' : ''}`}
                    onClick={() => setOpenAllFiltersMobile(true)}
                >
                    Filters
                </p>
                <div className='lg:hidden flex justify-center mt-3 mb-2' style={{width: '100%'}}>
                    <SearchBar
                        setDefaults={setDefaults}
                        setSearching={setSearching}
                    />
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={() => setSearching(false)}
                        style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        className={searching ? 'text-xl hover:bg-light-gray-2 rounded-full py-2 mr-2.5 -mt-1' : 'hidden'}
                    />
                </div>
                <p
                    id={isMobile ? 'step-one' : 'not-step-one'}
                    style={{height: '38px'}}
                    className={`${searching ? 'hidden' : ''} pl-1.5 pr-2.5 -ml-1.5 pt-1.5 rounded-sm text-base font-semibold cursor-pointer hover:bg-light-gray-2`}
                    onClick={() => setGlobalState('openDefaultModal', true)}
                >
                    Preferences
                </p>
            </div>
            <div className='lg:flex overflow-hidden mr-2 -mb-5'>
                {/* Map */}
                <div id="map" className='mr-2' style={{ height: '100%', width: '100%' }}>
                    <Map
                        properties={properties}
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                        setShow={setShow}
                        defaults={defaults}
                        setDefaults={setDefaults}
                        handleBoundsChange={handleBoundsChange}
                        session={session}
                        setOpenSignUp={setOpenLogin}
                        propertyEdits={propertyEdits}
                        setPropertyEdit={setPropertyEdit}
                        filterDescription={filterDescription}
                        getCurrentFilters={getCurrentFilters}
                        sortTitle={sortTitle}
                        setOpenSavedSearchesModal={setOpenSavedSearchesModal}
                        setSearching={setSearching}
                        handleApplyYearsProjected={handleApplyYearsProjected}
                        handleOpenProjectionsModal={handleOpenProjectionsModal}
                        setLoginHeader={setLoginHeader}
                    />
                </div>
                {/* Home List Items */}
                <div className='lg:home-list -mb-36'>
                    {/* Home List Header */}
                    <div className='ml-2' style={{fontSize: isMobile ? '15px' : ''}}>
                        <div className='flex justify-between'>
                            <div className={`flex justify-center ${isMobile ? 'mt-3' : ''}`}>
                                <p className={`text-2xl font-semibold tracking-tight ml-2`}>Investment Properties</p>
                                <p
                                    onClick={() => handleOpenProjectionsModal()}
                                    className={`${isMobile ? 'hidden' : ''} text-2xl font-semibold tracking-tight ml-2 cursor-pointer px-1 rounded-xl`}
                                >
                                    (<u className='hover:bg-light-gray rounded-xl'>{defaults.yearsProjected} year</u> projection)
                                </p>
                            </div>
                        </div>
                        <div className='lg:flex lg:justify-between lg:items-center'>
                            <div className={`flex  mt-1.5 ml-2 pb-1 mb-1 ${isMobile ? 'mt-3 mb-1 mr-2 justify-between' : 'mt-3 mr-8 justify-start'}`}>
                                <p className={`whitespace-nowrap`}>{filteredPropertiesLength} {`${isMobile ? 'props' : 'properties'}`}</p>
                                <p className={`text-lg font-thin text-gray -mt-1 ${isMobile ? 'ml-1' : 'mx-4'}`}>|</p>
                                <button
                                    id='step-six'
                                    onClick={handleFavoritesBtn}
                                    className={`lg:hidden text-black tracking-wide -mt-1.5 lg:py-1.5 ${isMobile ? 'px-2' : 'px-3'} rounded-lg hover:bg-light-gray-2`}
                                >
                                    {isMobile ? `Favs` : `${favoritesList.length} ${favoritesList.length === 1 ? 'Favorite' : 'Favorites'}`}
                                </button>
                                <p className={`lg:hidden text-lg font-thin text-gray -mt-1 ${isMobile ? 'mr-1' : 'mx-4'}`}>|</p>
                                <div className='flex justify-center pt-2 -mt-2' id='step-five'>
                                    <SortDropdown
                                        sortTitle={sortTitle}
                                        setSortTitle={setSortTitle}
                                        sortProperties={sortProperties}
                                    />
                                </div>
                            </div>
                            <div className={isMobile ? 'hidden' : 'mr-2'}>
                                <button
                                    id='step-six'
                                    onClick={handleFavoritesBtn}
                                    className={`text-black tracking-wide -mt-1.5 lg:py-2 rounded-xl ${isMobile ? 'px-2' : 'px-3'} ${favoritesList.length > 0 ? 'font-bold' : ''} hover:bg-light-gray-2`}
                                >
                                    {isMobile ? `Favorites` : `${favoritesList.length} ${favoritesList.length === 1 ? 'Favorite' : 'Favorites'}`}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Property Tiles */}
                    <div className={isMobile ? '' : 'home-list-height overflow-scroll'}>
                        <div className='flex justify-around flex-wrap w-home-list'>
                            {(loading) ? (
                                eight.map(function (num, index) {
                                    return (
                                        <Skeleton
                                            width={320}
                                            height={230}
                                            animation="wave"
                                            variant="rectangular"
                                            className='rounded-xl mt-2'
                                        />
                                    )
                                })
                            ) : ((filteredPropertiesLength > 0) ? (
                                properties.map(function (property, index) {
                                    return (index < 24)
                                        ? <PropertyTile
                                                session={session}
                                                property={property}
                                                setSelectedProperty={setSelectedProperty}
                                                setShow={setShow}
                                                defaults={defaults}
                                                hideCashFlow={hideCashFlow}
                                                hideCapRate={hideCapRate}
                                                hideCoCReturn={hideCoCReturn}
                                                hideTotalReturn={hideTotalReturn}
                                                propertyEdits={propertyEdits}
                                                setPropertyEdit={setPropertyEdit}
                                                index={index}
                                                setOpenSignUp={setOpenLogin}
                                                setLoginHeader={setLoginHeader}
                                            />
                                        : '';
                                })
                            ) : (
                                (suggestNewMarket) ? (
                                    <div className='lg:flex lg:justify-center'>
                                        <SuggestNewMarket
                                            disableSuggestNewMarket={disableSuggestNewMarket}
                                            setOpenContactTeamModal={setOpenContactTeamModal}
                                        />
                                        <div className={`text-xl mx-2 ${isMobile ? 'mt-4 mb-40' : 'mt-28'}`}>
                                            <p className='text-2xl text-center font-black tracking-tighter'>Check out these</p>
                                            <p className='text-2xl text-center font-black pb-2 tracking-tighter'>hot markets🔥</p>
                                            <div className='lg:flex lg:justify-around lg:items-center lg:flex-wrap' style={{width: isMobile ? '100%' : '300px'}}>
                                                {hotMarkets.map(function (market, index) {
                                                    return (
                                                        <p
                                                            style={{width: isMobile ? '100%' : '140px'}}
                                                            onClick={() => setDefaults((prevState) => { return { ...prevState, mapCenter: market.latLng } })}
                                                            className='py-3 px-1 mt-3 text-lg mr-1 font-semibold tracking-tight bg-light-gray text-center rounded-xl cursor-pointer hover:bg-light-gray-2'
                                                        >
                                                            {market.cityName}
                                                        </p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <NoHomes />
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
