import React, { Fragment, useEffect, useState } from 'react';
import logo_icon from '../assets/logo_icon.png';
import { supabase } from '../supabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCodeCompare, faSliders, faSortAmountDown,
    faPencil, faTrashAlt, faXmark, faUserGroup, faUser, faEnvelope, faPhone, faPlus, faEnvelopesBulk
} from '@fortawesome/free-solid-svg-icons';
import { Drawer, FormControlLabel, Menu, MenuItem, Skeleton, Switch, Tooltip } from '@mui/material';
import { Card, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import DashboardPropertyCard from '../components/DashboardPropertyCard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalState, useGlobalState } from '../state';
import allFunctions from './Functions';
import CompAnalysis from './CompAnalysis';
import Default from './Default';
import SharePortfolio from '../components/SharePortfolio';
import Property from './Property';
import SignupComponent from '../components/SignupComponent';
import AddClient from '../components/AddClient';
import ClientOnboarding from '../components/ClientOnboarding';
import no_homes from '../assets/no_homes.png';
import pulse from '../assets/pulse.png';
import ClientInfo from '../components/ClientInfo';
import info_popup from '../assets/info_popup.jpg';
import dashboard_walkthrough from '../assets/dashboard_walkthrough.gif';
import buyer_dashboard_walkthrough from '../assets/buyer_dashboard_walkthrough.gif';
import ReactTooltip from 'react-tooltip';
import EditClient from '../components/EditClient';
import Conversation from '../components/Conversation';
import NoHomes from '../components/NoHomes';
import FavoriteCard from '../components/FavoriteCard';
import AgentCard from '../components/AgentCard';
import CircleImage from '../components/CircleImage';
import ContactAgent from '../components/ContactAgent';
import SendBulkEmail from '../components/SendBulkEmail';

export default function Dashboard() {

    // set agent session
    let initSession = localStorage.getItem("sb-fusszpkrdwzhyaeqxlvv-auth-token");
    if (initSession) {
        initSession = JSON.parse(initSession);
    }
    const [session, setSession] = useState(initSession);

    // URL parameter queries
    const queryParameters = new URLSearchParams(window.location.search);
    const propertyIdFromURL = queryParameters.get("id");
    const userIdFromURL = queryParameters.get("user");
    const agentIdFromURL = queryParameters.get("agent");
    const clientFromURL = queryParameters.get("client");
    const inviteCodeFromURL = queryParameters.get("invitecode");
    const agentSignupFromURL = queryParameters.get("agentsignup");
    const portfolioCodeFromURL = queryParameters.get("portfoliocode");

    // local vars
    const [clients, setClients] = useState([]);
    const [activeClient, setActiveClient] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [loadingFavs, setLoadingFavs] = useState(true);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [clientSignup, setClientSignup] = useState(false);
    const [clientSignupEmail, setClientSignupEmail] = useState('');
    const [agentView, setAgentView] = useState(false);
    const [initAgentSignup, setInitAgentSignup] = useState(true);
    const [conversation, setConversation] = useState([]);
    const [viewActivity, setViewActivity] = useState(false);
    const [viewLeads, setViewLeads] = useState(false);
    const [leads, setLeads] = useState([]);
    const [loadingComps, setLoadingComps] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [agentProfilePic, setAgentProfilePic] = useState('default');
    const [agentName, setAgentName] = useState('');
    const [agentEmail, setAgentEmail] = useState('');
    const [agentPhone, setAgentPhone] = useState('');
    const [clientLead, setClientLead] = useState(null);
    
    const [activity, setActivity] = useState([]);
    const [loadingActivity, setLoadingActivity] = useState(false);
    const [favoritesList, setFavoritesList]= useState([]);
    const [favoritesListFull, setFavoritesListFull]= useState(null);
    const [propertyEdit, setPropertyEdit]= useState(null);
    const [propertyEdits, setPropertyEdits]= useState(null);
    const [compareFavorites, setCompareFavorites]= useState(false);
    const [compareFavoritesList, setCompareFavoritesList]= useState([]);
    const [compareFavoritesListLength, setCompareFavoritesListLength]= useState(0);
    const [sortCompareFavoritesTitle, setSortCompareFavoritesTitle] = useState('');
    const [compFavListFull, setCompFavListFull] = useState([]);
    // modals
    const [showPropertyModal, setShowPropertyModal]= useState(false);
    const [openAddClientModal, setOpenAddClientModal] = useState(false);
    const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
    const [openCompareFavsModal, setOpenCompareFavsModal]= useState(false);
    const [openSharePortfolioModal, setOpenSharePortfolioModal]= useState(false);
    const [openClientOnboardingModal, setOpenClientOnboardingModal]= useState(false);
    const [openClientInfoModal, setOpenClientInfoModal]= useState(false);
    const [openEditClientModal, setOpenEditClientModal]= useState(false);
    const [openAdjustsProjectionsModal, setOpenAdjustsProjectionsModal]= useState(false);
    const [openPrefModal, setOpenPrefModal]= useState(useGlobalState('openDefaultModal')[0]);
    const [openConversationModal, setOpenConversationModal] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [openAgentCard, setOpenAgentCard] = useState(false);
    const [openAgentModal, setOpenAgentModal] = useState(false);
    const [openBulkEmailsModal, setOpenBulkEmailsModal] = useState(false);
    
    useEffect(() => {
        // if agent and have clients
        const getClients = async (session) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, location, budget,
                        property_types, timeline, shared_with,
                        property_edits, agent_email, agent_id`)
                    .eq('agent_id', `${session.user.id}`)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    let initClients = [];
                    data.forEach(client => {
                        initClients.push({
                            'id': client.id,
                            'name': client.name,
                            'email': client.email,
                            'phone': client.phone,
                            'location': client.location,
                            'budget': client.budget,
                            'propertyTypes': client.property_types,
                            'timeline': client.timeline,
                            'sharedWith': client.shared_with,
                            'propertyEdits': client.property_edits,
                            'agentEmail': client.agent_email
                        })
                    })
                    setClients(initClients);
                    setActiveClient(initClients[0]);
                    setPropertyEdits(initClients[0].propertyEdits);
                    getDefaults(initClients[0].id);
                    getFavoritesInit(initClients[0].id);
                    setAgentView(true);
                    getAgentCard(data[0].agent_id);
                    resetURL();
                } else {
                    // if client
                    checkClient(session, session.user.id);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const resetURL = () => {
            let currentURL = window.location.href;
            let urlArray = currentURL.split("/dashboard");
            let newURL = `${urlArray[0]}/dashboard`;
            window.history.pushState('page2', 'Dashboard', newURL);
            setTimeout(function() { setLoadingFavs(false); }, 800);
        }
        
        const checkClient = async (session, userId) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, location, budget,
                        property_types, timeline, shared_with,
                        property_edits, agent_email, agent_id
                    `)
                    .eq('user_id', `${userId}`)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    let initClient = {
                        'id': data[0].id,
                        'name': data[0].name,
                        'email': data[0].email,
                        'phone': data[0].phone,
                        'location': data[0].location,
                        'budget': data[0].budget,
                        'propertyTypes': data[0].property_types,
                        'timeline': data[0].timeline,
                        'sharedWith': data[0].shared_with,
                        'propertyEdits': data[0].property_edits,
                        'agentEmail': data[0].agent_email
                    }
                    setActiveClient(initClient);
                    setPropertyEdits(initClient.propertyEdits);
                    getDefaults(initClient.id);
                    getFavoritesInit(initClient.id);
                    setAgentView(false);
                    getAgentCard(data[0].agent_id)
                    resetURL();
                }
            } catch (error) {
                console.error(error.message);
            }
        }
        
        const checkCode = async (session) => {
            try {
                let { data, error } = await supabase
                    .from('team')
                    .select(`id, invite_code, domain, agent_emails`)
                    .eq('invite_code', agentSignupFromURL)
                    
                if (error) throw error
        
                if (data && window.location.href.includes(data[0].domain) && !data[0].agent_emails.includes(session.user.email)) {
                    setAgentStatusInDB(session, data[0].agent_emails, data[0].id);
                    getAgentCard(session.user.email);
                }
        
            } catch (error) {
                console.log(error.message);
            }
        }
        
        const checkAdmin = async (session) => {
            try {
                let urlArray = window.location.href.split("/");
                let domain = urlArray[2];
                let { data, error } = await supabase
                    .from('team')
                    .select(`id, admin_email`)
                    .eq('domain', domain)
                    .eq('admin_email', session.user.email)
                    
                if (error) throw error
        
                if (data && data.length > 0) {
                    setIsAdmin(true);
                    handleGetLeads();
                }
        
            } catch (error) {
                console.log(error.message);
            }
        }
        
        const setAgentStatusInDB = async (session, agentEmails, teamId) => {
            if (agentEmails && agentEmails.length > 0) {
                agentEmails.push(session.user.email);
            } else {
                agentEmails = [session.user.email];
            }

            try {
                const updates = {
                    id: teamId,
                    agent_emails: agentEmails,
                }
        
                let { error } = await supabase.from('team').upsert(updates)
        
                if (error) {
                    throw error
                } else {
                    setAgentStatus(session);
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        
        const setAgentStatus = async (session) => {
            try {
                const updates = {
                    id: session.user.id,
                    created_at: new Date(),
                    updated_at: new Date(),
                    email: session.user.email,
                    user_type: "agent"
                }
        
                let { error } = await supabase.from('profiles').upsert(updates)
        
                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }
        
        const setUserType = async (session, userType) => {
            // only set user type if they do not have one
            try {
                let { data, error } = await supabase
                    .from('profiles')
                    .select(`user_type`)
                    .eq('id', session.user.id)
    
                if (error) throw error

                if (data && data.user_type === null) {
                    setNewUserType(session, userType);
                } else if (data.length === 0) {
                    setNewUserType(session, userType);
                }
                
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const setNewUserType = async (session, userType) => {
            try {
                const updates = {
                    id: session.user.id,
                    updated_at: new Date(),
                    email: session.user.email,
                    user_type: userType
                }

                let { error } = await supabase.from('profiles').upsert(updates)
    
                if (error) throw error
            } catch (error) {
                console.error(error.message);
            }
        }

        const getInviteCode = async () => {
            // check against the db
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, location, budget, property_types,
                        property_edits, timeline, agent_id, shared_with,
                        invite_accepted, agent_email`
                    )
                    .eq('invite_code', `${inviteCodeFromURL}`)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data[0].agent_id === agentIdFromURL && data[0].invite_accepted === false) {
                    if (session && session.user.email === data[0].email) {
                        // likely first sign in after account creation
                        let newClient = {
                            'id': data[0].id,
                            'name': data[0].name,
                            'email': data[0].email,
                            'phone': data[0].phone,
                            'location': data[0].location,
                            'budget': data[0].budget,
                            'propertyTypes': data[0].property_types,
                            'timeline': data[0].timeline,
                            'sharedWith': data[0].shared_with,
                            'propertyEdits': data[0].property_edits,
                            'agentEmail': data[0].agent_email
                        }
                        setActiveClient(newClient);
                        setPropertyEdits(newClient.propertyEdits);
                        setProfile();
                        setClientProfile(newClient.id);
                        setTimeout(function() { setOpenClientOnboardingModal(true); }, 100);
                        setUserType(session, "client");
                        getAgentCard(data[0].agent_id);
                    } else if (session === null) {
                        // likely user's first time clicking link, create account!
                        setClientSignup(true);
                        setClientSignupEmail(data[0].email);
                        setInitAgentSignup(false);
                        setTimeout(function() { setOpenLogin(true); }, 50)
                    }
                }
                
            } catch (error) {
                console.error(error.message);
            }
        }

        const checkUser = async () => {
            // delete client shares in supabase
            try {
                let { data, error } = await supabase
                    .from('client_shares')
                    .select(`id, client_id, user_id, confirmed`)
                    .eq('user_email', session.user.email)
                
                if (error) throw error

                if (data && data.length > 0) {
                    handleGetClientPortfolio(data[0].client_id);
                    if (data[0].confirmed === false && data[0].user_id === null) {
                        // add user_id to the existing record and set confirmed to true
                        syncAndConfirmSharedUser(data[0].id, session.user.id);
                    } else if (data[0].user_id !== session.user.id) {
                        // already has a shared account, just show the portfolio but don't save the client
                        notifySuccessMsg('Account already link with another portfolio. You can only view this portfolio with this link.');
                    }
                } else {
                    // likely first sign in after account creation
                    handleGetClientPortfolio(data[0].client_id);
                    handleSharedUserId(data[0].id, session.user.id);
                    setUserType(session, "shared");
                }
                
            } catch (error) {
                console.error(error.message)
            }
        }

        const syncAndConfirmSharedUser = async (id, userId) => {
            try {
                const updates = {
                    id: id,
                    user_id: userId,
                    confirmed: true
                }

                let { error } = await supabase.from('client_shares').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }
        
        const getSharedUser = async () => {
            // check against the db
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select(`id, client_id, user_id, user_email, invite_code, role, confirmed`)
                    .eq('invite_code', inviteCodeFromURL)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    if (session && session.user.email === data[0].user_email) {
                        // likely first sign in after account creation
                        handleGetClientPortfolio(data[0].client_id);
                        handleSharedUserId(data[0].id, session.user.id);
                        setUserType(session, "shared");
                    } else if (session === null) {
                        // likely user's first time clicking link, create account!
                        setClientSignup(true);
                        setClientSignupEmail(data[0].user_email);
                        setInitAgentSignup(false);
                        setLoginHeader('Sign up to view this portfolio');
                        setTimeout(function() { setOpenLogin(true); }, 50);
                    }
                }
                
            } catch (error) {
                console.error(error.message);
            }
        }
        
        const getSharedPortfolio = async () => {
            // check against the db
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`id, name, portfolio_code`)
                    .eq('portfolio_code', portfolioCodeFromURL)
    
                if (error && status !== 406) throw error

                if (data) {
                    if (session) {
                        // likely first sign in after account creation
                        handleGetClientPortfolio(data[0].id);
                        addSharedPortfolioUser(data[0].id, session);
                        setUserType(session, "shared");
                        // reset the URL
                        let urlArray = window.location.href.split("/dashboard");
                        let newURL = `${urlArray[0]}/dashboard`;
                        window.history.pushState('page2', 'Dashboard', newURL);
                    } else {
                        // likely user's first time clicking link, create account!
                        setClientSignup(true);
                        setInitAgentSignup(false);
                        setLoginHeader(`Sign up to view ${data[0].name}'s portfolio`);
                        setTimeout(function() { setOpenLogin(true); }, 50);
                    }
                }
                
            } catch (error) {
                console.error(error.message);
            }
        }
        
        const getSharedUserSession = async (session) => {
            // check against the db
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select(`id, client_id, user_id, user_email, invite_code, role, confirmed`)
                    .eq('user_email', session.user.email)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    if (session && session.user.email === data[0].user_email) {
                        handleGetClientPortfolio(data[0].client_id);
                        if (data[0].confirmed === false) {
                            handleSharedUserId(data[0].id, session.user.id);
                        }
                    }
                }
                
            } catch (error) {
                console.error(error.message);
            }
        }

        const handleGetClientPortfolio = async (clientId) => {
            try {
                let { data, error, status } = await supabase
                    .from('client')
                    .select(`
                        id, name, email, phone, location, budget,
                        property_types, timeline, shared_with,
                        property_edits, agent_email, agent_id
                    `)
                    .eq('id', clientId)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    let initClient = {
                        'id': data[0].id,
                        'name': data[0].name,
                        'email': data[0].email,
                        'phone': data[0].phone,
                        'location': data[0].location,
                        'budget': data[0].budget,
                        'propertyTypes': data[0].property_types,
                        'timeline': data[0].timeline,
                        'sharedWith': data[0].shared_with,
                        'propertyEdits': data[0].property_edits,
                        'agentEmail': data[0].agent_email,
                    }
                    setActiveClient(initClient);
                    setPropertyEdits(initClient.propertyEdits);
                    getDefaults(initClient.id);
                    getFavoritesInit(initClient.id);
                    setAgentView(false);
                    getAgentCard(data[0].agent_id);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const getAgentCard = async (agentId) => {
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select(`name, email, phone`)
                    .eq('id', agentId)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    setAgentName(data[0].name ? data[0].name : '');
                    setAgentEmail(data[0].email ? data[0].email : '');
                    setAgentPhone(data[0].phone ? data[0].phone : '');
                    // Check if the image exists in supabase storage
                    const profileURL = `https://fusszpkrdwzhyaeqxlvv.supabase.co/storage/v1/object/public/profile_pictures/${agentId}`
                    try {
                        const response = await fetch(profileURL);
                        if (response.ok) {
                            setAgentProfilePic(agentId);
                        }
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    // if no data, then it's the first time signing up, so start down the rabbit hole
                    getClients(session);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
        
        const getProfile = async (session) => {
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select(`user_type`)
                    .eq('id', session.user.id)
    
                if (error && status !== 406) {
                    throw error
                }

                if (data && data.length > 0) {
                    if (data[0].user_type === "agent") {
                        setAgentView(true);
                        getClients(session);
                        getAgentCard(session.user.id);
                    } else if (data[0].user_type === "client") {
                        checkClient(session, session.user.id);
                    } else if (data[0].user_type === "shared") {
                        getSharedUserSession(session);
                    }
                } else {
                    // if no data, then it's the first time signing up, so start down the rabbit hole
                    getClients(session);
                }
            } catch (error) {
                console.error(error.message);
            }

            return null;
        }

        const handleSharedUserId = async (shareId, userId) => {
            try {
                const updates = {
                    id: shareId,
                    user_id: userId,
                    confirmed: true
                }

                let { error } = await supabase.from('client_shares').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }

        let submitted = false;
        const addSharedPortfolioUser = async (shareId, session) => {
            // Only add if user has not been added yet
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select(`user_id`)
                    .eq('client_id', shareId)
                    .eq('user_id', session.user.id)
                    .single()
    
                if (error && status !== 406) throw error

                if (((data && data.length === 0) || (data === null)) && submitted === false) {
                    submitted = true;
                    addSharedUser(shareId, session);
                }
            } catch (error) {
                console.error(error.message)
            }
        }

        const addSharedUser = async (shareId, session) => {
            // add user to client_shares
            try {
                const updates = {
                    "client_id": shareId,
                    "user_id": session.user.id,
                    "user_email": session.user.email,
                    "portfolio_code": portfolioCodeFromURL,
                    "role": "Editor",
                    "confirmed": true
                }

                let { error } = await supabase.from('client_shares').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.error(error.message)
            }
        }

        const setProfile = async () => {
            // set favorites to supabase
            try {
                const updates = {
                    id: session.user.id,
                    created_at: new Date(),
                    updated_at: new Date(),
                    email: session.user.email,
                    user_type: "client"
                }

                let { error } = await supabase.from('profiles').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }
        
        const setClientProfile = async (id) => {
            // set favorites to supabase
            try {
                const updates = {
                    id: id,
                    invite_accepted: true
                }

                let { error } = await supabase.from('client').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.log(error.message)
            }
        }

        // session
        supabase.auth.getSession().then(({ data: { session } }) => {
            if (session) {
                setSession(session);
                if (inviteCodeFromURL && agentIdFromURL) {
                    // investor sign up
                    getInviteCode();
                } else if (inviteCodeFromURL && clientFromURL) {
                    // shared user sign up
                    getSharedUser();
                } else if (portfolioCodeFromURL) {
                    // shared user sign up (new way)
                    checkUser()
                } else if (agentSignupFromURL) {
                    // agent sign up
                    checkCode(session);
                    setAgentView(true);
                } else {
                    // normal sign in
                    getProfile(session);
                }
                // getAgentProfilePic(session);
                checkAdmin(session);
            }
            if (inviteCodeFromURL && agentIdFromURL) {
                getInviteCode();
            }
            if (inviteCodeFromURL && clientFromURL) {
                getSharedUser();
            }
            if (portfolioCodeFromURL) {
                getSharedPortfolio();
            }
            setTimeout(function() { setLoadingFavs(false); }, 800)
        })

        // eslint-disable-next-line
    }, []);

    // verify all properties on the favorites list are still on the market
    const verifyFavoritesList = async (initClientId, initFavs) => {
        // create zpid .or() string for supabase sql pull
        let zpidString = '';
        if (initFavs.length === 1) {
            zpidString = `zpid.eq.${initFavs[0]}`;
        } else if (initFavs.length > 1) {
            zpidString = `zpid.eq.${initFavs[0]}`;
            initFavs.map(function (zpid) {
                return (
                    zpidString = `${zpidString},zpid.eq.${zpid}`
                )
            })
        }
        
        // pull from database
        try {
            if (zpidString !== '') {
                let { data, error, status } = await supabase
                    .from('propertyData')
                    .select(`
                        zpid, address, bedrooms, bathrooms, price, imgSrc,
                        latitude, longitude, rentEstimate, rentEstimateSource,
                        monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                        livingArea, homeStatus, yearBuilt, datePostedString,
                        listingAgent, brokerageName
                    `)
                    .or(zpidString);
    
                if (error && status !== 406) {
                    throw error
                }
                
                if (data !== null && status !== 406) {
                    // add returns to properties
                    let compProperties = allFunctions.AddReturnsToProperties(data, defaults, propertyEdits);
                    compProperties = allFunctions.AddPricePerSqFt(compProperties);
                    compProperties = allFunctions.MasterQuicksort(compProperties, sortCompareFavoritesTitle);
                    // set full data
                    setFavoritesListFull(compProperties);

                    // get just zpid's
                    let newFavList = [];
                    compProperties.forEach((prop) => {
                        newFavList.push(prop.zpid);
                    })
                    setFavoritesList(newFavList);
                    // if there is a difference between 'initFavs' and 'newFavList' then call the updateFavorites() function
                    let updateFavs = false;
                    newFavList.forEach(fav => {
                        if (!initFavs.includes(fav)) {
                            updateFavs = true;
                        }
                    })
                    if (updateFavs) {
                        updateFavorites(initClientId, newFavList);
                    }
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    
    // verify property is still on the market
    const verifyProperty = async (propertyId) => {
        // pull from database
        try {
            let { data, error, status } = await supabase
                .from('propertyData')
                .select(`
                    zpid, address, bedrooms, bathrooms, price, imgSrc,
                    latitude, longitude, rentEstimate, rentEstimateSource,
                    monthlyHoaFee, propertyTaxRate, propertyTypeDimension,
                    livingArea, homeStatus, yearBuilt, datePostedString,
                    listingAgent, brokerageName
                `)
                .eq('zpid', propertyId)

            if (error && status !== 406) {
                throw error
            }
            
            if (data && data.length > 0 && status !== 406) {
                let initProperty = allFunctions.AddReturnsToProperties(data, defaults, propertyEdits);
                initProperty = allFunctions.AddPricePerSqFt(initProperty);
                setSelectedProperty(initProperty[0]);
                setTimeout(() => { setShowPropertyModal(true); }, 50);
            } else {
                notifyErrorMsg('Property is no longer listed.')
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // get favorites
    const getFavoritesInit = async (initClientId) => {
        try {
            let table = initClientId.includes('client-id') ? 'client_favorites' : 'favorites';
            let { data, error, status } = await supabase
                .from(table)
                .select(`saved_properties`)
                .eq('id', initClientId)

            if (error && status !== 406) {
                throw error
            }
            if (data !== null && status !== 406) {
                let tempFavList = (data[0].saved_properties === '[]') ? [] : data[0].saved_properties;
                if (tempFavList.length > 0) {
                    if (data[0].saved_properties.slice(0, 12) === '[{"dateSold"') {
                        tempFavList = [];
                        let jsonFavList = JSON.parse(data[0].saved_properties);
                        jsonFavList.map(function (property) {
                            return (
                                tempFavList.push(parseInt(property.zpid))
                            )
                        })
                    }
                    verifyFavoritesList(initClientId, tempFavList);
                } else {
                    setFavoritesList([])
                    setFavoritesListFull([])
                }
            } else if (status === 406) {
                setFavoritesList([])
                setFavoritesListFull([])
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    
    // get favorites
    const getLeadFavorites = async (initClientId) => {
        try {
            let table = initClientId.includes('client-id') ? 'client_favorites' : 'favorites';
            let { data, error, status } = await supabase
                .from(table)
                .select(`saved_properties`)
                .eq('id', initClientId)

            if (error && status !== 406) {
                throw error
            }
            if (data !== null && status !== 406) {
                let tempFavList = (data[0].saved_properties === '[]') ? [] : data[0].saved_properties;
                if (tempFavList.length > 0) {
                    if (data[0].saved_properties.slice(0, 12) === '[{"dateSold"') {
                        tempFavList = [];
                        let jsonFavList = JSON.parse(data[0].saved_properties);
                        jsonFavList.map(function (property) {
                            return (
                                tempFavList.push(parseInt(property.zpid))
                            )
                        })
                    }
                    verifyFavoritesList(initClientId, tempFavList);
                } else {
                    setFavoritesList([])
                    setFavoritesListFull([])
                }
            } else if (status === 406) {
                setFavoritesList([])
                setFavoritesListFull([])
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // get defaults (if any) from supabase
    const getDefaults = async (clientId) => {
        try {
            let table = clientId.includes('client-id') ? 'client_defaults' : 'defaults'
            let { data, error, status } = await supabase
                .from(table)
                .select(`
                    downpayment, downpayment_pct, interest_rate, loan_term, closing_costs, rehab,
                    laundry, storage, misc_income, insurance, mortgage_insurance_pct, repairs,
                    repairs_pct, repairs_type, capital_expenditures, capital_expenditures_pct,
                    capital_expenditures_type, property_management_pct, vacancy_pct, misc_costs,
                    misc_expenses, electricity, gas, sewage, garbage, lawn, sellers_agent_pct,
                    buyers_agent_pct, city, city_appreciation, personal_annual_income, tax_bracket,
                    filing_status, land_value, depreciation_period, downpayment_type, rent_appreciation,
                    expenses_appreciation, years_projected, utilities_paid, closing_costs_pct, closing_costs_type
                `)
                .eq('id', clientId)
                .single()

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                // override data if any
                if (data.downpayment !== null) {setDefaults((prevState) => { return { ...prevState, downpayment: data.downpayment } })}
                if (data.downpayment_pct !== null) {setDefaults((prevState) => { return { ...prevState, downpaymentPct: data.downpayment_pct } })}
                if (data.downpayment_type !== null) {setDefaults((prevState) => { return { ...prevState, downpaymentType: data.downpayment_type } })}
                if (data.interest_rate !== null) {setDefaults((prevState) => { return { ...prevState, interestRate: data.interest_rate } })}
                if (data.loan_term !== null) {setDefaults((prevState) => { return { ...prevState, loanTerm: data.loan_term } })}
                if (data.closing_costs !== null) {setDefaults((prevState) => { return { ...prevState, closingCosts: data.closing_costs } })}
                if (data.closing_costs_pct !== null) {setDefaults((prevState) => { return { ...prevState, closingCostsPct: data.closing_costs_pct } })}
                if (data.closing_costs_type !== null) {setDefaults((prevState) => { return { ...prevState, closingCostsType: data.closing_costs_type } })}
                if (data.rehab !== null) {setDefaults((prevState) => { return { ...prevState, rehab: data.rehab } })}
                if (data.laundry !== null) {setDefaults((prevState) => { return { ...prevState, laundry: data.laundry } })}
                if (data.storage !== null) {setDefaults((prevState) => { return { ...prevState, storage: data.storage } })}
                if (data.misc_income !== null) {setDefaults((prevState) => { return { ...prevState, miscIncome: data.misc_income } })}
                if (data.insurance !== null) {setDefaults((prevState) => { return { ...prevState, insurance: data.insurance } })}
                if (data.mortgage_insurance_pct !== null) {setDefaults((prevState) => { return { ...prevState, mortgageInsurancePct: data.mortgage_insurance_pct } })}
                if (data.repairs !== null) {setDefaults((prevState) => { return { ...prevState, repairs: data.repairs } })}
                if (data.repairs_pct !== null) {setDefaults((prevState) => { return { ...prevState, repairsPct: data.repairs_pct } })}
                if (data.repairs_type !== null) {setDefaults((prevState) => { return { ...prevState, repairsType: data.repairs_type } })}
                if (data.capital_expenditures !== null) {setDefaults((prevState) => { return { ...prevState, capex: data.capital_expenditures } })}
                if (data.capital_expenditures_pct !== null) {setDefaults((prevState) => { return { ...prevState, capexPct: data.capital_expenditures_pct } })}
                if (data.capital_expenditures_type !== null) {setDefaults((prevState) => { return { ...prevState, capexType: data.capital_expenditures_type } })}
                if (data.property_management_pct !== null) {setDefaults((prevState) => { return { ...prevState, propMgnt: data.property_management_pct } })}
                if (data.vacancy_pct !== null) {setDefaults((prevState) => { return { ...prevState, vacancy: data.vacancy_pct } })}
                if (data.misc_costs !== null) {setDefaults((prevState) => { return { ...prevState, miscCosts: data.misc_costs } })}
                if (data.misc_expenses !== null) {setDefaults((prevState) => { return { ...prevState, miscExpenses: data.misc_expenses } })}
                if (data.electricity !== null) {setDefaults((prevState) => { return { ...prevState, electricity: data.electricity } })}
                if (data.gas !== null) {setDefaults((prevState) => { return { ...prevState, gas: data.gas } })}
                if (data.sewage !== null) {setDefaults((prevState) => { return { ...prevState, sewage: data.sewage } })}
                if (data.garbage !== null) {setDefaults((prevState) => { return { ...prevState, garbage: data.garbage } })}
                if (data.lawn !== null) {setDefaults((prevState) => { return { ...prevState, lawn: data.lawn } })}
                if (data.sellers_agent_pct !== null) {setDefaults((prevState) => { return { ...prevState, sellersAgent: data.sellers_agent_pct } })}
                if (data.buyers_agent_pct !== null) {setDefaults((prevState) => { return { ...prevState, buyersAgent: data.buyers_agent_pct } })}
                if (data.city !== null) {setDefaults((prevState) => { return { ...prevState, city: data.city } })}
                if (data.city_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, cityAppr: data.city_appreciation } })}
                if (data.personal_annual_income !== null) {setDefaults((prevState) => { return { ...prevState, personalAnnualIncome: data.personal_annual_income } })}
                if (data.tax_bracket !== null) {setDefaults((prevState) => { return { ...prevState, taxBracket: data.tax_bracket } })}
                if (data.filing_status !== null) {setDefaults((prevState) => { return { ...prevState, filingStatus: data.filing_status } })}
                if (data.land_value !== null) {setDefaults((prevState) => { return { ...prevState, landValue: data.land_value } })}
                if (data.depreciation_period !== null) {setDefaults((prevState) => { return { ...prevState, deprPeriod: data.depreciation_period } })}
                if (data.rent_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, rentAppr: data.rent_appreciation } })}
                if (data.expenses_appreciation !== null) {setDefaults((prevState) => { return { ...prevState, expensesAppr: data.expenses_appreciation } })}
                if (data.years_projected !== null) {setDefaults((prevState) => { return { ...prevState, yearsProjected: data.years_projected } })}
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const latFromURL = queryParameters.get("lat");
    const lngFromURL = queryParameters.get("lng");

    // check local cache for map center and map bounds (on load)
    let currentMapCenter = { // Utah County
        lat: latFromURL ? parseFloat(latFromURL) : 40.312576260874614,
        lng: lngFromURL ? parseFloat(lngFromURL) : -111.71656594695311,
    };
    let currentMapBounds = { // Utah County
        'latitude': {
            'lo': latFromURL ? parseFloat(latFromURL) - 0.052 : 40.205549306017886,
            'hi': latFromURL ? parseFloat(latFromURL) + 0.042 : 40.41943386132337,
        },
        'longitude': {
            'lo': lngFromURL ? parseFloat(lngFromURL) - 0.085 : -111.92547784270506,
            'hi': lngFromURL ? parseFloat(lngFromURL) + 0.0803 : -111.50765405120116,
        }
    }
    let currentMapZoom = 12;
    try {
        let cacheMapCenter = localStorage.getItem('MAP_CACHE');
        if (cacheMapCenter !== null && (latFromURL === null && lngFromURL === null)) {
            let tempJSON = JSON.parse(cacheMapCenter);
            if (tempJSON.hasOwnProperty('mapBounds') && tempJSON.hasOwnProperty('mapCenter') && tempJSON.hasOwnProperty('mapZoom')) {
                if (
                    tempJSON.mapBounds.hasOwnProperty('latitude') && tempJSON.mapBounds.latitude !== null &&
                    tempJSON.mapCenter.hasOwnProperty('lat') && tempJSON.mapCenter.lat !== null &&
                    tempJSON.mapZoom !== null
                ) {
                    currentMapCenter = tempJSON.mapCenter;
                    currentMapBounds = tempJSON.mapBounds;
                    currentMapZoom = tempJSON.mapZoom;
                }
            }
        }
    } catch (e) {
        console.error('No map center in local storage');
    }

    // set defaults
    const [defaults, setDefaults] = useState({
        // init investment
        closingCosts: 3000,
        closingCostsPct: 2,
        closingCostsType: "%",
        rehab: 1000,
        miscCosts: 0,
        // income
        rentAppr: 3,
        laundry: 0,
        storage: 0,
        miscIncome: 0,
        // expenses
        expensesAppr: 2.5,
        taxes: null,
        insurance: 100,
        mortgageInsurancePct: 0.01,
        repairs: 100,
        repairsPct: 5,
        repairsType: "%",
        capex: 100,
        capexPct: 5,
        capexType: "%",
        propMgnt: 0,
        propMgntPct: 0,
        vacancy: 3,
        miscExpenses: 0,
        utilitiesPaid: true,
        electricity: 40,
        gas: 20,
        sewage: 0,
        garbage: 0,
        lawn: 40,
        HOA: 0,
        // selling expenses
        sellersAgent: 3,
        buyersAgent: 3,
        // mortgage
        downpayment: 30000,
        downpaymentPct: 25,
        downpaymentType: '%', // or '$'
        interestRate: 6.500,
        loanTerm: 30,
        // appr & taxes
        city: "Provo, UT",
        cityAppr: 3,
        personalAnnualIncome: 0,
        taxBracket: 0,
        filingStatus: "Single",
        deprPeriod: 27.5,
        // map
        mapCenter: currentMapCenter,
        mapBounds: currentMapBounds,
        mapZoom: currentMapZoom,
        mapZoomedOut: (currentMapZoom > 13) ? false : true,
        signUpReason: '',
        showBy: "Cash Flow",
        propertiesInBounds: null,
        setDefaultsFromSupabase: false,
        // share
        sharePropertyEdits: true,
        // projection
        yearsProjected: 10
    });

    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-semibold'>{errorMsg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const updateFavorites = async (clientId, newFavList) => {
        // set favorites to supabase
        try {
            const updates = {
                id: clientId,
                updated_at: new Date(),
                saved_properties: newFavList,
            }

            let { error } = await supabase.from('client_favorites').upsert(updates)

            if (error) throw error
        } catch (error) {
            console.log(error.message)
        }
    }

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    // drawer for mobile
    const [drawer, setDrawer] = useState({
        left: false
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawer({ ...drawer, [anchor]: open });
    };
    
    
    const handleDeleteClient = async () => {
        let newClients = [];
        let deleteId = '';
        let deleteIndex = 1;
        clients.forEach(function (tempClient, index) {
            if (tempClient.id !== activeClient.id) {
                newClients.push(tempClient);
            } else {
                deleteId = tempClient.id
                deleteIndex = index;
            }
        })
        if (deleteIndex === clients.length) {
            deleteIndex -= 1;
        }
        handleSetActiveClient(newClients.length > 0 ? newClients[0] : null);
        setTimeout(function() { setClients(newClients); }, 50);
        setOpenDeleteClientModal(false);
        if (newClients.length === 0) {
            setFavoritesList([]);
            setFavoritesListFull([]);
        }

        // delete client in supabase
        try {
            let { error } = await supabase
                .from('client')
                .delete()
                .eq('id', deleteId)
            
            if (error) {
                throw error
            } else {
                notifySuccessMsg('Successfully deleted client.');
            }
            
        } catch (error) {
            console.error(error.message)
        }
        
        // delete client shares in supabase
        try {
            let { error } = await supabase
                .from('client_shares')
                .delete()
                .eq('client_id', deleteId)
            
            if (error) throw error
            
        } catch (error) {
            console.error(error.message)
        }
        
        // delete client properties in supabase
        try {
            let { error } = await supabase
                .from('client_property')
                .delete()
                .eq('id', deleteId)
            
            if (error) throw error
            
        } catch (error) {
            console.error(error.message)
        }
        
        // delete client defaults in supabase
        try {
            let { error } = await supabase
                .from('client_defaults')
                .delete()
                .eq('id', deleteId)
            
            if (error) throw error
            
        } catch (error) {
            console.error(error.message)
        }
        
        // delete client favorites in supabase
        try {
            let { error } = await supabase
                .from('client_favorites')
                .delete()
                .eq('id', deleteId)
            
            if (error) throw error
            
        } catch (error) {
            console.error(error.message)
        }
    }

    const handleFavorite = async (property, status, setStatus) => {
        // show modal if not logged in/signed up
        if (session === null) {
            setLoginHeader('Login or register to save a property');
            setOpenLogin(true);
        } else {
            let newFavList = favoritesList;
            let newFavListFull = favoritesListFull;
            if (status) {
                // removes from favorites
                newFavList = newFavList.filter(data => data !== property.zpid);
                newFavListFull = newFavListFull.filter(prop => prop.zpid !== property.zpid);
                setFavoritesListFull(newFavListFull);
            } else {
                // adds to favorites
                if (newFavList.length === 0) {
                    newFavList.push(property.zpid);
                } else {    
                    let exists = newFavList.filter(data => data === property.zpid);
                    if (exists.length === 0) {
                        newFavList.push(property.zpid);
                    }
                }
            }
            // set icon and data
            setFavoritesList(newFavList);
            setStatus(!status);
            updateFavorites(activeClient.id,  newFavList);
        }
    }
    
    // Sort favorites list
    const [anchorEl, setAnchorEl] = useState(null);
    const openSortFavs = Boolean(anchorEl);
    
    const handleClickSortFavs = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const sortFavorites = (metric) => {
        setSortCompareFavoritesTitle(metric);
        setAnchorEl(null);
        let sortedFavList = favoritesListFull;
        if (metric === "Price/Sq Ft") {
            sortedFavList = allFunctions.AddPricePerSqFt(sortedFavList);
        }
        sortedFavList = allFunctions.MasterQuicksort(sortedFavList, metric);
        setFavoritesListFull(sortedFavList);
        setCompFavListFull(sortedFavList);
    }
  
    // Sort favorites list
    const [leadAnchor, setLeadAnchor] = useState(null);
    const openLeadActions = Boolean(leadAnchor);
    
    const handleClickLead = (event) => {
        setLeadAnchor(event.currentTarget);
    };

    const handleCompareFavorites = async () => {
        // only show the selected properties
        setOpenCompareFavsModal(true);
        let allFavProperties = favoritesListFull;
        let compareFavoritesListFull = []
        allFavProperties.forEach(prop => {
            if (compareFavoritesList.includes(prop.zpid)) {
                compareFavoritesListFull.push(prop);
            }
        })
        setCompFavListFull(compareFavoritesListFull);
    }

    const handleCloseProperty = () => {
        let currentURL = window.location.href;
        let urlArray = currentURL.split("/dashboard");
        let newURL = `${urlArray[0]}/dashboard`;
        window.history.pushState('page2', 'Title', newURL);
        setShowPropertyModal(false);
    }

    const [loginHeader, setLoginHeader] = useState("Sign up");
    const [loginOrSignup, setLoginOrSignup] = useState('Sign up');
    const [openLogin, setOpenLogin] = useState(false);

    const handleCloseLogin = () => {
        setOpenLogin(false);
        setTimeout(() => { setLoginHeader("Sign up"); }, 200)
    }

    const handleSetActiveClient = (client) => {
        setLoadingFavs(true);
        setActiveClient(client);
        setPropertyEdits(client.propertyEdits);
        setViewActivity(false);
        setViewLeads(false);
        // get defaults and favorites
        getDefaults(client.id);
        getFavoritesInit(client.id);
        // reset comp fav lists
        setCompareFavorites(false);
        setCompareFavoritesList([]);
        setCompareFavoritesListLength(0);
        setSortCompareFavoritesTitle('');
        // loading to false
        setTimeout(function() { setLoadingFavs(false); }, 800);
    }

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    const handleYearsProjected = (e) => {
        let newYearsProjected = e;
        if (/[0-9](([0-8](\.[0-9]*)?)|[0-9])?/.test(newYearsProjected)) {
            newYearsProjected = parseInt(newYearsProjected);
            if (newYearsProjected < 100) {
                setDefaults((prevState) => { return { ...prevState, yearsProjected: e } });
            }
        }
    }

    const handleApplyYearsProjected = async () => {
        if (session) {
            // save to supabase
            try {
                const updates = {
                    id: activeClient ? activeClient.id : session.user.id,
                    years_projected: parseFloat(defaults.yearsProjected)
                }

                let { error } = await supabase.from('defaults').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        } else {
            notifyErrorMsg('User must be logged in to save changes.');
        }
        // save new cash flow/cap rate/total return to property object
    }

    const handleSaveAppreciation = async () => {
        if (session) {
            // save to supabase
            try {
                const updates = {
                    id: activeClient ? activeClient.id : session.user.id,
                    rent_appreciation: parseFloat(defaults.rentAppr),
                    expenses_appreciation: parseFloat(defaults.expensesAppr)
                }

                let { error } = await supabase.from('defaults').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        } else {
            notifyErrorMsg('User must be logged in to save changes.');
        }
    }

    const handleSaveAppreciationChanges = () => {
        setOpenAdjustsProjectionsModal(false);
        handleApplyYearsProjected();
    }

    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    const handleOpenProjectionsModal = () => {
        setOpenAdjustsProjectionsModal(true);
        setTimeout(function() { handleSelectInput("years-projected") }, 200);
    }

    const handleDrawerClient = (newClient) => {
        toggleDrawer('left', false);
        handleSetActiveClient(newClient);
    }

    const handlePrefBtn = (open) => {
        setGlobalState('openDefaultModal', open ? true : false);
        setOpenPrefModal(open ? true : false);
    }

    const handleClickConversation = async () => {
        setLoadingConversation(true);
        setOpenConversationModal(true);
        // get conversation (if there is one) from supabase
        try {
            let { data, error, status } = await supabase
                .from('client_property')
                .select(`conversation`)
                .eq('id', `${activeClient.id}`)
                .eq('prop_id', `${selectedProperty.zpid}`)

            if (error && status !== 406) throw error

            if (data && data[0].conversation !== null && data[0].conversation.length > 0) {
                setConversation(data[0].conversation);
                setLoadingConversation(false);
            } else {
                setConversation([]);
                setTimeout(function() { setLoadingConversation(false); }, 500)
            }
        } catch (error) {
            console.error(error.message);
            setLoadingConversation(false);
        }
    }

    const handleAddMessage = async (msg) => {
        const date = new Date();
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const dateString = date.toLocaleString('en-US', options);
        let newMessage = {
            "user": agentView ? "agent" : "client",
            "message": msg,
            "timeSent": dateString
        }
        const newConversation = [...conversation, newMessage];
        setConversation(newConversation);
        // set conversation to supabase
        try {
            const updates = {
                id: activeClient.id,
                prop_id: selectedProperty.zpid,
                updated_at: new Date(),
                conversation: newConversation
            }
    
            let { error } = await supabase.from('client_property').upsert(updates);
    
            if (error) throw error;
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleClearConversation = async () => {
        setConversation([]);
        // set conversation to supabase
        try {
            const updates = {
                id: activeClient.id,
                prop_id: selectedProperty.zpid,
                updated_at: new Date(),
                conversation: []
            }
    
            let { error } = await supabase.from('client_property').upsert(updates);
    
            if (error) throw error;
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleCloseConversation = () => {
        setOpenConversationModal(false);
        setTimeout(function() { setConversation([]); }, 50)
    }
    
    const handleStartSearching = () => {
        let currentURL = window.location.href;
        let urlArray = currentURL.split("/dashboard");
        let newURL = `${urlArray[0]}/search?clientname=${activeClient.name}`;
        window.location.href = newURL;
    }

    const handleViewActivity = async () => {
        let newViewActivity = !viewActivity;
        setLoadingActivity(true);
        setCompareFavorites(false);
        setViewActivity(newViewActivity);
        if (newViewActivity) {
            try {
                let { data, error, status } = await supabase
                    .from('client_shares')
                    .select(`properties_viewed`)
                    .eq('client_id', `${activeClient.id}`)
    
                if (error && status !== 406) {
                    throw error
                }
    
                if (data && data.length > 0) {
                    let initActivity = [];
                    data.forEach(client => {
                        if (client.properties_viewed) {
                            client.properties_viewed.forEach(propActivity => {
                                if (propActivity.timeViewed.length > 0) {
                                    propActivity.timeViewed.forEach(event => {
                                        initActivity.push({
                                            "timeViewed": event,
                                            "user": propActivity.user,
                                            "id": propActivity.id,
                                            "address": propActivity.address,
                                            "details": propActivity.details
                                        })
                                    })
                                } else {
                                    initActivity.push(propActivity);
                                }
                            })
                        }
                    })
                    initActivity.sort(function(a, b) {
                        var timeA = Date.parse(a.timeViewed.replace(',', ''));
                        var timeB = Date.parse(b.timeViewed.replace(',', ''));
                        return timeB - timeA
                    });
                    setActivity(initActivity);
                    setTimeout(() => { setLoadingActivity(false); }, 50);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
    }

    const handleCallGetLeads = () => {
        setViewActivity(false);
        setCompareFavorites(false);
        setViewLeads(true);
        setActiveClient(null);
        handleGetLeads();
    }
    
    const handleBulkEmails = () => {
        setOpenBulkEmailsModal(true);
    }
    
    const handleGetLeads = async () => {
        let urlArray = window.location.href.split("/");
        let domain = urlArray[2];
        try {
            let { data, error, status } = await supabase
                .from('profiles')
                .select(`id, email, domain_login, created_at, updated_at`)
                .eq('domain_login', domain)
                .eq('user_type', "lead");

            if (error && status !== 406) {
                throw error
            }

            if (data && data.length > 0) {
                let initLeads = [];
                data.forEach(lead => {
                    initLeads.push(lead)
                })
                initLeads.sort(function(a, b) {
                    var timeA = Date.parse(a.created_at.replace(',', ''));
                    var timeB = Date.parse(b.created_at.replace(',', ''));
                    return timeB - timeA
                });
                setLeads(initLeads);
                setTimeout(() => { setLoadingActivity(false); }, 50);
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleCopy = (item) => {
        navigator.clipboard.writeText(item);
        notifySuccessMsg('Copied email');
        setLeadAnchor(false);
    }

    const handleCardClick = (propertyId) => {
        if (compareFavorites) {
            // before checking the mark
            let newCompareFavsList = [];
            if (compareFavoritesList.includes(propertyId)) {
                setCompareFavoritesListLength(compareFavoritesListLength - 1);
                newCompareFavsList = compareFavoritesList
                newCompareFavsList = newCompareFavsList.length === 1 ? [] : (newCompareFavsList.filter(data => data !== propertyId));
                setCompareFavoritesList(newCompareFavsList);
            } else {
                setCompareFavoritesListLength(compareFavoritesListLength + 1);
                newCompareFavsList = compareFavoritesList.length === 0 ? [] : compareFavoritesList;
                newCompareFavsList.push(propertyId);
                setCompareFavoritesList(newCompareFavsList);
            }
        } else {
            let propertyEdit = null;
            if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
                if (propertyEdits.properties.hasOwnProperty(`${propertyId}`)) {
                    propertyEdit = propertyEdits.properties[`${propertyId}`];
                }
            }
            
            let currentURL = window.location.href;
            let urlArray = currentURL.split("/dashboard");
            let newURL = `${urlArray[0]}/dashboard?id=${propertyId}`;
            window.history.pushState('page2', 'Title', newURL);
            
            setPropertyEdit(propertyEdit);
            // get initial property details
            verifyProperty(propertyId);
        }
    }

    const handleViewLeadFavorites = (lead) => {
        setLeadAnchor(false);
        setLoadingComps(true);
        setActiveClient(null);
        setClientLead(lead);
        // pull favs from supabase
        getLeadFavorites(lead.id);
        getDefaults(lead.id);

        setTimeout(() => { setLoadingComps(false); }, 50);
        setTimeout(() => { setShowFavorites(true); }, 50);
    }

    const handleCloseAddClientModal = () => {
        setOpenAddClientModal(false);
        setClientLead(null);
    }
    
    const handeCompareFavorites = (newCompFavorites) => {
        setCompareFavorites(newCompFavorites);
        setViewActivity(false);
        setViewLeads(false);
    }
    

    return (
        <>  
            {/* Conversation modal */}
            <Modal size={'lg'} centered={true} show={openConversationModal} onHide={() => handleCloseConversation()} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Body>
                    <Conversation
                        activeClient={activeClient}
                        selectedProperty={selectedProperty}
                        conversation={conversation}
                        setConversation={setConversation}
                        loadingConversation={loadingConversation}
                        handleAddMessage={handleAddMessage}
                        handleClearConversation={handleClearConversation}
                        handleCloseConversation={handleCloseConversation}
                        clients={clients}
                        session={session}
                    />
                </Modal.Body>
            </Modal>

            {/* Login modal */}
            <Modal size={'md'} centered={true} show={openLogin} onHide={() => handleCloseLogin()} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Body>
                    <SignupComponent
                        setOpenLogin={setOpenLogin}
                        loginOrSignup={loginOrSignup}
                        setLoginOrSignup={setLoginOrSignup}
                        handleCloseLogin={handleCloseLogin}
                        loginHeader={loginHeader}
                        clientSignup={clientSignup}
                        clientSignupEmail={clientSignupEmail}
                        initAgentSignup={initAgentSignup}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Edit client info modal */}
            <Modal size={'md'} centered={true} show={openClientInfoModal} onHide={() => setOpenClientInfoModal(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenClientInfoModal(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <ClientInfo
                        activeClient={activeClient}
                        setActiveClient={setActiveClient}
                        setOpenClientInfoModal={setOpenClientInfoModal}
                    />
                </Modal.Body>
            </Modal>

            {/* Contact agent modal */}
            <Modal size={'md'} centered={true} show={openAgentModal} onHide={() => setOpenAgentModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <ContactAgent
                        session={session}
                        agentName={agentName}
                        agentEmail={agentEmail}
                        clients={clients}
                    />
                </Modal.Body>
            </Modal>

            {/* Send bulk emails modal */}
            <Modal size={'md'} centered={true} show={openBulkEmailsModal} onHide={() => setOpenBulkEmailsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <SendBulkEmail
                        session={session}
                        clients={clients}
                        agentName={agentName}
                        setOpenBulkEmailsModal={setOpenBulkEmailsModal}
                    />
                </Modal.Body>
            </Modal>

            {/* Agent card modal */}
            <Modal size={'md'} centered={true} show={openAgentCard} onHide={() => setOpenAgentCard(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenAgentCard(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <AgentCard
                        session={session}
                        agentName={agentName}
                        setAgentName={setAgentName}
                        agentPhone={agentPhone}
                        agentEmail={agentEmail}
                        setAgentPhone={setAgentPhone}
                        agentProfilePic={agentProfilePic}
                        setAgentProfilePic={setAgentProfilePic}
                        setOpenAgentCard={setOpenAgentCard}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Add client modal */}
            <Modal size={'md'} centered={true} show={openAddClientModal} onHide={() => handleCloseAddClientModal()}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => handleCloseAddClientModal()}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <AddClient
                        session={session}
                        clients={clients}
                        setClients={setClients}
                        setActiveClient={setActiveClient}
                        setOpenAddClientModal={setOpenAddClientModal}
                        setFavoritesList={setFavoritesList}
                        setFavoritesListFull={setFavoritesListFull}
                        clientLead={clientLead}
                        setViewActivity={setViewActivity}
                        setCompareFavorites={setCompareFavorites}
                        setViewLeads={setViewLeads}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Edit client profile modal */}
            <Modal size={'md'} centered={true} show={openEditClientModal} onHide={() => setOpenEditClientModal(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenEditClientModal(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <EditClient
                        activeClient={activeClient}
                        setActiveClient={setActiveClient}
                        clients={clients}
                        setClients={setClients}
                        setOpenEditClientModal={setOpenEditClientModal}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Client onboarding modal */}
            <Modal size={'md'} centered={true} show={openClientOnboardingModal} onHide={() => setOpenClientOnboardingModal(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenClientOnboardingModal(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <ClientOnboarding
                        session={session}
                        activeClient={activeClient}
                        setActiveClient={setActiveClient}
                        inviteCode={inviteCodeFromURL}
                        setOpenClientOnboardingModal={setOpenClientOnboardingModal}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Share portfolio modal */}
            <Modal size={'md'} centered={true} show={openSharePortfolioModal} onHide={() => setOpenSharePortfolioModal(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-4'} mt-1 mb-2`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenSharePortfolioModal(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <SharePortfolio
                        session={session}
                        activeClient={activeClient}
                        setActiveClient={setActiveClient}
                        setOpenSharePortfolioModal={setOpenSharePortfolioModal}
                    />
                </Modal.Body>
            </Modal>
            
            {/* Delete client modal */}
            <Modal size={'md'} centered={true} show={openDeleteClientModal} onHide={() => setOpenDeleteClientModal(false)}>
                <Modal.Body className={`${isMobile ? 'mx-2' : 'mx-8'} mt-1 mb-4`}>
                    <div className="flex justify-between">
                        <img src={logo_icon} alt="logo" className='h-7 mt-2' />
                        {/* Close modal */}
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => setOpenDeleteClientModal(false)}
                            style={{ paddingLeft: '14px', paddingRight: '14px' }}
                            className='text-xl py-2.5 -mt-1 -mr-5 cursor-pointer hover:rounded-full hover:bg-light-gray-2'
                        />
                    </div>
                    <div className='mx-2'>
                        <p className='text-2xl text-center font-bold tracking-tight mt-4'>Are you sure you want to delete {activeClient ? activeClient.name : 'this client'}'s portfolio?</p>
                        <p className='text-xl mt-6 mx-2'>Deleting {activeClient ? activeClient.name : 'this client'}'s portfolio will erase all of their data and you will not be able to access it again.</p>
                        <button
                            onClick={() => handleDeleteClient()}
                            className='mt-4 w-100 h-12 rounded-xl text-xl text-white bg-red'
                        >
                            Yes, delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Analyze property modal */}
            <Modal className='lg:-mt-3' size={'xl'} centered={true} show={showPropertyModal} onHide={() => handleCloseProperty()} contentClassName={isMobile ? "" : ""}>
                <Modal.Body className={isMobile ? "" : ""}>
                    <Property
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                        defaults={defaults}
                        setShow={setShowPropertyModal}
                        propertyEdit={propertyEdit}
                        propertyEdits={propertyEdits}
                        setPropertyEdits={setPropertyEdits}
                        setPropertyEdit={setPropertyEdit}
                        session={session}
                        userId={userIdFromURL}
                        propertyId={propertyIdFromURL}
                        favoritesList={favoritesList}
                        handleFavorite={handleFavorite}
                        setOpenLogin={setOpenLogin}
                        setLoginHeader={setLoginHeader}
                        activeClient={activeClient}
                        clients={clients}
                    />
                </Modal.Body>
            </Modal>

            {/* Compare Favorites Modal */}
            <Modal size={'lg'} centered={true} show={openCompareFavsModal} onHide={() => setOpenCompareFavsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <CompAnalysis
                        compProperties={compFavListFull}
                        defaults={defaults}
                        propertyEdits={propertyEdits}
                        sortTitle={sortCompareFavoritesTitle}
                    />
                </Modal.Body>
            </Modal>

            {/* Defaults modal */}
            <Modal size={'lg'} show={useGlobalState('openDefaultModal')[0]} onHide={() => handlePrefBtn(false)}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <Default
                        defaults={defaults}
                        setDefaults={setDefaults}
                        client={activeClient}
                        table="client_defaults"
                    />
                </Modal.Body>
            </Modal>

            {/* Adjust projections modal */}
            <Modal size={'md'} centered={true} show={openAdjustsProjectionsModal} onHide={() => setOpenAdjustsProjectionsModal(false)} style={{ backgroundColor: '#5A5A5A80', zIndex: '5000' }}>
                <Modal.Header closeButton>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col justify-start items-center mb-3'>
                        <p className='text-center text-3xl font-extrabold tracking-tight mb-4'>Adjust projections</p>
                        <div className={`bg-light-gray rounded-xl p-3 ${isMobile ? 'w-80' : 'w-96'}`}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>Years projected:</p>
                                    <img data-tip data-for="yearsProjected" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='yearsProjected' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Years projected</p>
                                            <p className='mt-3'>The number of years you want to project into the future. For example, cash flow today could be $100 but a 10-year projection shows cash flow at $1,000</p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="years-projected"
                                    onClick={() => handleSelectInput("years-projected")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    value={defaults.yearsProjected}
                                    decimalsLimit={1}
                                    onValueChange={(e) => handleYearsProjected(e)}
                                    onBlur={() => handleApplyYearsProjected()}
                                />
                            </div>
                            <div className='flex justify-between items-center mt-3'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>{isMobile ? 'Rent appr. rate:' : 'Rent appreciation rate:'}</p>
                                    <img data-tip data-for="rentAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='rentAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Rent Appreciation Rate</p>
                                            <p className='mt-3'>The rent appreciation rate is the rate at which rent increase year-over-year. Between 2010 and 2020, rents increased by 2.6% annually. Between 2020 and 2022, rents rose 10.5%.</p>
                                            <p className='text-gray mt-3'>
                                                Ex: With a rent appreciation rate of {defaults.rentAppr}%, rent will go from $1,000 this year to ${Math.round(1000 * (1 + (defaults.rentAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="rent-appreciation"
                                    onClick={() => handleSelectInput("rent-appreciation")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    suffix='%'
                                    value={defaults.rentAppr}
                                    decimalsLimit={1}
                                    onValueChange={(e) => setDefaults((prevState) => { return { ...prevState, rentAppr: (e === undefined) ? 0 : e } })}
                                    onBlur={() => handleSaveAppreciation()}
                                />
                            </div>
                            <div className='flex justify-between items-center mt-3'>
                                <div className='flex justify-center gap-3'>
                                    <p className='text-lg'>{isMobile ? 'Expense appr. rate:' : 'Expense appreciation rate:'}</p>
                                    <img data-tip data-for="expensesAppr" className="w-4 mb-2 mt-1" src={info_popup} alt="info box"/>
                                    <ReactTooltip id='expensesAppr' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                        <div className='w-64 text-sm'>
                                            <p className='text-xl text-center text-bot-blue mb-2'>Expense Appreciation Rate</p>
                                            <p className='mt-3'>The expense appreciation rate is the rate at which expenses (operating expenses) increase year-over-year. Your mortgage will stay the same unless you refinance. Operating expenses will increase 2-3% on average closely following inflation.</p>
                                            <p className='text-gray mt-3'>
                                                Ex: With an expense appreciation rate of {defaults.expensesAppr}%, your operating expenses will go from $500 this year to ${Math.round(500 * (1 + (defaults.expensesAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <CurrencyInput
                                    id="expense-appreciation"
                                    onClick={() => handleSelectInput("expense-appreciation")}
                                    className="p-1 pl-2 w-20 border-one rounded-lg text-lg"
                                    suffix='%'
                                    value={defaults.expensesAppr}
                                    decimalsLimit={1}
                                    onValueChange={(e) => setDefaults((prevState) => { return { ...prevState, expensesAppr: (e === undefined) ? 0 : e } })}
                                    onBlur={() => handleSaveAppreciation()}
                                />
                            </div>
                        </div>
                        <div className={`flex justify-center items-center mt-4`}>
                            <button
                                onClick={() => {handleSaveAppreciationChanges()}}
                                className={`${isMobile ? 'w-80' : 'w-96'} py-2.5 text-center text-white text-xl rounded-xl bg-bot-blue hover:bg-dark-bot-blue`}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Favorites modal */}
            <Modal size={'xl'} centered={true} className='lg:-mt-3' show={showFavorites} onHide={() => setShowFavorites(false)}>
                <Modal.Header closeButton className='mt-header'>
                    <img src={logo_icon} alt="logo" className='h-7 lg:ml-5' />
                </Modal.Header>
                <Modal.Body>
                    <div className={isMobile ? 'm-1' : 'm-4'}>
                        <div className='lg:pl-2'>
                            <div className='flex justify-start items-center text-3xl font-bold tracking-tighter mb-3'>
                                <p className='mr-2'>
                                    {clientLead
                                        ? `${clientLead.email}'s`
                                        : activeClient && activeClient.name !== 'Personal'
                                            ? `${activeClient.name}'s`
                                            : 'My'
                                    }
                                </p>
                                <p>Favorites</p>
                            </div>
                            <div className='flex justify-start gap-3 my-3'>
                                <Tooltip title="Compare favorite properties">
                                    <p
                                        onClick={() => setCompareFavorites(!compareFavorites)}
                                        className={`py-2 px-3 rounded-xl hover:bg-light-gray-2 cursor-pointer text-lg ${compareFavorites ? 'bg-light-gray-3' : ''}`}
                                    >
                                        <FontAwesomeIcon icon={faCodeCompare} className={`text-xl font-black pr-2.5`} />
                                        Compare
                                    </p>
                                </Tooltip>
                                <div>
                                    <Tooltip title="Sort favorite properties">
                                        <p
                                            id="demo-positioned-button"
                                            aria-haspopup="true"
                                            className={`py-2 px-3 rounded-xl hover:bg-light-gray-2 cursor-pointer text-lg`}
                                            onClick={handleClickSortFavs}
                                        >
                                            <FontAwesomeIcon icon={faSortAmountDown} className={`text-xl font-black pr-2.5`} />
                                            {sortCompareFavoritesTitle === '' ? 'Sort' : 'Sort: '}
                                            {(isMobile && sortCompareFavoritesTitle !== '') ? '(1)' : sortCompareFavoritesTitle}
                                        </p>
                                    </Tooltip>
                                    <Menu
                                        id="demo-positioned-menu"
                                        anchorEl={anchorEl}
                                        open={openSortFavs}
                                        onClose={() => setAnchorEl(null)}
                                        className='mt-1.5 ml-1.5'
                                    >
                                        <MenuItem key='cash-flow' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Cash Flow")}>Cash Flow</MenuItem>
                                        <MenuItem key='cap-rate' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Cap Rate')}>Cap Rate</MenuItem>
                                        <MenuItem key='total-return' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Total Return')}>Total Return</MenuItem>
                                        <MenuItem key='price-per-sf' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price/Sq Ft")}>Price/Sq Ft</MenuItem>
                                        <MenuItem key='price-asc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (low to high)")}>Price (low to high)</MenuItem>
                                        <MenuItem key='price-desc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (high to low)")}>Price (high to low)</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        {(favoritesList.length !== 0)
                        ?   <div>
                                <div className='flex justify-start gap-8 mt-8 flex-wrap width-favorites'>
                                    {(loadingComps) ? (
                                        favoritesList.map(function (index) {
                                            return (
                                                <Skeleton
                                                    width={320}
                                                    height={230}
                                                    animation="wave"
                                                    variant="rectangular"
                                                    className='rounded mt-2'
                                                />
                                            )
                                        })
                                    ) : (
                                        (favoritesListFull.length === 0) ? (
                                            <NoHomes page="Favorites" />
                                        ) : (
                                            favoritesListFull.map(function (property) {
                                                return (
                                                    <FavoriteCard
                                                        property={property}
                                                        favoritesList={favoritesList}
                                                        setFavoritesList={setFavoritesList}
                                                        setSelectedProperty={setSelectedProperty}
                                                        setShowFavorites={setShowFavorites}
                                                        setShow={setShowPropertyModal}
                                                        updateFavorites={updateFavorites}
                                                        defaults={defaults}
                                                        favoritesListFull={favoritesListFull}
                                                        setFavoritesListFull={setFavoritesListFull}
                                                        propertyEdits={propertyEdits}
                                                        setPropertyEdit={setPropertyEdit}
                                                        compareFavorites={compareFavorites}
                                                        compareFavoritesList={compareFavoritesList}
                                                        setCompareFavoritesList={setCompareFavoritesList}
                                                        compareFavoritesListLength={compareFavoritesListLength}
                                                        setCompareFavoritesListLength={setCompareFavoritesListLength}
                                                    />
                                                )
                                            })
                                        )
                                    )}
                                </div>
                                <div className={`flex justify-center items-center ${compareFavorites ? '' : 'hidden'}`}>
                                    <div className='fixed bottom-0 lg:mb-16 shadow-xl shadow-gray rounded-xl'>
                                        {compareFavoritesListLength > 1 ? (
                                            <p
                                                onClick={() => handleCompareFavorites()}
                                                className='text-center w-96 px-3 py-4 bg-bot-blue hover:bg-dark-bot-blue cursor-pointer text-2xl font-bold rounded-xl text-white'
                                            >
                                                <FontAwesomeIcon icon={faCodeCompare} className={`font-black pr-3`} />
                                                Compare {compareFavoritesListLength} properties
                                            </p>
                                        ) : (
                                            <p className='text-center w-96 px-3 py-4 bg-light-bot-blue text-xl font-bold rounded-xl text-white'>
                                                Select 2+ properties to compare
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        : <NoHomes page="Favorites" />
                        }
                    </div>
                </Modal.Body>
            </Modal>

            {/* Future projections button */}
            <div
                className={(
                    activeClient &&
                    showPropertyModal === false &&
                    openAddClientModal === false &&
                    openDeleteClientModal === false &&
                    openCompareFavsModal === false &&
                    openSharePortfolioModal === false &&
                    openClientOnboardingModal === false &&
                    openClientInfoModal === false &&
                    openEditClientModal === false &&
                    openPrefModal === false &&
                    openConversationModal === false &&
                    viewActivity === false &&
                    viewLeads === false &&
                    drawer['left'] === false &&
                    openAgentCard === false &&
                    compareFavorites === false
                )
                    ? 'flex justify-center items-center'
                    : 'hidden'}
            >
                <div className='fixed bottom-0 z-50' style={{zIndex: '5000'}}>
                    <div
                        onClick={() => handleOpenProjectionsModal()}
                        className={"flex justify-center gap-2 mb-5 px-3 py-2 text-base bg-black hover:bg-[#252525] text-white cursor-pointer rounded-full shadow-md shadow-gray"}
                    >
                        <div className="flex justify-center gap-0.5 p-1.5">
                            <p>{defaults.yearsProjected} year projection</p>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-sm ml-2 mt-1 text-white"
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            {session === null ? (
                <div className={`flex flex-col justify-center items-center text-center ${isMobile ? 'mt-10 mx-3' : 'mt-16'}`}>
                    <p className={`font-extrabold tracking-tight ${isMobile ? 'text-3xl' : 'text-4xl'}`}>Your dashboard awaits 🎉</p>
                    <div className={`flex justify-center items-center gap-2 text-2xl ${isMobile ? 'mt-5' : 'mt-4'}`}>
                        <div>
                            <p onClick={() => setOpenLogin(true)} className={`cursor-pointer mb-2`}>Login or sign up</p>
                            <hr className='border-bot-blue border-2' />
                        </div>
                        <p className='mb-2'>to get started.</p>
                    </div>
                    <img src={dashboard_walkthrough} alt="Dashboard gif" className={`shadow rounded-2xl ${isMobile ? 'mt-16' : 'mt-16'}`} />
                </div>
            ) : (
                <div className={`lg:flex lg:justify-start lg:gap-4 ${isMobile ? 'mx-2' : 'mx-3'}`}>
                    
                    {/* Dashboard */}
                    {isMobile || agentView === false ? (
                        <div className='hidden'></div>
                    ) : (
                        <div className='flex flex-col justify-between items-between gap-0 bg-light-gray text-white w-96 rounded-xl max-h-[100vh]'>
                            <div>
                                <div className='px-4 pt-4'>
                                    <button
                                        onClick={() => setOpenAddClientModal(true)}
                                        style={{ width: '100%', borderWidth: '1px' }}
                                        className={`flex justify-center items-center text-black font-semibold text-lg border-black px-2.5 cursor-pointer py-2.5 hover:bg-light-gray-2 rounded-xl`}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className='mr-2' />
                                        Add client
                                    </button>
                                </div>
                                <div className='client-list max-h-[calc(100vh-220px)] overflow-y-scroll pl-4 pr-2'>
                                    {clients.map(function (client, index) {
                                        let name = '';
                                        if (client.hasOwnProperty('name') && client.name !== null) {
                                            name = client.name.length > 23 ? `${client.name.slice(0, 23)}..` : client.name;
                                            if (activeClient && client.id === activeClient.id) {
                                                name = client.name.length > 20 ? `${client.name.slice(0, 20)}..` : client.name;
                                            }
                                        }
                                        return (
                                            <div
                                                onClick={() => handleSetActiveClient(client)}
                                                className='flex flex-col justify-center gap-5 mt-3 text-black text-lg font-semibold'
                                            >
                                                <div
                                                    className={`flex justify-between items-center pl-3 pr-2.5 cursor-pointer py-2.5 hover:bg-light-gray-2 rounded-xl ${activeClient && client.id === activeClient.id ? 'bg-light-gray-2': 'bg-light-gray'}`}
                                                >
                                                    <p>{name}</p>
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        onClick={() => setOpenDeleteClientModal(true)}
                                                        className={activeClient && client.id === activeClient.id ? "text-xl text-gray hover:text-dark-gray mr-2" : "hidden"}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={'px-4 pb-4'}>
                                <hr className='border-light-gray-3' style={{borderWidth: '1px'}} />
                                <div className='flex flex-col justify-start items-start mt-3'>
                                    <div className='w-[100%] text-black text-base font-semibold'>
                                        <div
                                            onClick={() => handleCallGetLeads()}
                                            className={isAdmin ? `flex justify-start items-center gap-4 mt-1 pl-3 pr-2.5 cursor-pointer py-[14px] ${viewLeads ? 'bg-light-gray-2' : 'bg-light-gray'} hover:bg-light-gray-2 rounded-xl` : 'hidden'}
                                        >
                                            <FontAwesomeIcon icon={faUser} />
                                            <p className='text-black'>New Leads ({leads.length})</p>
                                        </div>
                                        <div
                                            onClick={() => handleBulkEmails()}
                                            className={`flex justify-start items-center gap-4 pl-3 pr-2.5 cursor-pointer py-[14px] bg-light-gray hover:bg-light-gray-2 rounded-xl`}
                                        >
                                            <FontAwesomeIcon icon={faEnvelopesBulk} />
                                            <p className='text-black'>Bulk email</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`${agentView ? '' : 'lg:ml-12'}`} style={{height: '88vh', width: '100%'}}>
                        <div className={`text-black px-4 pt-2 ${isMobile ? 'pb-28' : 'pb-2'}`}>
                            {/* Header/open nav bar */}
                            <div className={clients.length > 0 ? 'flex justify-between' : ''} style={{width: '100%'}}>
                                <div className={`flex justify-start gap-2`}>
                                    <div className={isMobile && agentView ? '' : 'hidden'}>
                                        <Fragment key={'left'}>
                                            <button
                                                onClick={toggleDrawer('left', true)}
                                                className='px-2.5 py-2.5 -mt-0.5 -ml-3 rounded-md bg-light-gray hover:bg-light-gray'
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} className="text-2xl text-gray" />
                                            </button>
                                            <Drawer
                                                anchor={'left'}
                                                open={drawer['left']}
                                                onClose={toggleDrawer('left', false)}
                                            >
                                                {/* TODO: Add "New Leads" button for admin to the mobile dashboard */}
                                                <div className='p-3 pt-5 w-72' onClick={toggleDrawer('left', false)}>
                                                    <button
                                                        onClick={() => setOpenAddClientModal(true)}
                                                        style={{width: '100%', borderWidth: '1px'}}
                                                        className={`flex justify-center items-center px-2.5 cursor-pointer text-black font-semibold py-2.5 border-black hover:bg-light-gray rounded-xl`}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} className='mr-2' />
                                                        Add client
                                                    </button>
                                                    {/* <hr className='border-light-gray-3 mt-3' style={{borderWidth: '1px'}} /> */}
                                                    {clients.map(function (client, index) {
                                                        let name = '';
                                                        if (client.hasOwnProperty('name') && client.name !== null) {
                                                            name = client.name.length > 23 ? `${client.name.slice(0, 23)}..` : client.name;
                                                            if (activeClient && client.id === activeClient.id) {
                                                                name = client.name.length > 20 ? `${client.name.slice(0, 20)}..` : client.name;
                                                            }
                                                        }
                                                        return (
                                                            <div
                                                                onClick={() => handleDrawerClient(client)}
                                                                className='flex flex-col justify-center gap-5 mt-3 text-black text-lg font-semibold'
                                                            >
                                                                <div
                                                                    className={`flex justify-between items-center pl-3 pr-2.5 cursor-pointer py-2.5 hover:bg-light-gray-2 rounded-xl ${activeClient && client.id === activeClient.id ? 'bg-light-gray': ''}`}
                                                                >
                                                                    <p>{name}</p>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashAlt}
                                                                        onClick={() => setOpenDeleteClientModal(true)}
                                                                        className={activeClient && client.id === activeClient.id ? "text-xl text-gray hover:text-dark-gray ml-3" : "hidden"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Drawer>
                                        </Fragment>
                                    </div>
                                    {viewLeads ? (
                                        <div className='flex justify-center items-center gap-3'>
                                            <p className={`pt-2.5 ${isMobile ? 'text-[26px]' : 'text-3xl'} -mt-2 font-extrabold tracking-tight`}>New Leads</p>
                                            <Tooltip title={<p className='text-sm'>These leads are all users who have created an account without being invited to the site. You can view their saved properties and should reach out ASAP to convert any lead to a client!</p>}>
                                                <img className="w-4" src={info_popup} alt="info box"/>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            {loadingFavs ? (
                                                <Skeleton
                                                    width={isMobile ? 200 : 250}
                                                    height={40}
                                                    animation="wave"
                                                    variant="rectangular"
                                                    className='rounded mt-2'
                                                />
                                            ) : (
                                                <div>
                                                    {activeClient ? (
                                                        <div className={`flex justify-center items-center ${isMobile ? 'text-[26px]' : 'text-3xl'} -mt-2 font-extrabold tracking-tight`}>
                                                            <p
                                                                onClick={() => setOpenEditClientModal(true)}
                                                                className='p-2.5 lg:-ml-2 cursor-pointer rounded-xl hover:bg-light-gray'
                                                            >
                                                                {activeClient.name}'s Portfolio
                                                            </p>
                                                            <Tooltip title="Share portfolio">
                                                                <div
                                                                    onClick={() => setOpenSharePortfolioModal(true)}
                                                                    className='flex justify-center items-center text-xl ml-2.5 p-2.5 cursor-pointer rounded-full hover:bg-light-gray'
                                                                >
                                                                    <FontAwesomeIcon icon={faUserGroup} />
                                                                    <p className='font-black mb-1'>+</p>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    ) : (
                                                        <div className='hidden'></div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={
                                    isMobile ||
                                    viewLeads ||
                                    loadingFavs ||
                                    viewActivity ||
                                    clients.length === 0
                                        ? 'hidden'
                                        : ''
                                }>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!agentView}
                                                onChange={() => setAgentView(!agentView)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={<p className={`text-lg`}>Client view</p>}
                                    />
                                </div>
                            </div>

                            {/* Client info */}
                            {loadingFavs ? (
                                <div className='lg:flex lg:justify-between lg:items-center mx-1 mt-7' style={{width: '100%'}}>
                                    <Skeleton
                                        width={isMobile ? 250 : 500}
                                        height={30}
                                        animation="wave"
                                        variant="rectangular"
                                        className={`rounded -ml-1 ${isMobile ? 'ml-1 mt-3' : '-mt-1'}`}
                                    />
                                    <Skeleton
                                        width={isMobile ? 250 : 320}
                                        height={30}
                                        animation="wave"
                                        variant="rectangular"
                                        className={`rounded -ml-1 ${isMobile ? 'ml-1 mt-2' : '-mt-1'}`}
                                    />
                                    <div className={isMobile ? '' : 'hidden'}>
                                        <Skeleton
                                            width={250}
                                            height={30}
                                            animation="wave"
                                            variant="rectangular"
                                            className='ml-1 rounded -ml-1 mt-8 mb-5'
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {viewLeads ? (
                                        <div className='lg:flex lg:justify-between lg:items-center mx-1 mt-7' style={{width: '100%'}}>
                                            {/* <p>Filter & sort here</p> */}
                                        </div>
                                    ) : (
                                        <div>
                                            {activeClient ? (
                                                <div className='lg:flex lg:justify-between lg:items-center lg:mx-1 mt-2' style={{width: '100%'}}>
                                                    <div
                                                        className={`flex ${isMobile ? 'justify-center text-start' : 'justify-start text-start gap-2 text-lg'} font-semibold leading-10 cursor-pointer px-2 -ml-2 hover:bg-light-gray hover:rounded-xl`}
                                                        onClick={() => setOpenClientInfoModal(true)}
                                                    >
                                                        <p>
                                                            Budget: {activeClient.budget ? activeClient.budget : 'Any'} |
                                                            Type: {activeClient.propertyTypes ? activeClient.propertyTypes : 'Any'} |
                                                            Timeline: {activeClient.timeline ? activeClient.timeline : 'Any'}
                                                        </p>
                                                    </div>
                                                    <div className={`flex justify-center lg:text-xl font-semibold ${isMobile ? 'mt-4' : 'gap-1'}`}>
                                                        <Tooltip title="Client's preferences">
                                                            <p
                                                                onClick={() => handlePrefBtn(true)}
                                                                className='flex justify-center items-center lg:px-3 px-2 py-2 lg:text-lg rounded-xl hover:bg-light-gray cursor-pointer'
                                                            >
                                                                <FontAwesomeIcon icon={faSliders} className='mr-2' />
                                                                {isMobile ? 'Pref' : 'Preferences'}
                                                            </p>
                                                        </Tooltip>
                                                        <div className={agentView ? '' : 'hidden'}>
                                                            <Tooltip title="View user activity">
                                                                <div
                                                                    onClick={() => handleViewActivity()}
                                                                    className={`flex justify-center items-center gap-2.5 py-2 lg:px-3 px-2 rounded-xl cursor-pointer lg:text-lg ${viewActivity ? 'bg-light-gray-2 hover:bg-light-gray-2' : 'hover:bg-light-gray'}`}
                                                                >
                                                                    <img src={pulse} className={`h-5 mt-1`} alt="client activity" />
                                                                    <p>{isMobile ? 'Act' : 'Activity'}</p>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                        <Tooltip title="Compare properties">
                                                            <p
                                                                onClick={() => handeCompareFavorites(!compareFavorites)}
                                                                className={`flex justify-center items-center py-2 lg:px-3 px-2 rounded-xl cursor-pointer lg:text-lg ${compareFavorites ? 'bg-light-gray-2 hover:bg-light-gray-2' : 'hover:bg-light-gray'}`}
                                                            >
                                                                <FontAwesomeIcon icon={faCodeCompare} className={`text-xl font-black pr-2.5`} />
                                                                {isMobile ? 'Comp' : 'Compare'}
                                                            </p>
                                                        </Tooltip>
                                                        <div>
                                                            <Tooltip title="Sort properties">
                                                                <p
                                                                    id="demo-positioned-button"
                                                                    aria-haspopup="true"
                                                                    className={`flex justify-center items-center py-2 lg:px-3 px-2 rounded-xl hover:bg-light-gray cursor-pointer lg:text-lg`}
                                                                    onClick={handleClickSortFavs}
                                                                >
                                                                    <FontAwesomeIcon icon={faSortAmountDown} className={`text-xl font-black pr-2.5`} />
                                                                    {sortCompareFavoritesTitle === '' ? 'Sort' : 'Sort: '}
                                                                    {(isMobile && sortCompareFavoritesTitle !== '') ? '(1)' : sortCompareFavoritesTitle}
                                                                </p>
                                                            </Tooltip>
                                                            <Menu
                                                                id="demo-positioned-menu"
                                                                anchorEl={anchorEl}
                                                                open={openSortFavs}
                                                                onClose={() => setAnchorEl(null)}
                                                            >
                                                                <MenuItem key='cash-flow' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Cash Flow")}>Cash Flow</MenuItem>
                                                                <MenuItem key='cap-rate' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Cap Rate')}>Cap Rate</MenuItem>
                                                                <MenuItem key='total-return' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites('Total Return')}>Total Return</MenuItem>
                                                                <MenuItem key='price-per-sf' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price/Sq Ft")}>Price/Sq Ft</MenuItem>
                                                                <MenuItem key='price-asc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (low to high)")}>Price (low to high)</MenuItem>
                                                                <MenuItem key='price-desc' sx={{fontSize: '17px', width: '180px'}} onClick={() => sortFavorites("Price (high to low)")}>Price (high to low)</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='hidden'></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={isMobile ? 'flex flex-col justify-center items-center' : 'lg:flex lg:justify-between'}>
                                {/* Favorite properties */}
                                <div
                                    style={{height: '100%', width: '100%'}}
                                    className={`lg:flex lg:justify-start lg:gap-10 ${isMobile ? 'mt-6' : 'mt-10'}`}
                                >
                                    {viewActivity ? (
                                        <div>
                                            {loadingActivity ? (
                                                <p className='text-light-gray-4'>Loading...</p>
                                            ) : (
                                                <div>
                                                    {activity.length === 0 ? (
                                                        <div>
                                                            <p className='text-lg font-semibold ml-2'>No activity recorded yet.</p>
                                                            <p className='ml-2 mt-3'>View any time a client/shared user opens a property along with the time opened.</p>
                                                        </div>
                                                    ) : (
                                                        <div className='overflow-scroll lg:h-[73vh] -mt-3 pb-4'>
                                                            {activity.map(function (activityRecord, index) {
                                                                return (
                                                                    <Tooltip title={`Open ${activityRecord.address}`}>
                                                                        <div
                                                                            onClick={() => handleCardClick(activityRecord.id)}
                                                                            className={`lg:flex lg:justify-start lg:gap-5 ${index === 0 ? '' : 'mt-3'} p-1.5 rounded-xl hover:bg-light-gray cursor-pointer`}
                                                                        >
                                                                            <p className='text-light-gray-4'>{activityRecord.timeViewed}</p>
                                                                            <p className='text-dark-gray'>{activityRecord.user}</p>
                                                                            <p className='text-light-bot-blue'>{activityRecord.address} | {activityRecord.details}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {viewLeads ? (
                                                <div>
                                                    {loadingActivity ? (
                                                        <p className='text-light-gray-4'>Loading...</p>
                                                    ) : (
                                                        <div>
                                                            {leads.length === 0 ? (
                                                                <div>
                                                                    <p className='text-lg font-semibold ml-2'>No inbound leads yet.</p>
                                                                    <p className='ml-2 mt-3'>View all users who have created an account on your website without being invited to the website (a link to a shared portfolio for instance).</p>
                                                                </div>
                                                            ) : (
                                                                <div className='overflow-scroll lg:h-[73vh] -mt-3 pb-4'>
                                                                    {leads.map(function (lead, index) {
                                                                        let dateCreated = new Date(lead.created_at);
                                                                        let dateUpdated = new Date(lead.updated_at);
                                                                        dateCreated = dateCreated.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });                                                                    
                                                                        dateUpdated = dateUpdated.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });                                                                    
                                                                        return (
                                                                            <div className='flex justify-start items-center'>
                                                                                {/* <div className='ml-2.5 -mb-2'>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox checked={checkLead} sx={{color: 'lightgray'}} />
                                                                                        }
                                                                                        onClick={() => setCheckLead(!checkLead)}
                                                                                    />
                                                                                </div> */}
                                                                                <div className={`lg:flex lg:justify-start lg:gap-5 ${index === 0 ? '' : 'mt-3'}`}>
                                                                                    <div className='-mr-2'>
                                                                                        <Tooltip title={<p className='text-sm'>View actions</p>}>
                                                                                            <p
                                                                                                id="demo-positioned-button"
                                                                                                aria-haspopup="true"
                                                                                                className={`flex justify-center items-center py-2 px-3 rounded-xl text-[17px] underline hover:no-underline hover:bg-light-gray cursor-pointer`}
                                                                                                onClick={handleClickLead}
                                                                                            >
                                                                                                {lead.email}
                                                                                            </p>
                                                                                        </Tooltip>
                                                                                        <Menu
                                                                                            id="demo-positioned-menu"
                                                                                            anchorEl={leadAnchor}
                                                                                            open={openLeadActions}
                                                                                            onClose={() => setLeadAnchor(null)}
                                                                                            className="ml-3"
                                                                                        >
                                                                                            {/* <MenuItem key='add-client' sx={{fontSize: '17px'}} onClick={() => handleAddClientLead(lead)}>Add as client</MenuItem> */}
                                                                                            <MenuItem key='view-properties' sx={{fontSize: '17px'}} onClick={() => handleViewLeadFavorites(lead)}>View saved properties</MenuItem>
                                                                                            <MenuItem key='copy-email' sx={{fontSize: '17px'}} onClick={() => handleCopy(lead.email)}>Copy email</MenuItem>
                                                                                        </Menu>
                                                                                    </div>
                                                                                    <p className='text-light-gray-4 pt-[9px]'>Created: {dateCreated}</p>
                                                                                    <p className='text-light-bot-blue pt-[9px]'>Last visited: {dateUpdated}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    {loadingFavs ? (
                                                        <div className='lg:flex lg:justify-start gap-5'>
                                                            <Skeleton
                                                                width={isMobile ? 300 : 320}
                                                                height={250}
                                                                animation="wave"
                                                                variant="rectangular"
                                                                className='rounded-xl ml-1 -mt-0.5'
                                                            />
                                                            <Skeleton
                                                                width={isMobile ? 300 : 320}
                                                                height={250}
                                                                animation="wave"
                                                                variant="rectangular"
                                                                className={`rounded-xl ml-1 ${isMobile ? 'mt-4' : '-mt-0.5'}`}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className='flex justify-between gap-5' style={{width: '100%'}}>
                                                            <div className='w-full'>
                                                                {favoritesListFull && favoritesListFull.length > 0 ? (
                                                                    <div
                                                                        style={{width: '100%'}}
                                                                        className='flex justify-start gap-3 flex-wrap'
                                                                    >
                                                                        {favoritesListFull.map(function (property, index) {
                                                                            return (
                                                                                <div className={isMobile ? 'mt-2' : ''}>
                                                                                    <DashboardPropertyCard
                                                                                        activeClient={activeClient}
                                                                                        setSelectedProperty={setSelectedProperty}
                                                                                        favoritesList={favoritesList}
                                                                                        setFavoritesList={setFavoritesList}
                                                                                        property={property}
                                                                                        defaults={defaults}
                                                                                        updateFavorites={updateFavorites}
                                                                                        setShowPropertyModal={setShowPropertyModal}
                                                                                        compareFavoritesListLength={compareFavoritesListLength}
                                                                                        setCompareFavoritesListLength={setCompareFavoritesListLength}
                                                                                        propertyEdits={propertyEdits}
                                                                                        compareFavorites={compareFavorites}
                                                                                        compareFavoritesList={compareFavoritesList}
                                                                                        setCompareFavoritesList={setCompareFavoritesList}
                                                                                        setPropertyEdit={setPropertyEdit}
                                                                                        favoritesListFull={favoritesListFull}
                                                                                        setFavoritesListFull={setFavoritesListFull}
                                                                                        handleClickConversation={handleClickConversation}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {activeClient ? (
                                                                            <div>
                                                                                <Card
                                                                                    className="lg:flex w-property-tile m-1 pb-2 drop-shadow-md shadow-slate-200 rounded-xl"
                                                                                >
                                                                                    <div className='h-48 w-auto' style={{
                                                                                            backgroundImage: `url(${no_homes})`,
                                                                                            backgroundPosition: 'center',
                                                                                            backgroundSize: 'cover',
                                                                                            borderTopLeftRadius: '12px',
                                                                                            borderTopRightRadius: '12px'
                                                                                        }}
                                                                                    />
                                                                                    <div className='my-3 mx-2'>
                                                                                        <p className='flex justify-center text-center text-xl font-bold'>No Saved Properties</p>
                                                                                        <p className='flex justify-center text-center mt-2 mx-3'>❤️️ a property to save it to your client's dashboard</p>
                                                                                    </div>
                                                                                    <div className='flex justify-center items-center mb-3'>
                                                                                        <p
                                                                                            onClick={() => handleStartSearching()}
                                                                                            className={'w-64 py-2 rounded-xl text-white bg-bot-blue hover:bg-dark-bot-blue text-center text-xl font-semibold cursor-pointer hover:no-underline'}
                                                                                        >
                                                                                            Start searching
                                                                                        </p>
                                                                                    </div>
                                                                                </Card>
                                                                            </div>
                                                                        ) : (
                                                                            <div className={`flex ${agentView ? 'justify-start' : 'justify-center'} lg:ml-5 text-xl font-bold`}>
                                                                                {agentView ? (
                                                                                    <p>{isMobile ? 'Tap the arrow above, then the "Add Client" button to get started.' : 'Click the "Add Client" button to get started.'}</p>
                                                                                ) : (
                                                                                    <div className={`flex flex-col justify-center items-center text-center ${isMobile ? 'mx-3' : ''}`} style={{width: '90vw'}}>
                                                                                        <p className={`font-extrabold tracking-tight ${isMobile ? 'text-3xl' : 'text-4xl'}`}>Your dashboard awaits 🎉</p>
                                                                                        <div className={`lg:flex lg:justify-center lg:items-center lg:gap-2 text-2xl ${isMobile ? 'mt-5' : 'mt-4'}`}>
                                                                                            <div>
                                                                                                <p onClick={() => setOpenAgentModal(true)} className={`cursor-pointer mb-2 py-2.5 px-3 bg-light-bot-blue-0 border-light-bot-blue hover:bg-light-bot-blue-2 border-2 rounded-xl`}>
                                                                                                    Contact an agent
                                                                                                </p>
                                                                                            </div>
                                                                                            <p className='mb-2'>to get started.</p>
                                                                                        </div>
                                                                                        <img src={buyer_dashboard_walkthrough} alt="Dashboard gif" className={`shadow rounded-2xl ${isMobile ? 'mt-16' : 'mt-16'}`} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <Card className={(loadingFavs || viewActivity || viewLeads || activeClient === null) ? "hidden" : `${isMobile ? 'w-property-tile' : 'w-[375px]'} ${agentView ? 'h-[350px]' : 'h-[415px]'} mx-4 mb-4 mt-10 pb-2 drop-shadow-md shadow-slate-200 rounded-xl pb-4`}>
                                    <p className='text-center text-xl font-semibold tracking-wide mt-3 -mb-3'>{agentView ? 'My Profile' : 'Contact your agent'}</p>
                                    <div className={agentView ? 'flex justify-end items-center -mt-8 mr-2' : 'hidden'}>
                                        <FontAwesomeIcon
                                            onClick={() => setOpenAgentCard(true)}
                                            icon={faPencil}
                                            className='rounded-full hover:bg-light-gray text-gray hover:text-black text-lg mr-1 mt-1 p-3 cursor-pointer z-50'
                                        />
                                    </div>
                                    <div className={`flex flex-col justify-center items-center text-center mx-7 ${agentView ? 'mt-3' : 'mt-8'}`}>
                                        {/* <FontAwesomeIcon icon={faUser} className='p-7 rounded-full bg-light-gray text-5xl mt-3' /> */}
                                        <div className='cursor-pointer' onClick={() => agentView ? setOpenAgentCard(true) : ''}>
                                            <CircleImage imageUrl={`https://fusszpkrdwzhyaeqxlvv.supabase.co/storage/v1/object/public/profile_pictures/${agentProfilePic}`} width={140} height={140} />
                                        </div>
                                        <div className='mt-3'>
                                            <p className='text-xl font-bold'>{agentName.length > 0 ? agentName : (agentView ? 'Your name' : 'Agent')}</p>
                                            <div className='flex justify-start items-center gap-2 mt-3 mb-2'>
                                                <FontAwesomeIcon icon={faEnvelope} className='text-base text-black' />
                                                <a href={`mailto:${agentEmail}`} className="hover:no-underline">{agentEmail}</a>
                                            </div>
                                            <div className={`flex justify-start items-center gap-2 ${agentPhone ? '' : 'hidden'}`}>
                                                <FontAwesomeIcon icon={faPhone} className='text-base text-black' />
                                                <a href={`tel:${agentPhone ? agentPhone : ''}`} className="hover:no-underline">{agentPhone ? agentPhone : ''}</a>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => setOpenAgentModal(true)}
                                            className={agentView ? 'hidden' : 'text-white bg-bot-blue hover:bg-dark-bot-blue rounded-xl w-full py-2 mt-6 text-xl'}
                                        >
                                            Contact agent
                                        </button>
                                    </div>
                                </Card>
                            </div>
                            <div className={`flex justify-center items-center ${compareFavorites && viewActivity === false && showFavorites === false && openAgentCard === false ? '' : 'hidden'}`}>
                                <div className='fixed bottom-0 lg:mb-10 mb-8 shadow shadow-gray rounded-xl' style={{zIndex: 5000}}>
                                    {compareFavoritesListLength > 1 ? (
                                        <p
                                            onClick={() => handleCompareFavorites()}
                                            className='text-center w-96 px-3 py-4 bg-bot-blue hover:bg-dark-bot-blue cursor-pointer text-2xl font-bold rounded-xl text-white'
                                        >
                                            <FontAwesomeIcon icon={faCodeCompare} className={`font-black pr-3`} />
                                            Compare {compareFavoritesListLength} properties
                                        </p>
                                    ) : (
                                        <p className='text-center w-96 px-3 py-4 bg-light-bot-blue text-xl font-bold rounded-xl text-white'>
                                            Select 2+ properties to compare
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}