import React from 'react'
import { Card } from 'react-bootstrap';
import allFunctions from '../containers/Functions';

export default function CompCard(props) {
    let { property, pricePerSqFt, defaults, propertyEdits } = props;

    // calculate returns
    property = allFunctions.AddReturnsToProperties([property], defaults, propertyEdits)[0];
    
    // re-doing the negative sign
    let cashFlow = property.cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (cashFlow.substring(0, 1) === "-") {
        cashFlow = `-$${cashFlow.substring(1, cashFlow.length)}`;
    } else {
        cashFlow = `$${cashFlow}`;
    }

    let propertyType = property.propertyTypeDimension;
    if (propertyType.length > 6) {
        propertyType = `${property.propertyTypeDimension.slice(0, 6)}..`;
    }

    return (
        <Card
            id={property.zpid}
            className='w-64 border-light-gray-3 border-1 rounded-xl shadow-gray shadow-sm mr-3 flex-shrink-0'
        >
            <div className='h-32 w-auto' style={{
                    backgroundImage: `url(${property.imgSrc})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: '11px 11px 0px 0px'
                }}
            />
            <p className="bg-light-gray font-gray w-full flex justify-center text-sm px-1">{property.address}</p>
            <div className='mt-1 ml-1'>
                <p><span className='font-bold'>${property.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span> | <span className='text-sm'>{property.bedrooms} bd, {property.bathrooms} bth, {property.livingArea} sf</span></p>
                <div className='flex pl-2 mr-1 mb-1 bg-light-gray-2 rounded-xl'>
                    <div className='text-left gap-2 my-1 mr-1 text-sm' style={{flex: '55%'}}>
                        <p>Cash Flow: <b>{cashFlow}</b></p>
                        <p>Cap Rate: <b>{property.capRate}%</b></p>
                    </div>
                    <div className='text-left gap-2 my-1 mr-1 text-sm' style={{flex: '45%'}}>
                        <p>Type: <b>{propertyType}</b></p>
                        <p>$/sf: <b>${pricePerSqFt}</b></p>
                    </div>
                </div>
            </div>
        </Card>
    )
}
