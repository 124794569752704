import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import no_homes from '../assets/no_homes.png';

export default function NoHomes(props) {
    const { page } = props;
    let instructions = "Adjust your view to see properties";
    if (page === "Favorites") {
        instructions = "❤️️ a property to save it in your favorites"
    } else if (page === "Agent Dashboard") {
        instructions = "❤️️ a property to save it to your client's dashboard"
    } else if (page === "Client Dashboard") {
        instructions = "❤️️ a property to save it to your dashboard"
    }

  return (
    <div className='flex justify-center mt-5'>
        <Card
            className="lg:flex w-property-tile m-1 pb-2 drop-shadow-md shadow-slate-200 rounded-xl"
        >
            <div className='h-48 w-auto' style={{
                    backgroundImage: `url(${no_homes})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                }}
            />
            <div className='my-3 mx-2'>
                <p className='flex justify-center text-center text-xl font-bold'>No Saved Properties</p>
                <p className='flex justify-center text-center mt-2 mx-3'>{instructions}</p>
            </div>
            <div className={page && page.includes('Dashboard') ? 'flex justify-center items-center mb-3' : 'hidden'}>
                <Link
                    to="/search"
                    className={'w-64 py-2 rounded-xl text-white bg-bot-blue hover:bg-dark-bot-blue text-center text-xl font-semibold cursor-pointer hover:no-underline'}
                >
                    Start searching
                </Link>
            </div>
        </Card>
    </div>
  )
}
