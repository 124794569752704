import { TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { supabase } from '../supabase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CircleImage from './CircleImage';

export default function AgentCard(props) {
    const { session, agentName, setAgentName, agentPhone, setAgentPhone, agentEmail,
        agentProfilePic, setAgentProfilePic, setOpenAgentCard
    } = props;
    const [loadingImage, setLoadingImage] = useState(false);

    const notifySuccessMsg = (msg) => toast.success(<p className='font-semibold'>{msg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
    
    const notifyErrorMsg = (msg) => toast.error(<p className='font-semibold'>{msg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const handleEditProfile = async () => {
        // save to supabase
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                name: agentName.length > 0 ? agentName : null,
                phone: agentPhone.length > 0 ? agentPhone : null
            }
            
            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) {
                throw error
            } else {
                setOpenAgentCard(false);
                notifySuccessMsg('Successfully updated profile.')
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    const handleFileInputChange = async (event) => {
        if (event.target.files.length > 0) {
            setLoadingImage(true);
            const file = event.target.files[0];
    
            // Check if the file already exists in the "profile_pictures" folder
            const profileURL = `https://fusszpkrdwzhyaeqxlvv.supabase.co/storage/v1/object/public/profile_pictures/${session.user.id}`
            
            try {
                const response = await fetch(profileURL, {
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate'
                    }
                });
                if (response.ok) {
                    // File with the same name already exists, update it
                    const { error } = await supabase.storage
                        .from('profile_pictures')
                        .update(session.user.id, file, {
                            cacheControl: '3600',
                            upsert: true
                        })
    
                    if (error) {
                        throw error
                    } else {
                        setAgentProfilePic(session.user.id);
                        notifySuccessMsg('Successfully saved image');
                    }
                } else {
                    // Upload the selected file to the "profile_pictures" folder in Supabase storage
                    const { newError } = await supabase.storage
                        .from('profile_pictures')
                        .upload(session.user.id, file);
        
                    if (newError) {
                        console.error(newError);
                    } else {
                        setAgentProfilePic(session.user.id);
                        notifySuccessMsg('Successfully saved image');
                    }
                }
            } catch (error) {
                if (error.hasOwnProperty('error') && error.error === "Payload too large") {
                    notifyErrorMsg('Image is too large. Please try again.')
                    setLoadingImage(false);
                } else {
                    console.error(error)
                }
            }
        }
    };
    
    return (
        <div>
            <div className='flex justify-center'>
                <p className='text-center text-3xl font-black tracking-tight mt-2 mb-3'>My Profile</p>
            </div>
            <div className='text-center mt-3 mb-7'>
                <label htmlFor="fileInput">
                    <Tooltip title="Change image">
                        <div className='cursor-pointer'>
                            <CircleImage imageUrl={`https://fusszpkrdwzhyaeqxlvv.supabase.co/storage/v1/object/public/profile_pictures/${agentProfilePic}`} width={160} height={160} />
                        </div>
                    </Tooltip>
                </label>
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileInputChange}
                    style={{ display: 'none' }}
                />
                <div className={loadingImage ? 'bg-light-bot-blue-0 rounded-xl p-2 text-gray' : 'hidden'}>
                    <div className='flex justify-end items-center'>
                        <FontAwesomeIcon icon={faXmark} className="text-base cursor-pointer" onClick={() => setLoadingImage(false)} />
                    </div>
                    <p className='text-start -mt-4'>This may take a few seconds to upload. If refreshing the page doesn't work, please try again.</p>
                </div>
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="John Doe"
                    style={{width: '100%'}}
                    value={agentName}
                    onChange={(e) => setAgentName(e.target.value)}
                />
            </div>
            <div className='flex justify-center my-3'>
                <Tooltip title={<p className='text-sm'>This email is linked with your account. You must create a new account to change it.</p>}>
                    <TextField
                        disabled={true}
                        id="email"
                        name="email"
                        label="Email"
                        style={{width: '100%'}}
                        value={agentEmail ? agentEmail : session.user.email}
                    />
                </Tooltip>
            </div>
            <div className='flex justify-center my-3'>
                <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    placeholder="123-456-7890"
                    style={{width: '100%'}}
                    value={agentPhone}
                    onChange={(e) => setAgentPhone(e.target.value)}
                />
            </div>
            <div className='flex justify-between gap-2 text-xl mt-5 mb-3'>
                <button
                    onClick={() => setOpenAgentCard(false)}
                    className='px-4 py-2.5 font-semibold rounded-xl text-gray hover:bg-light-gray cursor-pointer'
                >
                    Cancel
                </button>
                <button
                    onClick={() => handleEditProfile()}
                    className='px-4 py-2.5 font-semibold rounded-xl text-white bg-bot-blue hover:bg-dark-bot-blue cursor-pointer'
                >
                    Save
                </button>
            </div>
        </div>
    )
}
