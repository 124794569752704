import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import confetti from '../assets/confetti.gif';
import CurrencyInput from 'react-currency-input-field';
import { Autocomplete, TextField } from '@mui/material';
import { supabase } from '../supabase';

export default function ClientOnboarding(props) {
    const { session, activeClient, setActiveClient, inviteCode, setOpenClientOnboardingModal } = props;
    
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState(activeClient ? activeClient.name : '');
    const [phone, setPhone] = useState('');
    let initBudget = activeClient.budget.split('-');
    initBudget.forEach((budget, index) => {
        initBudget[index] = budget.includes("Any") ? 0 : budget.trim()
    });
    let initMinBudget = initBudget.length > 1 && initBudget[0] !== 0 ? parseInt(initBudget[0].replace('$', '').replace(',', '')) : 0;
    let initMaxBudget = initBudget.length > 1 && initBudget[1] !== 0 ? parseInt(initBudget[1].replace('$', '').replace(',', '')) : 0;
    let initPropertyTypes = activeClient.propertyTypes ? activeClient.propertyTypes.split(',') : [];
    let newInitPropTypes = []
    if (initPropertyTypes.length > 0) {
        initPropertyTypes.forEach(str => {
            newInitPropTypes.push({
                "title": str.trim(),
                "value": str.trim()
            })
        })
    }
    const [minBudget, setMinBudget] = useState(initMinBudget);
    const [maxBudget, setMaxBudget] = useState(initMaxBudget);
    const [timeline, setTimeline] = useState(activeClient.timeline);
    const [userPropertyTypes, setUserPropertyTypes] = useState(newInitPropTypes);

    useEffect(() => {
        // sets the client's session.user.id to their client profile
        const setClientId = async () => {
            // initialize client in supabase
            try {
                const updates = {
                    id: `client-id-${inviteCode}`,
                    user_id: session ? session.user.id : null
                }
                
                let { error } = await supabase.from('client').upsert(updates)

                if (error) throw error
            } catch (error) {
                console.error(error.message)
            }
        }
        setClientId()
        // eslint-disable-next-line
    }, [])


    const propertyTypes = [
        {
            "title": 'Condo',
            "value": 'Condo'
        },
        {
            "title": 'Townhouse',
            "value": 'Townhouse'
        },
        {
            "title": 'Multi Family',
            "value": 'Multi Family'
        },
        {
            "title": 'Single Family',
            "value": 'Single Family'
        }
    ]

    const notifySuccessMsg = (errorMsg) => toast.success(<p>{errorMsg}</p>, {
        toastId: 'addSuccess',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    // determine if mobile
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    }

    const handleSaveClientInfo = async () => {
        // update local data
        let newClient = activeClient;
        let savePropertyTypes = '';
        userPropertyTypes.forEach(propType => {
            savePropertyTypes += (savePropertyTypes === '' ? `${propType.title}` : `, ${propType.title}`)
        })
        let newBudget = '';
        if (minBudget > 0 && maxBudget === '') {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - Any`
        } else if (minBudget === '' && maxBudget > 0) {
            newBudget = `Any - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        } else if (minBudget > 0 && maxBudget > 0) {
            newBudget = `$${parseFloat(minBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })} - $${parseFloat(maxBudget).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        }
        newClient.budget = newBudget;
        newClient.propertyTypes = savePropertyTypes;
        newClient.timeline = timeline;
        setActiveClient(newClient);
        
        // submit data to supabase
        try {
            const updates = {
                id: `client-id-${inviteCode}`,
                name: name,
                phone: phone,
                budget: newBudget,
                property_types: savePropertyTypes,
                timeline: timeline,
                user_id: session.user.id,
                invite_code: inviteCode
            }
            
            let { error } = await supabase.from('client').upsert(updates)

            if (error) {
                throw error
            } else {
                setOpenClientOnboardingModal(false);
                notifySuccessMsg("Saved client's information.");
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <div className='px-4 py-4'>
            <div className={`${activeStep === 0 ? '' : 'hidden'}`}>
                <div className='flex flex-col justify-center'>
                    <div
                        className='h-48 rounded-md flex justify-center items-center'
                        style={{
                            backgroundImage: `url(${confetti})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    >
                        <p className='text-4xl text-center font-extrabold tracking-tighter'>Welcome to your new account!</p>
                    </div>
                    <div className='flex justify-center mt-16'>
                        <Button
                            variant="outline-primary"
                            className='h-14 w-64 text-xl bg-bot-blue text-white mt-8 border-none'
                            onClick={() => handleNextStep()}
                        >
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
            <div className={`${activeStep === 1 ? '' : 'hidden'}`}>
                <p className='text-3xl text-center font-extrabold tracking-tighter mb-5'>Let's set your profile 🎉</p>
                <div className='flex justify-center my-3'>
                    <input
                        id='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '100%' }}
                        className='h-12 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-xl'
                        placeholder='Name'
                    />
                </div>
                <div className='flex justify-center my-3'>
                    <input
                        id='phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{ width: '100%' }}
                        className='h-12 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-xl'
                        placeholder='Phone number'
                    />
                </div>
                <div className='flex justify-between items-center my-3'>
                    <p>Budget:</p>
                    <div className='flex justify-center items-center gap-2'>
                        <CurrencyInput
                            id="min-budget"
                            onClick={() => handleSelectInput("min-budget")}
                            className="p-1 pl-2 w-32 border-one rounded-md text-lg"
                            decimalsLimit={1}
                            prefix='$'
                            value={minBudget}
                            placeholder="$250,000"
                            onValueChange={(e) => setMinBudget(e)}
                        />
                        <p>to</p>
                        <CurrencyInput
                            id="max-budget"
                            onClick={() => handleSelectInput("max-budget")}
                            className="p-1 pl-2 w-32 border-one rounded-md text-lg"
                            decimalsLimit={1}
                            prefix='$'
                            value={maxBudget}
                            placeholder="$500,000"
                            onValueChange={(e) => setMaxBudget(e)}
                        />
                    </div>
                </div>
                <div className='flex justify-center items-center gap-2 my-4 mr-1.5'>
                    <p className='mr-2'>Timeline:</p>
                    <input
                        id='timeline'
                        name='timeline'
                        value={timeline}
                        onChange={(e) => setTimeline(e.target.value)}
                        style={{ width: isMobile ? '100%' : '100%', borderWidth: '1px' }}
                        className='h-10 pl-3 border-light-gray-3 text-dark-gray border-2 rounded-md'
                        placeholder='3-4 months'
                    />
                </div>
                <div className='flex justify-center items-center gap-3 mt-4 mr-1'>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        style={{width: isMobile ? '100%' : '100%'}}
                        options={propertyTypes}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, value) => setUserPropertyTypes(value)}
                        value={userPropertyTypes}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select property types"
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between items-center gap-16 mt-5 mb-3'>
                    <button
                        block
                        size="lg"
                        style={{width: '100%'}}
                        onClick={() => setOpenClientOnboardingModal(false)}
                        className='text-gray text-xl py-2.5 font-semibold rounded-xl hover:bg-light-gray'
                    >
                        Cancel
                    </button>
                    <button
                        block
                        size="lg"
                        style={{width: '100%'}}
                        onClick={() => handleSaveClientInfo()}
                        className='bg-bot-blue text-white text-xl py-2.5 font-semibold rounded-xl hover:bg-dark-bot-blue'
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}