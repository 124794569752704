import React from 'react';

function NotFound() {
    return (
        <div className="pt-100 text-center">
            <p className='text-3xl font-bold mt-5'>Sorry, page not found!</p>
        </div>
    );
}

export default NotFound;