import React from 'react';
import { Button } from 'react-bootstrap';

export default function BedButtons(props) {
    const { numBed, bed, setBed, bath, setBedBathTitle, handleFilter } = props;

    const handleBed = () => {
        setBed(numBed);
        setBedBathTitle(`Bd: ${numBed}, Bth: ${bath}`);
        handleFilter(numBed, '', 'bed');
    }
    
    return (
        <Button
            variant='primary'
            onClick={handleBed}
            className={(numBed === bed) ? 'bg-bot-blue text-white' : 'text-bot-blue hover:text-white'}
        >
            {numBed}
        </Button>
    )
}
