import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Success() {
  return (
    <div className='flex flex-col justify-center items-center gap-3 mt-5'>
      <FontAwesomeIcon icon={faCheckCircle} className="text-6xl text-green"/>
      <p className='text-4xl font-extrabold tracking-tight'>Purchase successful!</p>
      <p className='text-xl'>Short term data is now available</p>
      <div className='flex justify-center gap-4 mt-5'>
        <button
          style={{borderWidth: '1px'}}
          className='w-48 py-3 text-black rounded-md hover:bg-light-gray-2 hover:text-black'
        >
          View subscriptions
        </button>
        <Link
          to="/search"
          className='text-left hover:no-underline hover:text-bot-blue'
        >
          <button className='w-48 py-3 rounded-md bg-black text-white border'>Search homes</button>
        </Link>
      </div>
    </div>
  )
}
