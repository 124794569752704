import { React, useState } from 'react';

export default function AboutDescription(props) {
    const {limit, description} = props;
    const [showMore, setShowMore] = useState(false)

    return (
        <div className='w-auto wrap text-left mb-3'>
            {showMore ? (
                <p className='text-base text-left'>{description}</p>
            ) : (
                <p className='text-base'>{description.substr(0, limit)}...</p>
            )
            }
            <p className='flex justify-start text-bot-blue cursor-pointer hover:underline'
                onClick={() => setShowMore(!showMore)}
            >
                { showMore ? "Show less" : "Show more" }
            </p>
        </div>
    )
}
