import React, { useState } from 'react';
import { Divider, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function PortfolioUsers(props) {
    const { user, handleAddOrRemoveUser } = props;

    // role selection
    const [role, setRole] = useState(user.role);
    const [anchorEl, setAnchorEl] = useState(null);
    const openSortFavs = Boolean(anchorEl);

    const handleSelectRoleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectRole = (newRole) => {
        setAnchorEl(null);
        setRole(newRole);
        // save changes to supabase
    }

    const handleInitRemoveUser = () => {
        handleAddOrRemoveUser(user.email, null, user.id);
        setAnchorEl(null);
    }

    return (
        <div className='flex justify-between items-center pt-2.5'>
            <p>{user.email}</p>
            {user.role === "Owner" ? (
                <p className='mr-3 pt-2 pb-1.5'>Owner</p>
            ) : (
                <div>
                    <Tooltip title="Change role">
                        <p
                            id="demo-positioned-button"
                            aria-haspopup="true"
                            className={`flex justify-center gap-2 py-2 px-2 rounded-xl hover:bg-light-gray-2 cursor-pointer`}
                            onClick={handleSelectRoleMenu}
                        >
                            {role}
                            <FontAwesomeIcon icon={faAngleDown} className={`mt-1 font-black`} />
                        </p>
                    </Tooltip>
                    <Menu
                        id="demo-positioned-menu"
                        anchorEl={anchorEl}
                        open={openSortFavs}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem key='editor' sx={{fontSize: '17px', width: '125px'}} onClick={() => handleSelectRole('Editor')}>Editor</MenuItem>
                        <Divider />
                        <MenuItem key='remove' sx={{fontSize: '17px', width: '125px', color: 'red'}} onClick={() => handleInitRemoveUser()}>Remove</MenuItem>
                    </Menu>
                </div>
            )}
        </div>
    )
}