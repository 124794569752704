import React from 'react';
import { faCheckCircle, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiFillHeart } from 'react-icons/ai';
import { Card } from 'react-bootstrap';
import allFunctions from '../containers/Functions';

export default function FavoriteCard(props) {
    let { activeClient, setSelectedProperty, favoritesList, setFavoritesList,
        property, defaults, setShowPropertyModal, updateFavorites, compareFavoritesListLength,
        setCompareFavoritesListLength, propertyEdits, setPropertyEdit,
        compareFavorites, compareFavoritesList, setCompareFavoritesList,
        favoritesListFull, setFavoritesListFull, handleClickConversation
    } = props;
    
    // calculate cash flow and cap rate
    let purchasePrice = property.price.toLocaleString(undefined, { maximumFractionDigits: 2 })
    let propertyEdit = null;
    if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
        if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
            propertyEdit = propertyEdits.properties[`${property.zpid}`];
            if (propertyEdit.hasOwnProperty('price')) {
                purchasePrice = (propertyEdit['price']).toLocaleString(undefined, { maximumFractionDigits: 2 });
            }
        }
    }
    let cashFlow = allFunctions.CalcReturns(property, defaults, propertyEdit).cashFlow;
    let capRate = allFunctions.CalcReturns(property, defaults, propertyEdit).capRate;

    // re-doing the negative sign for cash flow and cap rate
    cashFlow = cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (cashFlow.substring(0, 1) === "-") {
        cashFlow = `-$${cashFlow.substring(1, cashFlow.length)}`;
    } else {
        cashFlow = `$${cashFlow}`;
    }
    capRate = capRate.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (capRate.substring(0, 1) === "-") {
        capRate = `-${capRate.substring(1, capRate.length)}`;
    } else {
        capRate = `${capRate}`;
    }

    // address
    let address = property.address;
    if (address.length > 44) {
        address = `${address.substring(0, 44)}...`;
    }

    // listing agent and brokerage
    let listingAgentAndBrokerage = (
        `${property.listingAgent ? property.listingAgent : ''}
        ${property.listingAgent && property.brokerageName ? ' | ' : ''}
        ${property.brokerageName ? property.brokerageName : ''}`
    )
    if (listingAgentAndBrokerage.length > 68) {
        listingAgentAndBrokerage = `${listingAgentAndBrokerage.substring(0, 68)}...`;
    }
    
    const handleCardClick = () => {
        if (compareFavorites) {
            // before checking the mark
            let newCompareFavsList = [];
            if (compareFavoritesList.includes(property.zpid)) {
                setCompareFavoritesListLength(compareFavoritesListLength - 1);
                newCompareFavsList = compareFavoritesList
                newCompareFavsList = newCompareFavsList.length === 1 ? [] : (newCompareFavsList.filter(data => data !== property.zpid));
                setCompareFavoritesList(newCompareFavsList);
            } else {
                setCompareFavoritesListLength(compareFavoritesListLength + 1);
                newCompareFavsList = compareFavoritesList.length === 0 ? [] : compareFavoritesList;
                newCompareFavsList.push(property.zpid);
                setCompareFavoritesList(newCompareFavsList);
            }
        } else {
            let propertyEdit = null;
            if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
                if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                    propertyEdit = propertyEdits.properties[`${property.zpid}`];
                }
            }
            
            let currentURL = window.location.href;
            let urlArray = currentURL.split("/dashboard");
            let newURL = `${urlArray[0]}/dashboard?id=${property.zpid}`;
            window.history.pushState('page2', 'Title', newURL);
            
            setPropertyEdit(propertyEdit);
            setSelectedProperty(property);
            setShowPropertyModal(true);
        }
    }

    const handleUnclickHeart = () => {
        let newFavList = favoritesList.filter(data => data !== property.zpid);
        let newFavListFull = favoritesListFull.filter(data => data.zpid !== property.zpid);
        setFavoritesList(newFavList);
        setFavoritesListFull(newFavListFull);
        updateFavorites(activeClient.id, newFavList);
    }


    return (
        <>
            <Card
                id={property.zpid}
                onMouseOver={() => setSelectedProperty(property)}
                className="lg:flex w-property-tile m-1 pb-2 drop-shadow-md shadow-slate-200 rounded-xl hover:shadow-light-gray-3 hover:shadow-lg"
            >
                <div
                    onClick={() => handleCardClick()}
                    className={`flex justify-end mr-3 mt-2 cursor-pointer -mb-10 z-50 ${compareFavorites ? '' : 'hidden'}`}
                >
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className={`cursor-pointer text-3xl ${compareFavoritesList.includes(property.zpid) ? 'text-bot-blue' : 'text-white'}`}
                    />
                </div>
                <div className={compareFavorites ? 'mt-0' : 'mt-2'}>
                    <div className={`flex justify-end -mb-10 z-50 ${compareFavorites ? 'hidden' : ''}`}>
                        <FontAwesomeIcon
                            icon={faComment}
                            className='text-2xl text-light-gray mr-3 mt-0.5 hover:text-light-gray-3 cursor-pointer'
                            onClick={() => handleClickConversation()}
                        />
                        <AiFillHeart
                            className="cursor-pointer text-3xl text-red hover:text-[#ce2913] mr-3"
                            data-tip data-for="saveProperty"
                            onClick={() => handleUnclickHeart()}
                        />
                    </div>
                    <div
                        className={`h-36 w-auto cursor-pointer rounded-t-xl ${compareFavorites ? 'opacity-50' : ''}`}
                        style={{
                            backgroundImage: `url(${property.imgSrc})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                        onClick={() => handleCardClick()}
                    />
                </div>
                <div className={`cursor-pointer ${compareFavorites ? 'opacity-50' : ''}`} onClick={() => handleCardClick()}>
                    <p className="bg-light-gray font-gray w-full flex justify-center text-sm">{address}</p>
                    <div className='mt-1 ml-3'>
                        <p className='text-lg'>${purchasePrice} | <span className='text-sm'>{property.bedrooms} bd, {property.bathrooms} bth, {property.livingArea} sq. ft.</span></p>
                        <div className='flex'>
                            <div className='mr-3'>
                                <p className=''>Cash Flow: <b className='text-lg'>{cashFlow}</b></p>
                            </div>
                            <div>
                                <p className=''>Cap Rate: <b className='text-lg'>{capRate}%</b></p>
                            </div>
                        </div>
                        <p className='text-xs mt-1 text-gray'>{listingAgentAndBrokerage}</p>
                    </div>
                </div>
            </Card>
        </>
    )
}