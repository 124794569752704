import { useState } from "react";
import styles from "./index.module.css";
import loading_spinner from '../assets/loading_spinner.gif';

export default function Home() {
  const [userPrompt, setUserPrompt] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const SERVER_URL = window.location.href.includes('/localhost:')
    ? process.env.REACT_APP_LOCAL_SERVER_URL
    : process.env.REACT_APP_SERVER_URL

  async function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/chat-gpt-response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: userPrompt,
          maxTokens: 50
        })
      });

      const data = await response.json();
      if (response.status !== 200) {
        throw data.error || new Error(`Request failed with status ${response.status}`);
      }

      let chatResponse = data.result;
      if (chatResponse.charAt(0) === "?") {
        chatResponse = chatResponse.substr(1);
      }
      if (chatResponse.charAt(chatResponse.length) !== ".") {
        chatResponse += "...";
      }
      setResult(chatResponse);
      setLoading(false);
      setUserPrompt("");
    } catch(error) {
      // Consider implementing your own error handling logic here
      console.error(error);
      alert(error.message);
    }
  }

  return (
    <div>
      <main className={styles.main}>
        <p className="text-5xl">🏠</p>
        <h3>The Investorbot</h3>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="prompt"
            placeholder="Ask me anything"
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}
          />
          <input type="submit" value="Submit" disabled={loading} />
        </form>
        <div className="mt-5 mx-3 mb-5 lg:w-[700px]">
          {loading ? (
            <div className='flex flex-col justify-center items-center gap-5 mt-3'>
              <img src={loading_spinner} className='h-10 text-center text-white' alt='Loading...' />
              <p className="text-gray text-lg text-center">One moment please...<br/>The Investorbot is thinking 🤔</p>
            </div>
          ) : (
            <div className={result.length > 0 ? 'text-lg p-3 rounded-xl bg-light-gray' : 'hidden'}>
              <p><span className="font-bold">The Investorbot: </span>{result}</p>
              <p className="text-sm italics text-light-gray-4 mt-3">Response has been clipped due to usage limitations.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
