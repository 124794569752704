import React, { useEffect, useState } from 'react'
import RentalCompRow from './RentalCompRow';

export default function Comp(props) {
    const { property, last } = props;

    let pricePerSqFt = (property.price !== null && property.livingArea !== null) ? (
        `$${(property.price / property.livingArea).toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    ) : (
        '-'
    )

    // re-doing the negative sign
    let cashFlow = property.cashFlow === null ? '-' : property.cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 });
    if (cashFlow.substring(0, 1) === "-") {
        cashFlow = `-$${cashFlow.substring(1, cashFlow.length)}`;
    } else {
        cashFlow = `$${cashFlow}`;
    }

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    
    // type
    let propertyType = property.propertyTypeDimension;
    if (property.propertyTypeDimension === "Single Family") {
        propertyType = isMobile ? "Single Fam.." : "Single Family"
    }

    // status
    let listingStatus = property.homeStatus;
    if (listingStatus === "FOR_SALE") {
        listingStatus = "For Sale";
    } else if (listingStatus === "SOLD") {
        listingStatus = "Sold";
    } else if (listingStatus === "OTHER") {
        listingStatus = "Other";
    } else if (listingStatus === "RECENTLY_SOLD") {
        listingStatus = "Recently Sold";
    } else if (listingStatus === "PENDING") {
        listingStatus = "Pending";
    } else if (listingStatus === "FOR_RENT") {
        listingStatus = "For Rent";
    }
    
    return (
        <div className='text-center'>
            <div className='flex justify-center'>
                <img src={property.imgSrc === null ? '' : property.imgSrc} alt="property" className="rounded-md" style={{height: isMobile ? '80px' : '120px'}} />
            </div>
            <div className='flex justify-center mb-2'>
                <p className={`pt-2 bg-light-gray text-xs rounded-md h-14 ${isMobile ? 'w-28' : 'w-44'}`}>{property.address === null ? '-' : property.address}</p>
            </div>
            <p className={(last) ? 'py-2.5 px-2 bg-light-bot-blue h-7 rounded-r-md' : 'py-2.5 px-2 bg-light-bot-blue h-7'}>&nbsp;</p>
            <p className={(last) ? 'py-2.5 px-2 rounded-r-md' : 'py-2.5 px-2'}>{cashFlow}</p>
            <p className={(last) ? 'py-2.5 px-2 bg-light-gray-2 rounded-r-md' : 'py-2.5 px-2 bg-light-gray-2'}>{property.capRate === null ? '-' : property.capRate}%</p>
            <p className={(last) ? 'py-2.5 px-2 rounded-r-md' : 'py-2.5 px-2'}>{property.totalReturn === null ? '-' : property.totalReturn}%</p>
            <p className={(last) ? 'py-2.5 px-2 bg-light-bot-blue h-7 rounded-r-md' : 'py-2.5 px-2 bg-light-bot-blue h-7'}>&nbsp;</p>
            <p className={(last) ? 'py-2.5 px-2 bg-light-gray-2 rounded-r-md' : 'py-2.5 px-2 bg-light-gray-2'}>${property.price === null ? '-' : property.price.toLocaleString(undefined, { maximumFractionDigits: 2})}</p>
            <p className={(last) ? 'py-2.5 px-2 rounded-r-md' : 'py-2.5 px-2'}>{property.bedrooms === null ? '-' : property.bedrooms}</p>
            <p className={(last) ? 'py-2.5 px-2 bg-light-gray-2 rounded-r-md' : 'py-2.5 px-2 bg-light-gray-2'}>{property.bathrooms === null ? '-' : property.bathrooms}</p>
            <p className={(last) ? 'py-2.5 px-2 rounded-r-md' : 'py-2.5 px-2'}>{property.livingArea === null ? '-' : property.livingArea.toLocaleString(undefined, { maximumFractionDigits: 2})}</p>
            <RentalCompRow last={last} gray={true} value={pricePerSqFt} toLocaleString={true} />
            <RentalCompRow last={last} gray={false} value={propertyType} />
            <RentalCompRow last={last} gray={true} value={listingStatus} />
        </div>
    )
}
