import React, { useEffect, useState } from 'react'
import Comp from '../components/Comp';
import allFunctions from './Functions';

export default function CompAnalysis(props) {
    const { compProperties, defaults, propertyEdits, sortTitle } = props;

    // determines if the screen is a mobile device
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    let newCompProperties = compProperties;
    if (!compProperties[0].hasOwnProperty('cashFlow')) {
        for (let i = 0; i < newCompProperties.length; i++) {
            // add returns to properties
            newCompProperties[i] = allFunctions.AddReturnsToProperties(newCompProperties[i], defaults, propertyEdits);
            newCompProperties[i] = allFunctions.MasterQuicksort(newCompProperties[i], sortTitle ? sortTitle : 'Cash Flow');
        }
        console.log('done');
    }

    return (
        <div>
            <p className='text-center font-bold text-3xl tracking-tight'>Comps Analysis</p>
            <div className='flex justify-start mt-5'>
                <div>
                    <div className='font-bold overflow-hidden' style={{marginTop: isMobile ? '144px' : '184px', width: isMobile ? '115px' : '130px'}}>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-bot-blue text-white`} style={{lineHeight: '8px'}}>Returns</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md`}>Cash Flow</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-gray-2`}>Cap Rate</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md`}>Total Return</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-bot-blue text-white`} style={{lineHeight: '8px'}}>Details</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-gray-2`}>Price</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md`}>Beds</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-gray-2`}>Baths</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md`}>Square feet</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-gray-2`}>Price/sq ft.</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md`}>Type</p>
                        <p className={`py-2.5 ${isMobile ? 'pl-1' : 'pl-3'} pr-2 rounded-l-md bg-light-gray-2`}>Status</p>
                    </div>
                </div>
                <div className='overflow-x-scroll'>
                    <div className='flex'>
                        {newCompProperties.map(function (compProperty) {
                            return <Comp property={compProperty} defaults={defaults} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
