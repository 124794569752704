import { React } from 'react';
import { Card } from 'react-bootstrap';
import allFunctions from '../containers/Functions';
import { setMapCache } from '../lib/mapCache';

export default function PropertyTile(props) {
    const {
        property, setSelectedProperty, setShow, defaults, hideCashFlow,
        hideCapRate, hideCoCReturn, hideTotalReturn, propertyEdits,
        setPropertyEdit, index, session, setOpenSignUp, setLoginHeader,
    } = props;

    // calculate returns here
    let propertyEdit = null;
    if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
        if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
            propertyEdit = propertyEdits.properties[`${property.zpid}`];
        }
    }
    let returns = allFunctions.CalcReturns(property, defaults, propertyEdit);  // if property has edits, pass it ONLY that property's edits here
    let cashflow = returns.cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 });
    if (cashflow.substring(0, 1) === "-") {
        cashflow = `-$${cashflow.substring(1, cashflow.length)}`;
    } else {
        cashflow = `$${cashflow}`;
    }

    // address
    let address = property.address;
    if (address.length > 44) {
        address = `${address.substring(0, 44)}...`;
    }

    // agent and brokerage
    let agentAndBrokerage = `${property.listingAgent} | ${property.brokerageName}`;
    if (property.listingAgent === null && property.brokerageName === null) {
        agentAndBrokerage = '';
    } else if (property.listingAgent === null) {
        agentAndBrokerage = property.brokerageName;
    } else if (property.brokerageName === null) {
        agentAndBrokerage = property.listingAgent;
    }
    if (agentAndBrokerage.length > 50) {
        agentAndBrokerage = `${agentAndBrokerage.slice(0, 50)}...`;
    }

    const handleMouseOver = () => {
        document.getElementById(property.zpid).style.boxShadow = "1px 1px 8px gray";
        document.getElementById(property.zpid).style.transition = "box-shadow 0.1s ease-in-out";
        setSelectedProperty(property);
    };

    const handleMouseOut = () => {
        document.getElementById(property.zpid).style.boxShadow = "None";
    };

    const handleCardClick = async () => {
        let propertiesViewed = localStorage.getItem('PROPERTIES_VIEWED');
        propertiesViewed = propertiesViewed ? parseInt(propertiesViewed) : 0
        if (session === null && propertiesViewed > 2) {
            // prompt a user to sign up after 3 properties viewed
            setLoginHeader('Sign up for free to view more');
            setOpenSignUp(true);
        } else {
            // increment properties viewed (if not signed in)
            if (session === null) {
                localStorage.setItem('PROPERTIES_VIEWED', propertiesViewed + 1);
            }

            let propertyEdit = null;
            if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
                if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                    propertyEdit = propertyEdits.properties[`${property.zpid}`];
                }
            }
            
            // set URL
            let currentURL = window.location.href;
            let urlArray = currentURL.split("/search");
            let newURL = `${urlArray[0]}/search?id=${property.zpid}`;
            window.history.pushState('page2', `${property.address} | Investorbot`, newURL);

            // open URL
            setSelectedProperty(property);
            setPropertyEdit(propertyEdit);
            setShow(true);
            setMapCache(defaults, property, defaults.mapCenter, defaults.mapZoom);
        }
    }


    return (
        <div className="small-flex-center" id={index === 0 ? 'step-seven' : ''}>
            <Card
                className="lg:flex w-property-tile m-1 pb-2 drop-shadow-md shadow-slate-200 rounded-xl"
                id={property.zpid}
            >
                <div
                    className='h-32 w-auto cursor-pointer'
                    style={{
                        backgroundImage: `url(${property.imgSrc})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        borderRadius: '11px 11px 0px 0px'
                    }}
                    onClick={() => handleCardClick()}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                />
                <p
                    onClick={() => handleCardClick()}
                    className="bg-light-gray font-gray w-full flex justify-center text-sm cursor-pointer"
                >
                    {address}
                </p>
                <div
                    className='mt-1 ml-3 cursor-pointer'
                    onClick={() => handleCardClick()}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    <p className='text-lg'>
                        ${property.price.toLocaleString(undefined, { maximumFractionDigits: 2 })} |
                        <span className='text-sm'> {property.bedrooms} bd, {property.bathrooms} bth, {property.livingArea} sq. ft.</span>
                    </p>
                    <div className='flex'>
                        <div className='mr-3'>
                            <p className={(hideCashFlow) ? 'hidden' : ''}>Cash Flow: <b className='text-lg'>{cashflow}</b></p>
                            <p className={(hideCoCReturn) ? 'hidden' : ''}>CoC Return: <b className='text-lg'>{returns.cocReturn}%</b></p>
                        </div>
                        <div>
                            <p className={(hideCapRate) ? 'hidden' : ''}>Cap Rate: <b className='text-lg'>{returns.capRate}%</b></p>
                            <p className={(hideTotalReturn) ? 'hidden' : ''}>Total Return: <b className='text-lg'>{returns.totalReturn}%</b></p>
                        </div>
                    </div>
                    <p className='text-xs mt-1 text-gray'>{agentAndBrokerage}</p>
                </div>
            </Card>
        </div>
    )
}
