import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faEnvelope, faPencil, faPhone, faShare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import map_bg from '../assets/map_bg.png';
import stop_switching from '../assets/stop_switching.png';
import share from '../assets/share.png';
import logo from '../assets/logo.png';
import adjust_rent from '../assets/adjust_rent.gif';
import future_returns from '../assets/future_returns.gif';
import preferences from '../assets/preferences.gif';
import taxes from '../assets/taxes.gif';
import comps from '../assets/comps.gif';
import { setGlobalState } from '../state';
import SearchBarLandingPage from '../components/SearchBarLandingPage';

export default function AgentLandingPage() {

  setGlobalState('runTour', false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;


  return (
    <div>

      <div
        className='-mt-4 bg-light-gray pb-32'
        style={{
          backgroundImage: `url(${map_bg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className='flex justify-center items-center'>
          <div className={`flex flex-col justify-center items-center text-start lg:pt-32 pb-16 pt-28 mx-2 ${isMobile ? 'w-header' : 'w-[740px]'}`}>
            <div>
              <div className={isMobile ? 'mx-1' : ''}>
                <p className={`font-black tracking-tight leading-tight ${isMobile ? 'text-4xl' : 'text-5xl'}`}>Invest <span className='text-bot-blue'>confidently</span></p>
                <p className='lg:text-2xl text-xl mt-2 font-semibold leading-10 ml-0.5'>Find your next investment fast 🚀</p>
              </div>
              <div className='mt-3'>
                <SearchBarLandingPage />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-center items-center'>
          <div className='lg:flex lg:justify-center lg:items-center lg:gap-20 lg:py-32 pt-28 mx-3'>
            <div className={isMobile ? 'mt-5' : ''} style={{width: isMobile ? '100%' : '500px'}}>
              <p className={`${isMobile ? 'text-4xl' : 'text-5xl'} font-extrabold tracking-tight`}>Stop the <span className='text-bot-blue'>switching</span></p>
              <p className='lg:text-2xl text-xl font-semibold mt-4'>Say goodbye <span className='text-2xl'>👋</span> to your spreadsheet, calculator, mortgage calculator, PDF exports, notepad...</p>
            </div>
            <div className={isMobile ? 'my-5' : ''}>
              <img src={stop_switching} alt="stop_switching" style={{width: isMobile ? '100%' : '600px'}} />
            </div>
          </div>
        </div>
        <div className='lg:flex lg:justify-center lg:gap-4 mb-5'>
          <div className='flex justify-center'>
            <Card className='py-5 mx-3 mt-5 shadow shadow-black rounded-xl w-96'>
              <div className='flex justify-center'>
                <FontAwesomeIcon icon={faCalculator} className='text-center text-4xl text-bot-blue' />
              </div>
              <p className='flex justify-center text-3xl font-black mt-3 font-landingSans tracking-tighter text-center'>Analyze</p>
              <div className='flex justify-center'>
                <p className='mt-3 text-center lg:w-4/5 text-lg text-gray mx-2 font-semibold'>See the cash flow, cap rate, cash-on-cash, and more for every property on the map.</p>
              </div>
            </Card>
          </div>
          <div className='flex justify-center'>
            <Card className='py-5 mx-3 mt-5 shadow shadow-black rounded-xl w-96'>
              <div className='flex justify-center'>
                <FontAwesomeIcon icon={faPencil} className='text-center text-4xl text-bot-blue' />
              </div>
              <p className='flex justify-center text-3xl font-black mt-3 font-landingSans tracking-tighter text-center'>Edit</p>
              <div className='flex justify-center'>
                <p className='mt-3 text-center lg:w-4/5 text-lg text-gray mx-2 font-semibold'>Edit all the numbers like rent and monthly expenses to automatically update your returns.</p>
              </div>
            </Card>
          </div>
          <div className='flex justify-center'>
            <Card className='py-5 mx-3 mt-5 shadow shadow-black rounded-xl w-96'>
              <div className='flex justify-center'>
                <FontAwesomeIcon icon={faShare} className='text-center text-4xl text-bot-blue' />
              </div>
              <p className='flex justify-center text-3xl font-black mt-3 font-landingSans tracking-tighter text-center'>Share</p>
              <div className='flex justify-center'>
                <p className='mt-3 text-center lg:w-4/5 text-lg text-gray mx-2 font-semibold'>Share deals with anyone to show them exactly how you got your numbers.</p>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <Link to="/dashboard" className='flex justify-center -mt-7 hover:no-underline'>
        <button className='px-5 py-3 text-xl bg-bot-blue hover:bg-dark-bot-blue text-white font-semibold rounded-xl'>Get started</button>
      </Link>

      <div id="how-it-works" className='lg:pt-24 lg:pb-44 pb-24'>
        <p className={`text-center lg:text-4xl text-3xl font-extrabold tracking-tight ${isMobile ? 'mt-5 mb-16' : 'mb-32'}`}>How does it work?</p>  
        {/* Set preferences */}
        <div className={isMobile ? 'mx-3' : ''}>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-32 ${isMobile ? '': 'mt-16'}`}>
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>01</p>
              <p
                style={{width: '280px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                Set preferences
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>Set your preferred down payment, interest rate, and more.</p>
            </div>
            <img src={preferences} alt="adjust_preferences" className={isMobile ? 'mt-12' : 'mt-3'} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-32`}>
            <img src={adjust_rent} alt="adjust_rent" className={isMobile ? 'hidden' : ''} style={{width: '450px'}} />
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>02</p>
              <p
                style={{width: '340px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                Adjust assumptions
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>Adjust assumptions like rent or monthly expenses and watch the returns update.</p>
            </div>
            <img src={adjust_rent} alt="adjust_rent" className={isMobile ? 'mt-10' : 'hidden'} style={{width: '100%'}} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-12`}>
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>03</p>
              <p
                style={{width: '340px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                Share & collaborate
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>Share deals seamlessly with others to show them <i>your</i> numbers</p>
            </div>
            <img src={share} alt="share" className={isMobile ? 'mt-10' : ''} style={{width: isMobile ? '100%' : '550px'}} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-32`}>
            <img src={future_returns} className={isMobile ? 'hidden' : ''} alt="share" style={{width: '450px'}} />
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>04</p>
              <p
                style={{width: '340px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                View future returns
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>See the 5, 10, and 20 year returns on any property.</p>
            </div>
            <img src={future_returns} className={isMobile ? 'mt-10' : 'hidden'} alt="share" style={{width: '100%'}} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-20`}>
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>05</p>
              <p
                style={{width: '280px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                See tax savings
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>View tax savings including interest and depreciation deductions.</p>
            </div>
            <img src={taxes} alt="tax_savings" className='mt-7' style={{width: isMobile ? '100%' : '500px'}} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
          <div className={`lg:flex lg:justify-center lg:items-center lg:gap-32`}>
            <img src={comps} alt="analyze comps" className={isMobile ? 'hidden' : ''} style={{width: '550px'}} />
            <div>
              <p className='lg:text-5xl text-4xl text-bot-blue font-extrabold'>06</p>
              <p
                style={{width: '280px'}}
                className='bg-light-bot-blue-0 py-2 mt-2 text-bot-blue font-bold lg:text-3xl text-2xl rounded-xl text-center shadow-md shadow-light-gray-3'
              >
                Analyze comps
              </p>
              <p className='text-xl mt-3 lg:w-96 leading-8 font-semibold'>Analyze comps for both property value and rental rates.</p>
            </div>
            <img src={comps} alt="analyze comps" className={isMobile ? 'mt-10' : 'hidden'} style={{width: '100%'}} />
          </div>
          <div className={`flex justify-center ${isMobile ? 'my-16': 'my-20'}`}>
            <hr className='border-light-gray-3' style={{ borderWidth: '1px', width: isMobile ? '90%' : '70%' }} />
          </div>
        </div>
        <div className={`font-extrabold tracking-tight text-center mt-16 ${isMobile ? 'mb-16 text-[27px] mx-3' : 'text-4xl'}`}>
          <p>The <span className='text-bot-blue'>ultimate</span> investment property analysis toolkit</p>
        </div>
      </div>

      <div className={`bg-light-gray ${isMobile ? 'pt-24 pb-10' : 'py-24'}`}>
        <p className='flex justify-center text-center mx-3 lg:text-4xl text-3xl font-black tracking-tight'>Check out the product demo</p>
        <div className='flex justify-center mt-5'>
          <Link to="/search" className='flex justify-center'>
            <iframe className='rounded-xl lg:shadow-xl shadow-gray cursor-pointer video-width video-height' src="https://www.youtube.com/embed/-klHjhFD2P4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Link>
        </div>
      </div>
      
      <div className='py-24 bg-light-gray lg:px-32'>
        <p className='flex justify-center lg:text-4xl text-3xl font-black tracking-tight'>Get searching today</p>
        <div className='flex flex-col items-center mt-7'>
          <SearchBarLandingPage />
        </div>
      </div>

      {/* Footer */}
      <hr className='border-gray' />
      <div className='flex flex-col justify-center items-center text-center'>
        <div className={`lg:w-1/2 lg:flex lg:justify-between lg:gap-32 pt-16 pb-3 ${isMobile ? 'px-4' : ''}`}>
          
          <div className='flex flex-col justify-start items-start mb-5'>
            <img src={logo} alt="investorbot logo" className={`mt-1 ${isMobile ? 'h-5' : 'h-7'}`} />
            {isMobile ? (
              <div className='mt-3'>
                <p className={`text-gray mb-3 text-start w-100`}>Join the beta list to help build the product and give your insights.</p>
                <a href="https://notionforms.io/forms/agents-beta-signup" target="__blank" className='flex justify-center my-4 hover:no-underline'>
                  <button className='px-5 py-3 text-xl bg-bot-blue hover:bg-dark-bot-blue text-white font-semibold rounded-xl'>Join the beta</button>
                </a>
              </div>
            ) : (
              <div className='mt-3'>
                <p className={`text-gray mb-3 text-start w-100`}>Join the beta list to help build the product and give your insights.</p>
                <div className='flex justify-start'>
                  <a href="https://notionforms.io/forms/agents-beta-signup" target="__blank" className='flex justify-center hover:no-underline'>
                    <button className='px-5 py-2.5 text-xl bg-bot-blue hover:bg-dark-bot-blue text-white font-semibold rounded-xl'>Join the beta</button>
                  </a>
                </div>
              </div>
            )}
            
            <div className='text-start mt-4 text-xl text-gray ml-2'>
              <a href="mailto:info@investorbot.us" className='hover:text-bot-blue hover:no-underline'>
                <FontAwesomeIcon icon={faEnvelope} className="mr-4" />
              </a>
              <a href="tel:949-632-1851" className='hover:text-bot-blue hover:no-underline'>
                <FontAwesomeIcon icon={faPhone} />
              </a>
            </div>
          </div>
          
          <div className='flex flex-col items-start gap-4 text-darkgray mb-5'>
            <p className='font-bold mb-2 text-bot-blue'>Product</p>
            <Link to='/search' className='hover:text-bot-blue hover:no-underline'>
              <p>Map</p>
            </Link>
            <Link to='/deal-calculator' className='hover:text-bot-blue hover:no-underline'>
              <p>Calculator</p>
            </Link>
            <Link to='/learn' className='hover:text-bot-blue hover:no-underline'>
              <p>Learn</p>
            </Link>
          </div>
          
          <div className='flex flex-col items-start gap-4 text-darkgray mb-5'>
            <p className='font-bold mb-2 text-bot-blue'>Company</p>
            <a href="#how-it-works" className='hover:text-bot-blue hover:no-underline'>About</a>
            <a href="mailto:info@investorbot.us" className='hover:text-bot-blue hover:no-underline'>Contact</a>
            <Link to='/learn' className='hover:text-bot-blue hover:no-underline'>
              <p>Blog</p>
            </Link>
          </div>
        
          
        </div>
        <div className={`lg:w-1/2 flex justify-center text-start pb-2 text-light-gray-4 mb-4 ${isMobile ? 'mx-7' : 'mx-5'}`}>
          <p style={{width: '100%'}}>The information provided on this website is for informational purposes only and should not be relied upon as financial or investment advice. We are a tool not a crystal ball. The estimated returns and other financial data displayed on this website are based on various assumptions and estimates, and are subject to change without notice. We do not guarantee the accuracy or completeness of the information and make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. You should always conduct your own research and consult a financial advisor before making any investment decisions. Your use of this website and any reliance you place on any information or content is strictly at your own risk.</p>
        </div>
        <div className='flex justify-center mx-2 pb-3 text-light-gray-4'>
          <p>© 2023 Investorbot, LLC. All rights reserved.</p>
        </div>
      </div>

    </div>
  )
}
