import React from 'react'

export default function RentalCompRow(props) {
    let { last, gray, value, toLocaleString, currency } = props;

    // null checking
    if (value === null || value === '') {
        value = '-';
    } else if (toLocaleString) {
        value = value.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }

    return (
        <div>
            {(gray) ? (
                <p className={`py-2.5 px-2 bg-light-gray-2 ${(last) ? 'rounded-r-md': ''}`}>{(currency) ? `$${value}` : value}</p>
            ) : (
                <p className={`py-2.5 px-2 ${(last) ? 'rounded-r-md': ''}`}>{(currency) ? `$${value}` : value}</p>
            )}
        </div>
    )
}
