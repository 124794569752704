import { React, useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { FormControlLabel, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from 'react-currency-input-field';
import { supabase } from '../supabase';
import ReactTooltip from 'react-tooltip';
import allFunctions from '../containers/Functions';
import info_popup from '../assets/info_popup.jpg';
import { setGlobalState } from '../state';


export default function Default(props) {
    const { defaults, setDefaults, setOpenLogin, setLoginHeader, client } = props;
    const [session, setSession] = useState(false);
    // closing costs
    const [closingCostsNum, setClosingCostsNum] = useState(defaults.closingCosts);
    const [closingCostsPct, setClosingCostsPct] = useState(defaults.closingCostsPct);
    const [closingCostsType, setClosingCostsType] = useState(defaults.closingCostsType);
    // downpayment
    const [downpaymentNum, setDownpaymentNum] = useState(defaults.downpayment);
    const [downpaymentPct, setDownpaymentPct] = useState(defaults.downpaymentPct);
    const [downpaymentType, setDownpaymentType] = useState(defaults.downpaymentType);
    // repairs
    const [repairsNum, setRepairsNum] = useState(defaults.repairs);
    const [repairsPct, setRepairsPct] = useState(defaults.repairsPct);
    const [repairsType, setRepairsType] = useState(defaults.repairsType);
    // cap ex
    const [capexNum, setCapExNum] = useState(defaults.capex);
    const [capexPct, setCapExPct] = useState(defaults.capexPct);
    const [capexType, setCapExType] = useState(defaults.capexType);
    
    // show/hide sections
    const [position, setPosition] = useState("Initial");
    const navButtons = ['Initial', 'Mortgage', 'Income', 'Expenses', 'Appreciation', 'Taxes'];

    // get session data
    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        })
    }, [])

    const handleOpenLogin = () => {
        setLoginHeader('Sign up to save preferences');
        setOpenLogin(true);
    }

    const notifyLoginMsg = () => toast.info(<p><span onClick={() => handleOpenLogin()} className='underline font-semibold'>Create an account</span> to save your changes.</p>, {
        toastId: 'login-message',
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });

    const updateDefaults = async (e) => {
        e.preventDefault()

        if (session === null) {
            notifyLoginMsg();
        } else {
            try {
                const updates = {
                    id: client ? client.id : session.user.id,
                    updated_at: new Date(),
                    downpayment: parseInt(defaults.downpayment),
                    downpayment_pct: parseFloat(defaults.downpaymentPct),
                    downpayment_type: defaults.downpaymentType,
                    interest_rate: parseFloat(defaults.interestRate),
                    loan_term: parseInt(defaults.loanTerm),
                    closing_costs: parseInt(defaults.closingCosts),
                    closing_costs_pct: parseFloat(defaults.closingCostsPct),
                    closing_costs_type: parseInt(defaults.closingCostsType),
                    rehab: parseInt(defaults.rehab),
                    rent_appreciation: parseFloat(defaults.rentAppr),
                    laundry: parseInt(defaults.laundry),
                    storage: parseInt(defaults.storage),
                    misc_income: parseInt(defaults.miscIncome),
                    taxes: parseInt(defaults.taxes === undefined ? '' : defaults.taxes),
                    expenses_appreciation: parseFloat(defaults.expensesAppr),
                    insurance: parseInt(defaults.insurance),
                    mortgage_insurance_pct: parseFloat(defaults.mortgageInsurancePct),
                    repairs: parseInt(defaults.repairs),
                    repairs_pct: parseFloat(defaults.repairsPct),
                    repairs_type: defaults.repairsType,
                    capital_expenditures: parseInt(defaults.capex),
                    capital_expenditures_pct: parseFloat(defaults.capexPct),
                    capital_expenditures_type: defaults.capexType,
                    property_management_pct: parseFloat(defaults.propMgnt),
                    vacancy_pct: parseFloat(defaults.vacancy),
                    misc_costs: parseInt(defaults.miscCosts),
                    misc_expenses: parseInt(defaults.miscExpenses),
                    electricity: parseInt(defaults.electricity),
                    gas: parseInt(defaults.gas),
                    sewage: parseInt(defaults.sewage),
                    garbage: parseInt(defaults.garbage),
                    lawn: parseInt(defaults.lawn),
                    sellers_agent_pct: parseFloat(defaults.sellersAgent),
                    buyers_agent_pct: parseFloat(defaults.buyersAgent),
                    city: defaults.city,
                    city_appreciation: parseInt(defaults.cityAppr),
                    personal_annual_income: parseInt(defaults.personalAnnualIncome),
                    tax_bracket: parseInt(defaults.taxBracket),
                    filing_status: defaults.filingStatus,
                    depreciation_period: parseFloat(defaults.deprPeriod),
                    utilities_paid: defaults.utilitiesPaid,
                }
    
                let { error } = await supabase.from(client ? 'client_defaults' : 'defaults').upsert(updates)
    
                if (error) throw error
            } catch (error) {
                console.error(error.message);
            }
        }

    }

    // Downpayment
    const handleDownpaymentType = (e) => {
        setDownpaymentType(e.target.value);
        setDefaults((prevState) => { return { ...prevState, downpaymentType: e.target.value } })
    }
    
    const handleDownpaymentNum = (e) => {
        setDownpaymentNum(e);
        setDefaults((prevState) => { return { ...prevState, downpayment: e } })
    }
    
    const handleDownpaymentPct = (e) => {
        setDownpaymentPct(parseFloat(e));
        setDefaults((prevState) => { return { ...prevState, downpaymentPct: parseFloat(e) } })
    }
    
    // Closing costs
    const handleClosingCostsType = (e) => {
        setClosingCostsType(e.target.value);
        setDefaults((prevState) => { return { ...prevState, closingCostsType: e.target.value } })
    }
    
    const handleClosingCostsNum = (e) => {
        setClosingCostsNum(e);
        setDefaults((prevState) => { return { ...prevState, closingCosts: e } })
    }
    
    const handleClosingCostsPct = (e) => {
        setClosingCostsPct(e);
        setDefaults((prevState) => { return { ...prevState, closingCostsPct: parseFloat(e) } })
    }

    // Repairs
    const handleRepairsType = (e) => {
        setRepairsType(e.target.value);
        setDefaults((prevState) => { return { ...prevState, repairsType: e.target.value } })
    }
    
    const handleRepairsNum = (e) => {
        setRepairsNum(e);
        setDefaults((prevState) => { return { ...prevState, repairs: e } })
    }
    
    const handleRepairsPct = (e) => {
        setRepairsPct(parseFloat(e));
        setDefaults((prevState) => { return { ...prevState, repairsPct: parseFloat(e) } })
    }

    // CapEx
    const handleCapExType = (e) => {
        setCapExType(e.target.value);
        setDefaults((prevState) => { return { ...prevState, capexType: e.target.value } })
    }
    
    const handleCapExNum = (e) => {
        setCapExNum(e);
        setDefaults((prevState) => { return { ...prevState, capex: e } })
    }
    
    const handleCapExPct = (e) => {
        setCapExPct(parseFloat(e));
        setDefaults((prevState) => { return { ...prevState, capexPct: parseFloat(e) } })
    }

    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 1025;

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }


    return (
        <div className='lg:ml-2 ml-1 lg:height-defaults'>
            <div className={isMobile ? 'flex justify-end' : 'hidden'}>
                <p 
                    onClick={() => setGlobalState('openDefaultModal', false)}
                    className='flex justify-end items-center cursor-pointer px-3 -mr-2 h-10 w-32 -mt-2 rounded-xl text-bot-blue hover:bg-light-bot-blue-0'
                >
                    Save & close
                </p>
            </div>
            <div className={`flex justify-between ${isMobile ? 'mx-2 mb-4' : 'mx-2'}`}>
                <div>
                    <div className='lg:flex lg:justify-start lg:items-center text-3xl font-bold tracking-tighter mb-3'>
                        <p className='mr-2'>{client && client.name !== 'Personal' ? `${client.name}'s` : 'My'}</p>
                        <p>Preferences</p>
                    </div>
                    <p className='text-lg font-semibold mb-3 text-gray italics'>Setting your preferences will change all properties on the map.</p>
                </div>
                <p 
                    onClick={() => setGlobalState('openDefaultModal', false)}
                    className={isMobile ? 'hidden' : 'flex justify-center items-center cursor-pointer px-3 h-11 rounded-xl text-bot-blue hover:bg-light-bot-blue-0'}
                >
                    Save & close
                </p>
            </div>
            <div className='lg:flex'>
                <div className='hide-element mb-3'>
                    <div className='bg-light-gray-2 rounded-xl lg:px-3 lg:pt-1 lg:pb-5' style={{height: '575px'}}>
                        {navButtons.map(function (title) {
                            return (
                                <a href={`#${title}`} className='hover:no-underline'>
                                    <button
                                        href={`#${title}`}
                                        className={`flex px-2.5 py-2 rounded-md text-xl mt-3 w-48 border-none ${position !== title ? 'hover:bg-light-bot-blue hover:text-white' : 'bg-bot-blue text-white'}`}
                                        onClick={() => setPosition(title)}
                                    >
                                        {title}
                                    </button>
                                </a>
                            )
                        })}
                    </div>
                </div>
                <div className='lg:ml-5 -ml-2 lg:overflow-scroll' style={{height: isMobile ? '' : '591px'}}>
                    <div className={"lg:ml-3 ml-1"} id="Initial">
                        <p id="Initial" className='text-lg font-bold text-gray ml-3 mb-2'>Initial Investment</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 pb-3 lg:px-3'>
                            <div className='px-2 rounded-lg'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal mt-4">Closing costs:</Form.Label>
                                        <img data-tip data-for="closingCosts" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <div className='flex justify-center'>
                                        {/* Toggle */}
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={closingCostsType}
                                            exclusive
                                            onChange={(e) => handleClosingCostsType(e)}
                                            className='h-10 mt-3'
                                            onBlur={(e) => updateDefaults(e)}
                                        >
                                            <ToggleButton selected={(closingCostsType === "%") ? true : false} className="py-1.5 px-2" size="small" value="%">%</ToggleButton>
                                            <ToggleButton selected={(closingCostsType === "$") ? true : false} className="py-1.5 px-2" size="small" value="$">$</ToggleButton>
                                        </ToggleButtonGroup>
                                        {/* Currency */}
                                        <CurrencyInput
                                            id="closing-costs"
                                            onClick={() => handleSelectInput("closing-costs")}
                                            className={(closingCostsType === '$') ? "w-28 pl-2 mt-3 border-one rounded-sm" : "w-24 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(closingCostsType === '$') ? '$' : ''}
                                            value={closingCostsNum}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleClosingCostsNum(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                        {/* Percentage */}
                                        <CurrencyInput
                                            id="closing-costs-pct"
                                            onClick={() => handleSelectInput("closing-costs-pct")}
                                            className={(closingCostsType === '%') ? "w-28 pl-2 mt-3 border-one rounded-sm" : "w-24 pl-2 mt-3 border-one rounded-sm hidden"}
                                            suffix={(closingCostsType === '%') ? '% of price' : ''}
                                            value={closingCostsPct}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleClosingCostsPct(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"lg:ml-3 ml-1 mt-4"} id="Mortgage">
                        <p id="Mortgage" className='text-lg font-bold text-gray ml-3 mb-2'>Mortgage</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 pb-3 lg:px-3'>
                            <div className='px-2 rounded-lg'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal mt-4">Down payment:</Form.Label>
                                        <img data-tip data-for="downpayment" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <div className='flex justify-center'>
                                        {/* Toggle */}
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={downpaymentType}
                                            exclusive
                                            onChange={(e) => handleDownpaymentType(e)}
                                            className='h-10 mt-3'
                                            onBlur={(e) => updateDefaults(e)}
                                        >
                                            <ToggleButton selected={(downpaymentType === "%") ? true : false} className="py-1.5 px-2" size="small" value="%">%</ToggleButton>
                                            <ToggleButton selected={(downpaymentType === "$") ? true : false} className="py-1.5 px-2" size="small" value="$">$</ToggleButton>
                                        </ToggleButtonGroup>
                                        {/* Currency */}
                                        <CurrencyInput
                                            id="downpayment"
                                            onClick={() => handleSelectInput("downpayment")}
                                            className={(downpaymentType === '$') ? "w-24 pl-2 mt-3 border-one rounded-sm" : "w-24 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(downpaymentType === '$') ? '$' : ''}
                                            suffix={(downpaymentType === '%') ? '%' : ''}
                                            value={downpaymentNum}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleDownpaymentNum(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                        {/* Percentage */}
                                        <CurrencyInput
                                            id="downpayment-pct"
                                            onClick={() => handleSelectInput("downpayment-pct")}
                                            className={(downpaymentType === '%') ? "w-24 pl-2 mt-3 border-one rounded-sm" : "w-24 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(downpaymentType === '$') ? '$' : ''}
                                            suffix={(downpaymentType === '%') ? '%' : ''}
                                            value={downpaymentPct}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleDownpaymentPct(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal mt-4">Interest rate:</Form.Label>
                                        <img data-tip data-for="interestRate" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="interest-rate"
                                        onClick={() => handleSelectInput("interest-rate")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.interestRate}
                                        decimalsLimit={3}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, interestRate: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center'>
                                        <Form.Label className="font-normal mt-4">Loan term:</Form.Label>
                                        <img data-tip data-for="loanTerm" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="loan-term"
                                        onClick={() => handleSelectInput("loan-term")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix=' years'
                                        defaultValue={defaults.loanTerm}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, loanTerm: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"lg:ml-3 ml-1"} id="Income">
                        <p id="Monthly Income" className='text-lg font-bold text-gray ml-3 mt-7 mb-2'>Monthly Income</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 mt-1 pb-3'>
                            <div className='pr-3 rounded-lg lg:w-96 w-80'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">{isMobile ? 'Rent appr. rate:' : 'Rent appreciation rate:'}</Form.Label>
                                        <img data-tip data-for="rentAppr" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="rent-appr-rate"
                                        onClick={() => handleSelectInput("rent-appr-rate")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.rentAppr}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, rentAppr: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"lg:ml-3 ml-1"} id="Expenses">
                        <p id="Monthly Expenses" className='text-lg font-bold text-gray ml-3 mt-7 mb-2'>Monthly Expenses</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 mt-1 pb-3'>
                            <div className='pr-3 rounded-lg lg:w-96 w-80'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal mt-4 ml-3">Repairs:</Form.Label>
                                        <img data-tip data-for="repairs" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <div className='flex justify-center'>
                                        {/* Toggle */}
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={repairsType}
                                            exclusive
                                            onChange={(e) => handleRepairsType(e)}
                                            className='h-10 mt-3'
                                            onBlur={(e) => updateDefaults(e)}
                                        >
                                            <ToggleButton selected={(repairsType === "%") ? true : false} className="py-1.5 px-2" size="small" value="%">%</ToggleButton>
                                            <ToggleButton selected={(repairsType === "$") ? true : false} className="py-1.5 px-2" size="small" value="$">$</ToggleButton>
                                        </ToggleButtonGroup>
                                        {/* Currency */}
                                        <CurrencyInput
                                            id="repairs"
                                            onClick={() => handleSelectInput("repairs")}
                                            className={(repairsType === '$') ? "w-16 pl-2 mt-3 border-one rounded-sm" : "w-16 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(repairsType === '$') ? '$' : ''}
                                            suffix={(repairsType === '%') ? '%' : ''}
                                            value={repairsNum}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleRepairsNum(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                        {/* Percentage */}
                                        <CurrencyInput
                                            id="repairs-pct"
                                            onClick={() => handleSelectInput("repairs-pct")}
                                            className={(repairsType === '%') ? "w-16 pl-2 mt-3 border-one rounded-sm" : "w-16 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(repairsType === '$') ? '$' : ''}
                                            suffix={(repairsType === '%') ? '%' : ''}
                                            value={repairsPct}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleRepairsPct(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                    </div>
                                </div>
                                
                                {/* New CapEx */}
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal mt-4 ml-3">{isMobile ? 'CapEx' : 'Capital expenditures'}:</Form.Label>
                                        <img data-tip data-for="capex" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <div className='flex justify-center'>
                                        {/* Toggle */}
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={capexType}
                                            exclusive
                                            onChange={(e) => handleCapExType(e)}
                                            className='h-10 mt-3'
                                            onBlur={(e) => updateDefaults(e)}
                                        >
                                            <ToggleButton selected={(capexType === "%") ? true : false} className="py-1.5 px-2" size="small" value="%">%</ToggleButton>
                                            <ToggleButton selected={(capexType === "$") ? true : false} className="py-1.5 px-2" size="small" value="$">$</ToggleButton>
                                        </ToggleButtonGroup>
                                        {/* Currency */}
                                        <CurrencyInput
                                            id="capex"
                                            onClick={() => handleSelectInput("capex")}
                                            className={(capexType === '$') ? "w-16 pl-2 mt-3 border-one rounded-sm" : "w-16 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(capexType === '$') ? '$' : ''}
                                            suffix={(capexType === '%') ? '%' : ''}
                                            value={capexNum}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleCapExNum(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                        {/* Percentage */}
                                        <CurrencyInput
                                            id="capex-pct"
                                            onClick={() => handleSelectInput("capex-pct")}
                                            className={(capexType === '%') ? "w-16 pl-2 mt-3 border-one rounded-sm" : "w-16 pl-2 mt-3 border-one rounded-sm hidden"}
                                            prefix={(capexType === '$') ? '$' : ''}
                                            suffix={(capexType === '%') ? '%' : ''}
                                            value={capexPct}
                                            decimalsLimit={2}
                                            onValueChange={(e) => handleCapExPct(e)}
                                            onBlur={(e) => updateDefaults(e)}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">{isMobile ? 'Prop. management:' : 'Property management:'}</Form.Label>
                                        <img data-tip data-for="propMgnt" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="prop-mgnt"
                                        onClick={() => handleSelectInput("prop-mgnt")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.propMgnt}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, propMgnt: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Vacancy:</Form.Label>
                                        <img data-tip data-for="vacancy" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="vacancy"
                                        onClick={() => handleSelectInput("vacancy")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.vacancy}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, vacancy: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Property taxes:</Form.Label>
                                        <img data-tip data-for="taxes" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="prop-taxes"
                                        onClick={() => handleSelectInput("prop-taxes")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        prefix='$'
                                        defaultValue={defaults.taxes}
                                        placeholder="Default"
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, taxes: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Utilities paid by renter:</Form.Label>
                                        <img data-tip data-for="utilitiesPaid" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                   <div className='mr-2 mt-2'>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={defaults.utilitiesPaid}
                                                    onChange={() => setDefaults((prevState) => { return { ...prevState, utilitiesPaid: !defaults.utilitiesPaid } })}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            className='-mb-6'
                                        />
                                   </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">{isMobile ? 'Expense appr. rate:' : 'Expense appreciation rate:'}</Form.Label>
                                        <img data-tip data-for="expensesAppr" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="expense-appr-rate"
                                        onClick={() => handleSelectInput("expense-appr-rate")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.expensesAppr}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, expensesAppr: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <p id="One Time Expenses" className='text-lg font-bold text-gray ml-3 mt-7 mt-4 mb-2'>One-Time Expenses</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 mt-1 pb-3'>
                            <div className='pb-px pr-3 rounded-lg lg:w-96 w-80'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Buyer's agent:</Form.Label>
                                        <img data-tip data-for="buyersAgent" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="buyers-agent"
                                        onClick={() => handleSelectInput("buyers-agent")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.buyersAgent}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, buyersAgent: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Seller's agent:</Form.Label>
                                        <img data-tip data-for="sellersAgent" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="sellers-agent"
                                        onClick={() => handleSelectInput("sellers-agent")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.sellersAgent}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, sellersAgent: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"lg:ml-3 ml-1"} id="Appreciation">
                        <p id="Appreciation" className='text-lg font-bold text-gray ml-3 mt-7 mb-2'>Appreciation</p>
                        <div className='bg-light-gray rounded-xl lg:w-96 w-80 pb-3'>
                            <div className='pb-px pr-3 rounded-lg lg:w-96 w-80'>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Appreciation rate:</Form.Label>
                                        <img data-tip data-for="marketAppr" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="market-appr-rate"
                                        onClick={() => handleSelectInput("market-appr-rate")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        suffix='%'
                                        defaultValue={defaults.cityAppr}
                                        decimalsLimit={2}
                                        onValueChange={e => setDefaults((prevState) => { return { ...prevState, cityAppr: e } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={isMobile ? 'ml-1 mb-4' : 'ml-1'} id="Taxes">
                        <p id="Taxes" className='text-lg font-bold text-gray ml-3 mt-7 mb-2'>Taxes</p>
                        <div className={`bg-light-gray rounded-xl pb-3 ${isMobile ? 'w-80' : 'w-default-taxes'}`}>
                            <div className={`pb-px pr-3 rounded-lg ${isMobile ? 'w-80' : 'w-default-taxes'}`}>
                                <div className="flex justify-between">
                                    <div className='flex justify-center gap-1 ml-3'>
                                        <Form.Label className="font-normal mt-4">Annual income:</Form.Label>
                                        <img data-tip data-for="personalAnnualIncome" className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <CurrencyInput
                                        id="annual-income"
                                        onClick={() => handleSelectInput("annual-income")}
                                        className="w-24 pl-2 mt-3 border-one rounded-sm"
                                        prefix='$'
                                        defaultValue={defaults.personalAnnualIncome}
                                        decimalsLimit={2}
                                        onValueChange={(e) => setDefaults((prevState) => { return { ...prevState, personalAnnualIncome: parseInt(e) } })}
                                        onBlur={(e) => updateDefaults(e)}
                                    />
                                </div>
                                <div className='flex justify-between ml-3 pt-3'>
                                    <div className='flex justify-center gap-1'>
                                        <Form.Label className="font-normal">{isMobile ? 'Filing as:' : 'Filing status:'}</Form.Label>
                                        <img data-tip data-for="filingStatus" className="w-4 mb-3 ml-1" src={info_popup} alt="info box"/>
                                    </div>
                                    <DropdownButton
                                        size='md'
                                        variant='secondary-outline'
                                        title={defaults.filingStatus}
                                        className="-mt-1.5 drop-shadow-2xl rounded-md z-50"
                                    >
                                        <Dropdown.Item
                                            onBlur={(e) => updateDefaults(e)}
                                            onSelect={() => setDefaults((prevState) => { return { ...prevState, filingStatus: "Single" } })}
                                        >
                                            Single
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onBlur={(e) => updateDefaults(e)}
                                            onSelect={() => setDefaults((prevState) => { return { ...prevState, filingStatus: "Head of Household" } })}
                                        >
                                            Head of Household
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onBlur={(e) => updateDefaults(e)}
                                            onSelect={() => setDefaults((prevState) => { return { ...prevState, filingStatus: "Married Filing Jointly" } })}
                                        >
                                            Married Filing Jointly
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onBlur={(e) => updateDefaults(e)}
                                            onSelect={() => setDefaults((prevState) => { return { ...prevState, filingStatus: "Married Filing Separately" } })}
                                        >
                                            Married Filing Separately
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onBlur={(e) => updateDefaults(e)}
                                            onSelect={() => setDefaults((prevState) => { return { ...prevState, filingStatus: "Qualified Widow" } })}
                                        >
                                            Qualified Widow
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <div className='flex justify-between pt-3 mx-3'>
                                    <div className='flex justify-center'>
                                        <div className='flex justify-center gap-1'>
                                            <Form.Label className="font-normal">Tax bracket:</Form.Label>
                                            <img data-tip data-for="taxBracket" className="w-4 mb-3 ml-1" src={info_popup} alt="info box"/>
                                        </div>
                                        <a href="https://www.bankrate.com/taxes/tax-brackets/" target="__blank">
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm mb-0.5 ml-1 p-1.5 text-bot-blue cursor-pointer rounded-lg hover:bg-light-gray-2" />
                                        </a>
                                    </div>
                                    <p>{allFunctions.calcTaxBracket(defaults.personalAnnualIncome, defaults.filingStatus)}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* React Tooltips */}
            <div className='flex justify-center'>
                <ReactTooltip id='closingCosts' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Closing costs</p>
                    <p className='text-sm text-start'>The closing costs are all costs associated with closing on a loan, including lender and escrow fees, buying down the interest rate, etc. The average closing costs are 2-3% of the purchase price of a home depending on the total value of the home.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='utilitiesPaid' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Utilities paid by renter</p>
                    <p className='text-sm text-start'>When this toggle is switched on, you are defaulting that all utilities will be paid by renters and thus is not included in the operating expenses.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='shareMyEdits' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Share my edits</p>
                    <p className='text-sm text-start'>When this toggle is switched on, someone will be able to see your property edits when you share a property with them.</p>
                    <p className='text-sm text-start text-gray mt-2'>Example: If you changed the rent estimate from $1,850 to $2,000, whoever you share that property link with will see the rent estimate as $2,000 not the default $1,850.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='downpayment' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Down payment</p>
                    <p className='text-sm text-start'>The down payment is the initial payment on a mortgage loan. You can set your down payment default as either a percentage or number value.</p>
                    <p className='text-sm text-start text-gray mt-2'>Example: Let's say you only have $30,000 to spend on a down payment. Click the "$" sign and enter $30,000. Your changes will automatically propagate to every property on the map.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='rentAppr' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Rent Appreciation Rate</p>
                    <p className='text-sm mt-3'>The rent appreciation rate is the rate at which rent increase year-over-year. Between 2010 and 2020, rents increased by 2.6% annually. Between 2020 and 2022, rents rose 10.5%.</p>
                    <p className='text-sm text-gray mt-3'>
                        Ex: With a rent muliplier of {defaults.rentAppr}%, rent will go from $1,000 this year to ${Math.round(1000 * (1 + (defaults.rentAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                    </p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='expensesAppr' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Expense Appreciation Rate</p>
                    <p className='text-sm text-start mt-3'>The expense appreciation rate is the rate at which expenses (operating expenses) increase year-over-year. Your mortgage will stay the same unless you refinance. Operating expenses will increase 2-3% on average closely following inflation.</p>
                    <p className='text-sm text-start text-gray mt-3'>
                        Ex: With a expense muliplier of {defaults.expensesAppr}%, your operating expenses would go from $500 this year to ${Math.round(500 * (1 + (defaults.expensesAppr / 100))).toLocaleString(undefined, { maximumFractionDigits: 2 })} next year.
                    </p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='interestRate' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Interest Rate</p>
                    <p className='text-sm text-start'>The interest rate is the proportion of a loan that is charged as interest to the borrower, typically expressed as an annual percentage of the loan outstanding. Interest rates fluctuate over time. We encourage you to verify the current interest rate in your area.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='loanTerm' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Loan Term</p>
                    <p className='text-sm text-start'>The loan term is the amount of time it takes to completely pay off your loan. The typical loan term on a mortgage is 30 years.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='repairs' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Repairs</p>
                    <p className='text-sm text-start'>The average monthly cost of repairs made to the property. According to Go Banking Rates, the average annual cost for maintaining a home is $1,204 ($120/month). Repairs can either be calculated as a flat value or percentage of total monthly rent.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='capex' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Capital Expenditures (CapEx)</p>
                    <p className='text-sm text-start'>Capital expenditures are the big purchases you have to make once every 10-20 years such as replacing the roof or water heater. BiggerPockets suggests putting aside $100/month is a fair estimate. CapEx can either be calculated as a flat value or percentage of total monthly rent.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='propMgnt' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Property Management</p>
                    <p className='text-sm text-start'>The property management fee is on average 10% of the monthly rent. Property managers take care of all your management needs such as sourcing and screening potential renters, maintenance repairs, and any problems with tenants.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='vacancy' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Vacancy</p>
                    <p className='text-sm text-start'>Vacancy is the percentage of time in a year that no tenants are renting. The national average rental vacancy rate for 2021 was 6.1%.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='taxes' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Property Taxes</p>
                    <p>Property taxes are calculated by multiplying the property's tax rate (on average between 0.58% and 1.58%) by the value of the home.</p>
                    <p className='mt-3'>Taxes are typically paid annually. Some finanicial institutions, however, prefer a monthly payment. Either way, we have included property taxes into the monthly payment.</p>
                    <p className='mt-3 mb-2 font-semibold'>** Leave this input box blank if you want each property to use its default calculation.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='buyersAgent' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Buyer's Agent Fee</p>
                    <p className='text-sm text-start'>The buyer's agent fee is the cost a homeowner pays when selling their property. The average rate is 3%.</p>
                    <p className='text-sm text-start text-gray mt-2'><b>TLDR;</b> If you are selling a home and you are using an agent, you negotiate a commission percentage with them if the home sells, typically between 5-6% of the value of the home. If a buyer comes and has an agent, your agent (the seller's agent) must then split their commission with the buyer's agent. So a 6% seller's agent commission would then be evenly split 3%/3% with the buyer's agent.</p>
                    <p className='text-sm text-start text-gray mt-2'>If you are selling a home and not using an agent (for sale by owner) and a buyer comes with an agent you are not legally obligated to pay the buyer's agent a percentage commission on the home, although this is typical.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='sellersAgent' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Seller's Agent Fee</p>
                    <p className='text-sm text-start'>The seller's agent fee is the cost a homeowner pays when selling their property. The average rate is 6%. If the buyer also has an agent, the seller's agent will evenly split their commission with the buyer's agent 3%/3%.</p>
                    <p className='text-sm text-start text-gray mt-2'><b>TLDR;</b> If you are selling a home and you are using an agent, you negotiate a commission percentage with them if the home sells, typically between 5-6% of the value of the home. If a buyer comes and has an agent, your agent (the seller's agent) must then split their commission with the buyer's agent. So a 6% seller's agent commission would then be evenly split 3%/3% with the buyer's agent.</p>
                    <p className='text-sm text-start text-gray mt-2'>If you are selling a home and not using an agent (for sale by owner) and a buyer comes with an agent you are not legally obligated to pay the buyer's agent a percentage commission on the home, although this is typical.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='marketAppr' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Appreciation Rate</p>
                    <p className='text-sm text-start'>The annual rate at which all properties on the market appreciate in value. Since 1991, the average annual home price increase has been 4.3%.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='personalAnnualIncome' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Personal Annual Income</p>
                    <p className='text-sm text-start'>Your personal annual salary will help set your total tax deduction savings.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='filingStatus' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Filing Status</p>
                    <p className='text-sm text-start'>Your filing status is used to determine your correct tax bracket.</p>
                </ReactTooltip>
            </div>
            <div className='flex justify-center'>
                <ReactTooltip id='taxBracket' effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                    className='shadow-lg p-3 bg-white opaque w-80'
                >
                    <p className='text-base text-bot-blue mb-2 text-center'>Tax Backet</p>
                    <p className='text-sm text-start'>Your tax bracket is the rate at which you are taxed and is determined based on your personal annual income.</p>
                </ReactTooltip>
            </div>
        </div>
    )
}