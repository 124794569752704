import React, { useEffect, useRef, useState } from 'react';
import { Card, Collapse, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import ArrowIcon from '../components/ArrowIcon';
import InputBox from '../components/InputBox';
import CurrencyInput from 'react-currency-input-field';
import { supabase } from '../supabase';
import allFunctions from './Functions';
import { Checkbox, FormControlLabel } from '@mui/material';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faTrashCan, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Drawer from '@mui/material/Drawer';
// images
import info_popup from '../assets/info_popup.jpg';
import loading_spinner from '../assets/loading_spinner.gif';
import DonutChart from '../components/DonutChart';
import SignupComponent from '../components/SignupComponent';

export default function DealCalculator() {
    // local vars
    const [loading, setLoading] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [session, setSession] = useState(null);
    const [property, setProperty] = useState(null);
    const [properties, setProperties] = useState([]);
    const [openWillDeleteModal, setOpenWillDeleteModal] = useState(false);
    const [loginHeader, setLoginHeader] = useState('Sign up to save your deals');
    
    useEffect(() => {
        const getProperties = async (currSession) => {
            try {
                let { data, error, status } = await supabase
                    .from('profiles')
                    .select('calculations')
                    .eq('id', currSession.user.id);
        
                if (error && status !== 406) {
                    throw error
                }
                
                if (data[0].calculations && data[0].calculations[0] !== null) {
                    setProperties(data[0].calculations);
                }
        
            } catch (error) {
                console.log(error.message);
            }
        }
        
        // get user session from local storage if available
        let currSession = JSON.parse(localStorage.getItem('sb-fusszpkrdwzhyaeqxlvv-auth-token'));
        if (currSession) {
            setSession(currSession);
            getProperties(currSession);
        } else {
            setLoginHeader("Sign up to save your deals");
            setOpenLogin(true);
        }
    }, [])

    // details
    const [address, setAddress] = useState('');
    const [bedrooms, setBedrooms] = useState(null);
    const [bathrooms, setBathrooms] = useState(null);
    const [squareFeet, setSquareFeet] = useState(null);
    const [propertyType, setPropertyType] = useState('Single Family');
    const [yearBuilt, setYearBuilt] = useState(null);
    // initial investment
    const [closingCosts, setClosingCosts] = useState(3000);
    const [rehabCosts, setRehabCosts] = useState(1000);
    const [miscCosts, setMiscCosts] = useState(0);
    const totalOtherCosts = parseInt(closingCosts) + parseInt(rehabCosts) + parseInt(miscCosts);
    // income
    const [rent, setRent] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const totalMonthlyIncome = parseInt(rent) + parseInt(otherIncome);
    const incomeDataPoints = [
        parseInt(rent) > 0 ? {
            type: 'Rent',
            value: parseInt(rent),
        } : '',
        parseInt(otherIncome) > 0 ? {
            type: 'Other',
            value: parseInt(otherIncome),
        } : ''
    ];

    const incomeData = [];
    incomeDataPoints.forEach(income => {
        if (income !== '') {
            incomeData.push({
                type: income.type,
                value: income.value,
            })
        }
    })
    // mortgage
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [downpayment, setDownpayment] = useState(0);
    const [downpaymentPct, setDownpaymentPct] = useState(20);
    const [interestRate, setInterestRate] = useState(6.0);
    const [loanTerm, setLoanTerm] = useState(30);
    const totalMonthlyMortgage = +parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), parseInt(downpayment), parseFloat(interestRate), parseInt(loanTerm))).toFixed(0);
    const totalInitialInvestment = parseInt(downpayment) + parseInt(totalOtherCosts);
    const [mortgageCard, setMortgageCard] = useState(false);
    const [mortgageArrow, setMortgageArrow] = useState(false);
    // expenses
    const [taxes, setTaxes] = useState(0);
    const [insurance, setInsurance] = useState(100);
    const [mortgageInsurance, setMortgageInsurance] = useState(0);
    const [mortgageInsurancePct, setMortgageInsurancePct] = useState(0.01);
    const [hoa, setHOA] = useState(0);
    const [utilitiesPaid, setUtilitiesPaid] = useState(true);
    const [electricity, setElectricity] = useState(70);
    const [gas, setGas] = useState(30);
    const [sewage, setSewage] = useState(0);
    const [garbage, setGarbage] = useState(0);
    const [lawn, setLawn] = useState(0);
    const totalUtilities = (utilitiesPaid) ? (0) : (parseInt(electricity) + parseInt(gas) + parseInt(sewage) + parseInt(garbage) + parseInt(lawn));
    const [repairs, setRepairs] = useState(100);
    const [capex, setCapex] = useState(100);
    const [propMgmtPct, setPropMgmtPct] = useState(0);
    const [propMgmt, setPropMgmt] = useState(0);
    const [vacancyPct, setVacancyPct] = useState(5);
    const [vacancy, setVacancy] = useState(0);
    const [otherExpenses, setOtherExpenses] = useState(0);
    const operatingExpenses = parseInt(totalUtilities) + parseInt(taxes) + parseInt(insurance) + parseInt(mortgageInsurance) + parseInt(hoa) + parseInt(repairs) + parseInt(capex) + parseInt(propMgmt) + parseInt(vacancy) + parseInt(otherExpenses);
    const netOperatingIncome = totalMonthlyIncome - operatingExpenses;
    const totalMonthlyExpenses = operatingExpenses + totalMonthlyMortgage;
    let monthlyCashFlow = Math.round(netOperatingIncome - totalMonthlyMortgage);
    
    // re-doing the negative sign
    let monthlyCashFlowText = monthlyCashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (monthlyCashFlowText.substring(0, 1) === "-") {
        monthlyCashFlowText = `-$${monthlyCashFlowText.substring(1, monthlyCashFlowText.length)}`;
    } else {
        monthlyCashFlowText = `$${monthlyCashFlowText}`;
    }

    const expensesDataPoints = [
        parseInt(totalMonthlyMortgage) > 0 ? ({
                type: 'Mortgage',
                value: parseInt(totalMonthlyMortgage)
            }
        ) : '',
        parseInt(taxes) > 0 ? ({
                type: 'Taxes',
                value: parseInt(taxes)
            }
        ) : '',
        parseInt(insurance) > 0 ? ({
                type: 'Insurance',
                value: parseInt(insurance)
            }
        ) : '',
        parseInt(mortgageInsurance) > 0 ? ({
                type: 'PMI',
                value: parseInt(mortgageInsurance)
            }
        ) : '',
        parseInt(hoa) > 0 ? ({
                type: 'HOA',
                value: parseInt(hoa)
            }
        ) : '',
        parseInt(utilitiesPaid ? 0 : totalUtilities) > 0 ? ({
                type: 'Utilities',
                value: totalUtilities
            }
        ) : '',
        parseInt(repairs) > 0 ? ({
                type: 'Repairs',
                value: parseInt(repairs)
            }
        ) : '',
        parseInt(capex) > 0 ? ({
                type: 'CapEx',
                value: parseInt(capex)
            }
        ) : '',
        parseInt(propMgmt) > 0 ? ({
                type: 'Prop Mgnt',
                value: parseInt(propMgmt)
            }
        ) : '',
        parseInt(vacancy) > 0 ? ({
                type: 'Vacancy',
                value: parseInt(vacancy)
            }
        ) : '',
        parseInt(otherExpenses) > 0 ? ({
                type: 'Other',
                value: parseInt(otherExpenses)
            }
        ) : ''
    ]

    const expensesData = [];
    expensesDataPoints.forEach(expense => {
        if (expense !== '') {
            expensesData.push({
                type: expense.type,
                value: expense.value,
            })
        }
    })

    // appreciation & depreciation
    const [marketAppr, setMarketAppr] = useState(5);
    const [annualIncome, setAnnualIncome] = useState(0);
    const [filingStatus, setFilingStatus] = useState('Single');
    const [landValue, setLandValue] = useState(0);
    const [deprPeriod, setDeprPeriod] = useState(27.5);
    const [buyersAgent, setBuyersAgent] = useState(3);
    const [sellersAgent, setSellersAgent] = useState(3);
    let principal = (parseInt(purchasePrice) - parseInt(downpayment));
    const annualInterest = allFunctions.calcInterest(parseInt(principal), parseInt(interestRate), parseInt(loanTerm * 12), 12);
    const totalEquity = allFunctions.calcEquity(parseInt(purchasePrice), principal, parseFloat(interestRate), (parseInt(loanTerm) * 12), 12)
    const addedEquity = totalEquity - downpayment;
    const totalAppreciation = Math.round(parseFloat(purchasePrice) * parseFloat(marketAppr / 100));
    const taxBracket = parseFloat(allFunctions.calcTaxBracket(parseInt(annualIncome), filingStatus));
    const TotalAnnualTaxDeduction = Math.round(parseInt(annualInterest) * parseFloat(taxBracket) / 100);
    const TotalAnnualDeprDeduction = Math.round((parseInt(purchasePrice) - parseInt(landValue) + parseInt(rehabCosts)) / parseInt(deprPeriod));
    const depreciationDeducationSavings = Math.round(TotalAnnualDeprDeduction * (taxBracket / 100));
    const totalAppreciationandTaxes = parseInt(totalAppreciation + TotalAnnualTaxDeduction + depreciationDeducationSavings)
    const estSellingCosts = parseInt(((parseInt(purchasePrice) + parseInt(purchasePrice) * (parseInt(marketAppr) / 100)))
        * ((parseFloat(buyersAgent) + parseFloat(sellersAgent)) / 100));

    // calculate returns
    const annualCashFlow = parseInt(monthlyCashFlow * 12);
    const newCOC = +parseFloat((parseFloat(annualCashFlow / totalInitialInvestment) * 100).toFixed(1));
    const newCapRate = +parseFloat((parseFloat((netOperatingIncome * 12) / parseInt(purchasePrice)) * 100).toFixed(1));
    let totalReturnCash = +parseFloat((annualCashFlow + addedEquity + totalAppreciationandTaxes - estSellingCosts).toFixed(1));
    const newTotalReturn = +parseFloat((parseFloat(totalReturnCash / totalInitialInvestment) * 100).toFixed(1));

    // collapsibles
    const [taxBenefits, setTaxBenefits] = useState(false);
    const [assetAppreciation, setAssetAppreciation] = useState(false);
    const [depreciationDeducation, setDepreciationDeducation] = useState(false);
    const [advancedExpenses, setAdvancedExpenses] = useState(false);
    const [advancedSavings, setAdvancedSavings] = useState(false);
    const [costs, setCosts] = useState(false);
    const [utilities, setUtilities] = useState(false);
    const [utilitiesArrow, setUtilitiesArrow] = useState(false);
    const [otherCostsArrow, setOtherCostsArrow] = useState(false);
    const [appreciationArrow, setAppreciationArrow] = useState(false);
    const [taxBenefitsArrow, setTaxBenefitsArrow] = useState(false);
    const [depreciationDeductionArrow, setDepreciationDeductionArrow] = useState(false);
    const [initArrow, setInitArrow] = useState(false);
    const [initInvestmentCard, setInitInvestmentCard] = useState(false);
    // calculate button
    const disableBtn = useRef(true);


    const handleCollapse = (valueCollapse, changeCollapse, valueArrow, changeArrow) => {
        changeCollapse(!valueCollapse);
        changeArrow(!valueArrow);
    }

    // update purchase price and all values that depend on it
    const handlePurchasePrice = (e) => {
        let newPurchasePrice = (e === 0) ? 0 : Number(e.replace(/[^0-9.-]+/g,""));
        setPurchasePrice(newPurchasePrice);
        // update downpayment pct
        let newDownpaymentPct = +parseFloat(downpaymentPct).toFixed(1)
        setDownpaymentPct(newDownpaymentPct);
        // update downpayment
        let newDownpayment = +((downpaymentPct === 0) ? 0 : parseFloat(downpaymentPct / 100) * newPurchasePrice).toFixed(0);
        setDownpayment(newDownpayment);
        // update insurance
        let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(newPurchasePrice), (parseFloat(downpaymentPct / 100) * newPurchasePrice), parseFloat(interestRate), parseInt(loanTerm))));
        let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * mortgageInsurancePct) * 12);
        newMortgageInsurance = (parseInt(downpaymentPct) < 20) ? newMortgageInsurance : 0
        setMortgageInsurance(newMortgageInsurance);
        // update property taxes
        let newTaxes = Math.round(parseFloat(parseInt(newPurchasePrice) * (0.0061)) / 12)
        setTaxes(newTaxes);
        // new land value
        let newLandValue = Math.round(newPurchasePrice * 0.33);
        setLandValue(newLandValue);
    }

    
    const handleDownpayment = (e) => {
        let newDownpayment = Math.round(e)
        setDownpayment(newDownpayment);
        let newDownpaymentPct = +((e === 0) ? 0 : (parseFloat(e / purchasePrice) * 100)).toFixed(1);
        setDownpaymentPct(newDownpaymentPct);
        let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), parseInt(e), parseFloat(interestRate), parseInt(loanTerm))));
        let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * mortgageInsurancePct) * 12);
        newMortgageInsurance = (parseInt(parseFloat(e / purchasePrice) * 100) < 20) ? newMortgageInsurance : 0
        setMortgageInsurance(newMortgageInsurance);
    }
    
    const handleDownpaymentPct = (e) => {
        let newDownpaymentPct = +(parseFloat(e)).toFixed(1);
        setDownpaymentPct(newDownpaymentPct);
        let newDownpayment = +((e === 0) ? 0 : parseFloat(e / 100) * purchasePrice).toFixed(0);
        setDownpayment(newDownpayment);
        let newtotalMonthlyMortgage = Math.round(parseInt(allFunctions.calcMortgage(parseInt(purchasePrice), (parseFloat(e / 100) * purchasePrice), parseFloat(interestRate), parseInt(loanTerm))));
        let newMortgageInsurance = Math.round(parseFloat(newtotalMonthlyMortgage * mortgageInsurancePct) * 12);
        newMortgageInsurance = (parseInt(e) < 20) ? newMortgageInsurance : 0
        setMortgageInsurance(newMortgageInsurance);
    }

    const handlePropMgmt = (e) => {
        setPropMgmt(e);
        let newPropMgntPct = +((parseFloat(e) / rent) * 100).toFixed(1);
        setPropMgmtPct(+((e === 0) ? 0 : newPropMgntPct).toFixed(1));
    }
    
    const handlePropMgmtPct = (e) => {
        setPropMgmtPct(+(parseFloat(e)).toFixed(1));
        let newPropMgnt = +((parseFloat(e) / 100) * rent).toFixed(0);
        setPropMgmt((e === 0) ? 0 : newPropMgnt);
    }
    
    const handleVacancy = (e) => {
        setVacancy(e);
        setVacancyPct(+((e === 0) ? 0 : ((e / rent) * 100)).toFixed(1));
    }
    
    const handleVacancyPct = (e) => {
        setVacancyPct(+(parseFloat(e)).toFixed(1));
        setVacancy(+((e === 0) ? 0 : ((e / 100) * rent)).toFixed(0));
    }

    const handleRent = (rent) => {
        setRent(rent)
        
        // update property management
        let newPropMgnt = +((parseFloat(propMgmtPct) / 100) * rent).toFixed(0);
        setPropMgmt((propMgmtPct === 0) ? 0 : newPropMgnt);
        
        // update vacancy
        let newVacancy = +((vacancyPct === 0) ? 0 : ((vacancyPct / 100) * rent)).toFixed(0);
        setVacancy(newVacancy);
    }
    
    // set isMobile bool
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

    const handleCalculate = async () => {
        setLoading(true);

        // create new custom Id
        let customId = 'TEST';
        if (properties.length > 0) {
            // increment the deal Id count by one from the previous deal
            let mostRecentId = properties[properties.length - 1]['id']
            customId = `deal_${parseInt(mostRecentId.charAt(mostRecentId.length - 1)) + 1}`
        } else {
            // else, this if the first time they saved a deal, so start with deal_1
            customId = 'deal_1'
        }

        // set property object
        const nowDate = new Date();
        const currentDate = (nowDate.getMonth() + 1) + '/' + nowDate.getDate() + '/' + nowDate.getFullYear();
        const newProperty = {
            "id": customId,
            "created_at": currentDate,
            "address": address,
            "price": purchasePrice,
            "bedrooms": bedrooms,
            "bathrooms": bathrooms,
            "squareFeet": squareFeet,
            "yearBuilt": yearBuilt,
            "propertyType": propertyType,
            "closingCosts": closingCosts,
            "rehabCosts": rehabCosts,
            "miscCosts": miscCosts,
            "rent": rent,
            "units": 1,
            "otherIncome": otherIncome,
            "downpayment": downpayment,
            "downpaymentPct": downpaymentPct,
            "interestRate": interestRate,
            "loanTerm": loanTerm,
            "taxes": taxes,
            "insurance": insurance,
            "mortgageInsurance": mortgageInsurance,
            "mortgageInsurancePct": mortgageInsurancePct,
            "propertyTaxRate": 0.61,
            "hoa": hoa,
            "lastUpdated": currentDate,
            "utilitiesPaid": utilitiesPaid,
            "electricity": electricity,
            "gas": gas,
            "sewage": sewage,
            "garbage": garbage,
            "lawn": lawn,
            "repairs": repairs,
            "capex": capex,
            "propMgmt": propMgmt,
            "propMgmtPct": propMgmtPct,
            "vacancy": vacancy,
            "vacancyPct": vacancyPct,
            "otherExpenses": otherExpenses,
            "buyersAgent": buyersAgent,
            "sellersAgent": sellersAgent,
            "marketAppr": marketAppr,
            "annualIncome": annualIncome,
            "filingStatus": filingStatus,
            "landValue": landValue,
            "deprPeriod": deprPeriod
        }
        let newProperties = properties;
        newProperties.push(newProperty);

        if (session) {
            // save properties to supabase
            try {
                const updates = {
                    id: session.user.id,
                    updated_at: new Date(),
                    calculations: newProperties
                }

                let { error } = await supabase.from('profiles').upsert(updates)

                if (error) {
                    throw error
                }
                    
            } catch (error) {
                console.error(error.message)
            }
        }

        setProperty(newProperty);
        setProperties(newProperties);
        setLoading(false);
    }

    // update property edits
    const handleUpdateProperty = async (key, value) => {
        // if currency, convert to integer
        if (typeof value === "object") {
            value = value.target.value
            value = parseInt(value.substring(1, value.length).replace(',', ''));
        } else if (typeof value === "string" && value.charAt(0) === "$") {
            value = parseInt(value.substring(1, value.length).replace(',', ''));
        }
        if (key === "squareFeet") {
            value = parseInt(value.replace(',', ''));
        }
        
        // find the property in the array
        let index = null;
        properties.forEach(prop => {
            if (prop.id === property.id) {
                index = properties.indexOf(prop);
            }
        })
        
        properties[index][key] = value;

        // Update percentage and value combos
        if (key === 'price') {
            properties[index]['price'] = parseInt(purchasePrice);
            properties[index]['downpayment'] = parseInt(downpayment);
            properties[index]['downpaymentPct'] = parseFloat(downpaymentPct);
            properties[index]['mortgageInsurance'] = parseInt(mortgageInsurance);
            properties[index]['taxes'] = parseInt(taxes);
        } else if (key === 'downpaymentPct' || key === 'downpayment') {
            properties[index]['downpayment'] = parseInt(downpayment);
            properties[index]['downpaymentPct'] = parseFloat(downpaymentPct);
            properties[index]['mortgageInsurance'] = parseInt(mortgageInsurance);
        } else if (key === 'propMgmtPct' || key === 'propMgmt' || key === 'rent') {
            properties[index]['propMgmt'] = parseInt(propMgmt);
            properties[index]['propMgmtPct'] = parseFloat(propMgmtPct);
        } else if (key === 'vacancyPct' || key === 'vacancy' || key === 'rent') {
            properties[index]['vacancy'] = parseInt(vacancy);
            properties[index]['vacancyPct'] = parseFloat(vacancyPct);
        } else if (key === 'interestRate') {
            properties[index]['interestRate'] = parseFloat(interestRate);
        }
        // update property object
        setProperty(properties[index]);

        // set new property edits from supabase
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                calculations: properties,
            }

            let { error } = await supabase.from('profiles').upsert(updates);

            if (error) {
                throw error
            } else {
                // update properties object
                setProperties(properties);
            }

        } catch (error) {
            console.error(error.message)
        }
    }

    if (purchasePrice > 0 && rent > 0 && address.length > 0) {  
        disableBtn.current = false;
    } else {
        disableBtn.current = true;
    }

    const selectAddress = () => {
        if (property === null) {
            const input = document.getElementById('address-input-box');
            input.select();
            const topOfDiv = document.getElementById('PropertyAddress');
            topOfDiv.scrollIntoView();
        }
    }

    const handleUpdatePropertyType = (type) => {
        setPropertyType(type);
        handleUpdateProperty('propertyType', type);
    }
   
    const handleUpdateFilingStatus = (status) => {
        setFilingStatus(status);
        handleUpdateProperty('filingStatus', status);
    }

    const handleSetProperty = (property) => {
        setProperty(property);
        // property info
        setAddress(property.address);
        setBedrooms(parseInt(property.bedrooms));
        setBathrooms(parseInt(property.bathrooms));
        setSquareFeet(parseInt(property.squareFeet));
        setPropertyType(property.propertyType);
        setYearBuilt(parseInt(property.yearBuilt));
        // income
        setRent(parseInt(property.rent));
        setOtherIncome(parseInt(property.otherIncome));
        // initial investment
        setClosingCosts(parseInt(property.closingCosts));
        setRehabCosts(parseInt(property.rehabCosts));
        setMiscCosts(parseInt(property.miscCosts));
        // mortgage
        setPurchasePrice(parseInt(parseInt(property.price)));
        setDownpayment(parseInt(property.downpayment));
        setDownpaymentPct(parseFloat(property.downpaymentPct));
        setInterestRate(parseFloat(property.interestRate));
        setLoanTerm(parseInt(property.loanTerm));
        // expenses
        setTaxes(parseInt(property.taxes));
        setInsurance(parseInt(property.insurance));
        setMortgageInsurance(parseInt(property.mortgageInsurance));
        setMortgageInsurancePct(parseFloat(property.mortgageInsurancePct));
        setHOA(parseInt(property.hoa));
        setUtilitiesPaid(property.utilitiesPaid);
        setElectricity(parseInt(property.electricity));
        setGas(parseInt(property.gas));
        setSewage(parseInt(property.sewage));
        setGarbage(parseInt(property.garbage));
        setLawn(parseInt(property.lawn));
        setRepairs(parseInt(property.repairs));
        setCapex(parseInt(property.capex));
        setPropMgmtPct(parseFloat(property.propMgmtPct));
        setPropMgmt(parseInt(property.propMgmt));
        setVacancyPct(parseFloat(property.vacancyPct));
        setVacancy(parseInt(property.vacancy));
        setOtherExpenses(parseInt(property.otherExpenses));
        // appreciation & savings
        setMarketAppr(parseInt(property.marketAppr));
        setAnnualIncome(parseInt(property.annualIncome));
        setFilingStatus(property.filingStatus);
        setLandValue(parseInt(property.landValue));
        setDeprPeriod(parseFloat(property.deprPeriod));
        setBuyersAgent(parseInt(property.buyersAgent));
        setSellersAgent(parseInt(property.sellersAgent));
    }

    const handleAddProperty = () => {
        setProperty(null);
        // property info
        setAddress('');
        setBedrooms('');
        setBathrooms('');
        setSquareFeet('');
        setPropertyType('Single Family');
        setYearBuilt('');
        // income
        setRent(0);
        setOtherIncome(0);
        // initial investment
        setClosingCosts(3000);
        setRehabCosts(1000);
        setMiscCosts(0);
        // mortgage
        setPurchasePrice(0);
        setDownpayment(0);
        setDownpaymentPct(20);
        setInterestRate(6.0);
        setLoanTerm(30);
        // expenses
        setTaxes(0);
        setInsurance(100);
        setMortgageInsurance(0);
        setMortgageInsurancePct(0);
        setHOA(0);
        setUtilitiesPaid(true);
        setElectricity(70);
        setGas(30);
        setSewage(0);
        setGarbage(0);
        setLawn(0);
        setRepairs(100);
        setCapex(100);
        setPropMgmtPct(0);
        setPropMgmt(0);
        setVacancyPct(5);
        setVacancy(0);
        setOtherExpenses(0);
        // appreciation & savings
        setMarketAppr(5);
        setAnnualIncome(0);
        setFilingStatus('Single');
        setLandValue(0);
        setDeprPeriod(27.5);
        setBuyersAgent(3);
        setSellersAgent(3);
        setTaxBenefits(false);
        setAssetAppreciation(false);
        setDepreciationDeducation(false);
        // collapsibles
        setMortgageCard(false);
        setMortgageArrow(false);
        setAdvancedExpenses(false);
        setAdvancedSavings(false);
        setCosts(false);
        setUtilities(false);
        setUtilitiesArrow(false);
        setOtherCostsArrow(false);
        setAppreciationArrow(false);
        setTaxBenefitsArrow(false);
        setDepreciationDeductionArrow(false);
        setInitArrow(false);
        setInitInvestmentCard(false);
        // select address box & scroll to top
        selectAddress();
    }

    const handleDeleteProperty = async (property) => {
        // find the property in the array
        let index = null;
        properties.forEach(prop => {
            if (prop.id === property.id) {
                index = properties.indexOf(prop);
            }
        })
        if (index > -1) { // only splice array when item is found
            properties.splice(index, 1); // 2nd parameter means remove one item only
        }

        // save to supabase and local vars
        try {
            const updates = {
                id: session.user.id,
                updated_at: new Date(),
                calculations: properties,
            }

            let { error } = await supabase.from('profiles').upsert(updates);

            if (error) {
                throw error
            } else {
                // update property objects
                if (index > 0) {
                    handleSetProperty(properties[index - 1]);
                } else {
                    handleAddProperty();
                }
                setProperties(properties);
            }

        } catch (error) {
            console.error(error.message)
        }
    }

    const returns = [
        {
            "label": "Cash flow",
            "value": `$${monthlyCashFlow}`
        },
        {
            "label": "Cap rate",
            "value": `${newCapRate}%`
        },
        {
            "label": "Total return",
            "value": `${newTotalReturn}%`
        },
        {
            "label": "Cash on Cash",
            "value": `${newCOC}%`
        },
    ]
    
    // drawer for mobile
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
    };

    const handleCloseLogin = () => {
        setOpenLogin(false);
        setOpenWillDeleteModal(true);
        setTimeout(() => { setLoginHeader("Sign up"); }, 200)
    }

    return (
        <div onLoad={() => selectAddress()}>
            
            {/* Login modal */}
            <Modal size={'md'} centered={true} show={openLogin} onHide={() => handleCloseLogin()}>
                <Modal.Body>
                    <SignupComponent
                        loginHeader={loginHeader}
                        loginOrSignup={"Sign up"}
                        handleCloseLogin={handleCloseLogin}
                    />
                </Modal.Body>
            </Modal>

            {/* Are you sure? modal */}
            <Modal size={'md'} centered={true} show={openWillDeleteModal} onHide={() => setOpenWillDeleteModal(false)}>
                <Modal.Body>
                    <div className='mx-3 mt-3'>
                        <p className='text-center text-2xl font-extrabold tracking-tighter'>Are you sure you don't want to save?</p>
                        <p className='text-lg mt-4'>This calculation will not save when you close this browser without linking it to a database by logging in or registering.</p>
                        <p className='text-lg mt-3'>Are you sure you want to continue without logging in or registering?</p>
                        <div className='flex justify-center lg:gap-3 mt-4 mb-3'>
                            <button onClick={() => { setOpenWillDeleteModal(false); setOpenLogin(true); }} className='w-48 py-3 font-bold text-lg hover:bg-light-gray rounded-xl'>Login/Register</button>
                            <button onClick={() => setOpenWillDeleteModal(false)} className='w-48 py-3 font-bold text-lg text-white bg-red hover:bg-dark-red rounded-xl'>Yes, do not save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={isMobile ? 'pl-3 pr-2.5 pb-2' : 'px-6 py-2'}>
                <div className='lg:flex'>
                    {isMobile ? (
                        <div>
                            <React.Fragment key={'bottom'}>
                                <button
                                    onClick={toggleDrawer('bottom', true)}
                                    className='bg-light-gray hover:bg-light-gray px-3 py-2.5 rounded-md mb-2'
                                >
                                    <FontAwesomeIcon icon={faBars} className="text-2xl text-gray" />
                                </button>
                                <Drawer
                                    anchor={'bottom'}
                                    open={state['bottom']}
                                    onClose={toggleDrawer('bottom', false)}
                                >
                                    <div className='h-auto p-4 pb-5'>
                                        <div className='flex justify-center items-center'>
                                            <button
                                                onClick={() => handleAddProperty()}
                                                className='w-72 rounded-md text-xl text-black border-dark-gray border-2 py-2.5 hover:bg-light-gray-2'
                                            >
                                                <FontAwesomeIcon icon={faPlus} className='mr-2' />
                                                Add Property
                                            </button>
                                        </div>
                                        {properties.map(function (prop, index) {
                                            const NUM_CHARS = property && prop.id === property.id ? 23 : 26
                                            return (
                                                <div className='flex flex-col justify-start items-center gap-5 mt-3 text-black text-lg font-semibold'>
                                                    <div
                                                        onClick={() => handleSetProperty(prop)}
                                                        className={`flex justify-between items-center w-72 px-2.5 cursor-pointer py-2.5 hover:bg-light-gray-2 rounded-md ${property && prop.id === property.id ? 'bg-light-gray-2': ''}`}
                                                    >
                                                        <p>
                                                            {property && prop.id === property.id
                                                                ? address.length > 25 ? `${address.substring(0, NUM_CHARS)}..` : address
                                                                : prop.address.length > 25 ? `${prop.address.substring(0, NUM_CHARS)}..` : prop.address}
                                                        </p>
                                                        <FontAwesomeIcon
                                                            icon={faTrashCan}
                                                            onClick={() => handleDeleteProperty(property)}
                                                            className={property && prop.id === property.id ? 'h-5 text-gray cursor-pointer hover:text-dark-gray' : 'hidden'}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Drawer>
                            </React.Fragment>
                        </div>
                    ) : (
                        <div className='rounded-xl bg-light-gray text-white w-80 h-auto p-4'>
                            <div className='flex justify-center items-center'>
                                <button
                                    onClick={() => handleAddProperty()}
                                    className='w-72 rounded-md text-xl text-black border-dark-gray border-2 py-2.5 hover:bg-light-gray-2'
                                >
                                    <FontAwesomeIcon icon={faPlus} className='mr-2' />
                                    Add Property
                                </button>
                            </div>
                            {properties.map(function (prop, index) {
                                const NUM_CHARS = property && prop.id === property.id ? 23 : 26
                                return (
                                    <div className='flex flex-col justify-start items-center gap-5 mt-3 text-black text-lg font-semibold'>
                                        <div
                                            onClick={() => handleSetProperty(prop)}
                                            className={`flex justify-between items-center w-72 px-2.5 cursor-pointer py-2.5 hover:bg-light-gray-2 rounded-md ${property && prop.id === property.id ? 'bg-light-gray-2': 'bg-light-gray'}`}
                                        >
                                            <p>
                                                {property && prop.id === property.id
                                                    ? address.length > 25 ? `${address.substring(0, NUM_CHARS)}..` : address
                                                    : prop.address.length > 25 ? `${prop.address.substring(0, NUM_CHARS)}..` : prop.address}
                                            </p>
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                onClick={() => handleDeleteProperty(property)}
                                                className={property && prop.id === property.id ? 'h-5 text-gray cursor-pointer hover:text-dark-gray' : 'hidden'}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {property ? (
                        <div className={`flex flex-col justify-start ${isMobile ? 'ml-0' : 'ml-5'}`} style={{width: isMobile ? '100%' : ''}}>
                            <div className='mb-4'>
                                <p className='text-3xl font-extrabold tracking-tight'>{address}</p>
                                <p className='text-xl mt-3'>Adjust the property's details below and we'll recalculate the returns for you.</p>
                            </div>
                            <div className='lg:flex lg:justify-center lg:gap-5'>
                                <div className={isMobile ? '' : 'hidden'}>
                                    <div className={`flex flex-col justify-start ${isMobile ? '' : 'overflow-scroll'}`} style={{height: isMobile ? '100%' : '650px'}}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <p className={`text-3xl font-bold ${monthlyCashFlow > 0 ? 'text-green' : 'text-red'}`}>
                                                {monthlyCashFlowText}
                                            </p>
                                            <p className='text-2xl font-bold'>
                                                Monthly cash flow
                                            </p>
                                        </div>
                                        <div className='lg:flex lg:justify-around'>
                                            <DonutChart data={incomeData} customTitle='Income' />
                                            <DonutChart data={expensesData} customTitle='Expenses' />
                                        </div>
                                        <hr className='text-gray text-xl mt-2 mb-12' />
                                        <div className={`flex justify-around ${isMobile ? 'mb-4' : ''}`}>
                                            {returns.map(function (myReturn, index) {
                                                if (myReturn.label === "Cash flow") {
                                                    return (
                                                        <p className='hidden'></p>
                                                    )
                                                } else {
                                                    return (
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <p className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold ${myReturn.label === "Cash flow" ? 'text-green' : 'text-bot-blue'}`}>
                                                                {myReturn.value}
                                                            </p>
                                                            <p className={`${isMobile ? 'text-lg' : 'text-xl'} font-semibold`}>
                                                                {myReturn.label}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <hr className='text-gray text-xl my-4' />
                                    </div>
                                </div>
                                <div style={{width: isMobile ? '100%' : '500px'}}>
                                    <div className={isMobile ? 'pr-1 pb-4' : 'pr-3 overflow-scroll'} style={{height: isMobile ? '100%' : '690px'}}>
                                        <p className='text-xl font-bold tracking-tight ml-1 mt-3'>Property Address</p>
                                        <Form.Control
                                            className='h-12 pl-2.5 ml-1 mt-3 text-xl font-bold border-dark-gray text-dark-gray'
                                            id='address-input-box'
                                            type="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            onBlur={(e) => handleUpdateProperty('address', (e === undefined) ? 0 : e.target.value)}
                                        />
                                        <p id='PropertyDetails' className='text-xl font-bold tracking-tight ml-1 mt-16'>Property Details</p>
                                        <div className='bg-light-gray rounded-md pb-3 mt-2'>
                                            <div className='px-4'>
                                                <div className="flex justify-between">
                                                    <Form.Label className="font-normal mt-4">Purchase Price:</Form.Label>
                                                    <CurrencyInput
                                                        className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                        prefix='$'
                                                        value={purchasePrice}
                                                        decimalsLimit={0}
                                                        onClick="this.select();"
                                                        onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                                        onBlur={(e) => handleUpdateProperty('price', (e === undefined) ? 0 : e.target.value)}
                                                    />
                                                </div>
                                                <InputBox label="Bedrooms" value={bedrooms} change={setBedrooms} labelKey={'bedrooms'} type="calculator_number" handleUpdateProperty={handleUpdateProperty} />
                                                <InputBox label="Bathrooms" value={bathrooms} change={setBathrooms} labelKey={'bathrooms'} type="calculator_number" handleUpdateProperty={handleUpdateProperty} />
                                                <InputBox label="Square feet" value={squareFeet} change={setSquareFeet} labelKey={'squareFeet'} type="calculator_number_long" handleUpdateProperty={handleUpdateProperty} />
                                                <div className="flex justify-between">
                                                    <Form.Label className="font-normal mt-4">Property type:</Form.Label>
                                                    <DropdownButton size="md" variant='secondary-outline' title={propertyType} className="mt-3 rounded-md z-50 border-light-gray-4 bg-white text-black hover:text-black" style={{borderWidth: '1px'}}>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Single Family")}>Single Family</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Townhouse")}>Townhouse</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Condo")}>Condo</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Multi-family")}>Multi-family</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Manufactured")}>Manufactured</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Lot")}>Lot</Dropdown.Item>
                                                        <Dropdown.Item onSelect={() => handleUpdatePropertyType("Land")}>Land</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                                <div className="flex justify-between">
                                                    <Form.Label className="font-normal mt-4">Year built:</Form.Label>
                                                    <input
                                                        className="w-20 pl-2 mt-3 border-one rounded-sm"
                                                        value={yearBuilt}
                                                        onChange={(e) => setYearBuilt(e.target.value)}
                                                        onBlur={(e) => handleUpdateProperty('yearBuilt', (e === undefined) ? 0 : e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <Card className='mt-2 p-2.5 pl-3 mx-2 cursor-pointer border-none bg-light-gray hover:bg-light-gray-2'
                                                onClick={() => handleCollapse(initInvestmentCard, setInitInvestmentCard, initArrow, setInitArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex justify-center'>
                                                        <p><ArrowIcon status={initArrow} /></p>
                                                        <p className="color-gray">Initial Investment</p>
                                                    </div>
                                                    <p className="color-gray mr-3">
                                                        ${totalInitialInvestment.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={initInvestmentCard}>
                                                <div className="bg-light-gray-2 rounded-md mt-2 pl-2 mx-2">
                                                    <div className='flex justify-between'>
                                                        <Form.Label className={`font-normal mt-4 mx-2`}>Down payment:</Form.Label>
                                                        <div className="flex mr-2">
                                                            <CurrencyInput
                                                                className="lg:ml-4 mt-3 p-1 pl-2 w-16 border-one rounded-l-md"
                                                                suffix='%'
                                                                value={downpaymentPct}
                                                                decimalsLimit={0}
                                                                onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('downpaymentPct', (e === undefined) ? 0 : e)}
                                                                />
                                                            <CurrencyInput
                                                                className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r-md"
                                                                prefix='$'
                                                                value={downpayment}
                                                                decimalsLimit={2}
                                                                onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('downpayment', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='pb-2.5 pr-2'>
                                                        <Card className='my-2 p-2.5 pl-3 cursor-pointer bg-light-gray-2 hover:bg-light-gray-3 border-none'
                                                            onClick={() => handleCollapse(costs, setCosts, otherCostsArrow, setOtherCostsArrow)}
                                                        >
                                                            <div className='flex justify-between'>
                                                                <div className='flex justify-center'>
                                                                    <ArrowIcon status={otherCostsArrow} color={"black"} />
                                                                    Other costs:
                                                                </div>
                                                                <p className="color-gray mr-4">
                                                                    ${totalOtherCosts.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                                </p>
                                                            </div>
                                                        </Card>
                                                        <Collapse in={costs}>
                                                            <div className='bg-light-gray-3 mt-1 pb-3 px-3 rounded-lg'>
                                                                <InputBox label="Closing costs" value={closingCosts} change={setClosingCosts} labelKey="closingCosts" handleUpdateProperty={handleUpdateProperty} />
                                                                <InputBox label="Rehab costs" value={rehabCosts} change={setRehabCosts} labelKey='rehab' handleUpdateProperty={handleUpdateProperty} />
                                                                <InputBox label="Misc. costs" value={miscCosts} change={setMiscCosts} labelKey='miscCosts' handleUpdateProperty={handleUpdateProperty} />
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <p id='MonthlyIncome' className='text-xl font-bold tracking-tight text-dark-green ml-1 mt-16'>Monthly Income: ${totalMonthlyIncome.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                        <div className='bg-light-green rounded-md px-4 pb-2 mt-2'>
                                            <div className="flex justify-between">
                                                <Form.Label className="font-normal mt-4">Rent:</Form.Label>
                                                <CurrencyInput
                                                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                    prefix='$'
                                                    value={rent}
                                                    decimalsLimit={2}
                                                    onValueChange={e => handleRent((e === undefined) ? 0 : e)}
                                                    onBlur={(e) => handleUpdateProperty('rent', (e === undefined) ? 0 : e)}
                                                />
                                            </div>
                                            <InputBox label="Other" value={otherIncome} change={setOtherIncome} labelKey={'otherIncome'} type="calculator" handleUpdateProperty={handleUpdateProperty} />
                                        </div>
                                        <p id='MonthlyExpenses' className='text-xl font-bold tracking-tight text-dark-red ml-1 mt-16'>Monthly Expenses: ${totalMonthlyExpenses.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                        <div className='bg-light-red rounded-md pt-1.5 pl-1.5 pb-2 mt-2'>
                                            <Card className='mx-2 mt-1 p-2.5 pl-3 cursor-pointer border-none bg-light-red hover:bg-light-red-2'
                                                onClick={() => handleCollapse(mortgageCard, setMortgageCard, mortgageArrow, setMortgageArrow)}
                                            >
                                                <div className='flex justify-between'>
                                                    <div className='flex justify-center'>
                                                        <p><ArrowIcon status={mortgageArrow} /></p>
                                                        <p className="color-gray">Mortgage</p>
                                                    </div>
                                                    <p className="color-gray mr-3">
                                                        ${totalMonthlyMortgage.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={mortgageCard}>
                                                <div className={`bg-light-red-2 m-2 pb-3 rounded-lg ${isMobile ? 'px-2' : 'px-3'}`}>
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Purchase Price:</Form.Label>
                                                        <CurrencyInput
                                                            className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={purchasePrice}
                                                            decimalsLimit={0}
                                                            onClick="this.select();"
                                                            onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                                            onBlur={(e) => handleUpdateProperty('price', (e === undefined) ? 0 : e)}
                                                        />
                                                    </div>
                                                    <div className='flex justify-between'>
                                                        <Form.Label className={`font-normal mt-4 ${isMobile ? '' : 'mr-3'}`}>Down payment:</Form.Label>
                                                        <div className="flex">
                                                            <CurrencyInput
                                                                className="lg:ml-4 mt-3 p-1 pl-2 w-16 border-one rounded-l"
                                                                suffix='%'
                                                                value={downpaymentPct}
                                                                decimalsLimit={0}
                                                                onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('downpaymentPct', (e === undefined) ? 0 : e)}
                                                                />
                                                            <CurrencyInput
                                                                className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r"
                                                                prefix='$'
                                                                value={downpayment}
                                                                decimalsLimit={2}
                                                                onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('downpayment', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputBox label="Interest rate" value={interestRate} change={setInterestRate} type="calculator_percentage" labelKey='interestRate' handleUpdateProperty={handleUpdateProperty} />
                                                    <div className="flex justify-between">
                                                        <Form.Label className="font-normal mt-4">Loan term:</Form.Label>
                                                        <CurrencyInput
                                                            className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                            suffix=" years"
                                                            value={loanTerm}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => setLoanTerm((e === undefined) ? 0 : e)}
                                                            onBlur={(e) => handleUpdateProperty('loanTerm', e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <div className='mx-1.5'>
                                                <p className='text-sm mt-3 ml-4 mb-2 text-gray cursor-pointer underline hover:underline' onClick={() => setAdvancedExpenses(!advancedExpenses)}>
                                                    {advancedExpenses ? 'Hide Advanced' : 'Show Advanced'}
                                                </p>
                                                <Collapse in={advancedExpenses}>
                                                    <div className={`bg-light-red m-1 pb-3 rounded-lg px-3`}>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4">{isMobile ? 'Prop Taxes:' : 'Property Taxes:'}</Form.Label>
                                                                <img data-tip data-for="taxEstimate" className="w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={taxes}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => setTaxes((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('taxes', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Insurance:' : 'Home Insurance:'}</Form.Label>
                                                                <img data-tip data-for="homeownersInsurance" className="w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={insurance}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => setInsurance((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('insurance', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">PMI:</Form.Label>
                                                                <img data-tip data-for="mortgageEstimate" className="rounded-xl w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={mortgageInsurance}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => setMortgageInsurance((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('mortgageInsurance', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">HOA:</Form.Label>
                                                                <img data-tip data-for="hoa" className="rounded-xl w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={hoa}
                                                                decimalsLimit={2}
                                                                onValueChange={e => setHOA((e === undefined) ? 0 : e)}
                                                                onBlur={(e) => handleUpdateProperty('hoa', (e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className='pt-1'>
                                                            <Card className='mt-2 p-2.5 pl-0 cursor-pointer bg-light-red hover:bg-light-red-2 border-none'
                                                                onClick={() => handleCollapse(utilities, setUtilities, utilitiesArrow, setUtilitiesArrow)}
                                                            >
                                                                <div className='flex justify-between'>
                                                                    <div className='flex justify-center pl-2'>
                                                                        <p><ArrowIcon status={utilitiesArrow} color={(utilitiesPaid) ? 'gray': ''} /></p>
                                                                        <p className={`${(utilitiesPaid) ? 'text-gray' : ''}`}>Utilities:</p>
                                                                    </div>
                                                                    <p className={`${(utilitiesPaid) ? 'text-gray' : ''} mr-4`}>
                                                                        ${totalUtilities.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                                    </p>
                                                                </div>
                                                            </Card>
                                                            <Collapse in={utilities}>
                                                                <div className='bg-light-red-2 mt-2 pb-1 px-3 rounded-lg'>
                                                                    <InputBox label="Electricity" value={electricity} change={setElectricity} labelKey='electricity' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                                    <InputBox label="Gas" value={gas} change={setGas} labelKey='gas' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                                    <InputBox label="Sewage" value={sewage} change={setSewage} labelKey='sewage' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                                    <InputBox label="Garbage" value={garbage} change={setGarbage} labelKey='garbage' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                                    <InputBox label="Other" value={lawn} change={setLawn} labelKey='lawn' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                                    <div className='flex justify-center mt-2'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox checked={utilitiesPaid} />
                                                                            }
                                                                            label="Paid by renters"
                                                                            onClick={() => setUtilitiesPaid(!utilitiesPaid)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className='lg:ml-3 ml-1 -pt-3'>
                                                            <div className="flex justify-between">
                                                                <div className='flex justify-center gap-1'>
                                                                    <Form.Label className="font-normal mt-4 text-left">Repairs:</Form.Label>
                                                                    <img data-tip data-for="repairs" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                                </div>
                                                                <CurrencyInput
                                                                    className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={repairs}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => setRepairs((e === undefined) ? 0 : e)}
                                                                    onBlur={(e) => handleUpdateProperty('repairs', (e === undefined) ? 0 : e)}
                                                                    />
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <div className='flex justify-center gap-1'>
                                                                    <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'CapEx:' : 'Capital Expenditures:'}</Form.Label>
                                                                    <img data-tip data-for="capEx" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                                </div>
                                                                <CurrencyInput
                                                                    className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={capex}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => setCapex((e === undefined) ? 0 : e)}
                                                                    onBlur={(e) => handleUpdateProperty('capex', (e === undefined) ? 0 : e)}
                                                                />
                                                            </div>
                                                            <div className='flex justify-between'>
                                                                <div className='flex justify-center gap-1'>
                                                                    <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Prop Mgnt:' : 'Property Management:'}</Form.Label>
                                                                    <img data-tip data-for="propMgnt" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <CurrencyInput
                                                                        className="lg:ml-4 ml-3 mt-3 p-1 pl-2 lg:w-16 w-14 border-one rounded-l-md"
                                                                        suffix='%'
                                                                        value={propMgmtPct}
                                                                        decimalsLimit={0}
                                                                        onValueChange={(e) => handlePropMgmtPct((e === undefined) ? 0 : e)}
                                                                        onBlur={(e) => handleUpdateProperty('propMgmt', (e === undefined) ? 0 : e)}
                                                                        />
                                                                    <CurrencyInput
                                                                        className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                        prefix='$'
                                                                        value={propMgmt}
                                                                        decimalsLimit={2}
                                                                        onValueChange={e => handlePropMgmt((e === undefined) ? 0 : e)}
                                                                        onBlur={(e) => handleUpdateProperty('propMgmtPct', (e === undefined) ? 0 : e)}
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between'>
                                                                <div className='flex justify-center gap-1'>
                                                                    <Form.Label className="font-normal mt-4 text-left">Vacancy:</Form.Label>
                                                                    <img data-tip data-for="vacancy" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <CurrencyInput
                                                                        className="lg:ml-4 ml-3 mt-3 p-1 pl-2 lg:w-16 w-14 border-one rounded-l-md"
                                                                        suffix='%'
                                                                        value={vacancyPct}
                                                                        decimalsLimit={0}
                                                                        onValueChange={(e) => handleVacancyPct((e === undefined) ? 0 : e)}
                                                                        onBlur={(e) => handleUpdateProperty('vacancyPct', (e === undefined) ? 0 : e)}
                                                                        />
                                                                    <CurrencyInput
                                                                        className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                        prefix='$'
                                                                        value={vacancy}
                                                                        decimalsLimit={2}
                                                                        onValueChange={e => handleVacancy((e === undefined) ? 0 : e)}
                                                                        onBlur={(e) => handleUpdateProperty('vacancy', (e === undefined) ? 0 : e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <InputBox label="Other expenses" value={otherExpenses} change={setOtherExpenses} labelKey='otherExpenses' type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                        </div>
                                                        <p className='text-lg font-semibold mt-5 -mb-1'>Expenses when selling this property:</p>
                                                        <InputBox label="Buyer's agent" value={buyersAgent} change={setBuyersAgent} labelKey={'buyersAgent'} type="calculator_percentage" handleUpdateProperty={handleUpdateProperty} />
                                                        <InputBox label="Seller's agent" value={sellersAgent} change={setSellersAgent} labelKey={'sellersAgent'} type="calculator_percentage" handleUpdateProperty={handleUpdateProperty} />

                                                    </div>
                                                </Collapse>
                    
                                                {/* React Tooltips */}
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='taxEstimate' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                        <p className='text-base text-bot-blue text-center mb-2'>Property Taxes</p>
                                                        <div className='w-80'>
                                                            <p className='text-sm'>Property taxes are calculated by multiplying the property's tax rate (on average between 0.58% and 1.58%) by the value of the home.</p>
                                                            <p className='mt-3 text-sm'>Taxes are typically paid annually. Some finanicial institutions, however, prefer a monthly payment. Either way, we have included property taxes into the monthly payment.</p>
                                                            <p className='mt-3 text-gray text-sm'>Property value: (<span className='font-bold'>${purchasePrice > 0 ? purchasePrice.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</span>) x property tax rate: (<span className='font-bold'>0.61%</span>)</p>
                                                        </div>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='mortgageEstimate' effect='solid' arrowColor='transparent' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque ml-5'
                                                    >
                                                        <p className='text-base text-center text-bot-blue mb-2'>Private Mortgage Insurance (PMI)</p>
                                                        <div className='w-80'>
                                                            <p className='text-sm'>
                                                                The average cost of private mortgage insurance, or PMI, for a conventional home loan ranges from 0.58% to 1.86%
                                                                of the original loan amount per year, according to Genworth Mortgage Insurance, Ginnie Mae and the Urban Institute.
                                                                We estimated a 1.00% of the original loan amount per year.
                                                            </p>
                                                            <p className='mt-2 text-sm'>We encourage you to find an exact amount by connecting with a lender.</p>
                                                        </div>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='homeownersInsurance' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Homeowners Insurance</p>
                                                        <p className='text-sm'>Homeowners insurance is a form of property insurance that covers losses and damages to an individual's residence, along with furnishings and other assets in the home. The annual US average is $1,383 ($138/month) for a policy with $250,000 in dwelling coverage.</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='hoa' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Homeowners Association Fee (HOA)</p>
                                                        <p className='text-sm'>A monthly fee paid to the homeowner's association who makes and enforces rules for a subdivision, planned community, or condominium building.</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='repairs' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Repairs</p>
                                                        <p className='text-sm'>The average monthly cost of repairs made to the property. According to Go Banking Rates, the average annual cost for maintaining a home is $1,204 ($120/month).</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='capEx' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Capital Expenditures (CapEx)</p>
                                                        <p className='text-sm'>Capital expenditures are the big purchases you have to make once every 10-20 years such as replacing the roof or water heater. BiggerPockets suggests putting aside $100/month is a fair estimate.</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='propMgnt' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Property Management</p>
                                                        <p className='text-sm'>The property management fee is on average 10% of the monthly rent. Property managers take care of all your management needs such as sourcing and screening potential renters, maintenance repairs, and any problems with tenants.</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <ReactTooltip id='vacancy' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                        className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                    >
                                                        <p className='text-base text-bot-blue mb-2 text-center'>Vacancy</p>
                                                        <p className='text-sm'>Vacancy is the percentage of time in a year that no tenants are renting. The national average rental vacancy rate for 2021 was 6.1%.</p>
                                                    </ReactTooltip>
                                                </div>
                    
                                            </div>
                                        </div>
                                        <p id='appreciationAndSavings' className='text-xl font-bold tracking-tight ml-1 mt-16'>Appreciation & Savings: ${totalAppreciationandTaxes.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                        <Form.Group className={`bg-light-gray mt-2 pt-1 pb-2 px-3`}>
                                                                    
                                            {/* Market Appreciation */}
                                            <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer bg-light-gray hover:bg-light-gray-2 border-none`}
                                                onClick={() => handleCollapse(assetAppreciation, setAssetAppreciation, appreciationArrow, setAppreciationArrow)}
                                            >
                                                <div className='flex justify-between text-left'>
                                                    <div className='flex flex-row justify-center text-start cursor-pointer'>
                                                        <ArrowIcon status={appreciationArrow} color={"black"} isMobile={isMobile} />
                                                        <Form.Label className="font-normal text-left cursor-pointer">{isMobile ? 'Appreciation:' : `Appreciation (1 yr):`}</Form.Label>
                                                        <img data-tip data-for="appreciation" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                    </div>
                                                    <p className="lg:mr-4 mr-2">
                                                        ${totalAppreciation.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    </p>
                                                </div>
                                            </Card>
                                            <Collapse in={assetAppreciation}>
                                                <div className='bg-light-gray-2 mt-1 pb-3 lg:px-4 px-3 rounded-lg'>
                                                    <InputBox label="Appreciation rate" value={marketAppr} change={setMarketAppr} type="calculator_percentage" labelKey='marketAppr' handleUpdateProperty={handleUpdateProperty} />
                                                </div>
                                            </Collapse>
                    
                                            <p className='text-sm mt-3 ml-4 mb-2 text-gray cursor-pointer underline hover:underline' onClick={() => setAdvancedSavings(!advancedSavings)}>
                                                {advancedSavings ? 'Hide Advanced' : 'Show Advanced'}
                                            </p>
                                            <Collapse in={advancedSavings}>
                                                <div>
                    
                                                    {/* Interest Deduction */}
                                                    <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer text-left bg-light-gray hover:bg-light-gray-2 border-none`}
                                                        onClick={() => handleCollapse(taxBenefits, setTaxBenefits, taxBenefitsArrow, setTaxBenefitsArrow)}
                                                    >
                                                        <div className='flex justify-between'>
                                                            <div className='flex flex-row justify-center cursor-pointer'>
                                                                <ArrowIcon status={taxBenefitsArrow} color={"black"} />
                                                                <Form.Label className="font-normal text-left cursor-pointer">Interest savings:</Form.Label>
                                                                <img data-tip data-for="interestDeduction" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <p className="lg:mr-4 mr-2">
                                                                ${TotalAnnualTaxDeduction.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </p>
                                                        </div>
                                                    </Card>
                                                    <Collapse in={taxBenefits}>
                                                        <div className='bg-light-gray-2 mt-1 pb-3 lg:px-3 rounded-lg'>
                                                            <div className="flex justify-between mx-2">
                                                                <Form.Label className="font-normal mt-4">Annual income:</Form.Label>
                                                                <CurrencyInput
                                                                    className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                                    prefix='$'
                                                                    value={annualIncome}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(e) => setAnnualIncome((e === undefined) ? 0 : e)}
                                                                    onBlur={(e) => handleUpdateProperty('annualIncome', (e === undefined) ? 0 : e.target.value)}
                                                                />
                                                            </div>
                                                            <div className='flex justify-between text-start lg:ml-3 ml-2 pt-3'>
                                                                <p>Filing Status:</p>
                                                                <DropdownButton size="md" variant='secondary-outline' title={filingStatus} className="-mt-1.5 drop-shadow-2xl rounded-md z-50 border-none text-black hover:text-black">
                                                                    <Dropdown.Item onSelect={() => handleUpdateFilingStatus("Single")}>Single</Dropdown.Item>
                                                                    <Dropdown.Item onSelect={() => handleUpdateFilingStatus("Head of Household")}>Head of Household</Dropdown.Item>
                                                                    <Dropdown.Item onSelect={() => handleUpdateFilingStatus("Married Filing Jointly")}>Married Filing Jointly</Dropdown.Item>
                                                                    <Dropdown.Item onSelect={() => handleUpdateFilingStatus("Married Filing Separately")}>Married Filing Separately</Dropdown.Item>
                                                                    <Dropdown.Item onSelect={() => handleUpdateFilingStatus("Qualified Widow")}>Qualified Widow</Dropdown.Item>
                                                                </DropdownButton>
                                                            </div>
                                                            <div className='flex justify-between pt-3 lg:mx-3 mx-2'>
                                                                <div className='flex justify-center'>
                                                                    <p>Tax Bracket: </p>
                                                                    <a href="https://www.bankrate.com/taxes/tax-brackets/" target="__blank">
                                                                        <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm mb-0.5 ml-3 text-bot-blue cursor-pointer" />
                                                                    </a>
                                                                </div>
                                                                <p>{allFunctions.calcTaxBracket(annualIncome, filingStatus)}%</p>
                                                            </div>
                                                            <hr className='mx-2 my-3 border-gray'/>
                                                            <div className='flex justify-between lg:mx-3 mx-2'>
                                                                <p className='text-left'>Deductable interest:</p>
                                                                <p>${annualInterest.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                    
                                                    {/* Depreciation Deduction */}
                                                    <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer text-left bg-light-gray hover:bg-light-gray-2 border-none`}
                                                        onClick={() => handleCollapse(depreciationDeducation, setDepreciationDeducation, depreciationDeductionArrow, setDepreciationDeductionArrow)}
                                                    >
                                                        <div className='flex justify-between'>
                                                            <div className='flex flex-row justify-center cursor-pointer'>
                                                                <ArrowIcon status={depreciationDeductionArrow} color={"black"} />
                                                                <Form.Label className="font-normal text-left cursor-pointer">{isMobile ? 'Depr. savings:' : 'Depreciation savings:'}</Form.Label>
                                                                <img data-tip data-for="depreciationDeduction" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <p className="lg:mr-4 mr-2">
                                                                ${depreciationDeducationSavings.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </p>
                                                        </div>
                                                    </Card>
                                                    <Collapse in={depreciationDeducation}>
                                                        <div className={`bg-light-gray-2 mt-1 pb-3 mb-2 rounded-lg ${isMobile ? 'pl-3 pr-2' : 'pl-4 pr-3'}`}>
                                                            <InputBox label="Property value" value={purchasePrice} change={handlePurchasePrice} labelKey={'price'} type='calculator_long' handleUpdateProperty={handleUpdateProperty} />
                                                            <InputBox label="- Land value" value={landValue} change={setLandValue} labelKey={'landValue'} type='calculator' handleUpdateProperty={handleUpdateProperty} />
                                                            {isMobile ? (
                                                                <InputBox label="&#247; Depr. period" value={deprPeriod} change={setDeprPeriod} suffix=" years" type="calculator_custom" labelKey='deprPeriod' handleUpdateProperty={handleUpdateProperty} />
                                                            ) : (
                                                                <InputBox label="&#247; Depreciation period" value={deprPeriod} change={setDeprPeriod} suffix=" years" type="calculator_custom" labelKey='deprPeriod' handleUpdateProperty={handleUpdateProperty} />
                                                            )}
                                                            <hr className='border-2 border-light-gray-3 my-3' />
                                                            <div className='flex justify-between mr-4'>
                                                                <p>Annual depreciation:</p>
                                                                <p>${TotalAnnualDeprDeduction.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                    
                                                </div>
                                            </Collapse>
                    
                                        </Form.Group>
                    
                                        {/* React Tooltips */}
                                        <div className='flex justify-center'>
                                            <ReactTooltip id='appreciation' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                className='shadow-lg p-3 bg-white opaque w-80'
                                            >
                                                <p className='text-base text-bot-blue mb-2 text-center'>Market Appreciation (1 Yr)</p>
                                                <p className='text-sm'>
                                                    The amount a property's value will have appreciated in a given time frame. Since 1991, real estate in the US has appreciated
                                                    4.3% annually. Given your estimated appreciation of {marketAppr}%, this property will have appreciated
                                                    ${totalAppreciation.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00 over the next year.
                                                </p>
                                            </ReactTooltip>
                                        </div>
                                        <div className='flex justify-center'>
                                            <ReactTooltip id='interestDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                className='shadow-lg p-3 bg-white opaque w-80'
                                            >
                                                <p className='text-base text-bot-blue mb-2 text-center'>Interest Tax Deducation Savings</p>
                                                <p className='text-sm'>
                                                    You don't have to pay any taxes on the money that went towards the interest on your loan. Thus, your interest tax deducation
                                                    savings is calculated by multiplying what you won't be paying in taxes (your payments on interest) by your current tax bracket.
                                                </p>
                                            </ReactTooltip>
                                        </div>
                                        <div className='flex justify-center'>
                                            <ReactTooltip id='depreciationDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                className='shadow-lg p-3 bg-white opaque w-80'
                                            >
                                                <p className='text-base text-bot-blue mb-2 text-center'>Depreciation Tax Deducation Savings</p>
                                                <p className='text-sm'>
                                                    Real estate is a physical asset that depreciates over time. Every year you can write off that year's depreciation on a
                                                    property. Thus, your tax savings from the depreciation deduction on this property (based on your current tax bracket)
                                                    would be ${depreciationDeducationSavings.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00.
                                                </p>
                                                <p className='text-sm mt-2'>
                                                    A property's annual depreciation is calculated by subtracting the land value from the property value (since land doesn't depreciate)
                                                    and then divide that by 27.5 years (the number of years determined by convention for a residential property to fully depreciate).
                                                </p>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className={isMobile ? 'hidden' : ''}>
                                    <div className='flex flex-col justify-start overflow-scroll' style={{height: '650px'}}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <p className={`text-3xl font-bold ${monthlyCashFlow > 0 ? 'text-green' : 'text-red'}`}>
                                                {monthlyCashFlowText}
                                            </p>
                                            <p className='text-2xl font-bold'>
                                                Monthly cash flow
                                            </p>
                                        </div>
                                        <div className='flex justify-around'>
                                            <DonutChart data={incomeData} customTitle='Income' />
                                            <DonutChart data={expensesData} customTitle='Expenses' />
                                        </div>
                                        <hr className='text-gray text-xl mt-2 mb-8' />
                                        <p className='text-3xl font-bold tracking-tight mb-4'>Returns</p>
                                        <div className='flex justify-around'>
                                            {returns.map(function (myReturn, index) {
                                                if (myReturn.label === "Cash flow") {
                                                    return (
                                                        <p className='hidden'></p>
                                                    )
                                                } else {
                                                    return (
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <p className={`text-2xl font-bold ${myReturn.label === "Cash flow" ? 'text-green' : 'text-bot-blue'}`}>
                                                                {myReturn.value}
                                                            </p>
                                                            <p className='text-xl font-semibold'>
                                                                {myReturn.label}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={`flex flex-col justify-center items-center ${isMobile ? 'ml-0' : 'ml-5'}`} style={{width: '100%'}}>
                            <div className='mb-4'>
                                <div className='flex justify-between items-center'>
                                    <p className={`font-extrabold tracking-tight ${isMobile ? 'text-[27px]' : 'text-4xl'}`}>New Property</p>
                                    <button
                                        disabled={disableBtn.current}
                                        onClick={() => handleCalculate()}
                                        className={`text-xl px-3 py-2.5 rounded-md text-white ${disableBtn.current ? 'bg-light-bot-blue' : 'cursor-pointer bg-bot-blue hover:bg-dark-bot-blue'}`}
                                    >
                                        {loading ? (
                                            <div className='flex justify-center items-center gap-2'>
                                                <img src={loading_spinner} className='h-7 text-center text-white' alt='Loading...' />
                                                <p>Calculate</p>
                                            </div>
                                        ) : (
                                            'Calculate'
                                        )}
                                    </button>
                                </div>
                                <p className='text-xl mt-3'>Enter a property's details below and we'll calculate the returns for you.</p>
                                <p className='text-red font-semibold mt-2'>* Required</p>
                            </div>
                            <div className='flex justify-center gap-5' style={{width: isMobile ? '100%' : '1000px'}}>
                                <div className={isMobile ? 'pr-2' : 'pr-3 overflow-scroll'} style={{height: isMobile ? '500px' : '650px', width: isMobile ? '100%' : '650px'}}>
                                    <p id='PropertyAddress' className='text-xl font-bold tracking-tight textrk-gray ml-1 mt-3'>1. Property Address <span className='text-red font-bold -mt-0.5'>*</span></p>
                                    <Form.Control
                                        className='h-12 pl-2.5 ml-1 mt-3 text-xl font-bold border-dark-gray text-dark-gray'
                                        id='address-input-box'
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                    <p id='PropertyDetails' className='text-xl font-bold tracking-tight textrk-gray ml-1 mt-16'>2. Property Details</p>
                                    <div className={`bg-light-gray rounded-md pb-3 mt-2 ${isMobile ? 'px-3' : 'px-4'}`}>
                                        <div className="flex justify-between">
                                            <Form.Label className="font-normal mt-4">Purchase Price: <span className='text-red font-bold -mt-0.5'>*</span></Form.Label>
                                            <CurrencyInput
                                                className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                prefix='$'
                                                value={purchasePrice}
                                                decimalsLimit={0}
                                                onClick="this.select();"
                                                onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                            />
                                        </div>
                                        <InputBox label="Bedrooms" value={bedrooms} change={setBedrooms} labelKey={'bedrooms'} type="number" />
                                        <InputBox label="Bathrooms" value={bathrooms} change={setBathrooms} labelKey={'bathrooms'} type="number" />
                                        <InputBox label="Square feet" value={squareFeet} change={setSquareFeet} labelKey={'squareFeet'} type="number_long" />
                                        <div className="flex justify-between">
                                            <Form.Label className="font-normal mt-4">Property type:</Form.Label>
                                            <DropdownButton size="md" variant='secondary-outline' title={propertyType} className="mt-3 rounded-md z-50 border-light-gray-4 bg-white text-black hover:text-black" style={{borderWidth: '1px'}}>
                                                <Dropdown.Item onSelect={() => setPropertyType("Single Family")}>Single Family</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Townhouse")}>Townhouse</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Condo")}>Condo</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Multi-family")}>Multi-family</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Manufactured")}>Manufactured</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Lot")}>Lot</Dropdown.Item>
                                                <Dropdown.Item onSelect={() => setPropertyType("Land")}>Land</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        <div className="flex justify-between">
                                            <Form.Label className="font-normal mt-4">Year built:</Form.Label>
                                            <input
                                                className="w-20 pl-2 mt-3 border-one rounded-sm"
                                                value={yearBuilt}
                                                onChange={(e) => setYearBuilt(e.target.value)}
                                            />
                                        </div>
                                        <Card className={`mt-2 p-2.5 pl-3 cursor-pointer border-none bg-light-gray hover:bg-light-gray-2`}
                                            onClick={() => handleCollapse(initInvestmentCard, setInitInvestmentCard, initArrow, setInitArrow)}
                                        >
                                            <div className='flex justify-between'>
                                                <div className='flex justify-center'>
                                                    <p><ArrowIcon status={initArrow} /></p>
                                                    <p className="color-gray">Initial Investment</p>
                                                </div>
                                                <p className="color-gray mr-3">
                                                    ${totalInitialInvestment.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                        </Card>
                                        <Collapse in={initInvestmentCard}>
                                            <div className={`bg-light-gray-2 rounded-md mt-2 ${isMobile ? 'px-1.5' : 'px-3'}`}>
                                                <div className='flex justify-between'>
                                                    <Form.Label className={`font-normal mt-4 mx-2`}>Down payment:</Form.Label>
                                                    <div className="flex">
                                                        <CurrencyInput
                                                            className="lg:ml-4 mt-3 p-1 pl-2 w-16 border-one rounded-l-md"
                                                            suffix='%'
                                                            value={downpaymentPct}
                                                            decimalsLimit={0}
                                                            onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                            />
                                                        <CurrencyInput
                                                            className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r-md"
                                                            prefix='$'
                                                            value={downpayment}
                                                            decimalsLimit={2}
                                                            onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='pb-2.5'>
                                                    <Card className='my-2 p-2.5 pl-3 cursor-pointer bg-light-gray-2 hover:bg-light-gray-3 border-none'
                                                        onClick={() => handleCollapse(costs, setCosts, otherCostsArrow, setOtherCostsArrow)}
                                                    >
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center'>
                                                                <ArrowIcon status={otherCostsArrow} color={"black"} />
                                                                Other costs:
                                                            </div>
                                                            <p className="color-gray mr-4">
                                                                ${totalOtherCosts.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </p>
                                                        </div>
                                                    </Card>
                                                    <Collapse in={costs}>
                                                        <div className='bg-light-gray-3 mt-1 pb-3 px-3 rounded-lg'>
                                                            <InputBox label="Closing costs" value={closingCosts} change={setClosingCosts} labelKey="closingCosts" />
                                                            <InputBox label="Rehab costs" value={rehabCosts} change={setRehabCosts} labelKey='rehab' />
                                                            <InputBox label="Misc. costs" value={miscCosts} change={setMiscCosts} labelKey='miscCosts' />
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <p id='MonthlyIncome' className='text-xl font-bold tracking-tight text-dark-green ml-1 mt-16'>3. Monthly Income: ${totalMonthlyIncome.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                    <div className='bg-light-green rounded-md px-4 pb-2 mt-2'>
                                        <div className="flex justify-between">
                                            <Form.Label className="font-normal mt-4">Rent: <span className='text-red font-bold -mt-0.5'>*</span></Form.Label>
                                            <CurrencyInput
                                                className="w-24 pl-2 mt-3 border-one rounded-sm"
                                                prefix='$'
                                                value={rent}
                                                decimalsLimit={2}
                                                onValueChange={e => handleRent((e === undefined) ? 0 : e)}
                                            />
                                        </div>
                                        <InputBox label="Other" value={otherIncome} change={setOtherIncome} labelKey={'otherIncome'} type="currency" />
                                    </div>
                                    <p id='MonthlyExpenses' className='text-xl font-bold tracking-tight text-dark-red ml-1 mt-16'>4. Monthly Expenses: ${totalMonthlyExpenses.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                    <div className='bg-light-red rounded-md pt-1.5 pl-1.5 pb-2 mt-2'>
                                        <Card className='mx-2 mt-1 p-2.5 pl-3 cursor-pointer border-none bg-light-red hover:bg-light-red-2'
                                            onClick={() => handleCollapse(mortgageCard, setMortgageCard, mortgageArrow, setMortgageArrow)}
                                        >
                                            <div className='flex justify-between'>
                                                <div className='flex justify-center'>
                                                    <p><ArrowIcon status={mortgageArrow} /></p>
                                                    <p className="color-gray">Mortgage</p>
                                                </div>
                                                <p className="color-gray mr-3">
                                                    ${totalMonthlyMortgage.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                        </Card>
                                        <Collapse in={mortgageCard}>
                                            <div className={`bg-light-red-2 m-2 pb-3 rounded-lg ${isMobile ? 'px-2' : 'px-3'}`}>
                                                <div className="flex justify-between">
                                                    <Form.Label className="font-normal mt-4">Purchase Price:</Form.Label>
                                                    <CurrencyInput
                                                        className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                        prefix='$'
                                                        value={purchasePrice}
                                                        decimalsLimit={0}
                                                        onClick="this.select();"
                                                        onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                                    />
                                                </div>
                                                <div className='flex justify-between'>
                                                    <Form.Label className={`font-normal mt-4 ${isMobile ? '' : 'mr-3'}`}>Down payment:</Form.Label>
                                                    <div className="flex">
                                                        <CurrencyInput
                                                            className="lg:ml-4 mt-3 p-1 pl-2 w-16 border-one rounded-l"
                                                            suffix='%'
                                                            value={downpaymentPct}
                                                            decimalsLimit={0}
                                                            onValueChange={(e) => handleDownpaymentPct((e === undefined) ? 0 : e)}
                                                            />
                                                        <CurrencyInput
                                                            className="mt-3 p-1 lg:w-28 w-24 pl-2 border-one rounded-r"
                                                            prefix='$'
                                                            value={downpayment}
                                                            decimalsLimit={2}
                                                            onValueChange={e => handleDownpayment((e === undefined) ? 0 : e)}
                                                        />
                                                    </div>
                                                </div>
                                                <InputBox label="Interest rate" value={interestRate} change={setInterestRate} type="percentage" />
                                                <InputBox label="Loan term" value={loanTerm} change={setLoanTerm} suffix=" years" type="custom" />
                                            </div>
                                        </Collapse>
                                        <div className='mx-1.5'>
                                            <p className='text-sm mt-3 ml-4 mb-2 text-gray cursor-pointer underline hover:underline' onClick={() => setAdvancedExpenses(!advancedExpenses)}>
                                                {advancedExpenses ? 'Hide Advanced' : 'Show Advanced'}
                                            </p>
                                            <Collapse in={advancedExpenses}>
                                                <div className={`bg-light-red m-1 pb-3 rounded-lg ${isMobile ? 'px-2' : 'px-3'}`}>
                                                    <div className="flex justify-between">
                                                        <div className='flex justify-center gap-1'>
                                                            <Form.Label className="font-normal mt-4">{isMobile ? 'Prop Taxes:' : 'Property Taxes:'}</Form.Label>
                                                            <img data-tip data-for="taxEstimate" className="w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={taxes}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => setTaxes((e === undefined) ? 0 : e)}
                                                            />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className='flex justify-center gap-1'>
                                                            <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Insurance:' : 'Home Insurance:'}</Form.Label>
                                                            <img data-tip data-for="homeownersInsurance" className="w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={insurance}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => setInsurance((e === undefined) ? 0 : e)}
                                                            />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className='flex justify-center gap-1'>
                                                            <Form.Label className="font-normal mt-4 text-left">PMI:</Form.Label>
                                                            <img data-tip data-for="mortgageEstimate" className="rounded-xl w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={mortgageInsurance}
                                                            decimalsLimit={2}
                                                            onValueChange={(e) => setMortgageInsurance((e === undefined) ? 0 : e)}
                                                            />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <div className='flex justify-center gap-1'>
                                                            <Form.Label className="font-normal mt-4 text-left">HOA:</Form.Label>
                                                            <img data-tip data-for="hoa" className="rounded-xl w-4 mb-3 mt-4 ml-1 rounded-xl" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <CurrencyInput
                                                            className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                            prefix='$'
                                                            value={hoa}
                                                            decimalsLimit={2}
                                                            onValueChange={e => setHOA((e === undefined) ? 0 : e)}
                                                        />
                                                    </div>
                                                    <div className='pt-1'>
                                                        <Card className='mt-2 p-2.5 pl-0 cursor-pointer bg-light-red hover:bg-light-red-2 border-none'
                                                            onClick={() => handleCollapse(utilities, setUtilities, utilitiesArrow, setUtilitiesArrow)}
                                                        >
                                                            <div className='flex justify-between'>
                                                                <div className='flex justify-center pl-2'>
                                                                    <p><ArrowIcon status={utilitiesArrow} color={(utilitiesPaid) ? 'gray': ''} /></p>
                                                                    <p className={`${(utilitiesPaid) ? 'text-gray' : ''}`}>Utilities:</p>
                                                                </div>
                                                                <p className={`${(utilitiesPaid) ? 'text-gray' : ''} mr-4`}>
                                                                    ${totalUtilities.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                                </p>
                                                            </div>
                                                        </Card>
                                                        <Collapse in={utilities}>
                                                            <div className='bg-light-red-2 mt-2 pb-1 px-3 rounded-lg'>
                                                                <InputBox label="Electricity" value={electricity} change={setElectricity} labelKey={'electricity'} />
                                                                <InputBox label="Gas" value={gas} change={setGas} labelKey={'gas'} />
                                                                <InputBox label="Sewage" value={sewage} change={setSewage} labelKey={'sewage'} />
                                                                <InputBox label="Garbage" value={garbage} change={setGarbage} labelKey={'garbage'} />
                                                                <InputBox label="Other" value={lawn} change={setLawn} labelKey={'lawn'} />
                                                                <div className='flex justify-center mt-2'>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox checked={utilitiesPaid} />
                                                                        }
                                                                        label="Paid by renters"
                                                                        onClick={() => setUtilitiesPaid(!utilitiesPaid)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                    <div className='lg:ml-3 ml-1 -pt-3'>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">Repairs:</Form.Label>
                                                                <img data-tip data-for="repairs" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={repairs}
                                                                decimalsLimit={2}
                                                                onValueChange={e => setRepairs((e === undefined) ? 0 : e)}
                                                                />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'CapEx:' : 'Capital Expenditures:'}</Form.Label>
                                                                <img data-tip data-for="capEx" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <CurrencyInput
                                                                className="lg:w-24 w-20 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={capex}
                                                                decimalsLimit={2}
                                                                onValueChange={e => setCapex((e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">{isMobile ? 'Prop Mgnt:' : 'Property Management:'}</Form.Label>
                                                                <img data-tip data-for="propMgnt" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 lg:w-16 w-14 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={propMgmtPct}
                                                                    decimalsLimit={0}
                                                                    onValueChange={(e) => handlePropMgmtPct((e === undefined) ? 0 : e)}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={propMgmt}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => handlePropMgmt((e === undefined) ? 0 : e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex justify-center gap-1'>
                                                                <Form.Label className="font-normal mt-4 text-left">Vacancy:</Form.Label>
                                                                <img data-tip data-for="vacancy" className="rounded-xl w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <CurrencyInput
                                                                    className="lg:ml-4 ml-3 mt-3 p-1 pl-2 lg:w-16 w-14 border-one rounded-l-md"
                                                                    suffix='%'
                                                                    value={vacancyPct}
                                                                    decimalsLimit={0}
                                                                    onValueChange={(e) => handleVacancyPct((e === undefined) ? 0 : e)}
                                                                />
                                                                <CurrencyInput
                                                                    className={(isMobile) ? "mt-3 p-1 w-16 pl-2 border-one rounded-r-md" : "mt-3 p-1 w-20 pl-2 border-one rounded-r-md"}
                                                                    prefix='$'
                                                                    value={vacancy}
                                                                    decimalsLimit={2}
                                                                    onValueChange={e => handleVacancy((e === undefined) ? 0 : e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <InputBox label="Other expenses" value={otherExpenses} change={setOtherExpenses} labelKey={'otherExpenses'} />
                                                    </div>
                                                    <p className='text-lg font-semibold mt-5 -mb-1'>Expenses when selling this property:</p>
                                                    <InputBox label="Buyer's agent" value={buyersAgent} change={setBuyersAgent} labelKey={'buyersAgent'} type="percentage" />
                                                    <InputBox label="Seller's agent" value={sellersAgent} change={setSellersAgent} labelKey={'sellersAgent'} type="percentage" />
                                                </div>
                                            </Collapse>

                                            {/* React Tooltips */}
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='taxEstimate' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light" className='shadow-lg p-3 bg-white opaque'>
                                                    <p className='text-base text-bot-blue text-center mb-2'>Property Taxes</p>
                                                    <div className='w-80'>
                                                        <p className='text-sm'>Property taxes are calculated by multiplying the property's tax rate (on average between 0.58% and 1.58%) by the value of the home.</p>
                                                        <p className='mt-3 text-sm'>Taxes are typically paid annually. Some finanicial institutions, however, prefer a monthly payment. Either way, we have included property taxes into the monthly payment.</p>
                                                        <p className='mt-3 text-gray text-sm'>Property value: (<span className='font-bold'>${purchasePrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>) x property tax rate: (<span className='font-bold'>0.61%</span>)</p>
                                                    </div>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='mortgageEstimate' effect='solid' arrowColor='transparent' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque ml-5'
                                                >
                                                    <p className='text-base text-center text-bot-blue mb-2'>Private Mortgage Insurance (PMI)</p>
                                                    <div className='w-80'>
                                                        <p className='text-sm'>
                                                            The average cost of private mortgage insurance, or PMI, for a conventional home loan ranges from 0.58% to 1.86%
                                                            of the original loan amount per year, according to Genworth Mortgage Insurance, Ginnie Mae and the Urban Institute.
                                                            We estimated a 1.00% of the original loan amount per year.
                                                        </p>
                                                        <p className='mt-2 text-sm'>We encourage you to find an exact amount by connecting with a lender.</p>
                                                    </div>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='homeownersInsurance' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Homeowners Insurance</p>
                                                    <p className='text-sm'>Homeowners insurance is a form of property insurance that covers losses and damages to an individual's residence, along with furnishings and other assets in the home. The annual US average is $1,383 ($138/month) for a policy with $250,000 in dwelling coverage.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='hoa' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Homeowners Association Fee (HOA)</p>
                                                    <p className='text-sm'>A monthly fee paid to the homeowner's association who makes and enforces rules for a subdivision, planned community, or condominium building.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='repairs' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Repairs</p>
                                                    <p className='text-sm'>The average monthly cost of repairs made to the property. According to Go Banking Rates, the average annual cost for maintaining a home is $1,204 ($120/month).</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='capEx' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Capital Expenditures (CapEx)</p>
                                                    <p className='text-sm'>Capital expenditures are the big purchases you have to make once every 10-20 years such as replacing the roof or water heater. BiggerPockets suggests putting aside $100/month is a fair estimate.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='propMgnt' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Property Management</p>
                                                    <p className='text-sm'>The property management fee is on average 10% of the monthly rent. Property managers take care of all your management needs such as sourcing and screening potential renters, maintenance repairs, and any problems with tenants.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className='flex justify-center'>
                                                <ReactTooltip id='vacancy' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                                    className='shadow-lg p-3 bg-white opaque w-80 ml-5'
                                                >
                                                    <p className='text-base text-bot-blue mb-2 text-center'>Vacancy</p>
                                                    <p className='text-sm'>Vacancy is the percentage of time in a year that no tenants are renting. The national average rental vacancy rate for 2021 was 6.1%.</p>
                                                </ReactTooltip>
                                            </div>

                                        </div>
                                    </div>
                                    <p id='appreciationAndSavings' className='text-xl font-bold tracking-tight text-dark-gray ml-1 mt-16'>5. Appreciation & Savings: ${totalAppreciationandTaxes.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                    <Form.Group className={`bg-light-gray mt-2 pt-1 pb-2 px-3`}>
                                                                
                                        {/* Market Appreciation */}
                                        <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer bg-light-gray hover:bg-light-gray-2 border-none`}
                                            onClick={() => handleCollapse(assetAppreciation, setAssetAppreciation, appreciationArrow, setAppreciationArrow)}
                                        >
                                            <div className='flex justify-between text-left'>
                                                <div className='flex flex-row justify-center text-start cursor-pointer'>
                                                    <ArrowIcon status={appreciationArrow} color={"black"} isMobile={isMobile} />
                                                    <Form.Label className="font-normal text-left cursor-pointer">{isMobile ? 'Appreciation:' : `Appreciation (1 yr):`}</Form.Label>
                                                    <img data-tip data-for="appreciation" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                </div>
                                                <p className="lg:mr-4 mr-2">
                                                    ${totalAppreciation.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                        </Card>
                                        <Collapse in={assetAppreciation}>
                                            <div className='bg-light-gray-2 mt-1 pb-3 lg:px-4 px-3 rounded-lg'>
                                                <InputBox label="Appreciation rate" value={marketAppr} change={setMarketAppr} type="percentage" labelKey={'marketAppr'} />
                                            </div>
                                        </Collapse>

                                        <p className='text-sm mt-3 ml-4 mb-2 text-gray cursor-pointer underline hover:underline' onClick={() => setAdvancedSavings(!advancedSavings)}>
                                            {advancedSavings ? 'Hide Advanced' : 'Show Advanced'}
                                        </p>
                                        <Collapse in={advancedSavings}>
                                            <div>

                                                {/* Interest Deduction */}
                                                <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer text-left bg-light-gray hover:bg-light-gray-2 border-none`}
                                                    onClick={() => handleCollapse(taxBenefits, setTaxBenefits, taxBenefitsArrow, setTaxBenefitsArrow)}
                                                >
                                                    <div className='flex justify-between'>
                                                        <div className='flex flex-row justify-center cursor-pointer'>
                                                            <ArrowIcon status={taxBenefitsArrow} color={"black"} />
                                                            <Form.Label className="font-normal text-left cursor-pointer">Interest savings:</Form.Label>
                                                            <img data-tip data-for="interestDeduction" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <p className="lg:mr-4 mr-2">
                                                            ${TotalAnnualTaxDeduction.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        </p>
                                                    </div>
                                                </Card>
                                                <Collapse in={taxBenefits}>
                                                    <div className='bg-light-gray-2 mt-1 pb-3 lg:px-3 rounded-lg'>
                                                        <div className="flex justify-between mx-2">
                                                            <Form.Label className="font-normal mt-4">Annual income:</Form.Label>
                                                            <CurrencyInput
                                                                className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={annualIncome}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => setAnnualIncome((e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <div className='flex justify-between text-start lg:ml-3 ml-2 pt-3'>
                                                            <p>Filing Status:</p>
                                                            <DropdownButton size="md" variant='secondary-outline' title={filingStatus} className="-mt-1.5 drop-shadow-2xl rounded-md z-50 border-none text-black hover:text-black">
                                                                <Dropdown.Item onSelect={() => setFilingStatus("Single")}>Single</Dropdown.Item>
                                                                <Dropdown.Item onSelect={() => setFilingStatus("Head of Household")}>Head of Household</Dropdown.Item>
                                                                <Dropdown.Item onSelect={() => setFilingStatus("Married Filing Jointly")}>Married Filing Jointly</Dropdown.Item>
                                                                <Dropdown.Item onSelect={() => setFilingStatus("Married Filing Separately")}>Married Filing Separately</Dropdown.Item>
                                                                <Dropdown.Item onSelect={() => setFilingStatus("Qualified Widow")}>Qualified Widow</Dropdown.Item>
                                                            </DropdownButton>
                                                        </div>
                                                        <div className='flex justify-between pt-3 lg:mx-3 mx-2'>
                                                            <div className='flex justify-center'>
                                                                <p>Tax Bracket: </p>
                                                                <a href="https://www.bankrate.com/taxes/tax-brackets/" target="__blank">
                                                                    <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm mb-0.5 ml-3 text-bot-blue cursor-pointer" />
                                                                </a>
                                                            </div>
                                                            <p>{allFunctions.calcTaxBracket(annualIncome, filingStatus)}%</p>
                                                        </div>
                                                        <hr className='mx-2 my-3 border-gray'/>
                                                        <div className='flex justify-between lg:mx-3 mx-2'>
                                                            <p className='text-left'>Deductable interest:</p>
                                                            <p>${annualInterest.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                        </div>
                                                    </div>
                                                </Collapse>

                                                {/* Depreciation Deduction */}
                                                <Card className={`my-2 px-2.5 pt-2.5 pb-0.5 pl-3 cursor-pointer text-left bg-light-gray hover:bg-light-gray-2 border-none`}
                                                    onClick={() => handleCollapse(depreciationDeducation, setDepreciationDeducation, depreciationDeductionArrow, setDepreciationDeductionArrow)}
                                                >
                                                    <div className='flex justify-between'>
                                                        <div className='flex flex-row justify-center cursor-pointer'>
                                                            <ArrowIcon status={depreciationDeductionArrow} color={"black"} />
                                                            <Form.Label className="font-normal text-left cursor-pointer">{isMobile ? 'Depr. savings:' : 'Depreciation savings:'}</Form.Label>
                                                            <img data-tip data-for="depreciationDeduction" className="w-4 mb-3 ml-1 rounded-xl cursor-pointer" src={info_popup} alt="info box"/>
                                                        </div>
                                                        <p className="lg:mr-4 mr-2">
                                                            ${depreciationDeducationSavings.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        </p>
                                                    </div>
                                                </Card>
                                                <Collapse in={depreciationDeducation}>
                                                    <div className={`bg-light-gray-2 mt-1 pb-3 mb-2 rounded-lg ${isMobile ? 'pl-3 pr-2' : 'pl-4 pr-3'}`}>
                                                        <div className="flex justify-between">
                                                            <Form.Label className="font-normal mt-4">Property value:</Form.Label>
                                                            <CurrencyInput
                                                                className="w-28 pl-2 mt-3 border-one rounded-sm"
                                                                prefix='$'
                                                                value={purchasePrice}
                                                                decimalsLimit={0}
                                                                onClick="this.select();"
                                                                onValueChange={(e) => handlePurchasePrice((e === undefined) ? 0 : e)}
                                                            />
                                                        </div>
                                                        <InputBox label="- Land value" value={landValue} change={setLandValue} labelKey={'landValue'} type='currency_long' />
                                                        {isMobile ? (
                                                            <InputBox label="&#247; Depr. period" value={deprPeriod} change={setDeprPeriod} suffix=" years" type="custom_suffix" />
                                                        ) : (
                                                            <InputBox label="&#247; Depreciation period" value={deprPeriod} change={setDeprPeriod} suffix=" years" type="custom_suffix" />
                                                        )}
                                                        <hr className='border-2 border-light-gray-3 my-3' />
                                                        <div className='flex justify-between mr-4'>
                                                            <p>Annual depreciation:</p>
                                                            <p>${TotalAnnualDeprDeduction.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                                        </div>
                                                    </div>
                                                </Collapse>

                                            </div>
                                        </Collapse>

                                    </Form.Group>

                                    {/* React Tooltips */}
                                    <div className='flex justify-center'>
                                        <ReactTooltip id='appreciation' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                            className='shadow-lg p-3 bg-white opaque w-80'
                                        >
                                            <p className='text-base text-bot-blue mb-2 text-center'>Market Appreciation (1 Yr)</p>
                                            <p className='text-sm'>
                                                The amount a property's value will have appreciated in a given time frame. Since 1991, real estate in the US has appreciated
                                                4.3% annually. Given your estimated appreciation of {marketAppr}%, this property will have appreciated
                                                ${totalAppreciation.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00 over the next year.
                                            </p>
                                        </ReactTooltip>
                                    </div>
                                    <div className='flex justify-center'>
                                        <ReactTooltip id='interestDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                            className='shadow-lg p-3 bg-white opaque w-80'
                                        >
                                            <p className='text-base text-bot-blue mb-2 text-center'>Interest Tax Deducation Savings</p>
                                            <p className='text-sm'>
                                                You don't have to pay any taxes on the money that went towards the interest on your loan. Thus, your interest tax deducation
                                                savings is calculated by multiplying what you won't be paying in taxes (your payments on interest) by your current tax bracket.
                                            </p>
                                        </ReactTooltip>
                                    </div>
                                    <div className='flex justify-center'>
                                        <ReactTooltip id='depreciationDeduction' effect='solid' place='top' delayHide={100} arrowColor='transparent' backgroundColor="white" borderColor="black" type="light"
                                            className='shadow-lg p-3 bg-white opaque w-80'
                                        >
                                            <p className='text-base text-bot-blue mb-2 text-center'>Depreciation Tax Deducation Savings</p>
                                            <p className='text-sm'>
                                                Real estate is a physical asset that depreciates over time. Every year you can write off that year's depreciation on a
                                                property. Thus, your tax savings from the depreciation deduction on this property (based on your current tax bracket)
                                                would be ${depreciationDeducationSavings.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00.
                                            </p>
                                            <p className='text-sm mt-2'>
                                                A property's annual depreciation is calculated by subtracting the land value from the property value (since land doesn't depreciate)
                                                and then divide that by 27.5 years (the number of years determined by convention for a residential property to fully depreciate).
                                            </p>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
