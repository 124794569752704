import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Card, Collapse, Dropdown, Form } from 'react-bootstrap'
import { supabase } from '../supabase'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SaveSearch(props) {
    const { session, setDropdownOpen, emailAlertID } = props;
    const [searchName, setSearchName] = useState('Property search')
    const [emailFrequency, setEmailFrequency] = useState('Instantly');
    const [howItWorks, setHowItWorks] = useState(false);

    const notifySuccessMsg = (errorMsg) => toast.success(<p className='font-bold'>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });
    
    const notifyErrorMsg = (errorMsg) => toast.error(<p>{errorMsg}</p>, {
        toastId: 'signupError',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
    });

    const updateSaveSearch = async () => {
        // make sure they are signed in
        if (!session) {
            notifyErrorMsg("You must be signed in to save a search!");
        } else {
            // save search in supabase
            try {
                const updates = {
                    id: emailAlertID,
                    created_at: new Date(),
                    user_id: session.user.id,
                    saved_search_name: searchName,
                    email_frequency: emailFrequency
                }
    
                let { error } = await supabase.from('emailAlerts').upsert(updates)
                if (error) {
                    throw error
                } else {
                    setDropdownOpen(false);
                    setSearchName('Property search');
                    setEmailFrequency('Instantly');
                    notifySuccessMsg("Successfully updated search.");
                }
            } catch (error) {
                console.error(error.message)
            }
        }
    }

    const handleSelectInput = (id) => {
        document.getElementById(id).select();
    }

    return (
        <div className='-mt-2 mb-7'>
            <p className='text-xl bg-green rounded-t py-2 text-white text-center font-semibold'>
                <FontAwesomeIcon icon={faCheckCircle} className='text-white h-5 pr-3' />
                Search saved!
            </p>
            <div className='flex flex-col justify-start gap-5 mx-4 mb-2'>
                <div className="mt-3">
                    <p>Search name:</p>

                    <Form.Control
                        id="search-name"
                        onClick={() => handleSelectInput("search-name")}
                        type="text"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                    />
                </div>
                <p>Email me:</p>
                <select
                    id="searchName"
                    name="searchName"
                    value={emailFrequency}
                    onChange={(e) => setEmailFrequency(e.target.value)}
                    style={{borderWidth: '1px'}}
                    className='w-64 py-2 pl-2 rounded-md border-light-gray-4 -mt-4 cursor-pointer font-normal'
                >
                    <option value="Instantly" className='py-3 cursor-pointer hover:bg-gray'>Instantly</option>
                    <option value="Daily" className='py-3 cursor-pointer hover:bg-gray'>Daily</option>
                    <option value="Never" className='py-3 cursor-pointer hover:bg-gray'>Never</option>
                </select>
                <Dropdown.Item
                    onClick={() => updateSaveSearch()}
                    className="text-xl font-bold rounded-md text-white text-center py-1.5 bg-bot-blue hover:bg-dark-bot-blue"
                >
                    Update
                </Dropdown.Item>
                <p onClick={() => setHowItWorks(!howItWorks)} className='underline font-normal ml-3 cursor-pointer hover:text-bot-blue'>How does it work?</p>
                <Collapse in={howItWorks}>
                    <Card className='px-3 py-2 cursor-pointer rounded-xl bg-light-gray border-none -mt-4'>
                        <p className='font-normal text-sm'>
                            Anytime there is a change to the top five properties of your current search,
                            Investorbot will send you an email with the changes. For example, if a new
                            property is added to the market and it falls in the top five properties
                            of your current search parameters, Investorbot will email you that property.
                        </p>
                    </Card>
                </Collapse>
            </div>
        </div>
    )
}
