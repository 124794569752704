import { useEffect, useState } from 'react';

// Returns class
class Returns {
    constructor(cashFlow, capRate, cocReturn, totalReturn) {
      this.cashFlow = cashFlow;
      this.capRate = capRate;
      this.cocReturn = cocReturn;
      this.totalReturn = totalReturn;
    }
}

const CalcReturns = (property, defaults, propertyEdit) => {

    // APPRECIATION RATES & PROJECTION
    let marketAppr = ((propertyEdit && propertyEdit.hasOwnProperty('marketAppr')) ? parseFloat(propertyEdit.marketAppr) : +parseFloat(defaults.cityAppr).toFixed(1));
    let rentAppr = ((propertyEdit && propertyEdit.hasOwnProperty('rentAppr')) ? parseFloat(propertyEdit.rentAppr) : +parseFloat(defaults.rentAppr).toFixed(1));
    let expensesAppr = ((propertyEdit && propertyEdit.hasOwnProperty('expensesAppr')) ? parseFloat(propertyEdit.expensesAppr) : +parseFloat(defaults.expensesAppr).toFixed(1));
    let yearsProjected = ((propertyEdit && propertyEdit.hasOwnProperty('yearsProjected')) ? parseFloat(propertyEdit.yearsProjected) : +parseFloat(defaults.yearsProjected).toFixed(1));
    
    // mortgage
    let purchasePrice = ((propertyEdit && propertyEdit.hasOwnProperty('price')) ? propertyEdit.price : parseInt(property.price));
    let interestRate = (propertyEdit && propertyEdit.hasOwnProperty('interestRate')) ? +parseFloat(propertyEdit.interestRate).toFixed(1) : +parseFloat(defaults.interestRate).toFixed(1);
    let loanTerm = ((propertyEdit && propertyEdit.hasOwnProperty('loanTerm')) ? propertyEdit.loanTerm : parseInt(defaults.loanTerm));
    // init investment
    let closignCosts = ((propertyEdit && propertyEdit.hasOwnProperty('closignCosts')) ? propertyEdit.closignCosts : parseInt(defaults.closingCosts));
    let rehabCosts = ((propertyEdit && propertyEdit.hasOwnProperty('rehabCosts')) ? propertyEdit.rehabCosts : parseInt(defaults.rehab));
    let miscCosts = ((propertyEdit && propertyEdit.hasOwnProperty('miscCosts')) ? propertyEdit.miscCosts : parseInt(defaults.miscCosts));
    // income
    let laundry = ((propertyEdit && propertyEdit.hasOwnProperty('laundry')) ? propertyEdit.laundry : parseInt(defaults.laundry));
    let storage = ((propertyEdit && propertyEdit.hasOwnProperty('storage')) ? propertyEdit.storage : parseInt(defaults.storage));
    let miscIncome = ((propertyEdit && propertyEdit.hasOwnProperty('miscIncome')) ? propertyEdit.miscIncome : parseInt(defaults.miscIncome));
    // expenses
    let insurance = Math.round((propertyEdit && propertyEdit.hasOwnProperty('insurance')) ? propertyEdit.insurance : parseInt(defaults.insurance));
    let propMgmtPct = ((propertyEdit && propertyEdit.hasOwnProperty('propMgmtPct')) ? (parseFloat(propertyEdit.propMgmtPct)) : +parseFloat(defaults.propMgnt).toFixed(1));
    let vacancyPct = ((propertyEdit && propertyEdit.hasOwnProperty('vacancyPct')) ? (propertyEdit.vacancyPct) : +parseFloat(defaults.vacancy).toFixed(1));
    let electricity = Math.round((propertyEdit && propertyEdit.hasOwnProperty('electricity')) ? propertyEdit.electricity : parseInt(defaults.electricity));
    let gas = Math.round((propertyEdit && propertyEdit.hasOwnProperty('gas')) ? propertyEdit.gas : parseInt(defaults.gas));
    let sewage = Math.round((propertyEdit && propertyEdit.hasOwnProperty('sewage')) ? propertyEdit.sewage : parseInt(defaults.sewage));
    let garbage = Math.round((propertyEdit && propertyEdit.hasOwnProperty('garbage')) ? propertyEdit.garbage : parseInt(defaults.garbage));
    let lawn = Math.round((propertyEdit && propertyEdit.hasOwnProperty('lawn')) ? propertyEdit.lawn : parseInt(defaults.lawn));
    let taxes = Math.round((propertyEdit && propertyEdit.hasOwnProperty('taxes')) ? (propertyEdit.taxes) : parseFloat(purchasePrice * (property.propertyTaxRate / 100) / 12));
    let hoa = Math.round((propertyEdit && propertyEdit.hasOwnProperty('monthlyHoaFee')) ? parseInt(propertyEdit.monthlyHoaFee) : (property.monthlyHoaFee === null) ? 0 : property.monthlyHoaFee);
    let miscExpenses = Math.round((propertyEdit && propertyEdit.hasOwnProperty('miscExpenses')) ? propertyEdit.miscExpenses : parseInt(defaults.miscExpenses));
    let utilitiesPaid = (propertyEdit && propertyEdit.hasOwnProperty('utilitiesPaid')) ? propertyEdit.utilitiesPaid : defaults.utilitiesPaid;
    // appreciation/taxes
    let annualIncome = ((propertyEdit && propertyEdit.hasOwnProperty('annualIncome')) ? propertyEdit.annualIncome : parseInt(defaults.personalAnnualIncome));
    let filingStatus = ((propertyEdit && propertyEdit.hasOwnProperty('filingStatus')) ? propertyEdit.filingStatus : defaults.filingStatus);
    let landValue = ((propertyEdit && propertyEdit.hasOwnProperty('landValue')) ? propertyEdit.landValue : (purchasePrice * 0.20));
    let deprPeriod = ((propertyEdit && propertyEdit.hasOwnProperty('deprPeriod')) ? propertyEdit.deprPeriod : +parseFloat(defaults.deprPeriod).toFixed(1));

    // initial investment
    let downpaymentPct = (
        (propertyEdit && propertyEdit.hasOwnProperty('downpaymentPct'))
            ? +parseFloat(propertyEdit.downpaymentPct).toFixed(1)
            : (defaults.downpaymentType === '%')
                ? +parseFloat(defaults.downpaymentPct).toFixed(1)
                : +parseFloat(parseInt(defaults.downpayment) / purchasePrice * 100).toFixed(1)
    );
    let downpayment = (
        (propertyEdit && propertyEdit.hasOwnProperty('downpayment'))
            ? Math.round(parseInt(propertyEdit.downpayment))
            : (defaults.downpaymentType === '%')
                ? Math.round(parseInt(purchasePrice * (downpaymentPct / 100)))
                : Math.round(parseInt(defaults.downpayment))
    );
    if (downpayment > purchasePrice) {
        downpayment = purchasePrice;
        downpaymentPct = 100
    }
    const totalOtherCosts = closignCosts + rehabCosts + miscCosts
    const totalInitialInvestment = downpayment + totalOtherCosts;

    // income
    const units = (
        (propertyEdit && propertyEdit.hasOwnProperty('rentEstimate'))
        ? propertyEdit.rentEstimate
        : [
            {
                unitNum: 'Unit 1',
                unitRent: parseInt((property.rentEstimate === null) ? 0 : property.rentEstimate)
            },
        ]
    );
    // adjust rent for appreciation & add up totals
    let totalMonthlyRent = 0;
    if (units && units.length > 0) {
        units.forEach((unit) => {
            totalMonthlyRent += unit.unitRent;
        })
    }

    // mortgage
    const totalMonthlyMortgage = allFunctions.calcMortgage(purchasePrice, downpayment, interestRate, loanTerm);
    let mortgageInsurancePct = parseFloat(defaults.mortgageInsurancePct);
    let mortgageInsurance = null;
    if (propertyEdit && propertyEdit.hasOwnProperty('mortgageInsurance')) {
        mortgageInsurance = (downpaymentPct < 20) ? propertyEdit.mortgageInsurance : 0;
    } else {
        mortgageInsurance = (downpaymentPct < 20) ? (Math.round(parseInt(totalMonthlyMortgage * mortgageInsurancePct) * 12)) : 0
    }
    
    // expenses
    const repairsPct = (
        (propertyEdit && propertyEdit.hasOwnProperty('repairsPct'))
            ? +parseFloat(propertyEdit.repairsPct).toFixed(1)
            : (defaults.repairsType === '%')
                ? +parseFloat(defaults.repairsPct).toFixed(1)
                : +parseFloat((parseFloat(defaults.repairs / totalMonthlyRent) * 100).toFixed(1))
    );
    const capexPct = (
        (propertyEdit && propertyEdit.hasOwnProperty('capexPct'))
            ? +parseFloat(propertyEdit.capexPct).toFixed(1)
            : (defaults.capexType === '%')
                ? +parseFloat(defaults.capexPct).toFixed(1)
                : +parseFloat((parseFloat(defaults.capex / totalMonthlyRent) * 100).toFixed(1))
    );

    // principal & tax bracket
    let principal = (purchasePrice - parseInt(downpayment))
    const taxBracket = parseFloat(allFunctions.calcTaxBracket(parseInt(annualIncome), filingStatus));
    
    // appreciations
    let newAppr = (parseInt(yearsProjected) === 0) ? 1 : Math.pow((1 + (parseFloat(marketAppr) / 100)), parseInt(yearsProjected));
    let newRentAppr = (parseInt(yearsProjected) === 0) ? 1 : Math.pow((1 + (parseFloat(rentAppr) / 100)), parseInt(yearsProjected));
    let newExpenseAppr = (parseInt(yearsProjected) === 0) ? 1 : Math.pow((1 + (parseFloat(expensesAppr) / 100)), parseInt(yearsProjected));

    // price
    let newPriceProjection = Math.round(parseInt(purchasePrice) * newAppr);

    // income
    let newTotalMonthlyIncomeProjection = 0;
    let unitsProjection = [];
    units.forEach((unit, index) => {
        unitsProjection.push({
            unitNum: index === 0 ? 'Rent' : `Unit ${index + 1} Rent`,
            unitRent: Math.round(unit.unitRent * newRentAppr)
        })
        newTotalMonthlyIncomeProjection += Math.round(unit.unitRent * newRentAppr)
    })
    let laundryProjection = Math.round(laundry * newRentAppr);
    let storageProjection = Math.round(storage * newRentAppr);
    let miscIncomeProjection = Math.round(miscIncome * newRentAppr);
    let totalOtherIncomeProjection = laundryProjection + storageProjection + miscIncomeProjection;
    newTotalMonthlyIncomeProjection += totalOtherIncomeProjection;
    
    // expenses
    let taxesProjection = Math.round(taxes * newExpenseAppr);
    let insuranceProjection = Math.round(insurance * newExpenseAppr);
    let hoaProjection = Math.round(hoa * newExpenseAppr);
    let lawnProjection = Math.round(lawn * newExpenseAppr);
    let electricityProjection = Math.round(electricity * newExpenseAppr);
    let gasProjection = Math.round(gas * newExpenseAppr);
    let sewageProjection = Math.round(sewage * newExpenseAppr);
    let garbageProjection = Math.round(garbage * newExpenseAppr);
    let totalUtilitiesProjection = lawnProjection + electricityProjection + gasProjection + sewageProjection + garbageProjection
    let repairsProjection = Math.round((parseFloat(repairsPct) / 100) * newTotalMonthlyIncomeProjection);
    let capexProjection = Math.round((parseFloat(capexPct) / 100) * newTotalMonthlyIncomeProjection);
    let propMgmtProjection = Math.round((parseFloat(propMgmtPct) / 100) * newTotalMonthlyIncomeProjection);
    let vacancyProjection = Math.round((parseFloat(vacancyPct) / 100) * newTotalMonthlyIncomeProjection);
    let miscExpensesProjection = Math.round(miscExpenses * newExpenseAppr);
    let newOperatingExpensesProjection = taxesProjection + insuranceProjection + hoaProjection + repairsProjection + capexProjection
        + propMgmtProjection + vacancyProjection + miscExpensesProjection + (utilitiesPaid ? 0 : totalUtilitiesProjection);
    let newNetOperatingIncomeProjection = newTotalMonthlyIncomeProjection - newOperatingExpensesProjection;
    let newAnnualNetOperatingIncomeProjection = newNetOperatingIncomeProjection * 12;
    let newTotalMonthlyExpensesProjection = newOperatingExpensesProjection + totalMonthlyMortgage + parseInt(mortgageInsurance);
    
    // cash flow, cap rate, cash on cash
    let newMonthlyCashFlowProjection = newTotalMonthlyIncomeProjection - newTotalMonthlyExpensesProjection;
    let newAnnualCashFlowProjection = newTotalMonthlyIncomeProjection - newTotalMonthlyExpensesProjection;
    let capRateDenominator = (parseInt(yearsProjected) === 0) ? (parseInt(purchasePrice) + totalOtherCosts) : (newPriceProjection);
    let newCapRateProjection = +parseFloat((newAnnualNetOperatingIncomeProjection / capRateDenominator) * 100).toFixed(1);
    let newCashOnCashProjection = +parseFloat((newAnnualCashFlowProjection / totalInitialInvestment) * 100).toFixed(1);

    // total return
    let numMonths = 12 * parseInt(yearsProjected);
    // equity
    let totalEquityProjection = allFunctions.calcEquity(parseInt(purchasePrice), principal, parseFloat(interestRate), (parseInt(loanTerm) * 12), numMonths)
    let addedEquityProjection = totalEquityProjection - downpayment;
    // appreciation
    let newAppreciationProjection = newPriceProjection - parseInt(purchasePrice);
    // interest
    let totalInterestProjection = (parseInt(yearsProjected) === 0) ? 0 : allFunctions.calcInterest(parseInt(principal), parseInt(interestRate), parseInt(loanTerm * 12), numMonths);
    let totalInterestTaxSavingsProjection = Math.round(parseInt(totalInterestProjection) * (parseFloat(taxBracket) / 100));
    // depreciation
    let totalDepreciationProjection = Math.round((parseInt(purchasePrice) - parseInt(landValue)) / parseFloat(deprPeriod)) * parseInt(yearsProjected);
    let depreciationDeducationSavingsProjection = Math.round(parseInt(totalDepreciationProjection) * (parseFloat(taxBracket) / 100));
    
    // cumulative cash flow
    let cummulativeCashFlow = 0;
    for (let year = 1; year < parseInt(yearsProjected); year++) {
        // appreciations
        let newRentAppr = Math.pow((1 + (parseFloat(defaults.rentAppr) / 100)), year);
        let newExpenseAppr = Math.pow((1 + (parseFloat(defaults.expensesAppr) / 100)), year);

        // income
        let totalMonthlyIncomeTemp = 0;
        let unitsProjection = [];
        units.forEach((unit, index) => {
            unitsProjection.push({
                unitNum: index === 0 ? 'Rent' : `Unit ${index + 1} Rent`,
                unitRent: Math.round(unit.unitRent * newRentAppr)
            })
            totalMonthlyIncomeTemp += Math.round(unit.unitRent * newRentAppr)
        })
        let laundryTemp = Math.round(laundry * newRentAppr);
        let storageTemp = Math.round(storage * newRentAppr);
        let miscIncomeTemp = Math.round(miscIncome * newRentAppr);
        totalMonthlyIncomeTemp += (laundryTemp + storageTemp + miscIncomeTemp);
        
        // expenses
        let taxesTemp = Math.round(taxes * newExpenseAppr);
        let insuranceTemp = Math.round(insurance * newExpenseAppr);
        let hoaTemp = Math.round(hoa * newExpenseAppr);
        let lawnTemp = Math.round(lawn * newExpenseAppr);
        let electricityTemp = Math.round(electricity * newExpenseAppr);
        let gasTemp = Math.round(gas * newExpenseAppr);
        let sewageTemp = Math.round(sewage * newExpenseAppr);
        let garbageTemp = Math.round(garbage * newExpenseAppr);
        let totalUtilitiesTemp = lawnTemp + electricityTemp + gasTemp + sewageTemp + garbageTemp
        let repairsTemp = Math.round((parseInt(repairsPct) / 100) * totalMonthlyIncomeTemp);
        let capexTemp = Math.round((parseInt(capexPct) / 100) * totalMonthlyIncomeTemp);
        let propMgmtTemp = Math.round((parseInt(propMgmtPct) / 100) * totalMonthlyIncomeTemp);
        let vacancyTemp = Math.round((parseInt(vacancyPct) / 100) * totalMonthlyIncomeTemp);
        let miscExpensesTemp = Math.round(miscExpenses * newExpenseAppr);
        let operatingExpensesTemp = taxesTemp + insuranceTemp + hoaTemp + repairsTemp + repairsTemp
            + capexTemp + propMgmtTemp + vacancyTemp + miscExpensesTemp + (utilitiesPaid ? 0 : totalUtilitiesTemp)
        let totalMonthlyExpensesTemp = operatingExpensesTemp + totalMonthlyMortgage + parseInt(mortgageInsurance)
        
        // cash flow, cap rate, cash on cash
        let yearCashFlow = (totalMonthlyIncomeTemp - totalMonthlyExpensesTemp) * 12;

        // cumulative cash flow
        cummulativeCashFlow += yearCashFlow;
    }
    // total
    let totalAppreciationandTaxesProjection = Math.round(newAppreciationProjection + totalInterestTaxSavingsProjection + depreciationDeducationSavingsProjection);
    let estSellingCostsProjection = newPriceProjection * ((parseFloat(defaults.buyersAgent) + parseFloat(defaults.sellersAgent)) / 100);
    let totalReturnCashProjection = +parseFloat((cummulativeCashFlow + addedEquityProjection + totalAppreciationandTaxesProjection - estSellingCostsProjection).toFixed(1));
    let newTotalReturnProjection = (parseInt(yearsProjected) === 0)
        ? 'N/A'
        : +parseFloat((parseFloat(totalReturnCashProjection / totalInitialInvestment) * 100).toFixed(1));

    // load into class
    const returns = new Returns(newMonthlyCashFlowProjection, newCapRateProjection, newCashOnCashProjection, newTotalReturnProjection);
    
    // if (property.address === "509 E 900 N #183, American Fork, UT 84003") {
    //     console.log('stop');
    // }

    return returns;
}


function calcTaxBracket(annualIncome, filingStatus) {
    // Source: https://www.bankrate.com/taxes/tax-brackets/

    // Tax Brackets
    const single = [
        [0, 10275, 0.1],
        [10276, 41775, 0.12],
        [41776, 89075, 0.22],
        [89076, 170050, 0.24],
        [170051, 215950, 0.32],
        [215951, 539900, 0.35],
        [539901, 1000000000, 0.37]
    ];
    
    const headOfHousehold = [
        [0, 14650, 0.1],
        [14651, 55900, 0.12],
        [55901, 89050, 0.22],
        [89051, 170050, 0.24],
        [170051, 215950, 0.32],
        [215951, 539900, 0.35],
        [539901, 1000000000, 0.37]
    ];

    const marriedFilingJointly = [
        [0, 20550, 0.1],
        [20551, 83550, 0.12],
        [83551, 178150, 0.22],
        [178151, 340100, 0.24],
        [340101, 431900, 0.32],
        [431901, 647850, 0.35],
        [647851, 1000000000, 0.37]
    ]
    
    const qualifiedWidow = [
        [0, 20550, 0.1],
        [20551, 83550, 0.12],
        [83551, 178150, 0.22],
        [178151, 340100, 0.24],
        [340101, 431900, 0.32],
        [431901, 647850, 0.35],
        [647851, 1000000000, 0.37]
    ]

    const marriedFilingSeparately = [
        [0, 10275, 0.1],
        [10276, 41775, 0.12],
        [41776, 89075, 0.22],
        [89076, 170050, 0.24],
        [170051, 215950, 0.32],
        [215951, 323925, 0.35],
        [323926, 1000000000, 0.37]
    ]
    
    let arr = [];
    if (filingStatus === "Single") { arr = single; }
    else if (filingStatus === "Head of Household") { arr = headOfHousehold; }
    else if (filingStatus === "Married Filing Jointly") { arr = marriedFilingJointly; }
    else if (filingStatus === "Qualified Widow") { arr = qualifiedWidow; }
    else if (filingStatus === "Married Filing Separately") { arr = marriedFilingSeparately; }

    let taxBracket = 0
    for (var i = 0; i < arr.length; i++) {
        if (annualIncome < arr[i][1]) {
            taxBracket = arr[i][2];
            break
        };
    };

    return (taxBracket * 100);
}

function calcMortgage(purchase_price, downpayment, interest_rate, loan_term) {
    
    const int_rate = parseFloat(parseFloat(interest_rate) / 100 / 12);
    const principal = purchase_price - downpayment;
    const loan_months = loan_term * 12;
    const totalMonthlyMortgage = parseInt(principal * int_rate * (Math.pow(1 + int_rate, loan_months)) / (Math.pow(1 + int_rate, loan_months) - 1));

    return totalMonthlyMortgage
};

function calcEquity(purchasePrice, principal, interest, months, specifiedMonth) {
    var rnd = function(n) {
        return Math.round(n * 100) / 100; // round to 2 digits
    };
    var monthly_interest = interest / 100 / 12;
    var payment = rnd(principal * (monthly_interest + monthly_interest / (Math.pow(1 + monthly_interest, months) - 1)));
    var month;
    var balance = principal;
    var totalinterest = 0;
    
    for (month = 1; month < months; month++) {
        var tointerest = rnd(balance * monthly_interest);
        totalinterest = rnd(totalinterest + tointerest);
        var toprincipal = rnd(payment - tointerest);
        balance = rnd(balance - toprincipal);
        if (month === specifiedMonth) {
            let equity = purchasePrice - balance
            return Math.round(equity)
        }
    }
    return false; // suppress default function
}

function calcInterest(principal, interest, months, specifiedMonth) {
    var rnd = function(n) {
        return Math.round(n * 100) / 100; // round to 2 digits
    };
    var monthly_interest = interest / 100 / 12;
    var month;
    var balance = principal;
    var totalinterest = 0;
    
    for (month = 1; month < months; month++) {
        var tointerest = rnd(balance * monthly_interest);
        totalinterest = rnd(totalinterest + tointerest);
        if (month === specifiedMonth) {
            return totalinterest
        }
    }
    return false;
}

function totalInitialInvestment (totalOtherCosts, downpayment) {
    return parseInt(totalOtherCosts) + parseInt(downpayment);
};

function MasterQuicksort(items, metric) {
    
    function swap(items, leftIndex, rightIndex){
        var temp = items[leftIndex];
        items[leftIndex] = items[rightIndex];
        items[rightIndex] = temp;
    }
    
    function partition(items, left, right) {
        var pivot   = items[Math.floor((right + left) / 2)], //middle element
            i       = left, //left pointer
            j       = right; //right pointer
        while (i <= j) {
            
            // while i
            if (metric === "Cash Flow") {
                while (items[i].cashFlow > pivot.cashFlow) {
                    i++;
                }
            } else if (metric === "Cap Rate") {
                while (items[i].capRate > pivot.capRate) {
                    i++;
                }
            } else if (metric === "Total Return") {
                while (items[i].totalReturn > pivot.totalReturn) {
                    i++;
                }
            } else if (metric === "Price (high to low)") {
                while (items[i].price > pivot.price) {
                    i++;
                }
            } else if (metric === "Price (low to high)") {
                while (items[i].price < pivot.price) {
                    i++;
                }
            } else if (metric === "Price/Sq Ft") {
                while (items[i].pricePerSqFt < pivot.pricePerSqFt) {
                    i++;
                }
            }
            
            // while j
            if (metric === "Cash Flow") {
                while (items[j].cashFlow < pivot.cashFlow) {
                    j--;
                }
            } else if (metric === "Cap Rate") {
                while (items[j].capRate < pivot.capRate) {
                    j--;
                }
            } else if (metric === "Total Return") {
                while (items[j].totalReturn < pivot.totalReturn) {
                    j--;
                }
            } else if (metric === "Price (high to low)") {
                while (items[j].price < pivot.price) {
                    j--;
                }
            } else if (metric === "Price (low to high)") {
                while (items[j].price > pivot.price) {
                    j--;
                }
            } else if (metric === "Price/Sq Ft") {
                while (items[j].pricePerSqFt > pivot.pricePerSqFt) {
                    j--;
                }
            }

            if (i <= j) {
                swap(items, i, j); //sawpping two elements
                i++;
                j--;
            }
        }
        return i;
    }
    
    function quickSort(items, left, right) {
        var index;
        if (items.length > 1) {
            index = partition(items, left, right); //index returned from partition
            if (left < index - 1) { //more elements on the left side of the pivot
                quickSort(items, left, index - 1);
            }
            if (index < right) { //more elements on the right side of the pivot
                quickSort(items, index, right);
            }
        }
        return items;
    }
    
    // first call to quick sort
    let sortedArray = quickSort(items, 0, items.length - 1);
    return sortedArray;
}

function SortProperties(properties, metric) {
    let i = 0;
    let j = 0;
    let temp = null;
    let first = null;
    let second = null;

    for (i = 0; i < properties.length; i++) {
        for (j = 0; j < properties.length; j++) {
            if (i !== j) {
                if (metric === "Cash Flow") {
                    first = properties[i].cashFlow;
                    second = properties[j].cashFlow;
                } else if (metric === "Cap Rate") {
                    first = properties[i].capRate;
                    second = properties[j].capRate;
                } else if (metric === "Total Return") {
                    first = properties[i].totalReturn;
                    second = properties[j].totalReturn;
                }

                // push all NaN properties to the end
                if (isNaN(first)) {
                    properties.push(properties.splice(i, 1)[0]);
                } else if (isNaN(second)) {
                    properties.push(properties.splice(j, 1)[0]);
                }

                // ordering high to low
                if (first > second) {
                    temp = properties[i];
                    properties[i] = properties[j];
                    properties[j] = temp;
                }
            }
        }
        j = 0;
    }
    
    return properties;
}

function AddReturnsToProperties(properties, defaults, propertyEdits) {
    let propertiesWithReturns = [];
    if (propertyEdits === undefined) {
        propertyEdits = [];
    }
    (properties && properties.forEach(property => {
        // set propertyEdit
        let propertyEdit = null;
        if (propertyEdits && propertyEdits.hasOwnProperty('properties')) {
            if (propertyEdits.properties.hasOwnProperty(`${property.zpid}`)) {
                propertyEdit = propertyEdits.properties[`${property.zpid}`];
            }
        }
        // calculate the returns
        let cashFlow = CalcReturns(property, defaults, propertyEdit).cashFlow;
        let capRate = CalcReturns(property, defaults, propertyEdit).capRate;
        let totalReturn = CalcReturns(property, defaults, propertyEdit).totalReturn;

        if (!isNaN(cashFlow) || !isNaN(capRate) || !isNaN(totalReturn)) {
            // push the new data
            property['cashFlow'] = cashFlow;
            property['capRate'] = capRate;
            property['totalReturn'] = totalReturn;

            // push into new array
            propertiesWithReturns.push(property);
        }
        
    }));
    return propertiesWithReturns;
}

function Mobile() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;
    return isMobile
}


const filterHomeType = (filteredProperties, homeType) => {
    // set home type
    let filterLabel = ""
    if (homeType === "Condo") {
        filterLabel = "CONDO";
    } else if (homeType === "Townhouse") {
        filterLabel = "TOWNHOUSE";
    } else if (homeType === "Multi-family") {
        filterLabel = "MULTI_FAMILY";
    } else if (homeType === "Multi-family") {
        filterLabel = "MULTI_FAMILY";
    } else if (homeType === "Lot") {
        filterLabel = "LOT";
    } else if (homeType === "Manufactured") {
        filterLabel = "MANUFACTURED";
    } else {
        filterLabel = "SINGLE_FAMILY";
    }
    
    // filter home type
    let newPropertyList = [];
    filteredProperties.forEach((property) => {
        if (property.propertyType === filterLabel) {
            newPropertyList.push(property);
        }
    });
    
    return newPropertyList;
}

const calcComps = (properties, index) => {
    // calc comps
    let compProperties = [];
    if (properties.length < 5) {
        properties.forEach(property => {
            compProperties.push(property)
        })
    } else {
        if (index === 0) {
            compProperties = [properties[index], properties[index + 1], properties[index + 2], properties[index + 3], properties[index + 4]]
        } else if (index === 1) {
            compProperties = [properties[index], properties[index - 1], properties[index + 1], properties[index + 2], properties[index + 3]]
        } else if (index === properties.length) {
            compProperties = [properties[index], properties[index - 5], properties[index - 4], properties[index - 3], properties[index - 2]]
        } else if (index === properties.length - 1) {
            compProperties = [properties[index], properties[index - 4], properties[index - 3], properties[index - 2], properties[index - 1]]
        } else if (index === properties.length - 2) {
            compProperties = [properties[index], properties[index - 3], properties[index - 2], properties[index - 1], properties[index + 1]]
        } else if (index === properties.length - 3) {
            compProperties = [properties[index], properties[index - 2], properties[index - 1], properties[index + 1], properties[index + 2]]
        } else if (index === properties.length - 4) {
            compProperties = [properties[index], properties[index - 1], properties[index + 1], properties[index + 2], properties[index + 3]]
        } else {
            compProperties = [properties[index], properties[index - 2], properties[index - 1], properties[index + 1], properties[index + 2]]
        }
    }
    

    return compProperties;
}

const AddPricePerSqFt = (propertiesInBounds) => {
    let newList = [];
    let pricePerSqFt = 0;
    propertiesInBounds.forEach((property) => {
        pricePerSqFt = Math.round(parseInt(property.price) / parseInt(property.livingArea));
        property['pricePerSqFt'] = (pricePerSqFt);
        newList.push(property);
    })
    return propertiesInBounds;
}

const allFunctions = {
    CalcReturns,
    calcMortgage,
    totalInitialInvestment,
    calcEquity,
    calcTaxBracket,
    calcInterest,
    MasterQuicksort,
    SortProperties,
    AddReturnsToProperties,
    Mobile,
    filterHomeType,
    calcComps,
    AddPricePerSqFt
}

export default allFunctions;