import React from 'react'
import RentalComp from '../components/RentalComp';

export default function RentalComps(props) {
    const { compProperties, defaults, propertyEdits } = props;

    return (
        <div>
            <p className='text-center font-bold text-3xl tracking-tight'>Rental Comps Analysis</p>
            <p className='text-center text-sm tracking-tight text-gray italic mt-3 px-4'>* The algorithm for selecting rental comps is still under development.<br/>For now, these are the same as the property comps.</p>
            <div className='flex justify-start mt-8'>
                <div className='font-bold comps-column-width' style={{marginTop: '184px'}}>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-bot-blue text-white' style={{width: '150px'}}>Rent Estimate</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Distance</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-gray-2' style={{width: '150px'}}>Listing price</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Beds</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-gray-2' style={{width: '150px'}}>Baths</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Square feet</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-gray-2' style={{width: '150px'}}>Price/sq ft.</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Type</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-gray-2' style={{width: '150px'}}>Year built</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Status</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md bg-light-gray-2' style={{width: '150px'}}>Listing date</p>
                    <p className='py-2.5 pl-3 pr-2 rounded-l-md' style={{width: '150px'}}>Days listed</p>
                </div>
                <div className='flex overflow-auto'>
                    {compProperties.map(function (compProperty) {
                        return (
                            <RentalComp
                                property={compProperty}
                                last={(compProperty.zpid === compProperties[compProperties.length - 1].zpid) ? true : false}
                                first={(compProperty.zpid === compProperties[0].zpid) ? true : false}
                                defaults={defaults}
                                propertyEdits={propertyEdits}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
