import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    // Property
    property: null,
    purchasePrice: null,
    allProperties: null,
    // Home filters & sorts
    showBy: 'Cash Flow',
    sortBy: 'Cash Flow',
    // Modals
    openLoginModal: false,
    openSignupModal: false,
    openDefaultModal: false,
    openDefaultReminderModal: false,
    // Buttons
    defaultButtonSelected: "Mortgage",
    // Tour
    runTour: false
});

export { useGlobalState, setGlobalState };