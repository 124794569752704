import React from 'react';
import Form from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import ReactTooltip from 'react-tooltip';
import info_popup from '../assets/info_popup.jpg';

function InputBox(props) {
    let { id, handleSelectInput, label, value, suffix, percentage, change,
        changePct, dataId, title, description, description2, type, labelKey,
        updatePropertyEdits, handleUpdateProperty
    } = props;
    
    if (type === "large") {
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4 lg:ml-3 ml-2">{label}:</Form.Label>
                <CurrencyInput
                    className="lg:w-28 w-24 pl-2 mt-3 mr-2 border-one rounded-sm"
                    prefix='$'
                    defaultValue={value}
                    id={id}
                    onClick={() => handleSelectInput(id)}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => updatePropertyEdits(labelKey, parseInt(value))}
                />
            </div>
        )
    } else if (type === "vacancy") {
        return (
            <div className='flex justify-between'>
                <Form.Label className="font-normal mt-4 mx-3">{label}:</Form.Label>
                <div className="flex">
                    <CurrencyInput
                        className="ml-4 mt-3 p-1 pl-2 w-16 border-one rounded-l-md"
                        suffix='%'
                        defaultValue={percentage}
                        decimalsLimit={2}
                        onValueChange={e => changePct((e === undefined) ? 0 : e)}
                        onBlur={() => updatePropertyEdits(labelKey, parseInt(value))}
                    />
                    <CurrencyInput
                        className="mt-3 p-1 w-20 pl-2 border-one rounded-r-md"
                        prefix='$'
                        defaultValue={value}
                        decimalsLimit={2}
                        onValueChange={e => change((e === undefined) ? 0 : e)}
                        onBlur={() => updatePropertyEdits(labelKey, parseInt(value))}
                    />
                </div>
            </div>
        )
    } else if (type === "info") {
        return (
            <div className="flex justify-between">
                <div className='flex justify-center'>
                    <Form.Label className="font-normal mt-4 ml-3">{label}:</Form.Label>
                    <ReactTooltip id={dataId} effect='solid' place='top' delayHide={100} backgroundColor="white" borderColor="black" type="light" className='shadow-xl p-3 bg-white opaque z-50 w-80'>
                        <p className='flex justify-center text-base text-bot-blue mb-2'>{title}</p>
                        <p className='text-sm text-dark-gray mb-2'>{description}</p>
                        <p className='text-sm text-gray mb-2'>{description2}</p>
                    </ReactTooltip>
                    <img data-tip data-for={dataId} className="w-4 mb-3 mt-4 ml-1" src={info_popup} alt="info box"/>
                </div>
                <CurrencyInput
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    suffix='%'
                    defaultValue={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => updatePropertyEdits(labelKey, parseInt(value))}
                />
            </div>
        )   
    } else if (type === "percent") {
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="lg:w-24 w-16 pl-2 mt-3 border-one rounded-sm"
                    suffix='%'
                    defaultValue={value}
                    id={id}
                    onClick={() => handleSelectInput(id)}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => updatePropertyEdits(labelKey, parseFloat(value))}
                />
            </div>
        )
    } else if (type === "custom") {
        // Custom suffix
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    id={id}
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    suffix={suffix}
                    defaultValue={value}
                    onClick={() => handleSelectInput(id)}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => updatePropertyEdits(labelKey, parseFloat(value))}
                />
            </div>
        )
    } else if (type === "calculator") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_percentage") {
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="lg:w-24 w-16 pl-2 mt-3 border-one rounded-sm"
                    suffix='%'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_long") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-32 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_short") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-16 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_number") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-16 pl-2 mt-3 border-one rounded-sm"
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_number_long") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={(e) => handleUpdateProperty(labelKey, (e === undefined) ? 0 : e.target.value)}
                />
            </div>
        )
    } else if (type === "calculator_custom") {
        // Custom suffix
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    suffix={suffix}
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => handleUpdateProperty(labelKey, parseFloat(value))}
                />
            </div>
        )
    } else if (type === "number") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-16 pl-2 mt-3 border-one rounded-sm"
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else if (type === "number_long") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else if (type === "currency") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-20 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else if (type === "currency_long") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-28 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else if (type === "percentage") {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-16 pl-2 mt-3 border-one rounded-sm"
                    suffix='%'
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else if (type === "custom_suffix") {
        // Custom suffix
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    suffix={suffix}
                    value={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                />
            </div>
        )
    } else {
        // Currency
        return (
            <div className="flex justify-between">
                <Form.Label className="font-normal mt-4">{label}:</Form.Label>
                <CurrencyInput
                    id={id}
                    className="w-24 pl-2 mt-3 border-one rounded-sm"
                    prefix='$'
                    onClick={() => handleSelectInput(id)}
                    defaultValue={value}
                    decimalsLimit={2}
                    onValueChange={e => change((e === undefined) ? 0 : e)}
                    onBlur={() => updatePropertyEdits(labelKey, parseInt(value))}
                />
            </div>
        )
    }
};

export default InputBox;